const codeMap = {
  'ABW': 'AW',
  '533': 'AW',
  'AFG': 'AF',
  '004': 'AF',
  'AGO': 'AO',
  '024': 'AO',
  'AIA': 'AI',
  '660': 'AI',
  'ALA': 'AX',
  '248': 'AX',
  'ALB': 'AL',
  '008': 'AL',
  'AND': 'AD',
  '020': 'AD',
  'ARE': 'AE',
  '784': 'AE',
  'ARG': 'AR',
  '032': 'AR',
  'ARM': 'AM',
  '051': 'AM',
  'ASM': 'AS',
  '016': 'AS',
  'ATA': 'AQ',
  '010': 'AQ',
  'ATF': 'TF',
  '260': 'TF',
  'ATG': 'AG',
  '028': 'AG',
  'AUS': 'AU',
  '036': 'AU',
  'AUT': 'AT',
  '040': 'AT',
  'AZE': 'AZ',
  '031': 'AZ',
  'BDI': 'BI',
  '108': 'BI',
  'BEL': 'BE',
  '056': 'BE',
  'BEN': 'BJ',
  '204': 'BJ',
  'BFA': 'BF',
  '854': 'BF',
  'BGD': 'BD',
  '050': 'BD',
  'BGR': 'BG',
  '100': 'BG',
  'BHR': 'BH',
  '048': 'BH',
  'BHS': 'BS',
  '044': 'BS',
  'BIH': 'BA',
  '070': 'BA',
  'BLM': 'BL',
  '652': 'BL',
  'SHN': 'SH',
  '654': 'SH',
  'BLR': 'BY',
  '112': 'BY',
  'BLZ': 'BZ',
  '084': 'BZ',
  'BMU': 'BM',
  '060': 'BM',
  'BOL': 'BO',
  '068': 'BO',
  'BES': 'BQ',
  '535': 'BQ',
  'BRA': 'BR',
  '076': 'BR',
  'BRB': 'BB',
  '052': 'BB',
  'BRN': 'BN',
  '096': 'BN',
  'BTN': 'BT',
  '064': 'BT',
  'BVT': 'BV',
  '074': 'BV',
  'BWA': 'BW',
  '072': 'BW',
  'CAF': 'CF',
  '140': 'CF',
  'CAN': 'CA',
  '124': 'CA',
  'CCK': 'CC',
  '166': 'CC',
  'CHE': 'CH',
  '756': 'CH',
  'CHL': 'CL',
  '152': 'CL',
  'CHN': 'CN',
  '156': 'CN',
  'CIV': 'CI',
  '384': 'CI',
  'CMR': 'CM',
  '120': 'CM',
  'COD': 'CD',
  '180': 'CD',
  'COG': 'CG',
  '178': 'CG',
  'COK': 'CK',
  '184': 'CK',
  'COL': 'CO',
  '170': 'CO',
  'COM': 'KM',
  '174': 'KM',
  'CPV': 'CV',
  '132': 'CV',
  'CRI': 'CR',
  '188': 'CR',
  'CUB': 'CU',
  '192': 'CU',
  'CUW': 'CW',
  '531': 'CW',
  'CXR': 'CX',
  '162': 'CX',
  'CYM': 'KY',
  '136': 'KY',
  'CYP': 'CY',
  '196': 'CY',
  'CZE': 'CZ',
  '203': 'CZ',
  'DEU': 'DE',
  '276': 'DE',
  'DJI': 'DJ',
  '262': 'DJ',
  'DMA': 'DM',
  '212': 'DM',
  'DNK': 'DK',
  '208': 'DK',
  'DOM': 'DO',
  '214': 'DO',
  'DZA': 'DZ',
  '012': 'DZ',
  'ECU': 'EC',
  '218': 'EC',
  'EGY': 'EG',
  '818': 'EG',
  'ERI': 'ER',
  '232': 'ER',
  'ESH': 'EH',
  '732': 'EH',
  'ESP': 'ES',
  '724': 'ES',
  'EST': 'EE',
  '233': 'EE',
  'ETH': 'ET',
  '231': 'ET',
  'FIN': 'FI',
  '246': 'FI',
  'FJI': 'FJ',
  '242': 'FJ',
  'FLK': 'FK',
  '238': 'FK',
  'FRA': 'FR',
  '250': 'FR',
  'FRO': 'FO',
  '234': 'FO',
  'FSM': 'FM',
  '583': 'FM',
  'GAB': 'GA',
  '266': 'GA',
  'GBR': 'GB',
  '826': 'GB',
  'GEO': 'GE',
  '268': 'GE',
  'GGY': 'GG',
  '831': 'GG',
  'GHA': 'GH',
  '288': 'GH',
  'GIB': 'GI',
  '292': 'GI',
  'GIN': 'GN',
  '324': 'GN',
  'GLP': 'GP',
  '312': 'GP',
  'GMB': 'GM',
  '270': 'GM',
  'GNB': 'GW',
  '624': 'GW',
  'GNQ': 'GQ',
  '226': 'GQ',
  'GRC': 'GR',
  '300': 'GR',
  'GRD': 'GD',
  '308': 'GD',
  'GRL': 'GL',
  '304': 'GL',
  'GTM': 'GT',
  '320': 'GT',
  'GUF': 'GF',
  '254': 'GF',
  'GUM': 'GU',
  '316': 'GU',
  'GUY': 'GY',
  '328': 'GY',
  'HKG': 'HK',
  '344': 'HK',
  'HMD': 'HM',
  '334': 'HM',
  'HND': 'HN',
  '340': 'HN',
  'HRV': 'HR',
  '191': 'HR',
  'HTI': 'HT',
  '332': 'HT',
  'HUN': 'HU',
  '348': 'HU',
  'IDN': 'ID',
  '360': 'ID',
  'IMN': 'IM',
  '833': 'IM',
  'IND': 'IN',
  '356': 'IN',
  'IOT': 'IO',
  '086': 'IO',
  'IRL': 'IE',
  '372': 'IE',
  'IRN': 'IR',
  '364': 'IR',
  'IRQ': 'IQ',
  '368': 'IQ',
  'ISL': 'IS',
  '352': 'IS',
  'ISR': 'IL',
  '376': 'IL',
  'ITA': 'IT',
  '380': 'IT',
  'JAM': 'JM',
  '388': 'JM',
  'JEY': 'JE',
  '832': 'JE',
  'JOR': 'JO',
  '400': 'JO',
  'JPN': 'JP',
  '392': 'JP',
  'KAZ': 'KZ',
  '398': 'KZ',
  'KEN': 'KE',
  '404': 'KE',
  'KGZ': 'KG',
  '417': 'KG',
  'KHM': 'KH',
  '116': 'KH',
  'KIR': 'KI',
  '296': 'KI',
  'KNA': 'KN',
  '659': 'KN',
  'KOR': 'KR',
  '410': 'KR',
  'UNK': 'XK',
  '': 'XK',
  'KWT': 'KW',
  '414': 'KW',
  'LAO': 'LA',
  '418': 'LA',
  'LBN': 'LB',
  '422': 'LB',
  'LBR': 'LR',
  '430': 'LR',
  'LBY': 'LY',
  '434': 'LY',
  'LCA': 'LC',
  '662': 'LC',
  'LIE': 'LI',
  '438': 'LI',
  'LKA': 'LK',
  '144': 'LK',
  'LSO': 'LS',
  '426': 'LS',
  'LTU': 'LT',
  '440': 'LT',
  'LUX': 'LU',
  '442': 'LU',
  'LVA': 'LV',
  '428': 'LV',
  'MAC': 'MO',
  '446': 'MO',
  'MAF': 'MF',
  '663': 'MF',
  'MAR': 'MA',
  '504': 'MA',
  'MCO': 'MC',
  '492': 'MC',
  'MDA': 'MD',
  '498': 'MD',
  'MDG': 'MG',
  '450': 'MG',
  'MDV': 'MV',
  '462': 'MV',
  'MEX': 'MX',
  '484': 'MX',
  'MHL': 'MH',
  '584': 'MH',
  'MKD': 'MK',
  '807': 'MK',
  'MLI': 'ML',
  '466': 'ML',
  'MLT': 'MT',
  '470': 'MT',
  'MMR': 'MM',
  '104': 'MM',
  'MNE': 'ME',
  '499': 'ME',
  'MNG': 'MN',
  '496': 'MN',
  'MNP': 'MP',
  '580': 'MP',
  'MOZ': 'MZ',
  '508': 'MZ',
  'MRT': 'MR',
  '478': 'MR',
  'MSR': 'MS',
  '500': 'MS',
  'MTQ': 'MQ',
  '474': 'MQ',
  'MUS': 'MU',
  '480': 'MU',
  'MWI': 'MW',
  '454': 'MW',
  'MYS': 'MY',
  '458': 'MY',
  'MYT': 'YT',
  '175': 'YT',
  'NAM': 'NA',
  '516': 'NA',
  'NCL': 'NC',
  '540': 'NC',
  'NER': 'NE',
  '562': 'NE',
  'NFK': 'NF',
  '574': 'NF',
  'NGA': 'NG',
  '566': 'NG',
  'NIC': 'NI',
  '558': 'NI',
  'NIU': 'NU',
  '570': 'NU',
  'NLD': 'NL',
  '528': 'NL',
  'NOR': 'NO',
  '578': 'NO',
  'NPL': 'NP',
  '524': 'NP',
  'NRU': 'NR',
  '520': 'NR',
  'NZL': 'NZ',
  '554': 'NZ',
  'OMN': 'OM',
  '512': 'OM',
  'PAK': 'PK',
  '586': 'PK',
  'PAN': 'PA',
  '591': 'PA',
  'PCN': 'PN',
  '612': 'PN',
  'PER': 'PE',
  '604': 'PE',
  'PHL': 'PH',
  '608': 'PH',
  'PLW': 'PW',
  '585': 'PW',
  'PNG': 'PG',
  '598': 'PG',
  'POL': 'PL',
  '616': 'PL',
  'PRI': 'PR',
  '630': 'PR',
  'PRK': 'KP',
  '408': 'KP',
  'PRT': 'PT',
  '620': 'PT',
  'PRY': 'PY',
  '600': 'PY',
  'PSE': 'PS',
  '275': 'PS',
  'PYF': 'PF',
  '258': 'PF',
  'QAT': 'QA',
  '634': 'QA',
  'REU': 'RE',
  '638': 'RE',
  'ROU': 'RO',
  '642': 'RO',
  'RUS': 'RU',
  '643': 'RU',
  'RWA': 'RW',
  '646': 'RW',
  'SAU': 'SA',
  '682': 'SA',
  'SDN': 'SD',
  '729': 'SD',
  'SEN': 'SN',
  '686': 'SN',
  'SGP': 'SG',
  '702': 'SG',
  'SGS': 'GS',
  '239': 'GS',
  'SJM': 'SJ',
  '744': 'SJ',
  'SLB': 'SB',
  '090': 'SB',
  'SLE': 'SL',
  '694': 'SL',
  'SLV': 'SV',
  '222': 'SV',
  'SMR': 'SM',
  '674': 'SM',
  'SOM': 'SO',
  '706': 'SO',
  'SPM': 'PM',
  '666': 'PM',
  'SRB': 'RS',
  '688': 'RS',
  'SSD': 'SS',
  '728': 'SS',
  'STP': 'ST',
  '678': 'ST',
  'SUR': 'SR',
  '740': 'SR',
  'SVK': 'SK',
  '703': 'SK',
  'SVN': 'SI',
  '705': 'SI',
  'SWE': 'SE',
  '752': 'SE',
  'SWZ': 'SZ',
  '748': 'SZ',
  'SXM': 'SX',
  '534': 'SX',
  'SYC': 'SC',
  '690': 'SC',
  'SYR': 'SY',
  '760': 'SY',
  'TCA': 'TC',
  '796': 'TC',
  'TCD': 'TD',
  '148': 'TD',
  'TGO': 'TG',
  '768': 'TG',
  'THA': 'TH',
  '764': 'TH',
  'TJK': 'TJ',
  '762': 'TJ',
  'TKL': 'TK',
  '772': 'TK',
  'TKM': 'TM',
  '795': 'TM',
  'TLS': 'TL',
  '626': 'TL',
  'TON': 'TO',
  '776': 'TO',
  'TTO': 'TT',
  '780': 'TT',
  'TUN': 'TN',
  '788': 'TN',
  'TUR': 'TR',
  '792': 'TR',
  'TUV': 'TV',
  '798': 'TV',
  'TWN': 'TW',
  '158': 'TW',
  'TZA': 'TZ',
  '834': 'TZ',
  'UGA': 'UG',
  '800': 'UG',
  'UKR': 'UA',
  '804': 'UA',
  'UMI': 'UM',
  '581': 'UM',
  'URY': 'UY',
  '858': 'UY',
  'USA': 'US',
  '840': 'US',
  'UZB': 'UZ',
  '860': 'UZ',
  'VAT': 'VA',
  '336': 'VA',
  'VCT': 'VC',
  '670': 'VC',
  'VEN': 'VE',
  '862': 'VE',
  'VGB': 'VG',
  '092': 'VG',
  'VIR': 'VI',
  '850': 'VI',
  'VNM': 'VN',
  '704': 'VN',
  'VUT': 'VU',
  '548': 'VU',
  'WLF': 'WF',
  '876': 'WF',
  'WSM': 'WS',
  '882': 'WS',
  'YEM': 'YE',
  '887': 'YE',
  'ZAF': 'ZA',
  '710': 'ZA',
  'ZMB': 'ZM',
  '894': 'ZM',
  'ZWE': 'ZW',
  '716': 'ZW'
}

export const getAlphaTwoCode = code => codeMap[String(code).toUpperCase()] ?? String(code).toUpperCase()