// prettier-ignore
import { ArrowLeftOutlined, ExclamationCircleTwoTone, PlayCircleOutlined, PrinterOutlined, QuestionCircleOutlined, SaveOutlined, SwapOutlined } from '@ant-design/icons';
import { ProCard, StatisticCard } from '@ant-design/pro-components';
import { useRequest } from 'ahooks';
import { Affix, Alert, Badge, Button, Card, Drawer, Dropdown, Popconfirm, Popover, Space, Tabs } from 'antd';
import cn from 'classnames';
import _ from 'lodash';
import { useContext, useEffect, useState } from 'react';

import { Block } from '@/components/Block';
import { LinkButton } from '@/components/LinkButton';
import { PRINTER_TYPE } from '@/configs/entity';
import { COLOR, ROUTE } from '@/configs/general';
import { onError } from '@/helpers/Alert';
import { useGoTo } from '@/helpers/hooks';
import { statisticString } from '@/helpers/ui';
import { userContext } from '@/helpers/userContext';
import { LogisticsModel } from '@/models/logistics';
import { RequestModel } from '@/models/request';

import { ACCESSORIES, ACTION, ACTION_LABEL, TASK_RESULT, TASK_RESULT_STATUS, TASK_TYPE_LABEL } from '../configs';
import { logisticsContext } from '../logisticsContext';
import styles from '../styles.module.scss';
import { AddCommentForm } from './AddCommentForm';
import { ArriveForm } from './ArriveForm';
import { CaseInfo } from './CaseInfo';
import { ContactDetails } from './ContactDetails';
import { CostProposalsView } from './CostProposalsView';
import { FilesView } from './FilesView';
import { ForwardForm } from './ForwardForm';
import { RerouteForm } from './RerouteForm';
import { ReturnForm } from './ReturnForm';
import { SetStatusForm } from './SetStatusForm';
import { TaskTimeline } from './TaskTimeline';

const { Statistic } = StatisticCard;

const FORM_INDEPENDENT_ACTIONS = [ACTION.ADD_COMMENT, ACTION.SET_STATUS, ACTION.ADD_COST_PROPOSALS];
const ROUTING_INDEPENDENT_ACTIONS = [ACTION.ADD_COMMENT, ACTION.SET_STATUS, ACTION.SAVE, ACTION.ADD_COST_PROPOSALS];
const ACTION_CONFIRMATION_TYPE = {
  NO_ACCESSORIES: 'no accessories',
  SAME_ACTION: 'same action'
};

const actionsMenuItems = Object.values(ACTION)
  .filter((action) => !ROUTING_INDEPENDENT_ACTIONS.includes(action))
  .map((action) => ({
    key: action,
    label: ACTION_LABEL[action]
  }));

const ViewCase = ({ caseData, warehouseData, suggestedAction, allTasks, onExecuteAction, onReload }) => {
  const { goBack, simplifiedFreeTextSearchArriving } = RequestModel.parseUrlQuery(window.location.search);
  const shipmentKeys = Object.keys(caseData.orderData).filter((x) => x.toLowerCase().includes('shipmentdata'));
  const [selectedMacroAction, selectMacroAction] = useState(
    simplifiedFreeTextSearchArriving ? ACTION.ADD_COMMENT : null
  );
  const [confirmActionProps, setConfirmActionProps] = useState(null);
  const {
    productTypeId,
    manufacturerId,
    serviceTypeId,
    formValues,
    routingData: { mergedData: modifiedRoutingData },
    mergeRoutingData,
    setRoutingDataError,
    informAboutAccessories
  } = useContext(logisticsContext);
  const goToShipment = useGoTo(goBack);
  const {
    user: { id: userId, canForwardActivities, canPrintLabels, printers }
  } = useContext(userContext);

  const getRouting = useRequest(
    () =>
      LogisticsModel.getRouting(caseData.guid, {
        serviceTypeId,
        manufacturerId,
        productTypeId
      }),
    {
      initialData: {},
      throwOnError: false,
      ready: Boolean(serviceTypeId && manufacturerId && productTypeId),
      refreshDeps: [serviceTypeId, manufacturerId, productTypeId],
      onError: () => setRoutingDataError(),
      onSuccess: (result) => {
        mergeRoutingData(caseData.orderData?.providerSpecific?.elcare, result?.serviceProviderCompetence);
      }
    }
  );
  const printLabel = useRequest(() => LogisticsModel.printLabel(caseData.guid), {
    manual: true,
    onError
  });

  const closeActionConfirmation = () => {
    setConfirmActionProps(null);
  };

  const startSelectedAction = (action) => {
    if (!action && !confirmActionProps?.action) {
      return;
    }

    const actionToStart = action || confirmActionProps?.action;
    const taskTypeOfAction = _.findKey(TASK_TYPE_LABEL, (label) => label.toLowerCase() === actionToStart.toLowerCase());

    if (confirmActionProps?.type !== ACTION_CONFIRMATION_TYPE.SAME_ACTION) {
      for (const task of allTasks) {
        if (
          !task.type.includes(taskTypeOfAction) ||
          task.userId !== userId ||
          TASK_RESULT_STATUS[task.status] !== TASK_RESULT.SUCCESS
        ) {
          continue;
        }

        setConfirmActionProps({
          type: ACTION_CONFIRMATION_TYPE.SAME_ACTION,
          action: actionToStart,
          title: 'Warning',
          description:
            'You’ve already done this logistic operation on this case before. Do you want to do the same operation again?',
          showCancel: false
        });

        return;
      }
    }

    selectMacroAction(actionToStart);
    closeActionConfirmation();
  };

  const handleSelectedMicroAction = () => {
    startSelectedAction();
  };

  useEffect(() => {
    if (!confirmActionProps) {
      return;
    }

    if (_.isEmpty(formValues?.productData?.otherAccessory)) {
      setConfirmActionProps({
        ...confirmActionProps,
        okButtonProps: { disabled: true }
      });
    } else if (Object.values(ACCESSORIES).includes(formValues?.productData?.otherAccessory)) {
      startSelectedAction();
    } else {
      setConfirmActionProps({
        ...confirmActionProps,
        okButtonProps: null
      });
    }
  }, [formValues?.productData?.otherAccessory]);

  const closeDrawer = () => selectMacroAction(null);

  const routingData = getRouting.data;
  const canBeProcessed = !_.isEmpty(routingData) && !getRouting.loading;
  const competence = routingData?.serviceProviderCompetence || {};

  const processCase = (macroAction, macroActionValues = {}) => {
    closeDrawer();

    const dataToProcess = {
      providerId: routingData?.serviceProvider?.id,
      coreValues: { productTypeId, manufacturerId, serviceTypeId },
      guid: caseData.guid,
      task: macroAction,
      informAboutAccessories,
      ..._.omit(macroActionValues, 'routingData')
    };

    let routingCaseData = {
      orderData: {
        providerSpecific: {
          elcare: {
            gref: competence.gref,
            spec: competence.spec,
            ...(macroActionValues?.routingData || modifiedRoutingData)
          }
        }
      }
    };

    if (ROUTING_INDEPENDENT_ACTIONS.includes(macroAction)) {
      routingCaseData = {};
    }

    if (macroActionValues.caseData || !_.isEmpty(routingCaseData)) {
      dataToProcess.caseData = _.merge(routingCaseData, macroActionValues.caseData);
    }

    if (!FORM_INDEPENDENT_ACTIONS.includes(macroAction)) {
      dataToProcess.caseData = _.merge(dataToProcess.caseData, formValues);

      if (macroAction === ACTION.SAVE) {
        dataToProcess.caseData = _.omit(dataToProcess.caseData, 'productData.otherAccessory');
      }
    }

    onExecuteAction(dataToProcess);
  };

  const openDrawer = ({ key: macroAction }) => {
    const action = macroAction || suggestedAction;

    if (!ROUTING_INDEPENDENT_ACTIONS.includes(action) && !formValues?.productData?.otherAccessory) {
      setConfirmActionProps({
        type: ACTION_CONFIRMATION_TYPE.NO_ACCESSORIES,
        action,
        title: 'Error',
        icon: (
          <QuestionCircleOutlined
            style={{
              color: 'red'
            }}
          />
        ),
        description:
          'You must provide accessory information. Once you select the accessories, the logistic operation will be continued. Or you can interrupt it by clicking ‘cancel’',
        okButtonProps: {
          disabled: true
        }
      });

      return;
    }

    if (simplifiedFreeTextSearchArriving && action === ACTION.ARRIVE) {
      const labelsCount = (printers || []).some(({ settings }) => settings?.type === PRINTER_TYPE.SMALL_LABEL) ? 1 : 0;

      processCase(action, { alsoAssign: true, labelsCount });

      return;
    }

    startSelectedAction(action);
  };

  const saveCase = () => {
    processCase(ACTION.SAVE);
  };

  const handleSetStatusComment = () => {
    selectMacroAction(ACTION.SET_STATUS);
  };

  const handleAddComment = () => {
    selectMacroAction(ACTION.ADD_COMMENT);
  };

  const tabPanes = [
    {
      key: 'overview',
      label: 'Overview',
      children: (
        <Block>
          <CaseInfo
            caseData={caseData}
            warehouseData={warehouseData}
            getRouting={getRouting}
            onAddComment={handleAddComment}
          />
        </Block>
      )
    },
    {
      key: 'shipments',
      label: 'Shipments',
      children: (
        <Block>
          <ProCard gutter={12} size="small">
            {shipmentKeys.map((key) => (
              <ProCard
                key={key}
                title={
                  <LinkButton to={`${ROUTE.LOGISTICS_SHIPMENT}/${caseData.orderData[key]?.guid}`}>
                    {_.capitalize(key.replace(/shipmentData/i, '') || 'Inbound')}
                  </LinkButton>
                }
                bordered
                headerBordered
                size="small"
                type="inner"
                colSpan={24 / shipmentKeys.length}
              >
                <StatisticCard className="value-over-title" size="small">
                  <Statistic title="Package number" value={statisticString(caseData.orderData[key].packageNumber)} />
                  <Statistic title="Shipment number" value={statisticString(caseData.orderData[key].shipmentNumber)} />
                </StatisticCard>
              </ProCard>
            ))}
            {!shipmentKeys.length && <Alert type="info" message="No shipments yet" />}
          </ProCard>
        </Block>
      )
    },
    {
      key: 'contactDetails',
      label: 'Contact details',
      children: (
        <Block>
          <ContactDetails caseData={caseData} />
        </Block>
      )
    },
    {
      key: 'costProposals',
      label: 'Cost proposals',
      children: (
        <Block>
          <CostProposalsView caseData={caseData} onFinish={processCase} />
        </Block>
      )
    },
    {
      key: 'files',
      label: 'Files',
      children: (
        <Block>
          <FilesView caseData={caseData} onReload={onReload} />
        </Block>
      )
    }
  ];

  const sortedTasks = _.sortBy(allTasks, (x) => -x.id);
  const tasksByType = _.groupBy(sortedTasks, (x) => _.last(x.type.split('_')));

  for (const group of Object.values(tasksByType)) {
    const lastTask = group[0];
    const taskType = _.last(lastTask.type.split('_'));

    tabPanes.push({
      key: lastTask.type,
      label: (
        <Badge status={TASK_RESULT_STATUS[lastTask.status] || 'default'} text={TASK_TYPE_LABEL[taskType] || taskType} />
      ),
      children: (
        <Block>
          <TaskTimeline group={group} />
        </Block>
      )
    });
  }

  const coreValues = {
    serviceTypeId,
    manufacturerId,
    productTypeId,
    serviceProviderId: routingData?.serviceProvider?.id || caseData.serviceProviderId
  };

  return (
    <>
      {!canForwardActivities && (
        <Alert
          message="You can't forward activities"
          description={
            <>
              The department settings is incorrect. Ask your supervisor to fix them, or do it yourself on{' '}
              <LinkButton to={ROUTE.MY_PAGE}>My page</LinkButton>
            </>
          }
          type="error"
          style={{ margin: 20 }}
        />
      )}
      <Tabs
        defaultActiveKey="overview"
        type="card"
        className={cn(styles.caseInfo, 'tabs-with-block', 'no-margin')}
        items={tabPanes}
      />
      <Drawer
        title={ACTION_LABEL[selectedMacroAction]}
        open={selectedMacroAction}
        width={[ACTION.FORWARD, ACTION.RETURN, ACTION.REROUTE].includes(selectedMacroAction) ? 550 : 300}
        destroyOnClose
        onClose={closeDrawer}
      >
        {selectedMacroAction === ACTION.ARRIVE && (
          <ArriveForm onFinish={processCase} caseData={caseData} serviceProvider={routingData?.serviceProvider} />
        )}
        {selectedMacroAction === ACTION.FORWARD && (
          <ForwardForm
            caseData={caseData}
            coreValues={coreValues}
            serviceProvider={routingData?.serviceProvider}
            inboundCompetence={competence}
            onFinish={processCase}
          />
        )}
        {selectedMacroAction === ACTION.RETURN && (
          <ReturnForm
            caseData={caseData}
            coreValues={coreValues}
            formValues={formValues}
            serviceProvider={routingData?.serviceProvider}
            outboundCompetence={routingData.outboundCompetence}
            tasks={sortedTasks}
            onFinish={processCase}
          />
        )}
        {selectedMacroAction === ACTION.SET_STATUS && <SetStatusForm caseData={caseData} onFinish={processCase} />}
        {selectedMacroAction === ACTION.ADD_COMMENT && <AddCommentForm onFinish={processCase} />}
        {selectedMacroAction === ACTION.REROUTE && (
          <RerouteForm
            caseData={caseData}
            coreValues={coreValues}
            serviceProvider={routingData?.serviceProvider}
            outboundCompetence={routingData?.outboundCompetence}
            routingData={modifiedRoutingData}
            onFinish={processCase}
          />
        )}
      </Drawer>
      <Affix offsetBottom={0} className="activity-actions-panel">
        <Card className="ant-card-full-width ant-card-gutter">
          <Space>
            {goBack && (
              <Button onClick={goToShipment} type="link">
                <ArrowLeftOutlined /> Go back to the shipment
              </Button>
            )}
            {!getRouting.loading && (
              <>
                <Popconfirm
                  open
                  overlayStyle={{
                    width: 400,
                    display: confirmActionProps ? 'block' : 'none'
                  }}
                  placement="bottomLeft"
                  {...confirmActionProps}
                  onCancel={closeActionConfirmation}
                  okText="Continue"
                  onConfirm={handleSelectedMicroAction}
                >
                  {canBeProcessed && (
                    <Dropdown.Button
                      type="primary"
                      placement="top"
                      onClick={openDrawer}
                      menu={{
                        items: actionsMenuItems,
                        onClick: openDrawer
                      }}
                    >
                      <PlayCircleOutlined /> {ACTION_LABEL[suggestedAction]}
                    </Dropdown.Button>
                  )}
                  {!canBeProcessed && (
                    <Button type="primary" onClick={() => openDrawer({ key: ACTION.REROUTE })}>
                      {ACTION_LABEL[ACTION.REROUTE]}
                    </Button>
                  )}
                </Popconfirm>
                {!canBeProcessed && (
                  <Alert
                    type="error"
                    message="No business rules for logistic operations found"
                    showIcon
                    style={{ padding: '4px 8px' }}
                  />
                )}
              </>
            )}
            <Button icon={<SwapOutlined />} onClick={handleSetStatusComment}>
              {ACTION_LABEL[ACTION.SET_STATUS]}
            </Button>
            <Popover
              content={
                formValues?.productData?.otherAccessory ? (
                  <div>
                    <ExclamationCircleTwoTone
                      twoToneColor={COLOR.GOLD}
                      style={{ margin: '0 8px 0 0', fontSize: 18, verticalAlign: 'sub' }}
                    />
                    Accessories are processed only during logistic operations, your value will not be saved
                  </div>
                ) : null
              }
              trigger="hover"
            >
              <Button disabled={_.isEmpty(formValues)} icon={<SaveOutlined />} onClick={saveCase}>
                {ACTION_LABEL[ACTION.SAVE]}
              </Button>
            </Popover>
            {canPrintLabels && (
              <Button icon={<PrinterOutlined />} loading={printLabel.loading} onClick={printLabel.run}>
                Print label
              </Button>
            )}
          </Space>
        </Card>
      </Affix>
    </>
  );
};

export { ViewCase };
