import { useRequest } from 'ahooks';
import { Alert, Button, Checkbox, Drawer, Input, Select, Space, TreeSelect } from 'antd';
import _ from 'lodash';
import { useState } from 'react';

import { onError } from '@/helpers/Alert';
import { RequestModel } from '@/models/request';

let carriersDict = {};

const ShipmentTypeImporter = (props) => {
  const { visible, onClose, onSuccess } = props;

  const [actor, setActor] = useState();
  const [productId, setProductId] = useState();
  const productData = carriersDict[productId];
  const [account, setAccount] = useState();
  const [sesamId, setSesamId] = useState();
  const [services, setServices] = useState([]);
  const [typeAlert, setTypeAlert] = useState();

  const handleProduct = (id) => {
    setProductId(id);
    setServices([]);
  };

  // We need this request only to avoid duplicated configs
  const actorsRequest = useRequest(() => RequestModel.getJsonData(`/api/v1/shipment/consignor-actors`), {
    initialData: [],
    formatResult: (data) => data.map(({ id, name }) => ({ value: id, label: name }))
  });

  const consignorRequest = useRequest(() => RequestModel.getJson(`/api/v1/shipment/consignor-products/${actor}`), {
    initialData: [],
    refreshDeps: [actor],
    ready: Boolean(actor),
    formatResult: ({ data }) => {
      carriersDict = {};
      const newData = data.map((carrier) => ({
        value: carrier.CarrierCSID,
        title: (
          <>
            <img src={`data:image/png;base64, ${carrier.Icon}`} style={{ height: '1em' }} alt="icon" />{' '}
            {carrier.CarrierFullName}
          </>
        ),
        selectable: false,
        children: carrier.Subcarriers.map((subcarrier) => ({
          value: subcarrier.SubcarrierCSID,
          title: subcarrier.SubcarrierName,
          selectable: false,
          children: subcarrier.Products.map((product) => {
            // Fill it here as well to get an easy access to the props
            carriersDict[product.ProdCSID] = {
              prodCSID: product.ProdCSID,
              prodConceptID: product.ProdConceptID,
              services: product.Services,
              name: `${carrier.CarrierShortName} ${subcarrier.SubcarrierName} ${product.ProdName}`
            };

            return {
              value: product.ProdCSID,
              title: product.ProdName
            };
          })
        }))
      }));

      return newData;
    },
    onError
  });

  const similarTypeRequest = useRequest(
    () =>
      RequestModel.postJson('/api/v1/shipment/similar-shipment-type', {
        body: {
          prodCSID: productData.prodCSID,
          actorCSID: actor,
          prodConceptID: productData.prodConceptID,
          payerAccountAtCarrier: account,
          senderAccountAtCarrier: account,
          services
        }
      }),
    {
      initialData: { data: {} },
      onSuccess: async ({ data }) => {
        if (data) {
          setTypeAlert(`Similar shipment type already exists: ${data.name} [#${data.id}]`);
        } else {
          setTypeAlert(undefined);
          const { data: preFill } = await RequestModel.getJson('/api/v1/shipment/shipment-type-default-settings', {
            query: { actorId: actor }
          });
          const result = _.merge(
            {
              name: productData.name,
              settings: {
                prodCSID: productData.prodCSID,
                actorCSID: actor,
                prodConceptID: productData.prodConceptID,
                payerAccountAtCarrier: account,
                senderAccountAtCarrier: account,
                sesamId,
                services
              }
            },
            preFill
          );
          onSuccess(result);
        }
      },
      manual: true,
      onError
    }
  );

  const handleContinue = () => {
    similarTypeRequest.run();
  };

  return (
    <Drawer title="Import shipment type" open={visible} onClose={onClose} width={720} destroyOnClose>
      <Space direction="vertical">
        <Space direction="vertical">
          <b>Actor / country:</b>
          <Select
            loading={actorsRequest.loading}
            className="full-width"
            options={actorsRequest.data}
            value={actor}
            onChange={setActor}
            placeholder="Select actor / country"
          />
        </Space>
        <Space direction="vertical">
          <b>Product:</b>
          <TreeSelect
            loading={consignorRequest.loading}
            disabled={!actor}
            treeData={consignorRequest.data}
            className="full-width"
            placeholder="Select product"
            treeExpandAction="click"
            value={productId}
            onSelect={handleProduct}
          />
        </Space>
        <Space direction="vertical">
          <b>Customer number:</b>
          <Input value={account} onChange={(e) => setAccount(e.target.value)} />
        </Space>
        <Space direction="vertical">
          <b>Sesam Id:</b>
          <Input value={sesamId} onChange={(e) => setSesamId(e.target.value)} />
        </Space>
        <Space direction="vertical">
          <b>Services:</b>
          <Checkbox.Group value={services} onChange={setServices}>
            {(productData?.services || []).map((x) => (
              <Checkbox value={x.ServiceID} key={x.ServiceID} className="full-width">
                {x.ServiceName} ({x.ServiceID})
              </Checkbox>
            ))}
          </Checkbox.Group>
        </Space>
        {typeAlert && <Alert type="warning" showIcon message={typeAlert} />}
        <Button
          type="primary"
          disabled={consignorRequest.loading || similarTypeRequest.loading || !productId || !productData}
          onClick={handleContinue}
          loading={similarTypeRequest.loading}
        >
          Continue
        </Button>
      </Space>
    </Drawer>
  );
};

export { ShipmentTypeImporter };
