import { useDebounceEffect } from 'ahooks';
import { Input } from 'antd';
import _ from 'lodash';
import { useState } from 'react';

const AutoSearchInput = (props) => {
  const [searchValue, setSearchValue] = useState(props?.value ?? '');
  const [previousSearchValue, setPreviousSearchValue] = useState(searchValue);

  useDebounceEffect(
    () => {
      setPreviousSearchValue(searchValue);

      if (
        !props.onSearch ||
        !searchValue?.length ||
        (Math.abs(searchValue.length - previousSearchValue?.length ?? 0) < 5 && searchValue !== previousSearchValue)
      ) {
        return;
      }

      props.onSearch(searchValue);
    },
    [searchValue],
    {
      wait: 100
    }
  );

  const handleSearch = (value, __, { source }) => {
    if (source === 'clear' || !props.onSearch) {
      return;
    }

    props.onSearch(value);
  };

  const handleSearchChange = (e) => {
    const value = _.trim(e.target.value);
    setSearchValue(value);

    if (props.onChange) {
      props.onChange(value);
    }
  };

  return <Input.Search {...props} onChange={handleSearchChange} onSearch={handleSearch} value={searchValue} />;
};

export { AutoSearchInput };
