import { PageHeader } from '@ant-design/pro-layout';

import { SHIPMENTS_TYPE } from '@/configs/shipment';
import { ShipmentsTable } from '@/containers/shipmentsTable/ShipmentsTable';

const ReturnedShipmentsPage = () => {
  return (
    <>
      <PageHeader title="Returned shipments" />
      <ShipmentsTable type={SHIPMENTS_TYPE.RETURNED} showOnlyMyShipments />
    </>
  );
};

export { ReturnedShipmentsPage };
