import { Input, Modal } from 'antd';
import _ from 'lodash';
import { useState } from 'react';

const InputPrompt = (props) => {
  const { modalProps, onOk, onClose, title, message } = props;
  const [value, setValue] = useState('');

  const handleValue = (e) => setValue(_.trim(e.target.value));

  const handleClose = () => {
    setValue('');
    onClose();
  };

  const handleOk = () => {
    if (!value.length) {
      return;
    }
    onOk(value);
    handleClose();
  };

  return (
    <Modal
      title={title}
      {...modalProps}
      okText="Ok"
      cancelText="Cancel"
      onOk={handleOk}
      okButtonProps={{ disabled: !value.length }}
      onCancel={handleClose}
      onClose={handleClose}
    >
      {message && <p>{message}</p>}
      <Input value={value} onChange={handleValue} onPressEnter={handleOk} />
    </Modal>
  );
};

export { InputPrompt };
