import { useRequest } from 'ahooks';
import { Alert, Button, Space } from 'antd';
import _ from 'lodash';
import { useState } from 'react';

import { SelectAutocomplete } from '@/components/SelectAutocomplete';
import { LogisticsModel } from '@/models/logistics';

import { ACTION } from '../configs';

const SetStatusForm = ({ caseData, onFinish }) => {
  const [status, setStatus] = useState((caseData.currentStatus || '').toLowerCase());

  const possibleStatuses = useRequest(() => LogisticsModel.getPossibleStatuses(caseData.guid), {
    formatResult: (data) =>
      _.sortBy(
        data.map(({ key, name }) => ({ label: `${key}${name ? ` - ${name}` : ''}`, value: key })),
        'name'
      ),
    throwOnError: true
  });

  const handleStatusChange = () =>
    onFinish(ACTION.SET_STATUS, {
      status
    });

  return (
    <>
      <Alert
        message="This status change tool can be used only if you simply need to force-change the status. It must not be used for setting statuses 'ass', 'arr', 'wext', or 'ret' if you arrive, forward or return devices. Use the big blue button on the left side of the page's bottom to perform the complete Arrival, Forwarding or Returning task with all included routines and operations."
        type="warning"
        style={{ marginTop: 0 }}
      />
      <Space className="full-width" direction="vertical">
        <label>New status:</label>
        <SelectAutocomplete
          className="full-width"
          disabled={possibleStatuses.loading}
          loading={possibleStatuses.loading}
          options={possibleStatuses.data}
          value={possibleStatuses.loading ? null : status}
          onSelect={setStatus}
          skipMapping
        />
        <Button disabled={!status || status === caseData.currentStatus} type="primary" onClick={handleStatusChange}>
          Set status
        </Button>
      </Space>
    </>
  );
};

export { SetStatusForm };
