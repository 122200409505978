import { CheckCard } from '@ant-design/pro-components';
import { FlagDK, FlagFI, FlagNO, FlagSE } from '@weston/react-world-flags';
import { useRequest } from 'ahooks';
// prettier-ignore
import { Alert, Button, Checkbox, Col, Collapse, DatePicker, Divider, Empty, Flex, Input, List, Radio, Row, Select, Space, Spin, Tag } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import { useState } from 'react';

import { DateInline } from '@/components/DateInline';
import { PrintButton } from '@/components/PrintButton';
import { PRINTER_TYPE } from '@/configs/entity';
import { ContainersModel } from '@/models/containers';
import { LogisticsModel } from '@/models/logistics';
import { ShipmentModel } from '@/models/shipment';

import { ACTION, TASK_TYPE } from '../configs';
import styles from '../styles.module.scss';
import { SelectAnyShippingMethod } from './SelectAnyShippingMethod';

const { addressToString, hubToSenderellaContacts } = ShipmentModel;
const NEW_SHIPMENT_GUID = 'new';

const getReturnAddresses = (caseData) => {
  return [
    { value: 'receiver', label: `${addressToString(hubToSenderellaContacts(caseData.receiver))} (return)` },
    { value: 'sender', label: `${addressToString(hubToSenderellaContacts(caseData.sender))} (sender)` },
    { value: 'customer', label: `${addressToString(hubToSenderellaContacts(caseData.customer))} (customer)` },
    { value: 'consumer', label: `${addressToString(hubToSenderellaContacts(caseData.consumer))} (end customer)` }
  ];
};

const LOCALE = {
  SE: {
    id: 'sv-se',
    title: 'Swedish',
    flag: FlagSE
  },
  NO: {
    id: 'nb-no',
    title: 'Norwegian',
    flag: FlagNO
  },
  DK: {
    id: 'da-dk',
    title: 'Danish',
    flag: FlagDK
  },
  FI: {
    id: 'fi-fi',
    title: 'Finnish',
    flag: FlagFI
  }
};

const countryToLocale = (code) => LOCALE[code].id || 'en';

const ReturnForm = (props) => {
  const { caseData, coreValues, formValues, outboundCompetence = {}, serviceProvider, tasks = [], onFinish } = props;
  const returnAddresses = getReturnAddresses(caseData);
  const [shipmentTypeId, setShipmentTypeId] = useState();
  const [containerId, setContainerId] = useState();
  const [disableContainer, setDisableContainer] = useState(false);
  const [bundleToShipment, setBundleTo] = useState(NEW_SHIPMENT_GUID);
  const [availableShipmentsOptions, setAvailableShipmentsOptions] = useState([]);
  const [customerCopyCountryCode, setCustomerCopyCountryCode] = useState(
    caseData.customer.countryCode?.toUpperCase?.()
  );
  const [addressType, setAddressType] = useState(returnAddresses[0].value);
  const withBundling = !outboundCompetence.disableBundling;
  const withContainers = outboundCompetence.requireContainerForReturn;

  const [contacts, setContacts] = useState(hubToSenderellaContacts(caseData.receiver));
  const [advancedOptions, setAdvancedOptions] = useState({});
  const [volumeDefinition, setVolumeDefinition] = useState('volume');
  const [disableMeasures, setDisableMeasures] = useState(true);
  const [disableVolume, setDisableVolume] = useState(false);

  const handleDisableContainerCheckboxChange = (event) => {
    setDisableContainer(event.target.checked);
    setContainerId(null);
  };

  const fillContacts = (group) => {
    setAddressType(group);
    setContacts(hubToSenderellaContacts(caseData[group]));
  };

  const changeContacts = (key) => (e) => {
    setContacts({ ...contacts, [key]: e.target.value });
  };

  const setAdvancedOption = (key) => (e) => {
    let value = e.target ? e.target.value : e;

    if (value instanceof moment) {
      value = value.toISOString();
    }

    setAdvancedOptions({ ...advancedOptions, [key]: value });
  };

  const setAdvancedOptionVolume = (key) => (e) => {
    const value = e.target ? e.target.value : e;

    setAdvancedOptions({ ...advancedOptions, volume: { ...advancedOptions.volume, [key]: value } });
  };

  const handleVolumeDefinition = (e) => {
    setVolumeDefinition(e.target.value);

    if (e.target.value === 'volume') {
      setDisableVolume(false);
      setDisableMeasures(true);

      setAdvancedOptions({
        ...advancedOptions,
        volume: { ...advancedOptions.volume, width: null, height: null, depth: null }
      });
    } else {
      setDisableVolume(true);
      setDisableMeasures(false);
      setAdvancedOptions({ ...advancedOptions, volume: { ...advancedOptions.volume, volume: null } });
    }
  };

  const { data: shippingMethods, loading: loadingShippingMethods } = useRequest(
    () => LogisticsModel.getShippingMethods(caseData.guid, ACTION.RETURN, coreValues),
    {
      initialData: [],
      throwOnError: true,
      formatResult: (data) => data.map((x) => ({ id: Number(x.properties.serviceId), name: x.name })),
      onSuccess: (data) => {
        if (!data.length) {
          return;
        }

        setShipmentTypeId(data[0].id);
        setBundleTo(NEW_SHIPMENT_GUID);
      }
    }
  );

  const { data: allShippingMethods, loading: loadingAllShippingMethods } = useRequest(
    () => LogisticsModel.getAllShippingMethods(),
    {
      initialData: [],
      throwOnError: true,
      formatResult: (data) => _.sortBy(data, (x) => x.name)
    }
  );

  const { loading: loadingAvailableShipments } = useRequest(
    () =>
      LogisticsModel.getShipmentsForBundling({
        postalCode: caseData[addressType].postalCode,
        serviceProviderId: serviceProvider.id,
        shipmentTypeId, // We need senderella shipment type here
        logisticAction: ACTION.RETURN,
        unlimitedBundling: outboundCompetence.unlimitedBundling ? 1 : undefined
      }),
    {
      initialData: [],
      throwOnError: true,
      refreshDeps: [shipmentTypeId, caseData[addressType].postalCode],
      ready: withBundling && Boolean(shipmentTypeId),
      onSuccess: (result) => {
        const sortedShipments = _.sortBy(result, (x) => -x.id);

        setBundleTo(sortedShipments?.[0]?.guid || NEW_SHIPMENT_GUID);

        setAvailableShipmentsOptions(
          sortedShipments.map((shipment) => {
            const { createdAt, guid, packages, receiver } = shipment;
            const items = _.flatMap(packages, (p) => p.items);

            return {
              description: (
                <Space direction="vertical">
                  <span>
                    <DateInline asTag value={createdAt} /> <b>{shipment.shipmentType.name}</b>
                  </span>
                  <span>
                    <b>Receiver:</b>
                    <br />
                    {addressToString(receiver)}
                  </span>
                  <Collapse
                    size="small"
                    items={[
                      {
                        label: `Contains ${items.length} item${items.length !== 1 ? 's' : ''}`,
                        children: (
                          <List
                            size="small"
                            dataSource={items}
                            renderItem={(item) => (
                              <List.Item>
                                {item.customData?.activityNumber ? <Tag>{item.customData.activityNumber}</Tag> : null}
                                {item.brand} {item.model}
                                {item.senderRef ? `, ref: ${item.senderRef}` : null}
                              </List.Item>
                            )}
                          />
                        ),
                        onClick: (e) => e.stopPropagation()
                      }
                    ]}
                  />
                </Space>
              ),
              value: guid
            };
          })
        );
      }
    }
  );

  const getContainers = useRequest(() => ContainersModel.getAllowedContainers(), {
    initialData: [],
    throwOnError: true,
    ready: withContainers && Boolean(shipmentTypeId),
    formatResult: (data) => data.map((container) => ({ value: container.id, label: container.name }))
  });

  const handleReturn = () => {
    onFinish(ACTION.RETURN, {
      shipmentTypeId,
      bundleToShipmentId: bundleToShipment === NEW_SHIPMENT_GUID ? undefined : bundleToShipment,
      containerId: bundleToShipment === NEW_SHIPMENT_GUID ? containerId : undefined,
      receiverContacts: bundleToShipment === NEW_SHIPMENT_GUID ? contacts : undefined,
      advancedOptions
    });
  };

  if (loadingShippingMethods || loadingAllShippingMethods) {
    return (
      <Spin tip="Loading shipping methods">
        <div />
      </Spin>
    );
  }

  if (!shippingMethods.length && !allShippingMethods.length) {
    return <Empty description="No shipping methods available" />;
  }

  const handleShipmentTypeChange = (e) => {
    const newShipmentId = e.target ? e.target.value : e;
    const newShipment = allShippingMethods.find((x) => x.id === newShipmentId);

    if (!newShipment) {
      return;
    }

    setShipmentTypeId(newShipmentId);
  };

  const handleShipmentChange = (guid) => {
    if (!guid) {
      return;
    }
    setBundleTo(guid === true ? NEW_SHIPMENT_GUID : guid);
  };

  const showAvailableShipments = withBundling && availableShipmentsOptions.length > 0;

  const toHubLink = (locale) =>
    `https://serviceorderhub.com/service-order/documents/service-report/${caseData.guid}?locale=${locale}`;

  const lastHandledAccessory = tasks.find(
    (x) => x.type.includes(TASK_TYPE.ARRIVE) || x.type.includes(TASK_TYPE.FORWARD)
  )?.data?.caseData?.productData?.otherAccessory;
  const currentAccessory = formValues?.productData?.otherAccessory;

  return (
    <Space direction="vertical" size="large">
      {serviceProvider.id !== caseData.serviceProvider.id && (
        <Alert type="warning" showIcon message={`This case will be assigned to ${serviceProvider.name}`} />
      )}
      <Flex vertical gap="middle" align="center">
        <CheckCard.Group
          className={styles.countryFlagContainer}
          value={customerCopyCountryCode}
          onChange={setCustomerCopyCountryCode}
        >
          {Object.entries(LOCALE).map(([countryCode, locale]) => (
            <CheckCard
              key={countryCode}
              className={styles.countryFlagCard}
              title={locale.title}
              size="small"
              value={countryCode}
            >
              <locale.flag />
            </CheckCard>
          ))}
        </CheckCard.Group>
        <PrintButton
          pdfUrl={toHubLink(countryToLocale(customerCopyCountryCode))}
          disabled={!customerCopyCountryCode}
          text="Print customer copy"
          convertToPdf
          type={PRINTER_TYPE.A4}
        />
      </Flex>
      {shippingMethods.length > 0 && (
        <Space direction="vertical">
          <b>Recommended shipping methods:</b>
          <div>
            <Radio.Group
              value={shipmentTypeId}
              onChange={handleShipmentTypeChange}
              disabled={loadingAvailableShipments}
            >
              {shippingMethods.map((x) => (
                <Radio key={x.id} value={x.id}>
                  {x.name} ({x?.settings?.sesamId ? `Sesam ID: ${x.settings.sesamId} / ` : ''}Hub ID: {x.id})
                </Radio>
              ))}
            </Radio.Group>
            <Divider plain>or select from all shipping methods</Divider>
            <SelectAnyShippingMethod
              allShippingMethods={allShippingMethods}
              value={shipmentTypeId}
              onChange={handleShipmentTypeChange}
            />
          </div>
        </Space>
      )}
      {!shippingMethods.length && (
        <Space direction="vertical">
          <b>Select shipping method:</b>
          <SelectAnyShippingMethod
            allShippingMethods={allShippingMethods}
            value={shipmentTypeId}
            onChange={handleShipmentTypeChange}
          />
        </Space>
      )}
      <Space direction="vertical">
        <b>Address presets:</b>
        <Select options={returnAddresses} value={addressType} onSelect={fillContacts} className="full-width" />
      </Space>
      {loadingAvailableShipments && (
        <Spin tip="Loading shipments">
          <div style={{ height: 60 }} />
        </Spin>
      )}
      {!loadingAvailableShipments && (
        <>
          <Space direction="vertical">
            {showAvailableShipments && <b>Shipment to bundle together:</b>}
            <div>
              {showAvailableShipments && (
                <>
                  <CheckCard.Group
                    className={styles.shipmentCheckCard}
                    value={bundleToShipment}
                    options={availableShipmentsOptions}
                    onChange={handleShipmentChange}
                  />
                  <Divider plain>or create new shipment</Divider>
                </>
              )}
              <Space direction="vertical">
                <CheckCard
                  className={styles.shipmentCheckCard}
                  title="Create new shipment"
                  checked={bundleToShipment === NEW_SHIPMENT_GUID}
                  onChange={handleShipmentChange}
                />
                {bundleToShipment === NEW_SHIPMENT_GUID && (
                  <>
                    <div />
                    {withContainers && (
                      <Space direction="vertical">
                        <b>Select container:</b>
                        <Row>
                          <Col span={12}>
                            <Select
                              options={getContainers.data}
                              className="full-width"
                              onChange={setContainerId}
                              value={containerId}
                              disabled={disableContainer}
                            />
                          </Col>
                          <Col span={12} type="flex" align="middle">
                            <Checkbox onChange={handleDisableContainerCheckboxChange}>No container</Checkbox>
                          </Col>
                        </Row>
                      </Space>
                    )}
                    <b>Name</b>
                    <Input className="full-width" value={contacts.name} onChange={changeContacts('name')} />
                    <b>Street</b>
                    <Input className="full-width" value={contacts.address} onChange={changeContacts('address')} />
                    <b>Postal code</b>
                    <Input className="full-width" value={contacts.postalCode} onChange={changeContacts('postalCode')} />
                    <b>City</b>
                    <Input className="full-width" value={contacts.city} onChange={changeContacts('city')} />
                    <b>Phone</b>
                    <Input
                      className="full-width"
                      value={contacts.phoneNumber}
                      onChange={changeContacts('phoneNumber')}
                    />
                    <b>Mobile</b>
                    <Input
                      className="full-width"
                      value={contacts.mobilePhoneNumber}
                      onChange={changeContacts('mobilePhoneNumber')}
                    />
                    <b>Email</b>
                    <Input className="full-width" value={contacts.email} onChange={changeContacts('email')} />
                    <b>Country code</b>
                    <Input
                      className="full-width"
                      value={contacts.countryCode}
                      onChange={changeContacts('countryCode')}
                    />
                    <b>Attention</b>
                    <Input className="full-width" value={contacts.attention} onChange={changeContacts('attention')} />
                    <div />
                    <Collapse
                      size="small"
                      items={[
                        {
                          label: `Advanced options`,
                          children: (
                            <Space direction="vertical">
                              <b>Weight (kg)</b>
                              <Input
                                className="full-width"
                                value={advancedOptions.weight}
                                onChange={setAdvancedOption('weight')}
                              />
                              <Radio.Group value={volumeDefinition} onChange={handleVolumeDefinition}>
                                <Radio value="volume">I have volume</Radio>
                                <Radio value="measures">I have measures</Radio>
                              </Radio.Group>
                              <b>Volume (m3)</b>
                              <Input
                                className="full-width"
                                value={advancedOptions.volume?.volume}
                                onChange={setAdvancedOptionVolume('volume')}
                                disabled={disableVolume}
                              />
                              <b>Width (m) (50 cm = 0.5 m) no commas</b>
                              <Input
                                className="full-width"
                                value={advancedOptions.volume?.width}
                                onChange={setAdvancedOptionVolume('width')}
                                disabled={disableMeasures}
                              />
                              <b>Height (m)</b>
                              <Input
                                className="full-width"
                                value={advancedOptions.volume?.height}
                                onChange={setAdvancedOptionVolume('height')}
                                disabled={disableMeasures}
                              />
                              <b>Depth (m)</b>
                              <Input
                                className="full-width"
                                value={advancedOptions.volume?.depth}
                                onChange={setAdvancedOptionVolume('depth')}
                                disabled={disableMeasures}
                              />
                              <b>Pickup date</b>
                              <DatePicker
                                className="full-width"
                                value={advancedOptions.pickupDate ? moment(advancedOptions.pickupDate) : null}
                                onChange={setAdvancedOption('pickupDate')}
                              />
                              <b>Pickup start</b>
                              <DatePicker
                                className="full-width"
                                value={advancedOptions.pickupStart ? moment(advancedOptions.pickupStart) : null}
                                onChange={setAdvancedOption('pickupStart')}
                                showTime
                              />
                              <b>Pickup end</b>
                              <DatePicker
                                className="full-width"
                                value={advancedOptions.pickupEnd ? moment(advancedOptions.pickupEnd) : null}
                                onChange={setAdvancedOption('pickupEnd')}
                                showTime
                              />
                              <b>Goods type</b>
                              <Input
                                className="full-width"
                                value={advancedOptions.goodsType}
                                onChange={setAdvancedOption('goodsType')}
                              />
                            </Space>
                          )
                        }
                      ]}
                    />
                  </>
                )}
              </Space>
            </div>
            <div />
          </Space>
        </>
      )}
      {lastHandledAccessory && currentAccessory !== lastHandledAccessory && (
        <Alert
          type="error"
          showIcon
          message={
            <span>
              Selected accessory <b>{currentAccessory}</b> doesn’t match with the arrived accessory{' '}
              <b>{lastHandledAccessory}</b>. Please double-check that everything is fine. You can proceed if you think
              that there is no problems with the accessories.
            </span>
          }
        />
      )}
      <Button
        type="primary"
        disabled={
          loadingAvailableShipments ||
          loadingAllShippingMethods ||
          !(!withContainers || containerId || disableContainer || bundleToShipment !== NEW_SHIPMENT_GUID)
        }
        onClick={handleReturn}
      >
        Return
      </Button>
    </Space>
  );
};

export { ReturnForm };
