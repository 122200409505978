// Used on the backend, so leave it strict

'use strict';

const MENU = {
  ACCESS_CONTROL: 'Access control',
  ACTIVITY: 'Activity',
  CONTAINERS: 'Containers',
  DASHBOARD: 'Dashboard',
  FORWARDED: 'Forwarded',
  LOGIN: 'Login',
  LOGOUT: 'Logout',
  MATCH_CASES: 'Match cases',
  MY_PAGE: 'My page',
  PUSH_ACTIVITIES: 'Push activities',
  RETURNED: 'Returned',
  SEARCH_CASE: 'Case search',
  SEARCH_CASE_FREE_TEXT: 'Free text search',
  SEARCH_SHIPMENT: 'Shipment search',
  SETTINGS: 'Settings',
  TIME_TRACKER: 'Time tracker',
  TOOLBOX_ACTIVITY: 'Toolbox activity',
  SHIPMENT_TYPES: 'Shipment types',
  CASE_LISTS: 'Case lists'
};

const PERMISSION = {
  AUTH_TOOL_SUPERVISOR: 'authTool.edit',
  DELETE_CONTAINERS: 'logistic.deleteContainers',
  EDIT_DEPARTMENTS: 'logistic.editDepartments',
  EDIT_NEWS: 'dashboard.editNews',
  EDIT_PRINTERS: 'logistic.editPrinters',
  EDIT_SHIPMENT_TYPES: 'logistic.editShipmentTypes',
  EDIT_TEAMS: 'admin.editTeams',
  EDIT_USERS: 'logistic.editUsers',
  LOGISTIC_PUSH_ACTIVITIES: 'logistic.pushActivities',
  OUTBOUND: 'outbound',
  PRODUCT_ARRIVAL: 'productArrival',
  MATCH_CASES_WITH_ACTIVITIES: 'technician.matchCases',
  TIMETRACKER_ADMIN: 'timeTracker.admin',
  TIMETRACKER_CHECK_IN_OUT_TESTER: 'timeTracker.checkInOutTester',
  TIMETRACKER_LOCATION_SUPERVISOR: 'timeTracker.locationSupervisor',
  TIMETRACKER_SUPERVISOR: 'timeTracker.supervisor',
  TIMETRACKER_VIEW_ALL_LOCATIONS: 'timeTracker.viewAllLocations',
  TRACK_TIME: 'timeTracker.trackTime',
  VIEW_MY_PAGE: 'viewMyPage',
  VIEW_NEWS: 'dashboard.viewNews',
  VIEW_UNREPLIED_EMAILS: 'customerCenter.viewUnrepliedForwardedEmails',
  VIEW_CASE_LISTS: 'caseLists.view',
  WILDCARD: '*'
};

const COMMON_LOGISTIC_PERMISSIONS = [
  PERMISSION.LOGISTIC_PUSH_ACTIVITIES,
  PERMISSION.OUTBOUND,
  PERMISSION.PRODUCT_ARRIVAL
];

const COMMON_TIMETRACKER_PERMISSIONS = [
  PERMISSION.TIMETRACKER_LOCATION_SUPERVISOR,
  PERMISSION.TIMETRACKER_SUPERVISOR,
  PERMISSION.TRACK_TIME
];

const SETTINGS_PERMISSIONS = [PERMISSION.EDIT_DEPARTMENTS, PERMISSION.EDIT_PRINTERS, PERMISSION.EDIT_USERS];

const ROUTE = {
  ACCESS_CONTROL: '/access-control',
  ACTIVITY: '/activity',
  CONTAINER: '/container',
  CONTAINERS: '/containers',
  FORWARDED_SHIPMENTS: '/forwarded-shipments',
  LOGIN: '/login',
  LOGISTICS_ACTIVITY: '/logistics/activity',
  LOGISTICS_SHIPMENT: '/logistics/shipment',
  LOGISTICS: '/logistics',
  MATCH_CASES: '/cases',
  MY_PAGE: '/my-page',
  NEWS_PAGE: '/news',
  PUSH_ACTIVITIES: '/push-activities',
  RETURNED_SHIPMENTS: '/returned-shipments',
  SEARCH_CASE: '/search-case',
  FREE_TEXT_SEARCH_CASE: '/free-text-search-case',
  SEARCH_SHIPMENT: '/search-shipment',
  SETTINGS: '/settings',
  TIME_TRACKER_EMPLOYEE: '/time-tracker/employee',
  TIME_TRACKER: '/time-tracker',
  TOOLBOX_ACTIVITY: '/toolbox-activity',
  SHIPMENT_TYPES: '/shipment-types',
  CASE_LISTS: '/case-lists'
};

const COLOR = {
  BLUE: '#1890ff',
  GREEN: '#52c41a',
  GOLD: '#faad14',
  VOLCANO: '#fa541c',
  RED: '#f5222d'
};

module.exports = {
  COLOR,
  COMMON_LOGISTIC_PERMISSIONS,
  COMMON_TIMETRACKER_PERMISSIONS,
  MENU,
  PERMISSION,
  ROUTE,
  SETTINGS_PERMISSIONS
};
