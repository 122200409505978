import { Select } from 'antd';
import { identity } from 'lodash';

const SelectAutocomplete = (props) => {
  const { skipMapping, options, extractKey = identity, extractValue = identity, ...selectProps } = props;

  selectProps.onSearch = selectProps.onChange;

  if (props.onSearch === null) {
    delete selectProps.onSearch;
  }

  if (skipMapping) {
    const filterProp = options?.[0]?.label ? 'label' : 'value';

    return <Select showSearch options={options} optionFilterProp={filterProp} {...selectProps} />;
  }

  return (
    <Select showSearch onSearch={selectProps.onChange} {...selectProps}>
      {options.map((x) => (
        <Select.Option key={extractKey(x)}>{extractValue(x)}</Select.Option>
      ))}
    </Select>
  );
};

export { SelectAutocomplete };
