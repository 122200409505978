// prettier-ignore
import { ApiOutlined, AppstoreOutlined, ClockCircleOutlined, ExportOutlined, FileSearchOutlined, GroupOutlined, ImportOutlined, LinkOutlined, LockOutlined, LogoutOutlined, OrderedListOutlined,SearchOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons';
import { useBoolean, useDebounceFn } from 'ahooks';
import { Layout, Menu } from 'antd';
import { useContext } from 'react';
import { Link, Redirect, Route, Switch, useLocation } from 'react-router-dom';

import logo from '@/assets/logo_white.svg';
import { MENU, PERMISSION, ROUTE, SETTINGS_PERMISSIONS } from '@/configs/general';
import { userContext } from '@/helpers/userContext';
import { AuthPage } from '@/pages/accessControl/AuthPage';
import { CaseListEditor } from '@/pages/caseLists/CaseListEditor';
import { CaseListPage } from '@/pages/caseLists/CaseListPage';
import { ContainerPage } from '@/pages/containers/ContainerPage';
import { ContainersPage } from '@/pages/containers/ContainersPage';
import { DashboardPage } from '@/pages/dashboard/DashboardPage';
import { ForwardedShipmentsPage } from '@/pages/forwardedShipments/ForwardedShipmentsPage';
import { CaseSearchPage } from '@/pages/logistics/CaseSearchPage';
import { ShipmentSearchPage } from '@/pages/logistics/ShipmentSearchPage';
import { ViewItemPage } from '@/pages/logistics/ViewItemPage';
import { MatchCasesPage } from '@/pages/matchCases/MatchCasesPage';
import { MyPage } from '@/pages/my/MyPage';
import { PushActivitiesPage } from '@/pages/pushActivities/PushActivitiesPage';
import { ReturnedShipmentsPage } from '@/pages/returnedShipments/ReturnedShipmentsPage';
import { SettingsPage } from '@/pages/settings/SettingsPage';
import { ShipmentTypesPage } from '@/pages/shipmentTypes/ShipmentTypesPage';
import { TimeTrackerDashboardPage } from '@/pages/timeTracker/TimeTrackerDashboardPage';
import { TimeTrackerEmployeePage } from '@/pages/timeTracker/TimeTrackerEmployeePage';
import { TimeTrackerMenuWidget } from '@/pages/timeTracker/TimeTrackerMenuWidget';

const ROUTES_LIST = [
  {
    path: '/',
    menuTitle: MENU.DASHBOARD,
    icon: <AppstoreOutlined />,
    component: DashboardPage,
    exact: true
  },
  {
    permission: PERMISSION.PRODUCT_ARRIVAL,
    path: ROUTE.SEARCH_CASE,
    menuTitle: MENU.SEARCH_CASE,
    icon: <SearchOutlined />,
    component: CaseSearchPage
  },
  {
    permission: PERMISSION.PRODUCT_ARRIVAL,
    userDataValidation: (user) => user?.logisticUser?.settings?.refinedWorkflows?.simplifiedFreeTextSearchArriving,
    path: ROUTE.FREE_TEXT_SEARCH_CASE,
    menuTitle: MENU.SEARCH_CASE_FREE_TEXT,
    icon: <FileSearchOutlined />,
    component: CaseSearchPage,
    componentProps: {
      simplifiedFreeTextSearchArriving: true
    }
  },
  {
    permission: PERMISSION.PRODUCT_ARRIVAL,
    path: ROUTE.SEARCH_SHIPMENT,
    menuTitle: MENU.SEARCH_SHIPMENT,
    icon: <SearchOutlined />,
    component: ShipmentSearchPage
  },
  {
    permission: PERMISSION.MATCH_CASES_WITH_ACTIVITIES,
    path: ROUTE.MATCH_CASES,
    menuTitle: MENU.MATCH_CASES,
    icon: <LinkOutlined />,
    component: MatchCasesPage,
    exact: true
  },
  {
    permission: PERMISSION.PRODUCT_ARRIVAL,
    path: ROUTE.FORWARDED_SHIPMENTS,
    menuTitle: MENU.FORWARDED,
    icon: <ExportOutlined />,
    component: ForwardedShipmentsPage
  },
  {
    permission: PERMISSION.PRODUCT_ARRIVAL,
    path: `${ROUTE.LOGISTICS}/:type/:identifier`,
    component: ViewItemPage,
    exact: true
  },
  {
    permission: PERMISSION.OUTBOUND,
    path: ROUTE.RETURNED_SHIPMENTS,
    menuTitle: MENU.RETURNED,
    icon: <ImportOutlined />,
    component: ReturnedShipmentsPage
  },
  {
    permission: PERMISSION.OUTBOUND,
    path: ROUTE.CONTAINERS,
    menuTitle: MENU.CONTAINERS,
    icon: <GroupOutlined />,
    component: ContainersPage
  },
  { permission: PERMISSION.OUTBOUND, path: `${ROUTE.CONTAINER}/:id`, component: ContainerPage },
  {
    permission: PERMISSION.LOGISTIC_PUSH_ACTIVITIES,
    path: ROUTE.PUSH_ACTIVITIES,
    menuTitle: MENU.PUSH_ACTIVITIES,
    icon: <ApiOutlined />,
    component: PushActivitiesPage
  },
  {
    permission: PERMISSION.EDIT_SHIPMENT_TYPES,
    path: ROUTE.SHIPMENT_TYPES,
    menuTitle: MENU.SHIPMENT_TYPES,
    icon: <SettingOutlined />,
    component: ShipmentTypesPage,
    exact: true
  },
  {
    permission: PERMISSION.TIMETRACKER_SUPERVISOR,
    path: ROUTE.TIME_TRACKER,
    menuTitle: MENU.TIME_TRACKER,
    icon: <ClockCircleOutlined />,
    component: TimeTrackerDashboardPage,
    exact: true
  },
  {
    permission: PERMISSION.TIMETRACKER_SUPERVISOR,
    path: `${ROUTE.TIME_TRACKER_EMPLOYEE}/:employeeId`,
    component: TimeTrackerEmployeePage
  },
  {
    permission: PERMISSION.VIEW_CASE_LISTS,
    path: ROUTE.CASE_LISTS,
    menuTitle: MENU.CASE_LISTS,
    icon: <OrderedListOutlined />,
    component: CaseListEditor,
    exact: true
  },
  {
    permission: PERMISSION.VIEW_CASE_LISTS,
    path: `${ROUTE.CASE_LISTS}/:slug`,
    component: CaseListPage
  },
  {
    type: 'divider'
  },
  {
    insert: 'timeTrackerWidget'
  },
  {
    permission: PERMISSION.VIEW_MY_PAGE,
    path: ROUTE.MY_PAGE,
    menuTitle: MENU.MY_PAGE,
    icon: <UserOutlined />,
    component: MyPage
  },
  {
    permission: SETTINGS_PERMISSIONS,
    path: ROUTE.SETTINGS,
    menuTitle: MENU.SETTINGS,
    icon: <SettingOutlined />,
    component: SettingsPage
  },
  {
    permission: PERMISSION.AUTH_TOOL_SUPERVISOR,
    path: ROUTE.ACCESS_CONTROL,
    menuTitle: MENU.ACCESS_CONTROL,
    icon: <LockOutlined />,
    component: AuthPage
  },
  { menuTitle: MENU.LOGOUT, icon: <LogoutOutlined />, onClickMethod: 'logout' }
];

const getMenuItems = (permissionFilter, inserts, handlers = {}, user) => {
  const menuItems = ROUTES_LIST.flatMap((x) => {
    if (!x.insert) {
      return x;
    }

    return inserts?.[x?.insert];
  });

  const filteredMenuItems = menuItems.filter(
    (x) =>
      x &&
      (x.children || x.menuTitle || x.type) &&
      (!x.permission || permissionFilter(x.permission)) &&
      (!x.userDataValidation || x.userDataValidation(user))
  );

  return filteredMenuItems.map((x) => ({
    key: x.path,
    icon: x.icon,
    label: x.children || (x.path ? <Link to={x.path}>{x.menuTitle}</Link> : x.menuTitle),
    type: x.type,
    onClick: x.onClick,
    ...(handlers?.[x.onClickMethod] ? { onClick: handlers[x.onClickMethod] } : {})
  }));
};

const AppLayout = () => {
  const { pathname } = useLocation();
  const { changeLocalSettings, hasPermission, logout, localSettings, user } = useContext(userContext);

  const [siderCollapsed, { toggle: toggleSider }] = useBoolean(localSettings?.siderCollapsed);
  const { run: debouncedSaveSettings } = useDebounceFn(
    () => {
      changeLocalSettings({
        siderCollapsed
      });
    },
    { wait: 1000 }
  );

  const handleCollapse = async () => {
    toggleSider();

    debouncedSaveSettings();
  };

  const timeTrackerWidget = hasPermission(PERMISSION.TRACK_TIME) ? TimeTrackerMenuWidget() : null;
  const menuItems = getMenuItems(hasPermission, { timeTrackerWidget }, { logout }, user);

  return (
    <Layout>
      <Layout.Sider collapsible collapsed={siderCollapsed} onCollapse={handleCollapse} collapsedWidth="50" width="180">
        <Link to="/" className="logo">
          {!siderCollapsed && <img src={logo} alt="Logo" />}
          {siderCollapsed && <strong>EC</strong>}
        </Link>
        <Menu theme="dark" mode="inline" selectedKeys={[pathname]} items={menuItems} inlineIndent={12} />
      </Layout.Sider>
      <Layout>
        <Layout.Content>
          <Switch>
            {ROUTES_LIST.filter(
              (x) =>
                Boolean(x.component) &&
                (!x.permission || hasPermission(x.permission)) &&
                (!x.userDataValidation || x.userDataValidation(user))
            ).map(({ path, component: Component, permission, hasPermission: __, componentProps = {}, ...rest }) => (
              <Route {...rest} key={path} path={path} render={(p) => <Component {...p} {...componentProps} />} />
            ))}
            <Redirect to="/" />
          </Switch>
        </Layout.Content>
        <Layout.Footer>&copy; {new Date().getFullYear()}</Layout.Footer>
      </Layout>
    </Layout>
  );
};

export { AppLayout };
