import { DeleteOutlined, EditOutlined, PlusOutlined, RedoOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { Button, Card, Drawer, Skeleton, Space, Spin } from 'antd';
import { useState } from 'react';

import { ENTITY } from '@/configs/entity';
import { SchemaForm } from '@/containers/schemaForm/SchemaForm';
import { EntitiesModel } from '@/models/entities';

const Departments = () => {
  const [departmentToEditIndex, setDepartmentToEditIndex] = useState(null);
  const { data, loading, refresh } = useRequest(
    () => EntitiesModel.getEntityValues(ENTITY.DEPARTMENT, { relations: [ENTITY.CONTACT_DATA] }),
    { initialData: null }
  );

  const deleteEntityInstance = useRequest((id) => EntitiesModel.deleteEntityInstance(ENTITY.DEPARTMENT, id), {
    manual: true,
    onSuccess: refresh
  });

  const handleEditClick = (departmentIndex) => () => setDepartmentToEditIndex(departmentIndex);
  const handleDeleteClick = (id) => () => deleteEntityInstance.run(id);

  const handleSuccessChanges = () => {
    setDepartmentToEditIndex(null);
    refresh();
  };

  return (
    <Skeleton active loading={!data?.data}>
      <Spin spinning={loading}>
        <Space className="tab-actions">
          <Button type="primary" onClick={handleEditClick('new')} icon={<PlusOutlined />}>
            Add new department
          </Button>
          <Button onClick={refresh} icon={<RedoOutlined />}>
            Refresh
          </Button>
        </Space>
        <div className="cards-wrapper">
          {(data?.data || []).map((department, index) => (
            <DepartmentCard
              key={department.id}
              data={department}
              index={index}
              onDeleteClick={handleDeleteClick}
              onEditClick={handleEditClick}
              disabled={loading || deleteEntityInstance.loading}
            />
          ))}
          <i area-hidden="true" />
          <i area-hidden="true" />
        </div>
      </Spin>
      <Drawer
        title={
          departmentToEditIndex === 'new'
            ? 'Add new department'
            : `Edit department: ${data?.data?.[departmentToEditIndex]?.name || []}`
        }
        open={departmentToEditIndex !== null}
        onClose={handleEditClick(null)}
        width={720}
        destroyOnClose
      >
        <SchemaForm
          entity={ENTITY.DEPARTMENT}
          id={data?.data?.[departmentToEditIndex]?.id}
          onSuccess={handleSuccessChanges}
        />
      </Drawer>
    </Skeleton>
  );
};

const DepartmentCard = (props) => {
  const { data, disabled, index, onDeleteClick, onEditClick } = props;
  const { address, city, countryCode, name, organizationName, postalCode } = data.contactData;

  return (
    <Card
      title={data.name}
      actions={[
        <Button type="primary" onClick={onEditClick(index)} icon={<EditOutlined />} disabled={disabled}>
          Edit
        </Button>,
        <Button type="primary" danger onClick={onDeleteClick(data.id)} icon={<DeleteOutlined />} disabled={disabled}>
          Delete
        </Button>
      ]}
    >
      <p>Contact name: {name || '-'}</p>
      <p>Organization: {organizationName || '-'}</p>
      <p>
        Full address: {address} {city}, {postalCode}, {countryCode}
      </p>
    </Card>
  );
};

export { Departments };
