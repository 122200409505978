// prettier-ignore
import { ProCard, ProForm, ProFormDatePicker, ProFormDependency, ProFormRadio, ProFormText, ProFormTextArea } from '@ant-design/pro-components';
import { useMount } from 'ahooks';
import { Alert, Button, Space, Tooltip } from 'antd';
import _ from 'lodash';
import { useContext, useRef, useState } from 'react';

import { COLOR } from '@/configs/general';
import { LogisticsModel } from '@/models/logistics';

import { ACCESSORIES } from '../configs';
import { logisticsContext } from '../logisticsContext';

const DATA_ENTITY = {
  ELCARE_DATA: 'elcareData',
  ORDER_DATA: 'orderData',
  PRODUCT_DATA: 'productData'
};

const NO_ACCESSORIES_REGEX = /^\W*(nej|na|n\/a|nei|no|ingen|inga|none|ei|ej)\W*$/i;

const getLabeledOption = (value) => ({ label: value, value });

const getFormPath = (key, entity) => {
  if (entity === DATA_ENTITY.ELCARE_DATA) {
    return ['orderData', 'providerSpecific', 'elcare', key];
  }

  if (entity === DATA_ENTITY.ORDER_DATA) {
    return ['orderData', key];
  }

  if (entity === DATA_ENTITY.PRODUCT_DATA) {
    return ['productData', key];
  }

  return key;
};

const formatDate = (value) => value.format();

const getInitialValues = (caseData) => {
  let otherAccessory = _.get(caseData, getFormPath('otherAccessory', DATA_ENTITY.PRODUCT_DATA));

  if (NO_ACCESSORIES_REGEX.test(otherAccessory) || !otherAccessory) {
    otherAccessory = ACCESSORIES.NO_ACCESSORIES;
  }

  if (otherAccessory) {
    return { ...caseData, accessories: otherAccessory };
  }

  return _.omit(caseData, [getFormPath('otherAccessory', DATA_ENTITY.PRODUCT_DATA)]);
};

const getAccessoriesOptions = (caseData) => {
  const otherAccessoryValue = [
    ...(_.isArray(caseData?.[DATA_ENTITY.PRODUCT_DATA]?.accessory) ? caseData[DATA_ENTITY.PRODUCT_DATA].accessory : []),
    !NO_ACCESSORIES_REGEX.test(caseData?.[DATA_ENTITY.PRODUCT_DATA]?.otherAccessory) &&
      caseData?.[DATA_ENTITY.PRODUCT_DATA]?.otherAccessory
  ]
    .filter(Boolean)
    .join(', ');

  const definedAccessoriesOption =
    otherAccessoryValue && !Object.values(ACCESSORIES).includes(otherAccessoryValue)
      ? getLabeledOption(otherAccessoryValue)
      : null;

  return [
    getLabeledOption(ACCESSORIES.NO_ACCESSORIES),
    definedAccessoriesOption,
    getLabeledOption(ACCESSORIES.ACCESSORIES_UNKNOWN),
    getLabeledOption(ACCESSORIES.OTHER_ACCESSORIES)
  ].filter(Boolean);
};

const EditableCaseInfoForm = ({ caseData }) => {
  const [initialValues] = useState(getInitialValues(caseData));
  const [accessoriesOptions] = useState(getAccessoriesOptions(caseData));
  const { handleFormChange, setIsOtherAccessoriesChanged } = useContext(logisticsContext);
  const formRef = useRef();

  const handleValuesChange = (val) => {
    const otherAccessoryPath = getFormPath('otherAccessory', DATA_ENTITY.PRODUCT_DATA);

    if (_.has(val, 'otherAccessory')) {
      formRef.current.setFieldValue(otherAccessoryPath, val.otherAccessory);

      setIsOtherAccessoriesChanged(val.otherAccessory !== initialValues.accessories);
    } else if (val.accessories) {
      if (val.accessories === ACCESSORIES.OTHER_ACCESSORIES) {
        formRef.current.setFieldValue(otherAccessoryPath, '');
        formRef.current.setFieldValue('otherAccessory', '');
      } else {
        formRef.current.setFieldValue(otherAccessoryPath, val.accessories);
      }

      setIsOtherAccessoriesChanged(val.accessories !== initialValues.accessories);
    }

    const allValues = _.omit(formRef.current.getFieldsFormatValue(), ['accessories', 'otherAccessory']);

    if (
      _.has(caseData, getFormPath('purchaseDate', DATA_ENTITY.PRODUCT_DATA)) &&
      !_.has(allValues, getFormPath('purchaseDate', DATA_ENTITY.PRODUCT_DATA))
    ) {
      _.set(allValues, getFormPath('purchaseDate', DATA_ENTITY.PRODUCT_DATA), null);
    }

    handleFormChange(val, allValues);
  };

  useMount(() => {
    // console.log(_.get(caseData, getFormPath('otherAccessory', DATA_ENTITY.PRODUCT_DATA)));
    // console.log(initialValues.accessories);

    handleValuesChange({ accessories: initialValues.accessories });
  });

  const clearDateHandler = () => {
    formRef?.current?.setFieldValue(getFormPath('purchaseDate', DATA_ENTITY.PRODUCT_DATA), null);
    handleValuesChange({});
  };

  const warrantyWarning = LogisticsModel.warrantyCheck(
    _.set(
      { ...caseData },
      getFormPath('purchaseDate', DATA_ENTITY.PRODUCT_DATA),
      formRef?.current?.getFieldFormatValue(getFormPath('purchaseDate', DATA_ENTITY.PRODUCT_DATA))
    )
  );

  return (
    <ProCard colSpan={24} size="small">
      <ProForm
        grid
        layout="horizontal"
        submitter={false}
        initialValues={initialValues}
        formRef={formRef}
        onValuesChange={handleValuesChange}
        dateFormatter={formatDate}
      >
        <ProCard className="no-outer-padding" colSpan={24} size="small" gutter={[12, 0]}>
          <ProCard className="no-outer-padding form-card" colSpan={12} size="small">
            <ProFormText name={getFormPath('model', DATA_ENTITY.PRODUCT_DATA)} label="Model" placeholder="" />
            <ProFormText name={getFormPath('serial', DATA_ENTITY.PRODUCT_DATA)} label="Serial" placeholder="" />
            <ProFormText name={getFormPath('imei', DATA_ENTITY.PRODUCT_DATA)} label="IMEI" placeholder="" />
            <ProFormText name={getFormPath('gref', DATA_ENTITY.ELCARE_DATA)} label="GRef" placeholder="" />
            <ProFormText name={getFormPath('spec', DATA_ENTITY.ELCARE_DATA)} label="Spec" placeholder="" />
            <ProFormRadio.Group
              name="accessories"
              layout="vertical"
              label="Accessories"
              className="vertical-radio-group"
              required
              options={accessoriesOptions}
            />
            <ProFormDependency name={['accessories']}>
              {({ accessories }) => {
                if (accessories !== ACCESSORIES.OTHER_ACCESSORIES) {
                  return null;
                }

                return (
                  <ProFormTextArea
                    name="otherAccessory"
                    placeholder="Specify accessories"
                    fieldProps={{
                      autoSize: { minRows: 2, maxRows: 2 }
                    }}
                  />
                );
              }}
            </ProFormDependency>
          </ProCard>
          <ProCard className="no-outer-padding form-card" colSpan={12} size="small">
            <ProFormTextArea
              name={getFormPath('prodDesc', DATA_ENTITY.ELCARE_DATA)}
              label="Product description"
              fieldProps={{
                autoSize: { minRows: 1, maxRows: 1 }
              }}
              placeholder=""
            />
            <ProFormText
              name={getFormPath('replacementSerial', DATA_ENTITY.PRODUCT_DATA)}
              label="Replacement serial"
              placeholder=""
            />
            <ProFormText
              name={getFormPath('replacementImei', DATA_ENTITY.PRODUCT_DATA)}
              label="Replacement IMEI"
              placeholder=""
            />
            <ProFormTextArea
              name={getFormPath('problemText', DATA_ENTITY.PRODUCT_DATA)}
              label="Problem"
              fieldProps={{
                autoSize: { minRows: 2, maxRows: 2 }
              }}
              placeholder=""
            />
            <ProFormTextArea
              name={getFormPath('solution', DATA_ENTITY.PRODUCT_DATA)}
              label="Solution"
              fieldProps={{
                autoSize: { minRows: 2, maxRows: 2 }
              }}
              placeholder=""
            />
            <Space style={{ marginTop: 8 }}>
              <Space size="small">
                <ProFormDatePicker
                  name={getFormPath('purchaseDate', DATA_ENTITY.PRODUCT_DATA)}
                  label="Sales date"
                  placeholder=""
                  allowClear={false}
                  fieldProps={{
                    style: { width: 125, marginRight: -4 }
                  }}
                />
                <Button ghost type="primary" onClick={clearDateHandler}>
                  Clear date
                </Button>
                {warrantyWarning && (
                  <Tooltip title={warrantyWarning} color={COLOR.RED}>
                    <Alert message="Warranty violation" type="error" showIcon style={{ padding: '4px 8px' }} />
                  </Tooltip>
                )}
              </Space>
            </Space>
          </ProCard>
        </ProCard>
        <ProFormText name={getFormPath('otherAccessory', DATA_ENTITY.PRODUCT_DATA)} hidden />
      </ProForm>
    </ProCard>
  );
};

export { EditableCaseInfoForm };
