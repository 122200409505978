import { DatePicker } from 'antd';
import moment from 'moment';
import { useState } from 'react';

const CustomDatePicker = (props) => {
  const { onChange, value, ...restProps } = props;
  const [defaultValue] = useState(value ? moment(value) : null);

  const handleChange = (date) => onChange(date ? date.format('YYYY-MM-DD') : null);

  return <DatePicker defaultValue={defaultValue} onChange={handleChange} style={{ width: 118 }} {...restProps} />;
};

export { CustomDatePicker };
