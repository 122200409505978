export const WORKTYPE = {
  FLEX_TIME: 'flexTime',
  COMPENSATION_TIME: 'compensationTime',
  REGULAR: 'regular',
  ABSENCE: 'absence',
  PAID_OVERTIME: 'paidOvertime',
  MERTID: 'mertid'
};

export const WORKTYPE_LABEL = {
  [WORKTYPE.FLEX_TIME]: 'Flex time',
  [WORKTYPE.COMPENSATION_TIME]: 'Compensation time',
  [WORKTYPE.REGULAR]: 'Regular work time',
  [WORKTYPE.ABSENCE]: 'Absence',
  [WORKTYPE.PAID_OVERTIME]: 'Paid overtime',
  [WORKTYPE.MERTID]: 'Mertid'
};

export const HOURLY_WORKTYPE_LABEL = {
  [WORKTYPE.REGULAR]: 'Paid time',
  [WORKTYPE.COMPENSATION_TIME]: 'Compensation time',
  [WORKTYPE.PAID_OVERTIME]: 'Paid overtime'
};

export const RATE_LABEL = {
  100: '1.0',
  150: 'Enkel (x1,5)',
  200: 'Kvalificerad (x2)'
};

export const RATE_LABEL_NO = {
  100: '1.0',
  150: '50%',
  200: '100%'
};

export const DAY_ABSENCE = {
  '0103': '0103',
  '0198': '0198',
  '0210': '0210',
  '0214': '0214',
  '0215': '0215',
  '0216': '0216',
  '0217': '0217',
  '0302': '0302',
  '0303': '0303',
  '0305': '0305',
  '0310': '0310',
  '0398': '0398',
  '0399': '0399',
  '0502': '0502',
  '0504': '0504',
  '0510': '0510',
  '0521': '0521',
  '0602': '0602',
  '0604': '0604',
  '0606': '0606',
  '0610': '0610',
  '0611': '0611',
  '0636': '0636',
  '0638': '0638',
  '0690': '0690',
  '0691': '0691',
  '0710': '0710'
};

export const DAY_ABSENCE_NO = {
  '0101': '0101',
  '0102': '0102',
  '0103': '0103',
  '0104': '0104',
  '0232': '0232',
  '0202': '0202',
  '0234': '0234',
  '0214': '0214',
  '0218': '0218',
  '0302': '0302',
  '0301': '0301',
  '0304': '0304',
  '0307': '0307',
  '0601': '0601',
  '0602': '0602',
  '0603': '0603',
  '0604': '0604',
  '0605': '0605',
  '0635': '0635',
  '0610': '0610',
  '0606': '0606',
  '0290': '0290',
  PARENTAL_LEAVE_25: 'PARENTAL_LEAVE_25',
  PARENTAL_LEAVE_50: 'PARENTAL_LEAVE_50',
  PARENTAL_LEAVE_75: 'PARENTAL_LEAVE_75',
  SICK_LEAVE: 'SICK_LEAVE'
};

export const DAY_ABSENCE_LABEL = {
  [DAY_ABSENCE['0103']]: 'Obetald semester',
  [DAY_ABSENCE['0198']]: 'Semester',
  [DAY_ABSENCE['0210']]: 'Arbetsskada, hel dag',
  [DAY_ABSENCE['0214']]: 'Sjuk, hel dag',
  [DAY_ABSENCE['0215']]: 'Sjuk 25% m läkarintyg',
  [DAY_ABSENCE['0216']]: 'Sjuk 50% m läkarintyg',
  [DAY_ABSENCE['0217']]: 'Sjuk 75% m läkarintyg',
  [DAY_ABSENCE['0302']]: 'Permission, hel dag',
  [DAY_ABSENCE['0303']]: 'Facklig utb heldag, betald',
  [DAY_ABSENCE['0305']]: 'Facklig arb heldag, betald',
  [DAY_ABSENCE['0310']]: 'Utbildning m lön, hel dag',
  [DAY_ABSENCE['0398']]: 'Arbetsbefriad ej semester grundande',
  [DAY_ABSENCE['0399']]: 'Arbetsbefriad semestergrundande',
  [DAY_ABSENCE['0502']]: 'Vård av barn, hel dag',
  [DAY_ABSENCE['0504']]: 'Pappadagar, hel dag',
  [DAY_ABSENCE['0510']]: 'Graviditetspenning',
  [DAY_ABSENCE['0521']]: 'Föräldraled, heldag',
  [DAY_ABSENCE['0602']]: 'Ogiltig frånvaro, hel dag',
  [DAY_ABSENCE['0604']]: 'Tjänstledighet, hel dag',
  [DAY_ABSENCE['0606']]: 'Studieledighet hel dag',
  [DAY_ABSENCE['0610']]: 'Militärtjänst/civiltjänst',
  [DAY_ABSENCE['0611']]: 'Repetitionsutb., hel dag',
  [DAY_ABSENCE['0636']]: 'Facklig utb. hel dag, obet. ',
  [DAY_ABSENCE['0638']]: 'Fackligt arb hel dag, obet',
  [DAY_ABSENCE['0689']]: 'Närst.vård m FKbeslut 25%',
  [DAY_ABSENCE['0690']]: 'Närst.vård m FKbeslut 50%',
  [DAY_ABSENCE['0691']]: 'Närstvård m FKbeslut 100% ',
  [DAY_ABSENCE['0710']]: 'Uttag arbetstidsförkortn.'
};

export const DAY_ABSENCE_LABEL_NO = {
  [DAY_ABSENCE_NO['0101']]: 'Ferie',
  [DAY_ABSENCE_NO['0102']]: 'Ferie uten lønn',
  [DAY_ABSENCE_NO['0103']]: 'Ekstra feriedag',
  [DAY_ABSENCE_NO['0104']]: 'Forskuddsferie',
  [DAY_ABSENCE_NO['0232']]: 'Covid-19 Omsorg barn',
  [DAY_ABSENCE_NO['0202']]: 'Sykt barn/barnepasser',
  [DAY_ABSENCE_NO['0234']]: 'Covid-19 egenmelding',
  [DAY_ABSENCE_NO['0214']]: 'Egenmelding',
  [DAY_ABSENCE_NO['0218']]: 'Syk uten lønn',
  [DAY_ABSENCE_NO['0302']]: 'Annen permisjon m/lønn',
  [DAY_ABSENCE_NO['0301']]: 'Velferdspermisjon m/lønn',
  [DAY_ABSENCE_NO['0304']]: 'Rep. øvelse m/lønn',
  [DAY_ABSENCE_NO['0307']]: 'Kurs/konferanse',
  [DAY_ABSENCE_NO['0601']]: 'Permisjon uten lønn',
  [DAY_ABSENCE_NO['0602']]: 'Skoft/ugyldig fravær',
  [DAY_ABSENCE_NO['0603']]: 'Velferdsperm u/lønn',
  [DAY_ABSENCE_NO['0604']]: 'Militærtjeneste u/lønn',
  [DAY_ABSENCE_NO['0605']]: 'Rep.øvelse u/lønn',
  [DAY_ABSENCE_NO['0635']]: 'Covid-19 permittering u/l',
  [DAY_ABSENCE_NO['0610']]: 'Omsorgsperm. v/fødsel u/l',
  [DAY_ABSENCE_NO['0606']]: 'Permitert u/lønn',
  [DAY_ABSENCE_NO['0290']]: 'Syk dok. ettersendes',
  [DAY_ABSENCE_NO.PARENTAL_LEAVE_25]: 'Foreldrepermisjon 25%',
  [DAY_ABSENCE_NO.PARENTAL_LEAVE_50]: 'Foreldrepermisjon 50%',
  [DAY_ABSENCE_NO.PARENTAL_LEAVE_75]: 'Foreldrepermisjon 75%',
  [DAY_ABSENCE_NO.SICK_LEAVE]: 'Syk med egenmelding'
};

export const HOURLY_ABSENCE = {
  '0209': '0209',
  '0211': '0211',
  '0301': '0301',
  '0304': '0304',
  '0306': '0306',
  '0311': '0311',
  '0501': '0501',
  '0503': '0503',
  '0514': '0514',
  '0524': '0524',
  '0601': '0601',
  '0603': '0603',
  '0605': '0605',
  '0637': '0637',
  '0639': '0639',
  '0692': '0692'
};

export const HOURLY_ABSENCE_NO = {
  '0201': '0201',
  '0202': '0202',
  '0218': '0218'
};

export const HOURLY_ABSENCE_LABEL = {
  [HOURLY_ABSENCE['0209']]: 'Arbetsskada, del av dag',
  [HOURLY_ABSENCE['0211']]: 'Sjuk, del av dag',
  [HOURLY_ABSENCE['0301']]: 'Permission, del av dag',
  [HOURLY_ABSENCE['0304']]: 'Facklig utb timmar, betald',
  [HOURLY_ABSENCE['0306']]: 'Facklig arb timmar, betald',
  [HOURLY_ABSENCE['0311']]: 'Utbildning m lön, timmar',
  [HOURLY_ABSENCE['0501']]: 'Vård av barn del av',
  [HOURLY_ABSENCE['0503']]: 'Pappadagar, del av dag',
  [HOURLY_ABSENCE['0514']]: 'Graviditetspenning, tim',
  [HOURLY_ABSENCE['0524']]: 'Föräldraled, enstaka tim',
  [HOURLY_ABSENCE['0601']]: 'Ogiltig frånvaro, delavdag',
  [HOURLY_ABSENCE['0603']]: 'Tjänstledighet del av dag',
  [HOURLY_ABSENCE['0605']]: 'Studieledighet del av dag',
  [HOURLY_ABSENCE['0637']]: 'Facklig utb. timmar, obet.',
  [HOURLY_ABSENCE['0639']]: 'Fackligt arb timmar, obet',
  [HOURLY_ABSENCE['0692']]: 'Närst. vård m FKbeslut, tim'
};

export const HOURLY_ABSENCE_LABEL_NO = {
  [HOURLY_ABSENCE_NO['0201']]: 'Syk del av dag',
  [HOURLY_ABSENCE_NO['0202']]: 'Sykt barn/barnepasser',
  [HOURLY_ABSENCE_NO['0218']]: 'Syk uten lønn'
};

export const WAGE_CODE = {
  /** Travel time weekday */
  '2274': '2274',
  /** Travel time weekend */
  '2275': '2275'
};

export const WAGE_LABEL = {
  [WAGE_CODE['2274']]: 'Travel time weekday',
  [WAGE_CODE['2275']]: 'Travel time weekend'
};
