import './styles/global.module.scss';
import './styles/fullcalendar.module.scss';

import { useContext } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';

import { ROUTE } from '@/configs/general';
import { AppLayout } from '@/containers/AppLayout';
import { userContext, UserProvider } from '@/helpers/userContext';
import { LoginPage } from '@/pages/login/LoginPage';

const AppGlobalRouting = () => {
  const { user } = useContext(userContext);

  if (user) {
    return <AppLayout />;
  }

  return (
    <Switch>
      <Route path={ROUTE.LOGIN} component={LoginPage} exact />
      <Redirect to={ROUTE.LOGIN} />
    </Switch>
  );
};

const App = () => {
  return (
    <Router>
      <UserProvider>
        <LastLocationProvider>
          <AppGlobalRouting />
        </LastLocationProvider>
      </UserProvider>
    </Router>
  );
};

export { App };
