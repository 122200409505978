import { CalendarTwoTone, EditOutlined, EnvironmentTwoTone } from '@ant-design/icons';
import { PageHeader } from '@ant-design/pro-layout';
import { useRequest } from 'ahooks';
import { Button, Card, Col, Row, Skeleton, Space, Spin, Statistic } from 'antd';
import { useContext, useState } from 'react';

import { DateInline } from '@/components/DateInline';
import { ENTITY } from '@/configs/entity';
import { PERMISSION, ROUTE } from '@/configs/general';
import { useGoBack } from '@/helpers/hooks';
import { userContext } from '@/helpers/userContext';
import { EntitiesModel } from '@/models/entities';

import { STATUS_LABEL } from './config';
import { ContainerEditor } from './ContainerEditor';
import { ContainerItems } from './ContainerItems';

const ContainerCard = (props) => {
  const { status, statusDate } = props;

  return (
    <Row justify="space-around">
      <Space size="large">
        <Card bordered={false}>
          <Statistic title="Status" value={STATUS_LABEL[status] || status} prefix={<EnvironmentTwoTone />} />
        </Card>
        <Card bordered={false}>
          <Statistic
            title="Status changed"
            value={DateInline({ value: statusDate, asString: true })}
            prefix={<CalendarTwoTone />}
          />
        </Card>
      </Space>
    </Row>
  );
};

const ContainerPage = (props) => {
  const id = props.match.params.id;
  const { hasPermission } = useContext(userContext);
  const goBack = useGoBack(ROUTE.CONTAINERS);
  const canEdit = hasPermission(PERMISSION.OUTBOUND);
  const [isEdit, setIsEdit] = useState(false);

  const { data, loading, refresh } = useRequest(() => EntitiesModel.getEntityValueById(ENTITY.CONTAINER, id, false), {
    initialData: null
  });
  const item = data?.data;
  const handleEditClick = () => setIsEdit(true);

  const handleEditChanges = () => {
    setIsEdit(false);
    refresh();
  };

  const extra =
    canEdit && id ? (
      <Button type="primary" icon={<EditOutlined />} onClick={handleEditClick}>
        Edit container
      </Button>
    ) : null;

  return (
    <>
      <PageHeader title={`Container: ${item?.name || ''}`} onBack={goBack} extra={extra} />
      <Skeleton active loading={!data}>
        <Spin spinning={loading}>
          {item && (
            <>
              <ContainerCard {...item} />
              <Row>
                <Col span={24}>
                  <ContainerItems containerId={item.id} />
                </Col>
              </Row>
            </>
          )}
        </Spin>
        <ContainerEditor id={id} visible={isEdit} onClose={handleEditChanges} />
      </Skeleton>
    </>
  );
};

export { ContainerPage };
