import moment from 'moment';

import { RequestModel } from './request';

const WARRANTY_TIME = { FI: 24, DK: 24, SE: 36, NO: 60 };

const LogisticsModel = {
  getActivityCase: async (id) => await RequestModel.getJsonData(`/api/v1/logistics/case/${id}`),

  getCoreValues: async (guid) => await RequestModel.getJsonData(`/api/v1/logistics/case/${guid}/core-values`),

  getPossibleStatuses: async (guid) =>
    await RequestModel.getJsonData(`/api/v1/logistics/case/${guid}/possible-statuses`),

  getRouting: async (guid, queryParams) =>
    await RequestModel.getJsonData(`/api/v1/logistics/case/${guid}/routing`, { query: queryParams }),

  getSimilarCases: async (guid) => await RequestModel.getJsonData(`/api/v1/logistics/case/${guid}/similar-cases`),

  getAllShippingMethods: async () => await RequestModel.getJsonData(`/api/v1/logistics/all-shipping-methods`),

  getShipmentsForBundling: async ({
    customerNumber,
    serviceProviderId,
    departmentId,
    shipmentTypeId,
    logisticAction,
    postalCode,
    unlimitedBundling
  }) =>
    await RequestModel.getJsonData('/api/v1/logistics/shipments-for-bundling', {
      query: {
        customerNumber,
        serviceProviderId,
        departmentId,
        shipmentTypeId,
        logisticAction,
        postalCode,
        unlimitedBundling: Number(Boolean(unlimitedBundling))
      }
    }),

  getShippingMethods: async (guid, type, coreValues) =>
    await RequestModel.getJsonData(`/api/v1/logistics/case/${guid}/${type}/shipping-methods`, {
      query: coreValues
    }),

  executeAction: async (guid, body) => await RequestModel.postJsonData(`/api/v1/logistics/case/${guid}`, { body }),

  printLabel: async (guid, count = 1) =>
    await RequestModel.postJson(`/api/v1/logistics/case/${guid}/print-label`, { body: { count } }),

  getTask: async (taskId) => await RequestModel.getJsonData(`/api/v1/tasks/${taskId}`),

  reRunTask: async (taskId, state) =>
    await RequestModel.postJsonData(`/api/v1/tasks/rerun/${taskId}`, { body: { state } }),

  search: async (type, searchValue) =>
    await RequestModel.getJsonData(`/api/v1/logistics/search/${type}`, { query: { search: searchValue } }),

  getRerouteTargets: async () => await RequestModel.getJsonData(`/api/v1/logistics/reroute-targets`),

  importActivity: async (activityNumber) =>
    await RequestModel.postJsonData(`/api/v1/logistics/activity/${activityNumber}/import`, { body: {} }),

  attachFiles: async (guid, data) =>
    await RequestModel.postJson(`/api/v1/logistics/case/${guid}/files`, { body: data }),

  getSunflowerCostProposals: async (id) =>
    await RequestModel.getJson(`/api/v1/logistics/activity/${id}/sunflower-cost-proposals`),

  copySunflowerActivity: async (guid, sunflowerDb) =>
    await RequestModel.postJson(`/api/v1/logistics/case/${guid}/copy-sunflower-activity`, { body: { sunflowerDb } }),

  notifyAboutCostProposals: async (guid, data) =>
    await RequestModel.postJson(`/api/v1/logistics/case/${guid}/notify-about-cost-proposals`, { body: data }),

  warrantyCheck(hubCase) {
    const hasWarrantyTag = (hubCase?.tags || []).find((tag) => tag.name === 'in warranty flow');

    if (!hasWarrantyTag) {
      return null;
    }

    const dateBooked = moment(hubCase.createdAt);
    const purchaseDate = moment(hubCase.productData.purchaseDate);
    const months = dateBooked.diff(purchaseDate, 'months');
    const country = hubCase.sender.countryCode;
    const maxMonths = WARRANTY_TIME[country];

    if (!hubCase.productData.purchaseDate) {
      return 'Sales date is not defined';
    }

    if (months <= maxMonths) {
      return null;
    }

    return `The product purchased ${purchaseDate.format('YYYY-MM-DD')} and registered for service ${dateBooked.format(
      'YYYY-MM-DD'
    )}
which is longer than ${maxMonths} months according to the law of ${country}.`;
  }
};

export { LogisticsModel };
