import { PageHeader } from '@ant-design/pro-layout';
import { useRequest, useTimeout } from 'ahooks';
import { Alert, Button, InputNumber, Radio, Space, Table } from 'antd';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { AutoSearchInput } from '@/components/AutoSearchInput';
import { Block } from '@/components/Block';
import { LinkButton } from '@/components/LinkButton';
import { ROUTE } from '@/configs/general';
import { onError } from '@/helpers/Alert';
import { LogisticsModel } from '@/models/logistics';
import { ShipmentModel } from '@/models/shipment';

import { CASE_SEARCH_TYPE } from './configs';

const INPUT_ID = 'search';

const CaseTable = ({ data }) => {
  const columns = [
    {
      title: 'Activity',
      render: (_, row) => (
        <LinkButton to={`${ROUTE.LOGISTICS_ACTIVITY}/${row.guid}`}>{row.orderData.activityNumber}</LinkButton>
      ),
      key: 'activityNumber'
    },
    {
      title: 'Partner',
      render: (_, row) => row.servicePartner.name,
      key: 'partner'
    },
    {
      title: 'Provider',
      render: (_, row) => row.serviceProvider.name,
      key: 'provider'
    },
    {
      title: 'Customer',
      render: (_, row) => ShipmentModel.addressToString(ShipmentModel.hubToSenderellaContacts(row?.customer || {})),
      key: 'customer'
    }
  ];

  return <Table dataSource={data} columns={columns} rowKey="id" pagination={false} />;
};

const CaseSearchPage = (props) => {
  const [searchType, setSearchType] = useState(
    props.simplifiedFreeTextSearchArriving ? CASE_SEARCH_TYPE.CASES_COMMON : CASE_SEARCH_TYPE.CASES_STRICT
  );
  const [searchValue, setSearchValue] = useState('');
  const [newCaseActivityNumber, setNewCaseActivityNumber] = useState(null);
  const history = useHistory();

  const setFocus = () => document.querySelector(`#${INPUT_ID}`)?.focus?.();

  useTimeout(setFocus, 300);

  const searchRequest = useRequest((type) => LogisticsModel.search(type, searchValue), {
    manual: true,
    onError,
    onSuccess: (cases) => {
      if ((searchType !== CASE_SEARCH_TYPE.CASES_STRICT && !props.simplifiedFreeTextSearchArriving) || !cases?.length) {
        return;
      }

      history.push(
        `${ROUTE.LOGISTICS_ACTIVITY}/${cases[0].guid}${
          props.simplifiedFreeTextSearchArriving ? '/?simplifiedFreeTextSearchArriving=true' : ''
        }`
      );
    }
  });

  const importRequest = useRequest(() => LogisticsModel.importActivity(newCaseActivityNumber), {
    manual: true,
    onError,
    onSuccess: ({ guid }) => {
      history.push(`${ROUTE.LOGISTICS_ACTIVITY}/${guid}`);
    }
  });

  const handleSearch = () => searchRequest.run(searchType);

  const handleSearchTypeChange = (e) => {
    searchRequest.reset();
    setSearchType(e.target.value);
    setFocus();
  };

  const handleSearchChange = (value) => setSearchValue(value);

  const handleImport = () => {
    if (!newCaseActivityNumber) {
      return;
    }

    importRequest.run();
  };

  const placeholder =
    searchType === CASE_SEARCH_TYPE.CASES_STRICT
      ? 'Activity number or hub case GUID'
      : 'Serial, IMEI, ref no, gref, etc';

  const resultsFound = searchRequest.data?.length;

  return (
    <>
      <PageHeader title="Case search" />
      <Block fitContent={!resultsFound} flexVerticalAlign={!resultsFound} padded={resultsFound} transparent>
        <Space direction="vertical" size="middle">
          <Space className="ant-space-first-item-flex">
            <AutoSearchInput
              disabled={searchRequest.loading}
              loading={searchRequest.loading}
              allowClear
              placeholder={placeholder}
              id={INPUT_ID}
              onChange={handleSearchChange}
              onPressEnter={handleSearch}
              onSearch={handleSearch}
              size="large"
            />
          </Space>
          <Radio.Group onChange={handleSearchTypeChange} value={searchType}>
            <Radio value={CASE_SEARCH_TYPE.CASES_STRICT}>Activity number or hub case GUID</Radio>
            <Radio value={CASE_SEARCH_TYPE.CASES_COMMON}>Free text search</Radio>
          </Radio.Group>
          {searchType === CASE_SEARCH_TYPE.CASES_STRICT && searchRequest.data && !searchRequest.data.length && (
            <Alert type="info" message="Nothing found. Please try the free text search instead" />
          )}
          {searchType === CASE_SEARCH_TYPE.CASES_COMMON && searchRequest.data?.length > 0 && (
            <CaseTable data={searchRequest.data} />
          )}
          {searchType === CASE_SEARCH_TYPE.CASES_COMMON && searchRequest.data && (
            <Alert
              type="info"
              message={
                <Space direction="vertical">
                  <span>
                    Haven’t found the case you’re searching for? Load it to the system using the activity number:{' '}
                    <InputNumber controls={false} size="small" onChange={setNewCaseActivityNumber} />
                  </span>
                  <Button loading={importRequest.loading} onClick={handleImport} type="primary">
                    Convert activity to hub case
                  </Button>
                </Space>
              }
            />
          )}
        </Space>
      </Block>
    </>
  );
};

export { CaseSearchPage };
