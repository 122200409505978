const SESAM_PROJECT_ID = 'SESAM_EXTERNAL';

const SESAM_PROJECT = {
  id: SESAM_PROJECT_ID,
  name: 'Sesam',
  accessToken: '',
  createdAt: '1970-01-01T00:00:00Z',
  updatedAt: '1970-01-01T00:00:00Z',
  deletedAt: null,
  readOnly: true
};

export { SESAM_PROJECT, SESAM_PROJECT_ID };
