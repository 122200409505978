import { DeleteOutlined, EditOutlined, HomeOutlined, PlusOutlined } from '@ant-design/icons';
import { PageHeader } from '@ant-design/pro-layout';
import { useRequest } from 'ahooks';
import { Button, Flex, Input, Select, Space, Tag } from 'antd';
import { trim } from 'lodash';
import moment from 'moment';
import { useContext, useRef, useState } from 'react';

import { DateInline } from '@/components/DateInline';
import ExtendedTable from '@/components/ExtendedTable';
import { LinkButton } from '@/components/LinkButton';
import { ENTITY } from '@/configs/entity';
import { PERMISSION, ROUTE } from '@/configs/general';
import { onError } from '@/helpers/Alert';
import { userContext } from '@/helpers/userContext';
import { ContainersModel } from '@/models/containers';
import { EntitiesModel } from '@/models/entities';

import { STATUS, STATUS_LABEL } from './config';
import { ContainerEditor } from './ContainerEditor';

const ALL_CONTAINERS_FILTER = 'all';
const MORE_THAN_70_DAYS_FILTER = 'moreThan70Days';
const containersFilterOptions = [
  { value: ALL_CONTAINERS_FILTER, label: 'All containers' },
  { value: MORE_THAN_70_DAYS_FILTER, label: 'Away > 70 days ago' }
];

const ContainersPage = () => {
  const { hasPermission } = useContext(userContext);
  const tableRef = useRef(null);
  const [searchValue, setSearchValue] = useState('');
  const [containerToEdit, setContainerToEdit] = useState(null);
  const [containerFilter, setContainersFilter] = useState(ALL_CONTAINERS_FILTER);
  const canEdit = hasPermission(PERMISSION.OUTBOUND);
  const canDelete = hasPermission(PERMISSION.DELETE_CONTAINERS);

  const getContainersRequest = useRequest(
    ({ page, pageSize, commonSearch, filter = containerFilter }) =>
      EntitiesModel.getEntityValues(ENTITY.CONTAINER, {
        commonSearch,
        filters:
          filter === MORE_THAN_70_DAYS_FILTER
            ? { statusDate: { $lt: moment().subtract(70, 'days').toISOString() }, status: STATUS.AWAY }
            : {},
        pagination: { offset: page * pageSize, limit: pageSize }
      }),
    {
      manual: true,
      onError
    }
  );

  const search = (value, filter) => {
    tableRef.current.reset({ commonSearch: value ?? searchValue, filter });
  };

  const handleSearch = () => search();

  const deleteContainerRequest = useRequest((id) => ContainersModel.deleteContainer(id), {
    manual: true,
    onSuccess: handleSearch,
    onError
  });

  const handleSearchChanged = (e) => {
    setSearchValue(trim(e.target.value));
  };

  const handleContainerFilterChange = (value) => {
    setSearchValue('');
    setContainersFilter(value);
    search('', value);
  };

  const returnContainer = useRequest(
    (id) => ContainersModel.saveContainer(id, { status: STATUS.IN_STOCK, statusDate: new Date() }),
    {
      onSuccess: handleSearch,
      onError,
      manual: true
    }
  );

  const handleAddContainer = () => setContainerToEdit(0);
  const handleEditContainer = (id) => () => setContainerToEdit(id);
  const handleDeleteContainer = (id) => () => deleteContainerRequest.run(id);
  const handleReturnContainer = (id) => () => returnContainer.run(id);

  const hideEditor = () => {
    setContainerToEdit(null);
    handleSearch();
  };

  const actionsDisabled = returnContainer.loading || deleteContainerRequest.loading;

  const cols = [
    {
      title: 'Id',
      key: 'id',
      render: (_, row) => (
        <LinkButton to={`${ROUTE.CONTAINER}/${row.id}`} key="1">
          {row.id}
        </LinkButton>
      )
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Status date',
      dataIndex: 'statusDate',
      key: 'statusDate',
      render: (text) => <DateInline value={text} />
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <Tag color={status === STATUS.IN_STOCK ? 'success' : 'warning'}>
          {STATUS_LABEL[status] || status || 'No status'}
        </Tag>
      )
    },
    {
      title: 'Action',
      key: 'action',
      width: 100,
      render: (_, row) => (
        <Space>
          {row.status !== STATUS.IN_STOCK && (
            <Button
              type="primary"
              onClick={handleReturnContainer(row.id)}
              icon={<HomeOutlined />}
              disabled={actionsDisabled}
            >
              Check-in home
            </Button>
          )}
          {canEdit && (
            <Button
              type="primary"
              ghost
              onClick={handleEditContainer(row.id)}
              icon={<EditOutlined />}
              disabled={actionsDisabled}
            >
              Edit
            </Button>
          )}
          {canDelete && (
            <Button
              type="default"
              danger
              onClick={handleDeleteContainer(row.id)}
              icon={<DeleteOutlined />}
              disabled={actionsDisabled}
            >
              Delete
            </Button>
          )}
        </Space>
      )
    }
  ];

  return (
    <>
      <PageHeader
        title="Containers"
        extra={
          canEdit && (
            <Button onClick={handleAddContainer} icon={<PlusOutlined />} type="primary">
              Add new container
            </Button>
          )
        }
      />
      <ExtendedTable
        ref={tableRef}
        columns={cols}
        request={getContainersRequest}
        title={() => (
          <Flex gap="small">
            <Input.Search
              placeholder="Search for containers"
              size="large"
              enterButton
              value={searchValue}
              onChange={handleSearchChanged}
              onSearch={handleSearch}
              onPressEnter={handleSearch}
            />
            <Select
              className="full-width"
              style={{ maxWidth: 190 }}
              size="large"
              options={containersFilterOptions}
              value={containerFilter}
              onChange={handleContainerFilterChange}
            />
          </Flex>
        )}
      />
      <ContainerEditor id={containerToEdit} visible={containerToEdit !== null} onClose={hideEditor} />
    </>
  );
};

export { ContainersPage };
