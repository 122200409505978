import { ReloadOutlined } from '@ant-design/icons';
import { Button, Result } from 'antd';

const NotFound = (props) => {
  const { onReload, error } = props;

  const reload = () => {
    if (!onReload) {
      window.location.reload();

      return;
    }

    onReload();
  };

  const title = error
    ? `An error occured: ${error.name} ${error.message}`
    : 'Nothing found. If you are looking for the recently created activity, please reload this page in a few seconds.';

  return (
    <Result
      title={title}
      status="warning"
      extra={
        <Button icon={<ReloadOutlined spin />} onClick={reload} type="primary">
          Reload
        </Button>
      }
    />
  );
};

export { NotFound };
