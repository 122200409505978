import { notification } from 'antd';

import { ROUTE } from '@/configs/general';

// Extending builtin types is not supported in Babel
function NotFoundError(message) {
  this.name = 'NotFoundError';
  this.message = message;
  this.stack = new Error().stack;
}

NotFoundError.prototype = new Error();

function ServerError(message) {
  this.name = 'ServerError';
  this.message = message;
  this.stack = new Error().stack;
}

ServerError.prototype = new Error();

function ForbiddenError(message) {
  this.name = 'ForbiddenError';
  this.message = message;
  this.stack = new Error().stack;
}

ForbiddenError.prototype = new Error();

function UnauthorizedError(message) {
  this.name = 'UnauthorizedError';
  this.message = message;
  this.stack = new Error().stack;
}

UnauthorizedError.prototype = new Error();

const buildQuery = (url, query, useNullInBody) => {
  if (!query) {
    return url;
  }

  const queryString = Object.entries(query)
    // eslint-disable-next-line no-unused-vars
    .filter(([_, value]) => value !== undefined && (useNullInBody || value !== null))
    .map((pair) => pair.map(encodeURIComponent).join('='))
    .join('&');

  return `${url}?${queryString}`;
};

let notificationShown = false;

const showOutdatedNotification = () => {
  if (notificationShown) {
    return;
  }

  notification.error({
    message: 'Outdated',
    description: 'Please refresh the page to use up to date version',
    duration: null,
    // eslint-disable-next-line react/jsx-filename-extension
    closeIcon: <></>
  });

  notificationShown = true;
};

const RequestModel = {
  NotFoundError,
  ServerError,
  ForbiddenError,
  UnauthorizedError,

  async _parse(res) {
    if (res.status === 404) {
      throw new NotFoundError(`Not found: ${res.url}`);
    }

    const json = await res.json();

    if (res.status === 401) {
      const error = new UnauthorizedError(json.error.message || json.error || `UnauthorizedError: ${res.url}`);

      if (json.error === 'Logged out') {
        window.location.href = `${ROUTE.LOGIN}/?goBack=${encodeURIComponent(
          window.location.pathname + window.location.search
        )}`;

        error.bypassOutput = true;
      }

      throw error;
    }

    if (res.status === 403) {
      throw new ForbiddenError(json.error.message || json.error || `Forbidden: ${res.url}`);
    }

    if (![200, 201].includes(res.status)) {
      throw new ServerError(json.error.message || json.error || res.statusText);
    }

    const buildId = res.headers.get('x-build-id');

    if (buildId && process.env.BUILD_ID && process.env.BUILD_ID !== buildId) {
      showOutdatedNotification();
    }

    return json; // json.data && json.pagination ? json : json.data;
  },

  async getJson(path, { query, ...options } = {}) {
    const res = await fetch(buildQuery(path, query), {
      credentials: 'same-origin',
      ...options
    });

    return this._parse(res);
  },

  async postJson(path, { query, body, useNullInBody, ...options } = {}) {
    const res = await fetch(buildQuery(path, query, useNullInBody), {
      method: 'POST',
      credentials: 'same-origin',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
      ...options
    });

    return this._parse(res);
  },

  async deleteJson(path, { query, body, useNullInBody, ...options } = {}) {
    const res = await fetch(buildQuery(path, query, useNullInBody), {
      method: 'DELETE',
      credentials: 'same-origin',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
      ...options
    });

    return this._parse(res);
  },

  async getJsonData(...params) {
    return (await this.getJson(...params)).data;
  },

  async postJsonData(...params) {
    return (await this.postJson(...params)).data;
  },

  async deleteJsonData(...params) {
    return (await this.deleteJson(...params)).data;
  },

  parseUrlQuery(queryString) {
    if (!queryString) {
      return {};
    }

    const query = {};
    const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');

    for (let i = 0; i < pairs.length; i++) {
      const pair = pairs[i].split('=');
      query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }

    return query;
  }
};

export { RequestModel };
