import { CheckCard } from '@ant-design/pro-components';
import { useRequest } from 'ahooks';
import { Alert, Button, Collapse, Divider, Empty, Input, List, Radio, Space, Spin, Tag, TreeSelect } from 'antd';
import _ from 'lodash';
import React, { useState } from 'react';

import { DateInline } from '@/components/DateInline';
import { SelectAutocomplete } from '@/components/SelectAutocomplete';
import { ENTITY } from '@/configs/entity';
import { EntitiesModel } from '@/models/entities';
import { LogisticsModel } from '@/models/logistics';
import { ShipmentModel } from '@/models/shipment';

import { ACTION, ROUTING_DATA_MAPPING } from '../configs';
import styles from '../styles.module.scss';
import { AccessoriesDeviationInformCheckbox } from './AccessoriesDeviationInformCheckbox';
import { SelectAnyShippingMethod } from './SelectAnyShippingMethod';

const { addressToString } = ShipmentModel;

const ALLOWED_KEYS = ROUTING_DATA_MAPPING.map(({ newKey }) => newKey);
const NEW_SHIPMENT_GUID = 'new';

let destinationMap = {};

const TeamSelect = (props) => {
  const { data, loading } = useRequest(
    () =>
      EntitiesModel.getEntityValues(ENTITY.TEAM, {
        order: ['code']
      }),
    {
      formatResult: (result) => ({
        ...result,
        options: result?.data?.map(({ code, name }) => ({ label: `${code} (${name})`, value: code }))
      }),
      initialData: null
    }
  );

  return <SelectAutocomplete {...props} loading={loading} options={data?.options || []} skipMapping />;
};

const RerouteForm = ({ caseData, coreValues, outboundCompetence = {}, serviceProvider, routingData, onFinish }) => {
  const [rerouteTarget, setRerouteTarget] = useState();
  const [shipmentTypeId, setShipmentTypeId] = useState();
  const [bundleToShipment, setBundleTo] = useState(NEW_SHIPMENT_GUID);
  const [availableShipmentsOptions, setAvailableShipmentsOptions] = useState([]);
  const [routingOptions, setRoutingOptions] = useState(routingData);
  const withBundling = !outboundCompetence.disableBundling;

  const { data: rerouteTargets } = useRequest(() => LogisticsModel.getRerouteTargets(), {
    throwOnError: true,
    formatResult: (data) => {
      destinationMap = {};

      return _.sortBy(
        data
          .filter((x) => x.departments.length > 0)
          .map((workshop) => ({
            value: workshop.id,
            title: workshop.name,
            selectable: false,
            children: _.sortBy(
              workshop.departments.map((dep) => {
                destinationMap[dep.id] = dep;
                dep.serviceProviderId = workshop.id;

                return { value: dep.id, title: dep.name };
              }),
              (x) => x.title
            )
          })),
        (x) => (x.title.startsWith('Elcare Nordic') ? '!' : x.title) // Elcare first
      );
    }
  });

  const { data: shippingMethods, loading: loadingShippingMethods } = useRequest(
    () =>
      LogisticsModel.getShippingMethods(
        caseData.guid,
        ACTION.FORWARD /* This is not a mistake, we use shipping methods for forwarding */,
        coreValues
      ),
    {
      initialData: [],
      throwOnError: true,
      formatResult: (data) => data.map((x) => ({ id: Number(x.properties.serviceId), name: x.name })),
      onSuccess: (data) => {
        setBundleTo(NEW_SHIPMENT_GUID);

        if (data.length) {
          setShipmentTypeId(data[0].id);
        }
      },
      ready: Boolean(rerouteTargets && rerouteTargets.length)
    }
  );

  const { data: allShippingMethods, loading: loadingAllShippingMethods } = useRequest(
    () => LogisticsModel.getAllShippingMethods(),
    {
      initialData: [],
      throwOnError: true,
      formatResult: (data) => _.sortBy(data, (x) => x.name)
    }
  );

  const postalCode = rerouteTarget && destinationMap[rerouteTarget].contactData.postalCode;

  const { loading: loadingAvailableShipments } = useRequest(
    () =>
      LogisticsModel.getShipmentsForBundling({
        postalCode,
        serviceProviderId: destinationMap[rerouteTarget].serviceProviderId,
        shipmentTypeId, // We need senderella shipment type here
        logisticAction: ACTION.FORWARD, // This is okay, use forwarded shipments for bundling
        unlimitedBundling: outboundCompetence.unlimitedBundling ? 1 : undefined
      }),
    {
      initialData: [],
      throwOnError: true,
      refreshDeps: [shipmentTypeId, rerouteTarget, postalCode],
      ready: withBundling && Boolean(shipmentTypeId) && Boolean(rerouteTarget) && Boolean(postalCode),
      onSuccess: (result) => {
        const sortedShipments = _.sortBy(result, (x) => -x.id);

        setBundleTo(sortedShipments?.[0]?.guid || NEW_SHIPMENT_GUID);

        setAvailableShipmentsOptions(
          sortedShipments.map((shipment) => {
            const { createdAt, guid, packages, receiver } = shipment;
            const items = _.flatMap(packages, (p) => p.items);

            return {
              description: (
                <Space direction="vertical">
                  <span>
                    <DateInline asTag value={createdAt} /> <b>{shipment.shipmentType.name}</b>
                  </span>
                  <span>
                    <b>Receiver:</b>
                    <br />
                    {addressToString(receiver)}
                  </span>
                  <Collapse
                    size="small"
                    items={[
                      {
                        label: `Contains ${items.length} item${items.length !== 1 ? 's' : ''}`,
                        children: (
                          <List
                            size="small"
                            dataSource={items}
                            renderItem={(item) => (
                              <List.Item>
                                {item.customData?.activityNumber ? <Tag>{item.customData.activityNumber}</Tag> : null}
                                {item.brand} {item.model}
                                {item.senderRef ? `, ref: ${item.senderRef}` : null}
                              </List.Item>
                            )}
                          />
                        ),
                        onClick: (e) => e.stopPropagation()
                      }
                    ]}
                  />
                </Space>
              ),
              value: guid
            };
          })
        );
      }
    }
  );

  const setRoutingOption = (key) => (e) => {
    const value = e.target ? e.target.value : e;

    setRoutingOptions({ ...routingOptions, [key]: value });
  };

  const handleReroute = (alsoSetForwardingStatuses = undefined) => () => {
    onFinish(ACTION.REROUTE, {
      shipmentTypeId,
      alsoSetForwardingStatuses,
      bundleToShipmentId: bundleToShipment === NEW_SHIPMENT_GUID ? undefined : bundleToShipment,
      providerId: destinationMap[rerouteTarget].serviceProviderId,
      departmentId: rerouteTarget,
      routingData: _.pick(routingOptions, ALLOWED_KEYS)
    });
  };

  if (!rerouteTargets) {
    return (
      <Spin tip="Loading destinations">
        <div />
      </Spin>
    );
  }

  if (loadingShippingMethods || loadingAllShippingMethods) {
    return (
      <Spin tip="Loading shipping methods">
        <div />
      </Spin>
    );
  }

  if (!shippingMethods.length && !allShippingMethods.length) {
    return <Empty description="No shipping methods available" />;
  }

  const handleShipmentTypeChange = (e) => {
    const newShipmentId = e.target ? e.target.value : e;
    const newShipment = allShippingMethods.find((x) => x.id === newShipmentId);

    if (!newShipment) {
      return;
    }

    setShipmentTypeId(newShipmentId);
  };

  const handleShipmentChange = (guid) => {
    if (guid) {
      setBundleTo(guid === true ? NEW_SHIPMENT_GUID : guid);
    }
  };

  const showAvailableShipments = withBundling && availableShipmentsOptions.length > 0;

  return (
    <Space direction="vertical" size="large">
      {serviceProvider && serviceProvider.id !== caseData.serviceProvider.id && (
        <Alert
          type="warning"
          showIcon
          message={`Warning! This case is registered to ${serviceProvider.name}. If you think that it is wrong, you can reroute it to another service provider.`}
        />
      )}
      <Space direction="vertical">
        <b>Destination:</b>
        <TreeSelect
          treeData={rerouteTargets}
          className="full-width"
          placeholder="Select destination"
          value={rerouteTarget}
          onChange={setRerouteTarget}
          treeExpandAction="click"
          showSearch
          treeNodeFilterProp="title"
        />
      </Space>
      {shippingMethods.length > 0 && (
        <Space direction="vertical">
          <b>Recommended shipping methods:</b>
          <div>
            <Radio.Group
              value={shipmentTypeId}
              onChange={handleShipmentTypeChange}
              disabled={loadingAvailableShipments}
            >
              {shippingMethods.map((x) => (
                <Radio key={x.id} value={x.id}>
                  {x.name} ({x?.settings?.sesamId ? `Sesam ID: ${x.settings.sesamId} / ` : ''}Hub ID: {x.id})
                </Radio>
              ))}
            </Radio.Group>
            <Divider plain>or select from all shipping methods</Divider>
            <SelectAnyShippingMethod
              allShippingMethods={allShippingMethods}
              value={shipmentTypeId}
              onChange={handleShipmentTypeChange}
            />
          </div>
        </Space>
      )}
      {!shippingMethods.length && (
        <Space direction="vertical">
          <b>Select shipping method:</b>
          <SelectAnyShippingMethod
            allShippingMethods={allShippingMethods}
            value={shipmentTypeId}
            onChange={handleShipmentTypeChange}
          />
        </Space>
      )}
      {loadingAvailableShipments && (
        <Spin tip="Loading shipments">
          <div style={{ height: 60 }} />
        </Spin>
      )}
      {!loadingAvailableShipments && rerouteTarget && shipmentTypeId && (
        <>
          <Space direction="vertical">
            {showAvailableShipments && <b>Shipment to bundle together (postal code: {postalCode}):</b>}
            <div>
              {showAvailableShipments && (
                <>
                  <CheckCard.Group
                    className={styles.shipmentCheckCard}
                    value={bundleToShipment}
                    options={availableShipmentsOptions}
                    onChange={handleShipmentChange}
                  />
                  <Divider plain>or create new shipment</Divider>
                </>
              )}

              <CheckCard
                className={styles.shipmentCheckCard}
                title={`New shipment (postal code: ${postalCode})`}
                checked={bundleToShipment === NEW_SHIPMENT_GUID}
                onChange={handleShipmentChange}
              />
            </div>
          </Space>
        </>
      )}
      <Collapse
        size="small"
        defaultActiveKey="routingData"
        items={[
          {
            key: 'routingData',
            label: `Change service properties`,
            children: (
              <Space direction="vertical">
                {ROUTING_DATA_MAPPING.map(({ newKey: key, label }) => (
                  <React.Fragment key={key}>
                    <b>{label}</b>
                    {key === 'group' ? (
                      <TeamSelect
                        className="full-width"
                        value={routingOptions?.[key]}
                        onChange={setRoutingOption(key)}
                      />
                    ) : (
                      <Input
                        className="full-width"
                        value={routingOptions?.[key] || ''}
                        onChange={setRoutingOption(key)}
                      />
                    )}
                  </React.Fragment>
                ))}
              </Space>
            )
          }
        ]}
      />
      <AccessoriesDeviationInformCheckbox />
      <Space direction="horizontal">
        <Button
          type="primary"
          disabled={loadingAvailableShipments || !bundleToShipment || !shipmentTypeId || !rerouteTarget}
          onClick={handleReroute(true)}
        >
          Reroute (arr+start+wext)
        </Button>
        <Button
          type="primary"
          disabled={loadingAvailableShipments || !bundleToShipment || !shipmentTypeId || !rerouteTarget}
          onClick={handleReroute()}
        >
          Reroute (no status change)
        </Button>
      </Space>
    </Space>
  );
};

export { RerouteForm };
