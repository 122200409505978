export const SHIPMENTS_TYPE = {
  FORWARDED: 'forwarded',
  PUSHED: 'pushed',
  RETURNED: 'returned',
  SHOW_INFO: 'showInfo'
};

export const SHIPMENT_ACTIVITY_STATUS = {
  ALL: 'all',
  PROCESSED: 'processed',
  UNPROCESSED: 'unprocessed'
};

export const SHIPMENT_LABEL_TYPE = {
  BIG_ZEBRA: 'bigZebra',
  NONE: 'none'
};
