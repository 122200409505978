import { PageHeader } from '@ant-design/pro-layout';
import { useDebounceFn, useRequest, useTimeout } from 'ahooks';
import { Space, Spin, Tooltip } from 'antd';
import _ from 'lodash';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { AutoSearchInput } from '@/components/AutoSearchInput';
import { NotFound } from '@/components/NotFound';
import { COLOR, ROUTE } from '@/configs/general';
import { onError, showError, showSuccess } from '@/helpers/Alert';
import { LogisticsModel } from '@/models/logistics';
import { RequestModel } from '@/models/request';
import { ShipmentModel } from '@/models/shipment';

import { CopySunflowerActivity } from './components/CopySunflowerActivity';
import { ViewCase } from './components/ViewCase';
import { ViewShipment } from './components/ViewShipment';
import { CASE_SEARCH_TYPE, TASK_RESULT, TASK_TYPE, TASK_TYPE_LABEL } from './configs';
import { LogisticsProvider } from './logisticsContext';

const INPUT_ID = 'search';

const ITEM_TYPE = {
  ACTIVITY: 'activity',
  SHIPMENT: 'shipment'
};

const getTitle = (data, itemType) => {
  if (!data || (itemType === ITEM_TYPE.ACTIVITY && !data.caseData) || (itemType === ITEM_TYPE.SHIPMENT && !data.guid)) {
    return `Looking for ${itemType === ITEM_TYPE.ACTIVITY ? 'an' : 'a'} ${itemType}`;
  }

  const id = itemType === ITEM_TYPE.ACTIVITY && data.caseData?.orderData?.activityNumber;

  return (
    <Space size="large">
      {id && <img src={`https://senderella.io/v1/barcode/code39?text=${id}&includetext=0`} alt={id} />}
      <CopySunflowerActivity caseData={data.caseData} />
      {data.caseData?.orderData?.activityNumber && <span>RefNo: {data.caseData.orderData.refNo}</span>}
      {data.caseData?.id && (
        <span>
          Hub case:{' '}
          <a target="_blank" rel="noreferrer" href={`https://serviceorderhub.com/admin/orders/${data.caseData.id}`}>
            hub{data.caseData.id}
          </a>
        </span>
      )}
      {itemType === ITEM_TYPE.SHIPMENT && <span>Shipment: {data.guid}</span>}
    </Space>
  );
};

const ViewItemPage = () => {
  const [searchValue, changeSearchValue] = useState('');
  const [searchTooltip, setSearchTooltip] = useState('');
  const { identifier, type } = useParams();
  const history = useHistory();
  const { simplifiedFreeTextSearchArriving } = RequestModel.parseUrlQuery(window.location.search);

  const setFocus = () => document.querySelector(`#${INPUT_ID}`)?.focus?.();

  useTimeout(setFocus, 300);

  const hideTooltip = useDebounceFn(() => setSearchTooltip(''), { wait: 3000 });

  const getItem = useRequest(
    () =>
      type === ITEM_TYPE.ACTIVITY ? LogisticsModel.getActivityCase(identifier) : ShipmentModel.getShipment(identifier),
    {
      refreshDeps: [identifier],
      formatResult: (data) => {
        if (type === ITEM_TYPE.SHIPMENT) {
          return data;
        }

        if (data.caseData?.caseMessages?.length) {
          data.caseData.caseMessages = _.sortBy(data.caseData.caseMessages, (x) => -x.id);
        }

        if (data.caseData?.caseStatuses?.length) {
          data.caseData.caseStatuses = _.sortBy(data.caseData.caseStatuses, (x) => -x.id);
        }

        return data;
      },
      onError,
      throwOnError: false
    }
  );

  const reloadItem = () => getItem.run();

  // eslint-disable-next-line no-unused-vars
  const getTask = useRequest((id, fetchedOnActionExecution = false) => LogisticsModel.getTask(id), {
    manual: true,
    pollingInterval: 3000,
    onSuccess: (result, params) => {
      if (![TASK_RESULT.FINISHED, TASK_RESULT.FAILED].includes(result.status)) {
        return;
      }

      // Remove prefix if existed
      const taskType = /(.*_)?(.+)/.exec(result.type)[2];

      if (result.status === TASK_RESULT.FINISHED) {
        if (params[1] && taskType === TASK_TYPE.ARRIVE && simplifiedFreeTextSearchArriving) {
          history.push(ROUTE.FREE_TEXT_SEARCH_CASE);

          return;
        }

        showSuccess(`${TASK_TYPE_LABEL[taskType]} task processed successfully`, { displayTime: 5 });
      } else {
        showError(`${TASK_TYPE_LABEL[taskType]} task failed. Check corresponding tab for details`, {
          displayTime: 10
        });
      }

      getTask.cancel();
      getTask.mutate(null);
      reloadItem();
    },
    onError,
    throwOnError: true
  });

  const searchCase = useRequest((value) => LogisticsModel.search(CASE_SEARCH_TYPE.CASES_STRICT, value), {
    manual: true,
    debounceInterval: 500,
    onError,
    onSuccess: (cases) => {
      if (!cases?.length) {
        setSearchTooltip('Nothing found');
        hideTooltip.run();

        return;
      }

      setSearchTooltip('');
      hideTooltip.cancel();
      history.push(`${ROUTE.LOGISTICS_ACTIVITY}/${cases[0].guid}`);
    }
  });

  const executeAction = useRequest((body) => LogisticsModel.executeAction(body.guid, body), {
    manual: true,
    throwOnError: false,
    onSuccess: (result) => {
      getTask.run(result.taskId, true);
    }
  });

  const search = (value = searchValue) => {
    if (value?.toString()?.length < 6) {
      return;
    }

    if (value === identifier) {
      getItem.refresh();

      return;
    }

    searchCase.run(value);
  };

  const handleSearchExecute = () => search();

  const handleSearchChange = (value) => {
    changeSearchValue(value);
    search(value);
  };

  const isLoading = getItem.loading || executeAction.loading || Boolean(getTask?.data?.status);
  // eslint-disable-next-line no-nested-ternary
  const spinTip = executeAction.loading ? 'Processing' : getTask?.data ? `Performing: ${getTask.data.data?.state}` : '';
  const { data } = getItem;

  return (
    <LogisticsProvider caseData={data?.caseData}>
      <PageHeader
        className="ant-page-header-extra-input"
        title={getTitle(data, type)}
        extra={
          type === ITEM_TYPE.ACTIVITY && (
            <Tooltip placement="bottom" open={searchTooltip} title={searchTooltip} color={COLOR.RED}>
              <AutoSearchInput
                allowClear
                flushevents={['onSearch', 'onPressEnter']}
                placeholder="Activity number or hub case GUID"
                size="large"
                id={INPUT_ID}
                onChange={handleSearchChange}
                onSearch={handleSearchExecute}
                onPressEnter={handleSearchExecute}
                loading={searchCase.loading}
              />
            </Tooltip>
          )
        }
      />
      <Spin className="ant-spin-inner-fixed" spinning={isLoading} tip={spinTip}>
        {!data && getItem.error && <NotFound onReload={getItem.refresh} error={getItem.error} />}
        {/* Setting a key is the easiest way to re-init Editable form and other components */}
        {type === ITEM_TYPE.ACTIVITY && data?.caseData && (
          <ViewCase
            key={data.caseData.id}
            caseData={data.caseData}
            warehouseData={data.warehouseData}
            coreValues={data.coreValues}
            routingData={data.routing}
            messages={data.messages}
            suggestedAction={data.suggestedAction}
            shippingMethods={data.shippingMethods}
            allTasks={data.allTasks}
            onExecuteAction={executeAction.run}
            onReload={reloadItem}
          />
        )}
        {type === ITEM_TYPE.SHIPMENT && data?.guid && <ViewShipment data={data} onRefresh={getItem.refresh} />}
      </Spin>
    </LogisticsProvider>
  );
};

export { ViewItemPage };
