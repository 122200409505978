import 'moment/locale/en-gb';
import 'core-js/stable';
import 'regenerator-runtime/runtime';

import moment from 'moment';
// eslint-disable-next-line no-unused-vars
import momentWeekdayCalc from 'moment-weekday-calc';
import React from 'react';
import { createRoot } from 'react-dom/client';

import { App } from './App';

moment.updateLocale('en', {
  week: {
    dow: 1
  }
});
const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);
