import { DeleteOutlined, EditOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { PageHeader } from '@ant-design/pro-layout';
import { useRequest } from 'ahooks';
import { Button, Checkbox, Drawer, Form, Input, Select, Space } from 'antd';
import { useContext, useRef, useState } from 'react';

import ExtendedTable from '@/components/ExtendedTable';
import { LinkButton } from '@/components/LinkButton';
import { ENTITY } from '@/configs/entity';
import { PERMISSION } from '@/configs/general';
import { SchemaForm } from '@/containers/schemaForm/SchemaForm';
import { onError } from '@/helpers/Alert';
import { userContext } from '@/helpers/userContext';
import { EntitiesModel } from '@/models/entities';

const CaseColumnsEditor = () => (
  <Form.List name="caseColumns">
    {(fields, { add, remove }) => (
      <>
        {fields.map(({ key, name, ...restField }) => (
          <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
            <Form.Item {...restField} name={[name, 'key']}>
              <Input placeholder="Key" />
            </Form.Item>
            <Form.Item {...restField} name={[name, 'title']}>
              <Input placeholder="Title" />
            </Form.Item>
            <MinusCircleOutlined onClick={() => remove(name)} />
          </Space>
        ))}
        <Form.Item>
          <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
            Add field
          </Button>
        </Form.Item>
      </>
    )}
  </Form.List>
);

const FORM_TYPES = ['string', 'boolean', 'number'].map((x) => ({ label: x, value: x }));
const FormDataEditor = () => (
  <Form.List name="formData">
    {(fields, { add, remove }) => (
      <>
        {fields.map(({ key, name, ...restField }) => (
          <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
            <Form.Item {...restField} name={[name, 'id']}>
              <Input placeholder="Id" />
            </Form.Item>
            <Form.Item {...restField} name={[name, 'description']}>
              <Input placeholder="Description" />
            </Form.Item>
            <Form.Item {...restField} name={[name, 'type']}>
              <Select placeholder="Type" options={FORM_TYPES} />
            </Form.Item>
            <Form.Item {...restField} name={[name, 'required']} valuePropName="checked">
              <Checkbox>Required</Checkbox>
            </Form.Item>
            <MinusCircleOutlined onClick={() => remove(name)} />
          </Space>
        ))}
        <Form.Item>
          <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
            Add field
          </Button>
        </Form.Item>
      </>
    )}
  </Form.List>
);

const WebhookEditor = () => (
  <>
    <Form.Item name={['webhookData', 'url']} label="URL">
      <Input placeholder="AMQP URL" />
    </Form.Item>
    <Form.Item name={['webhookData', 'queueName']} label="Queue name">
      <Input placeholder="Queue name" />
    </Form.Item>
  </>
);

const CaseListForm = (props) => {
  const { id, visible, onClose, onSuccess = undefined } = props;

  return (
    <Drawer
      title={id ? 'Edit case list' : 'Add new case list'}
      open={visible}
      onClose={onClose}
      width={720}
      destroyOnClose
    >
      <SchemaForm
        entity={ENTITY.CASE_LIST}
        id={id || null}
        onSuccess={onSuccess || onClose}
        withRelations={false}
        predefinedObjects={{
          caseColumns: { type: 'array', component: CaseColumnsEditor },
          formData: { type: 'array', component: FormDataEditor },
          webhookData: { type: 'object', component: WebhookEditor }
        }}
      />
    </Drawer>
  );
};

const CaseListEditor = () => {
  const { hasPermission } = useContext(userContext);
  const tableRef = useRef(null);
  const [toEdit, setToEdit] = useState(null);
  const canEdit = hasPermission(PERMISSION.OUTBOUND);
  const canDelete = hasPermission(PERMISSION.DELETE_CONTAINERS);

  const getRequest = useRequest(
    ({ page, pageSize }) =>
      EntitiesModel.getEntityValues(ENTITY.CASE_LIST, {
        pagination: { offset: page * pageSize, limit: pageSize }
      }),
    {
      manual: true,
      onError
    }
  );

  const search = (value, filter) => {
    tableRef.current.reset({ filter });
  };

  const handleSearch = () => search();

  const deleteRequest = useRequest((id) => EntitiesModel.deleteEntityInstance(ENTITY.CASE_LIST, id), {
    manual: true,
    onSuccess: handleSearch,
    onError
  });

  const handleAdd = () => setToEdit(0);
  const handleEdit = (id) => () => setToEdit(id);
  const handleDelete = (id) => () => deleteRequest.run(id);

  const hideEditor = () => {
    setToEdit(null);
    handleSearch();
  };

  const actionsDisabled = deleteRequest.loading;

  const cols = [
    {
      title: 'Id',
      key: 'id',
      dataIndex: 'id'
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (_, row) => (
        <LinkButton to={`/case-lists/${row.slug}`} key="1">
          {row.name}
        </LinkButton>
      )
    },
    {
      title: 'Tags',
      dataIndex: 'tags',
      key: 'tags',
      render: (tags) => (tags || []).join(', ')
    },
    {
      title: 'Action',
      key: 'action',
      width: 100,
      render: (_, row) => (
        <Space>
          {canEdit && (
            <Button
              type="primary"
              ghost
              onClick={handleEdit(row.id)}
              icon={<EditOutlined />}
              disabled={actionsDisabled}
            >
              Edit
            </Button>
          )}
          {canDelete && (
            <Button
              type="default"
              danger
              onClick={handleDelete(row.id)}
              icon={<DeleteOutlined />}
              disabled={actionsDisabled}
            >
              Delete
            </Button>
          )}
        </Space>
      )
    }
  ];

  return (
    <>
      <PageHeader
        title="Case lists"
        extra={
          canEdit && (
            <Button onClick={handleAdd} icon={<PlusOutlined />} type="primary">
              Add new case list
            </Button>
          )
        }
      />
      <ExtendedTable ref={tableRef} columns={cols} request={getRequest} />
      <CaseListForm id={toEdit} visible={toEdit !== null} onClose={hideEditor} />
    </>
  );
};

export { CaseListEditor };
