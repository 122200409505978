export const LAYOUT = {
  L4_W19: {
    labelCol: { span: 4 },
    wrapperCol: { span: 19 }
  },
  L5_W19: {
    labelCol: { span: 5 },
    wrapperCol: { span: 19 }
  },
  L6_W17: {
    labelCol: { span: 6 },
    wrapperCol: { span: 17 }
  },
  L6_W18: {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 }
  },
  L8_W15: {
    labelCol: { span: 8 },
    wrapperCol: { span: 15 }
  },
  L8_W16: {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 }
  },
  L9_W15: {
    labelCol: { span: 9 },
    wrapperCol: { span: 15 }
  },
  L11_W12: {
    labelCol: { span: 11 },
    wrapperCol: { span: 12 }
  },
  L14_W9: {
    labelCol: { span: 14 },
    wrapperCol: { span: 9 }
  }
};

export const LAYOUT_ONLY_WRAPPER = {
  W15_O8: {
    wrapperCol: { span: 15, offset: 8 }
  },
  W16_O8: {
    wrapperCol: { span: 16, offset: 8 }
  }
};
