import { PageHeader } from '@ant-design/pro-layout';
import { Tabs } from 'antd';

import { Block } from '@/components/Block';

import { ProjectsList } from './ProjectsList';
import { UsersList } from './UsersList';

const AuthPage = () => {
  return (
    <>
      <PageHeader title="Auth access control" />
      <Tabs
        className="tabs-with-block settings-tabs collapsed-headers-clickable-arrow"
        defaultActiveKey="users"
        type="card"
        destroyInactiveTabPane
        items={[
          {
            key: 'users',
            label: 'Users',
            children: (
              <Block>
                <UsersList />
              </Block>
            )
          },
          {
            key: 'projects',
            label: 'Projects',
            children: (
              <Block>
                <ProjectsList />
              </Block>
            )
          }
        ]}
      />
    </>
  );
};

export { AuthPage };
