import { DeleteOutlined, EditOutlined, PlusOutlined, RedoOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { Button, Drawer, Form, Skeleton, Space, Spin, Table } from 'antd';
import _ from 'lodash';
import { useState } from 'react';

import { SelectAutocomplete } from '@/components/SelectAutocomplete';
import { ENTITY } from '@/configs/entity';
import { SchemaForm } from '@/containers/schemaForm/SchemaForm';
import { EntitiesModel } from '@/models/entities';
import { LogisticsModel } from '@/models/logistics';

const RESULTS_LIMIT = 50;

const Teams = () => {
  const [teamToEditIndex, setTeamToEditIndex] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(RESULTS_LIMIT);
  const [teamShippingMethods, setTeamShippingMethods] = useState([]);
  const [allShippingMethodsSorted, setAllShippingMethodsSorted] = useState([]);
  const { data, loading, refresh, run } = useRequest(
    (currentPage = 1, limit = RESULTS_LIMIT) =>
      EntitiesModel.getEntityValues(ENTITY.TEAM, {
        order: ['name'],
        pagination: { offset: (currentPage - 1) * limit, limit }
      }),
    { initialData: null }
  );

  const { data: allShippingMethods, loading: loadingAllShippingMethods } = useRequest(
    () => LogisticsModel.getAllShippingMethods(),
    {
      initialData: [],
      throwOnError: true
    }
  );

  const deleteEntityInstance = useRequest((id) => EntitiesModel.deleteEntityInstance(ENTITY.TEAM, id), {
    manual: true,
    onSuccess: refresh
  });

  const handleEditClick = (teamIndex) => () => {
    setTeamToEditIndex(teamIndex);

    if (!teamIndex) {
      setTeamShippingMethods([]);
    }
  };
  const handleDeleteClick = (id) => () => deleteEntityInstance.run(id);

  const handleDataLoaded = (loadedData) => {
    const favoriteMethods = [];
    const otherMethods = [];
    const teamMethods = loadedData?.data?.favoriteShippingMethods || [];

    for (const method of allShippingMethods) {
      if (teamMethods.includes(method.id)) {
        favoriteMethods.push(method);
      } else {
        otherMethods.push(method);
      }
    }

    setTeamShippingMethods(teamMethods);

    setAllShippingMethodsSorted([
      ..._.sortBy(favoriteMethods, (x) => x.name),
      ..._.sortBy(otherMethods, (x) => x.name)
    ]);
  };

  const handleFavoriteShipmentChanges = (shipmentIds) => setTeamShippingMethods(shipmentIds);

  const handleSuccessChanges = () => {
    setTeamToEditIndex(null);
    setTeamShippingMethods([]);
    refresh();
  };

  const handlePaginationChange = (currentPage, limit) => {
    setPage(currentPage);
    setPageSize(limit);
    run(currentPage, limit);
  };

  const transformSchemaFormValues = (values) => ({ ...values, favoriteShippingMethods: teamShippingMethods });

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name'
    },
    {
      title: 'Code',
      dataIndex: 'code'
    },
    {
      title: 'Actions',
      width: 200,
      render: (__, rowData, index) => (
        <Actions
          data={rowData}
          index={index}
          onEditClick={handleEditClick}
          onDeleteClick={handleDeleteClick}
          disabled={deleteEntityInstance.loading}
        />
      )
    }
  ];

  const paginationProps = {
    disable: loading,
    page,
    pageSize,
    position: ['bottomLeft'],
    onChange: handlePaginationChange,
    total: data?.pagination?.count || 0
  };

  return (
    <Skeleton active loading={!data?.data}>
      <Spin spinning={loading || loadingAllShippingMethods}>
        <Space className="tab-actions">
          <Button type="primary" onClick={handleEditClick('new')} icon={<PlusOutlined />}>
            Add new team
          </Button>
          <Button onClick={refresh} icon={<RedoOutlined />}>
            Refresh
          </Button>
        </Space>
        <Table dataSource={data?.data} columns={columns} rowKey="id" pagination={paginationProps} />
      </Spin>
      <Drawer
        title={teamToEditIndex === 'new' ? 'Add new team' : `Edit team: ${data?.data?.[teamToEditIndex]?.name || []}`}
        open={teamToEditIndex !== null}
        onClose={handleEditClick(null)}
        width={720}
        destroyOnClose
      >
        <SchemaForm
          entity={ENTITY.TEAM}
          id={data?.data?.[teamToEditIndex]?.id}
          hiddenFieldsKeys={['favoriteShippingMethods']}
          onDataLoaded={handleDataLoaded}
          onSuccess={handleSuccessChanges}
          transformValuesBeforeChange={transformSchemaFormValues}
          formSuffix={
            <Form.Item label="Favorite shipping methods:">
              <SelectAutocomplete
                options={allShippingMethodsSorted.map((x) => ({
                  value: x.id,
                  label: `${x.name} (${x?.settings?.sesamId ? `Sesam ID: ${x.settings.sesamId} / ` : ''}Hub ID: ${
                    x.id
                  })`
                }))}
                skipMapping
                className="ant-select-no-ellipsis full-width"
                popupClassName="select-nowrap"
                mode="multiple"
                value={teamShippingMethods}
                onSearch={null}
                onChange={handleFavoriteShipmentChanges}
              />
            </Form.Item>
          }
        />
      </Drawer>
    </Skeleton>
  );
};

const Actions = (props) => {
  const { data, disabled, index, onEditClick, onDeleteClick } = props;

  return (
    <Space>
      <Button type="primary" onClick={onEditClick(index)} icon={<EditOutlined />} disabled={disabled}>
        Edit
      </Button>
      <Button type="primary" danger onClick={onDeleteClick(data.id)} icon={<DeleteOutlined />} disabled={disabled}>
        Delete
      </Button>
    </Space>
  );
};

export { Teams };
