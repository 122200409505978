import { useRequest } from 'ahooks';
import { Empty, List, Spin, Table } from 'antd';
import { flatMap } from 'lodash';

import { DateInline } from '@/components/DateInline';
import { LinkButton } from '@/components/LinkButton';
import { ROUTE } from '@/configs/general';
import { ContainersModel } from '@/models/containers';

const ContainerPreview = (props) => {
  const { data } = props;

  return (
    <List
      size="small"
      bordered
      dataSource={flatMap(data.packages, (x) => x.items)}
      renderItem={(x) => {
        const hubGuid = x.customData.hubGuid;

        return (
          <List.Item>
            {hubGuid ? (
              <LinkButton to={`${ROUTE.LOGISTICS_ACTIVITY}/${hubGuid}`}>{x.customData.activityNumber}</LinkButton>
            ) : (
              x.customData.activityNumber
            )}{' '}
            {x.brand} {x.model} {x.senderRef}{' '}
          </List.Item>
        );
      }}
    />
  );
};

const ContainerItems = (props) => {
  const { containerId } = props;
  const { data } = useRequest(() => ContainersModel.getShipmentsByContainerId(containerId), {
    initialData: null
  });

  if (!data) {
    return (
      <Spin>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      </Spin>
    );
  }

  const cols = [
    {
      title: 'Sender',
      dataIndex: 'sender',
      key: 'sender',
      render: (_, row) =>
        [row.sender.name, row.sender.address, row.sender.postalCode, row.sender.city, row.sender.countryCode].join(' ')
    },
    {
      title: 'Receiver',
      dataIndex: 'receiver',
      key: 'receiver',
      render: (_, row) =>
        [
          row.receiver.name,
          row.receiver.address,
          row.receiver.postalCode,
          row.receiver.city,
          row.receiver.countryCode
        ].join(' ')
    },
    {
      title: 'Created at',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_, row) => <DateInline value={row.createdAt} />
    }
  ];

  return (
    <Table
      columns={cols}
      dataSource={data.data}
      rowKey="guid"
      expandable={{
        expandedRowRender: (row) => <ContainerPreview data={row} />
      }}
    />
  );
};

export { ContainerItems };
