/* eslint-disable react/no-array-index-key */

import { DeleteOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { Alert, Button, Card, Col, Flex, Form, Input, InputNumber, Row, Select, Space, Table } from 'antd';
import _ from 'lodash';
import { useEffect, useState } from 'react';

import { DateInline } from '@/components/DateInline';
import { onError, showSuccess } from '@/helpers/Alert';
import { LogisticsModel } from '@/models/logistics';

import { ACTION } from '../configs';

const detailColumns = [
  { title: '#', dataIndex: 'line', key: 'line', align: 'right', width: '5%' },
  { title: 'Type', dataIndex: 'type', key: 'type', width: '10%' },
  { title: 'Text', dataIndex: 'text', key: 'text' },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    key: 'quantity',
    render: (x) => Number(x).toFixed(0),
    align: 'right',
    width: '5%'
  },

  {
    title: 'Price',
    dataIndex: 'priceNet',
    key: 'priceNet',
    render: (x) => <strong>{Number(x).toFixed(2)}</strong>,
    align: 'right',
    width: '10%'
  }
];

const COST_PROPOSAL_TYPES = [
  { label: 'repair', value: 'repair' },
  { label: 'scrap', value: 'scrap' },
  { label: 'RUR', value: 'RUR' }
];

const CURRENCIES = [
  { label: 'SEK', value: 'SEK' },
  { label: 'NOK', value: 'NOK' },
  { label: 'DKK', value: 'DKK' },
  { label: 'EUR', value: 'EUR' }
];

const LINE_TYPES = [
  { label: 'labor', value: 'labor' },
  { label: 'shipping', value: 'shipping' },
  { label: 'spare part', value: 'spare part' },
  { label: 'deductible', value: 'deductible' }
];

const CostProposalForm = ({ value, onChange, isLoading }) => {
  const [form] = Form.useForm();
  const [totalPrice, setTotalPrice] = useState(0);

  const onFormValuesChange = (changed, allFields) => {
    if (!allFields) {
      return;
    }
    const price = _.sum(allFields.details.map((x) => (Number(x.priceNet) || 0) * (Number(x.quantity) || 0)));
    setTotalPrice(price);
    allFields.priceNet = price;
    allFields.isActive = true;
    allFields.timeStamp = new Date().toISOString();

    for (let i = 0; i < allFields.details.length; i++) {
      allFields.details[i].line = i + 1;
    }
    onChange(allFields);
  };

  useEffect(() => onFormValuesChange(undefined, value), [value]);

  return (
    <Card title="New Cost Proposal" loading={isLoading}>
      <Form
        form={form}
        layout="vertical"
        onValuesChange={onFormValuesChange}
        initialValues={
          value || {
            externalId: '',
            name: 'repair',
            currency: 'SEK',
            comment: '',
            details: [{ type: 'labor', text: '', priceNet: 0, quantity: 0 }]
          }
        }
      >
        <Form.Item label="External Id (comma separated)" name="externalId">
          <Input />
        </Form.Item>
        <Form.Item label="Type" name="name" rules={[{ required: true }]}>
          <Select options={COST_PROPOSAL_TYPES} />
        </Form.Item>
        <Form.Item label="Currency" name="currency">
          <Select options={CURRENCIES} />
        </Form.Item>
        <Form.Item label="Comment" name="comment">
          <Input.TextArea />
        </Form.Item>
        <Form.Item label="Lines">
          <Row gutter={24}>
            <Col span={2}>
              <strong>#</strong>
            </Col>
            <Col span={6}>
              <strong>Type</strong>
            </Col>
            <Col span={6}>
              <strong>Text</strong>
            </Col>
            <Col span={4}>
              <strong>Price Net</strong>
            </Col>
            <Col span={4}>
              <strong>Quantity</strong>
            </Col>
          </Row>
          <Form.List name="details">
            {(fields, { add, remove }) => (
              <div>
                {fields.map(({ key, name, ...restField }, i) => (
                  <Row key={key} gutter={12}>
                    <Col span={2}>{i + 1}</Col>
                    <Col span={6}>
                      <Form.Item {...restField} name={[name, 'type']} rules={[{ required: true }]}>
                        <Select options={LINE_TYPES} />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item {...restField} name={[name, 'text']}>
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item {...restField} name={[name, 'priceNet']}>
                        <InputNumber step={0.01} min={0} />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item {...restField} name={[name, 'quantity']}>
                        <InputNumber step={1} min={0} />
                      </Form.Item>
                    </Col>
                    <Col span={1}>
                      <Button type="link" onClick={() => remove(name)} icon={<DeleteOutlined />} />
                    </Col>
                  </Row>
                ))}
                <br />
                <Button type="dashed" onClick={() => add({ priceNet: 0, quantity: 1 })}>
                  + Add line
                </Button>
              </div>
            )}
          </Form.List>
        </Form.Item>
        <h1>Total: {totalPrice.toFixed(2)}</h1>
      </Form>
    </Card>
  );
};

const CostProposalsView = ({ caseData, onFinish }) => {
  const [newCostProposals, setNewCostProposals] = useState([]);
  const [importedCostProposals, setImportedCostProposals] = useState(null);

  const [notifySmsTarget, setNotifySmsTarget] = useState(null);
  const [notifyEmailTarget, setNotifyEmailTarget] = useState(null);

  const importCostProposals = useRequest(
    () => LogisticsModel.getSunflowerCostProposals(caseData.orderData.providerSpecific.infocare.combinedActivityId),
    {
      onSuccess: (result) => {
        if (result.data.length === 1) {
          setNewCostProposals([result.data[0]]);
        } else if (result.data.length > 1) {
          setImportedCostProposals(result.data);
        }
      },
      manual: true,
      onError
    }
  );

  const addCostProposal = () => {
    setNewCostProposals([...newCostProposals, undefined]);
  };

  const changeCostProposal = (index, value) => {
    const newValues = [...newCostProposals];
    newValues[index] = value;
    setNewCostProposals(newValues);
  };

  const triggerSendCostProposals = () => {
    for (const cp of newCostProposals) {
      if (Number(cp.priceNet) <= 0) {
        onError('Invalid price');

        return;
      }

      if (!cp.name) {
        onError('Cost proposal type not set');

        return;
      }

      if (!cp.currency) {
        onError('Currency is empty');

        return;
      }

      for (const line of cp.details) {
        if (!line.type) {
          onError('Cost proposal line type not set');

          return;
        }
      }
    }

    onFinish(ACTION.ADD_COST_PROPOSALS, {
      costProposals: newCostProposals.map(({ externalId, ...rest }) => ({
        externalId: externalId ? String(externalId).split(',') : undefined,
        ...rest
      }))
    });
    setNewCostProposals([]);
  };

  const sendNotification = useRequest(
    () =>
      LogisticsModel.notifyAboutCostProposals(caseData.guid, {
        customEmail: notifyEmailTarget || undefined,
        customSms: notifySmsTarget || undefined
      }),
    {
      manual: true,
      onError,
      onSuccess() {
        showSuccess('Notifications sent successfully');
      }
    }
  );

  const triggerSendNotification = () => {
    sendNotification.run();
  };

  const triggerImportCostProposals = () => {
    importCostProposals.run();
  };

  const confirmImportCostProposal = (cp) => {
    setImportedCostProposals(null);
    setNewCostProposals([...newCostProposals, cp]);
  };

  return (
    <Space direction="vertical" size={16}>
      <Alert
        type="error"
        message="WARNING. This page is only for SUNFLOWER activities. If you are handling the activity in Sesam, please create the cost proposal there!"
      />
      {(caseData.costProposals || []).map((cp) => (
        <Card
          key={cp.id}
          title={`${_.capitalize(cp.name)} – ${Number(cp.priceNet).toFixed(2)} ${cp.currency}`}
          extra={<DateInline asString value={cp.createdAt} />}
        >
          {cp.reaction && (
            <Alert
              type="success"
              message={
                <>
                  Approved at: <DateInline asString value={cp.reactionDate} /> by <strong>{cp.reactionName}</strong>
                </>
              }
            />
          )}
          <Table
            rowKey="line"
            columns={detailColumns}
            dataSource={cp.details}
            pagination={false}
            size="small"
            footer={() => (
              <div>
                Total:{' '}
                <strong>
                  {Number(cp.priceNet).toFixed(2)} {cp.currency}
                </strong>
              </div>
            )}
          />
        </Card>
      ))}
      {newCostProposals.map((value, i) => (
        <CostProposalForm key={i} value={value} onChange={(newValue) => changeCostProposal(i, newValue)} />
      ))}
      {importedCostProposals && (
        <Alert
          type="info"
          message="Cost proposals imported"
          description="This case contains more than one cost proposal. Which one do you want to import?"
          action={
            <Space direction="vertical">
              {importedCostProposals.map((x) => (
                <Button size="small" key={x.externalId[2]} onClick={() => confirmImportCostProposal(x)}>
                  {x.externalId[2]}
                </Button>
              ))}
            </Space>
          }
        />
      )}
      <Card className="ant-card-full-width ant-card-gutter">
        <Flex gap="small" wrap="wrap" align="center">
          <Button onClick={addCostProposal} icon={<PlusOutlined />} disabled={importCostProposals.loading}>
            New Cost Proposal
          </Button>
          <Button
            onClick={triggerImportCostProposals}
            icon={<PlusOutlined />}
            disabled={importCostProposals.loading}
            loading={importCostProposals.loading}
          >
            Import from Sunflower
          </Button>
          <Button
            onClick={triggerSendCostProposals}
            icon={<SaveOutlined />}
            disabled={!newCostProposals.length}
            type="primary"
            ghost
          >
            Finalize cost proposal
          </Button>
        </Flex>
        <br />
        <Flex gap="small" wrap="wrap" align="center">
          <span>Notify by SMS:</span>
          <Input
            placeholder="Default"
            value={notifySmsTarget}
            onChange={(e) => setNotifySmsTarget(e.targevalue)}
            style={{ width: 200 }}
          />
          <span>Notify by Email:</span>
          <Input
            placeholder="Default"
            value={notifyEmailTarget}
            onChange={(e) => setNotifyEmailTarget(e.target.value)}
            style={{ width: 200 }}
          />
          <Button onClick={triggerSendNotification}>Send notification</Button>
        </Flex>
      </Card>
    </Space>
  );
};

export { CostProposalsView };
