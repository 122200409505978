import { StarTwoTone } from '@ant-design/icons';
import { useBoolean, useRequest } from 'ahooks';
import { Flex } from 'antd';
import cn from 'classnames';
import _ from 'lodash';
import { useContext, useEffect, useState } from 'react';

import { SelectAutocomplete } from '@/components/SelectAutocomplete';
import { ENTITY } from '@/configs/entity';
import { COLOR } from '@/configs/general';
import { onError } from '@/helpers/Alert';
import { userContext } from '@/helpers/userContext';
import { EntitiesModel } from '@/models/entities';

import styles from '../styles.module.scss';

const applyShippingFavoritesDiff = (shippingMethods, teamShippingMethods = [], diff = { add: [], delete: [] }) => {
  const data = [...shippingMethods];

  const favoriteMethods = [];
  const otherMethods = [];

  for (const method of data) {
    method.label = `${method.name} (${
      method?.settings?.sesamId ? `Sesam ID: ${method.settings.sesamId} / ` : ''
    }Hub ID: ${method.id})`;
    method.value = method.id;
    method.isFavorite = false;

    if (teamShippingMethods.includes(method.id)) {
      method.isTeamDefault = true;

      if (!diff.delete.includes(method.id)) {
        method.isFavorite = true;
        favoriteMethods.push(method);
      } else {
        otherMethods.push(method);
      }
    } else if (diff.add.includes(method.id)) {
      method.isFavorite = true;
      favoriteMethods.push(method);
    } else {
      otherMethods.push(method);
    }
  }

  return [..._.sortBy(favoriteMethods, (x) => x.name), ..._.sortBy(otherMethods, (x) => x.name)];
};

const SelectAnyShippingMethod = (props) => {
  const { allShippingMethods, value } = props;

  const { syncUserSettings, user } = useContext(userContext);
  const { logisticUser, team } = user;
  const [isDirty, { setTrue: setDirty }] = useBoolean(false);
  const [sortedShippingMethods, setSortedShippingMethods] = useState([]);

  useEffect(() => {
    if (!allShippingMethods?.length) {
      return;
    }

    setSortedShippingMethods(
      applyShippingFavoritesDiff(
        allShippingMethods,
        team?.favoriteShippingMethods,
        logisticUser.settings.teamShippingMethodsDiff
      )
    );
  }, [
    allShippingMethods?.length,
    logisticUser.settings?.teamShippingMethodsDiff?.add?.length,
    logisticUser.settings?.teamShippingMethodsDiff?.delete?.length
  ]);

  const changeLogisticUserSettings = useRequest(
    (values) => EntitiesModel.changeEntityValues(ENTITY.LOGISTIC_USER, values),
    {
      manual: true,
      onSuccess: () => {
        if (logisticUser.settings.teamShippingMethodsDiff) {
          return;
        }

        syncUserSettings();
      },
      onError
    }
  );

  const onChange = (v) => {
    setDirty();
    props.onChange(v);
  };

  const toggleFavoriteMethodChange = (shippingMethod) => (e) => {
    e.stopPropagation();

    if (changeLogisticUserSettings.loading) {
      return;
    }

    const settings = { ...logisticUser.settings };
    settings.teamShippingMethodsDiff = settings.teamShippingMethodsDiff || {};
    settings.teamShippingMethodsDiff.add = settings.teamShippingMethodsDiff.add || [];
    settings.teamShippingMethodsDiff.delete = settings.teamShippingMethodsDiff.delete || [];

    if (shippingMethod.isFavorite) {
      if (shippingMethod.isTeamDefault) {
        settings.teamShippingMethodsDiff.delete.push(shippingMethod.id);
      } else {
        settings.teamShippingMethodsDiff.add = _.filter(
          settings.teamShippingMethodsDiff.add,
          (v) => v !== shippingMethod.id
        );
      }
    } else if (!shippingMethod.isTeamDefault) {
      settings.teamShippingMethodsDiff.add.push(shippingMethod.id);
    } else {
      settings.teamShippingMethodsDiff.delete = _.filter(
        settings.teamShippingMethodsDiff.delete,
        (v) => v !== shippingMethod.id
      );
    }

    settings.teamShippingMethodsDiff.add = _.uniq(settings.teamShippingMethodsDiff.add);
    settings.teamShippingMethodsDiff.delete = _.uniq(settings.teamShippingMethodsDiff.delete);

    changeLogisticUserSettings.run({ ...logisticUser, settings });
  };

  // const handleRadioSelect = (e) => onChange(e.target.value);

  return (
    <div>
      {/* {favoriteShippingMethods.length > 0 && (
        <>
          <Radio.Group value={value} onChange={handleRadioSelect} className="full-width">
            {favoriteShippingMethods.map((x) => (
              <Radio key={x.id} value={x.id}>
                <Flex align="center" justify="space-between">
                  <span>
                    {x.name} ({x?.settings?.sesamId ? `Sesam ID: ${x.settings.sesamId} / ` : ''}Hub ID: {x.id})
                  </span>
                  <StarTwoTone />
                </Flex>
              </Radio>
            ))}
          </Radio.Group>
          <Divider plain>or select from all the other shipping methods</Divider>
        </>
      )} */}
      <SelectAutocomplete
        options={sortedShippingMethods}
        optionRender={(option) => {
          return (
            <Flex align="center" justify="space-between">
              <span>{option.label}</span>
              <StarTwoTone
                className={cn(styles.favoriteShippingMethodIcon, {
                  isFavorite: option.data.isFavorite
                })}
                onClick={toggleFavoriteMethodChange(option.data)}
                twoToneColor={COLOR.GOLD}
              />
            </Flex>
          );
        }}
        skipMapping
        className="ant-select-no-ellipsis full-width"
        popupClassName="select-nowrap"
        value={isDirty ? value : null}
        onChange={onChange}
      />
    </div>
  );
};

export { SelectAnyShippingMethod };
