/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useBoolean, useRequest } from 'ahooks';
import { Button, Drawer, Form, Select, Space } from 'antd';
import { useState } from 'react';

import { onError } from '@/helpers/Alert';
import { LogisticsModel } from '@/models/logistics';

import { SESAM_DB } from '../configs';

const SESAM_DBS = Object.values(SESAM_DB);

const CopySunflowerActivity = (props) => {
  const { caseData } = props;
  const [newSunflowerDb, setNewSunflowerDb] = useState('');
  const [isDrawerOpened, { setTrue: openDrawer, setFalse: closeDrawer }] = useBoolean(false);
  const copyRequest = useRequest(() => LogisticsModel.copySunflowerActivity(caseData.guid, newSunflowerDb), {
    manual: true,
    onError,
    onSuccess: () => {
      closeDrawer();
    }
  });

  const activityNumber = caseData?.orderData?.activityNumber;
  const sunflowerCombinedId = caseData?.orderData?.providerSpecific?.infocare?.combinedActivityId || ':';
  const mirroredSunflowerCombinedId =
    caseData?.orderData?.providerSpecific?.infocare?.mirroredCombinedActivityId || ':';
  const [sunflowerDb, sunflowerActivityNumber] = sunflowerCombinedId.split(':');
  const [, mirroredSunflowerActivityNumber] = mirroredSunflowerCombinedId.split(':');
  const isSesamDBCorrect = SESAM_DBS.includes(sunflowerDb);

  if (!activityNumber) {
    return null;
  }

  if (!sunflowerDb || !sunflowerActivityNumber || !isSesamDBCorrect) {
    return <span>Activity: {activityNumber}</span>;
  }

  return (
    <>
      <span>
        Activity:{' '}
        <a onClick={openDrawer} onKeyDown={openDrawer}>
          {activityNumber}
          {mirroredSunflowerActivityNumber && ` / ${mirroredSunflowerActivityNumber}`}
        </a>
      </span>
      <Drawer title="Copy activity" open={isDrawerOpened} onClose={closeDrawer} width={300}>
        <Form>
          <Form.Item label="Current Sunflower DB">
            <b>{sunflowerDb}</b>
          </Form.Item>
          <Form.Item label="New Sunflower DB" style={{ marginTop: -16 }}>
            <Select
              className="full-width"
              options={SESAM_DBS.filter((db) => db !== sunflowerDb).map((db) => ({
                id: db,
                value: db
              }))}
              onChange={setNewSunflowerDb}
            />
          </Form.Item>
          <Form.Item>
            <Space>
              <Button type="primary" disabled={!newSunflowerDb} onClick={copyRequest.run} loading={copyRequest.loading}>
                Copy
              </Button>
              <Button onClick={closeDrawer}>Close</Button>
            </Space>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
};

export { CopySunflowerActivity };
