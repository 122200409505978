// prettier-ignore
import { DeleteOutlined, EditOutlined, QuestionCircleOutlined, ReloadOutlined, WarningFilled } from '@ant-design/icons';
import { PageHeader } from '@ant-design/pro-layout';
import { useBoolean, useMount, usePersistFn, useRequest, useUnmount } from 'ahooks';
import { Badge, Button, Card, Drawer, Modal, Space, Switch, Table, Tag } from 'antd';
import classNames from 'classnames';
import _ from 'lodash';
import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';

import { Block } from '@/components/Block';
import { ENTITY } from '@/configs/entity';
import { COLOR, PERMISSION, ROUTE } from '@/configs/general';
import { userContext } from '@/helpers/userContext';
import { EntitiesModel } from '@/models/entities';
import { TimeTrackerModel } from '@/models/timetracker';

import { TimeTrackerEmployeeEditForm } from './components/EmployeeEditForm';
import { TimeTrackerIntervalEditForm } from './components/IntervalEditForm';
import { TimeTrackerPendingRequests } from './components/PendingRequests';
import { TimeTrackerReports } from './components/Reports';
import { DAY_ABSENCE_LABEL, DAY_ABSENCE_LABEL_NO } from './configs';
import styles from './styles.module.scss';
import { TIMETRACKER_EVENT, timeTrackerContext, TimeTrackerProvider } from './timeTrackerContext';

const ACTION = {
  DELETE: 'delete',
  EDIT: 'edit'
};

const BLOCK_ID = {
  EMPLOYEES: 'employeesBlock',
  REPORTS: 'reportsBlock'
};

const getUserName = (user) => (user?.user?.firstName ? `${user.user?.firstName} ${user.user.lastName}` : '-');

const getColumns = ({ onSelectUser, showRequestsColumn, supervisorsList, supervisorsMap }) =>
  [
    {
      key: 'requests',
      render: showRequestsColumn ? (__, { pendingRequests }) => <Badge count={pendingRequests} /> : null,
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.pendingRequests - b.pendingRequests,
      width: 40
    },
    {
      title: 'Name',
      key: 'name',
      render: (__, user) => <Link to={`${ROUTE.TIME_TRACKER_EMPLOYEE}/${user.user.id}`}>{getUserName(user)}</Link>
    },
    supervisorsList?.length
      ? {
          title: 'Supervisor',
          key: 'supervisor',
          sorter: (a, b) =>
            getUserName(supervisorsMap?.[a.supervisorUserId]).localeCompare(
              getUserName(supervisorsMap?.[b.supervisorUserId])
            ),
          filters: supervisorsList.map((user) => ({
            text: getUserName(user),
            value: user.userId
          })),
          onFilter: (value, { supervisorUserId }) => supervisorUserId === value,
          render: (__, { supervisorUserId }) => getUserName(supervisorsMap?.[supervisorUserId])
        }
      : null,
    {
      title: 'Status',
      key: 'status',
      sorter: (a, b) => Boolean(a.activeCheckInTimestamp) - Boolean(b.activeCheckInTimestamp),
      filters: [
        {
          text: 'Working',
          value: 'working'
        },
        {
          text: 'Away or absence',
          value: 'away'
        }
      ],
      onFilter: (value, { activeCheckInTimestamp, flexTimeBypassed, workdays }) => {
        if (value === 'away') {
          return workdays?.[0]?.code || (!activeCheckInTimestamp && !flexTimeBypassed);
        }

        return Boolean(activeCheckInTimestamp) || (flexTimeBypassed && !workdays?.[0]?.code);
      },
      render: (__, timeTrackerUser) => {
        const { activeCheckInTimestamp, flexTimeBypassed, workdays, user } = timeTrackerUser;

        const { country } = user;
        const checkedIn = TimeTrackerModel.checkCheckedIn(activeCheckInTimestamp);

        if (workdays?.[0]?.code) {
          return (
            <Tag color="gold">{(country === 'NO' ? DAY_ABSENCE_LABEL_NO : DAY_ABSENCE_LABEL)[workdays[0].code]}</Tag>
          );
        }

        if (flexTimeBypassed) {
          return <Tag color="success">{country === 'NO' ? 'Tillitsbasert arbeidstid' : 'Förtroendetid'}</Tag>;
        }

        if (country === 'NO') {
          return <Tag color={checkedIn ? 'success' : 'gold'}>{checkedIn ? 'Innskjekket' : 'Utsjekket'}</Tag>;
        }

        return <Tag color={checkedIn ? 'success' : 'gold'}>{checkedIn ? 'Incheckad' : 'Utcheckad'}</Tag>;
      }
    },
    {
      title: 'External id',
      dataIndex: 'externalId',
      key: 'externalId',
      sorter: (a, b) => a.externalId - b.externalId
    },
    {
      title: 'Schema',
      dataIndex: 'schema',
      key: 'schema',
      sorter: (a, b) => a.schema - b.schema
    },
    {
      title: 'Hourly paid',
      key: 'isHourlyPaid',
      render: (__, { isHourlyPaid }) => (isHourlyPaid ? 'Yes' : 'No'),
      sorter: (a, b) => a.isHourlyPaid - b.isHourlyPaid
    },
    {
      title: 'Premise',
      key: 'premise',
      render: (__, { location }) => location?.name,
      sorter: (a, b) => a?.location?.name?.localeCompare(b.location?.name)
    },
    {
      key: 'actions',
      width: 32,
      render: (__, user) => (
        <Space>
          <Button icon={<EditOutlined />} onClick={onSelectUser(user, ACTION.EDIT)} type="primary" ghost />
          <Button icon={<DeleteOutlined />} onClick={onSelectUser(user, ACTION.DELETE)} type="primary" danger />
        </Space>
      )
    }
  ].filter(Boolean);

const DashboardPage = () => {
  const { hasPermission, changeLocalSettings, localSettings, user } = useContext(userContext);
  const { toggleCallback } = useContext(timeTrackerContext);
  const [showExpandable, setExpandable] = useState(false);
  const [selectedUser, selectUser] = useState(null);
  const [supervisors, setSupervisors] = useState([]);
  const [inAdminMode, { toggle: setAdminMode }] = useBoolean(localSettings?.showAllTimetrackerUsers || false);
  const { timeTrackerUser } = user;

  const isAdmin = hasPermission(PERMISSION.TIMETRACKER_ADMIN);
  const isLocationSupervisor = hasPermission(PERMISSION.TIMETRACKER_LOCATION_SUPERVISOR);

  const handleSelectUser = (u, action) => () => selectUser(u ? { action, ...u } : null);

  const [columns, setColumns] = useState(getColumns({ onSelectUser: handleSelectUser }));
  const { run: loadPendingRequests, fetches: pendingRequests } = useRequest(TimeTrackerModel.getPendingRequests, {
    manual: true,
    fetchKey: (data) => data.userId
  });

  const handleEmployeesData = (employees) => {
    let supervisorsList;
    let supervisorsMap;

    if (inAdminMode || isLocationSupervisor) {
      const employeesById = _.keyBy(employees, 'userId');
      supervisorsMap = {};

      _.forEach(employees, ({ supervisorUserId }) => {
        if (!supervisorUserId) {
          return;
        }
        supervisorsMap[supervisorUserId] = employeesById[supervisorUserId];
      });
      const filteredSupervisors = _.map(supervisorsMap, (__, key) => employeesById[key]).filter(Boolean);

      supervisorsList = _.sortBy(filteredSupervisors, ['user.firstName', 'user.lastName']);
      setSupervisors(supervisorsList);
    }

    const somePendingRequests = employees.some((employee) => employee.pendingRequests > 0);
    setExpandable(somePendingRequests);

    setColumns(
      getColumns({
        onSelectUser: handleSelectUser,
        showRequestsColumn: somePendingRequests,
        supervisorsList,
        supervisorsMap
      })
    );
  };

  const getEmployees = () => {
    if (inAdminMode) {
      return TimeTrackerModel.getEmployees(true);
    }

    if (isLocationSupervisor) {
      return TimeTrackerModel.getEmployeesByLocation(timeTrackerUser, true);
    }

    return TimeTrackerModel.getEmployeesOfSupervisor(timeTrackerUser, true);
  };
  const fetchEmployees = useRequest(getEmployees, {
    onSuccess: handleEmployeesData,
    refreshDeps: [inAdminMode]
  });

  const deleteUser = useRequest((id) => EntitiesModel.deleteEntityInstance(ENTITY.USER, id), {
    manual: true,
    onSuccess: () => {
      selectUser(null);
      fetchEmployees.refresh();
    }
  });
  const handleDeleteUser = () => deleteUser.run(selectedUser?.userId);

  const handleRefreshEmployeeRequests = usePersistFn((data) => {
    loadPendingRequests(data);
    fetchEmployees.refresh();
  });

  useMount(() =>
    toggleCallback(
      TIMETRACKER_EVENT.ON_PENDING_REQUEST_RESOLVE,
      'refreshEmployeeRequests',
      handleRefreshEmployeeRequests
    )
  );

  useUnmount(() =>
    toggleCallback(
      TIMETRACKER_EVENT.ON_PENDING_REQUEST_RESOLVE,
      'refreshEmployeeRequests',
      handleRefreshEmployeeRequests
    )
  );

  const handleEditSuccess = async () => {
    selectUser(null);
    await fetchEmployees.refresh();
  };

  const handleAdminModeChange = (status) => {
    changeLocalSettings({ showAllTimetrackerUsers: status });
    setAdminMode(status);
  };

  const drawerTitle = selectedUser?.userId
    ? `Edit: ${selectedUser.user.firstName} ${selectedUser.user.lastName}`
    : 'Add an employee';

  const loading = fetchEmployees.loading || deleteUser.loading;

  return (
    <>
      <PageHeader
        title="Time tracker dashboard"
        subTitle={
          <Button
            type="link"
            href="https://elkjopnordic.sharepoint.com/:w:/s/icw/IT/Eay7ym1-YGdFi3p4cYTCzZMBFesZpJ-aEBTyZlQnap3Tjw?e=mgvU0L"
            target="_blank"
            rel="noopener noreferrer"
          >
            <QuestionCircleOutlined />
            Manual
          </Button>
        }
        extra={
          isAdmin && (
            <Switch
              onChange={handleAdminModeChange}
              checked={inAdminMode}
              checkedChildren="Show all employees"
              unCheckedChildren="Show my employees"
              disabled={loading}
            />
          )
        }
      />
      <Block id={BLOCK_ID.EMPLOYEES}>
        <Card
          title={
            <Space size="large">
              <span>Employees</span>
              <Button type="link" href={`#${BLOCK_ID.REPORTS}`} size="small">
                Scroll down to reports
              </Button>
            </Space>
          }
          bordered={false}
          extra={<Button icon={<ReloadOutlined />} loading={loading} onClick={fetchEmployees.refresh} type="primary" />}
        >
          <Table
            className={classNames({ [styles.hideRequestsColumn]: !showExpandable })}
            columns={columns}
            dataSource={fetchEmployees?.data}
            rowKey="userId"
            loading={loading}
            pagination={false}
            expandable={
              showExpandable && {
                expandedRowRender: (data) => (
                  <TimeTrackerPendingRequests
                    {...pendingRequests?.[data?.userId]}
                    user={{ ...data?.user, timeTrackerUser: data }}
                  />
                ),
                rowExpandable: (data) => data.pendingRequests > 0,
                onExpand: (expanded, data) => {
                  if (expanded) {
                    loadPendingRequests(data);
                  }
                }
              }
            }
          />
        </Card>
      </Block>
      <Block id={BLOCK_ID.REPORTS}>
        <Card
          title={
            <Space size="large">
              <span>Reports</span>
              <Button type="link" href={`#${BLOCK_ID.EMPLOYEES}`} size="small">
                Scroll up to employees
              </Button>
            </Space>
          }
          bordered={false}
        >
          <TimeTrackerReports supervisors={supervisors} />
        </Card>
      </Block>
      <Drawer
        title={drawerTitle}
        open={selectedUser?.action === ACTION.EDIT}
        width={450}
        onClose={handleSelectUser()}
        destroyOnClose
      >
        <TimeTrackerEmployeeEditForm user={selectedUser} onSuccess={handleEditSuccess} />
      </Drawer>
      <Modal
        open={selectedUser?.action === ACTION.DELETE}
        title="Warning!"
        onOk={handleDeleteUser}
        onCancel={handleSelectUser()}
        okButtonProps={{ type: 'primary', danger: true }}
        okText="I understand the risk, delete"
      >
        <p>
          If you proceed with this action, {selectedUser?.user?.firstName} {selectedUser?.user?.lastName} will be
          completely deleted from the system. The user will lose access to all NGen&apos;s functions.
        </p>
        <p>
          <WarningFilled style={{ color: COLOR.RED }} /> <b>DANGER!</b> <WarningFilled style={{ color: COLOR.RED }} />{' '}
          All tracked working time for this month will NOT be reported and paid. If the user quit the job, remove they
          after the work time report containing the last working day has been attested and sent to Zalaris (the month
          after)
        </p>
      </Modal>
      <TimeTrackerIntervalEditForm />
    </>
  );
};

const TimeTrackerDashboardPage = (props) => (
  <TimeTrackerProvider>
    <DashboardPage {...props} />
  </TimeTrackerProvider>
);

export { TimeTrackerDashboardPage };
