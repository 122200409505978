import { EditOutlined } from '@ant-design/icons';
import { PageHeader } from '@ant-design/pro-layout';
import { useRequest, useTimeout } from 'ahooks';
import { Button, Card, Col, Empty, Form, Input, Row, Select, Space, Spin } from 'antd';
import _ from 'lodash';
import { useContext, useState } from 'react';

import { Block } from '@/components/Block';
import { onError } from '@/helpers/Alert';
import { userContext } from '@/helpers/userContext';
import { RequestModel } from '@/models/request';

import { InputPrompt } from './InputPrompt';

const INPUT_ID = 'search';

const ALLOWED_COUNTRIES = [
  { value: 'SE', label: 'Sweden' },
  { value: 'NO', label: 'Norway' },
  { value: 'FI', label: 'Finland' },
  { value: 'DK', label: 'Denmark' }
];

const plainToNested = (obj) => {
  const storage = {};

  for (const [path, val] of Object.entries(obj)) {
    if (!_.isNil(val) && val !== '') {
      _.set(storage, path, val);
    }
  }

  return storage;
};

const CaseCard = (props) => {
  const { row, loading, onEdit } = props;
  const { guid, orderData, productData, manufacturer, customer, servicePartner } = row;

  return (
    <Card
      title={
        <Button type="link" href={`https://elcare.com/msbridge/record_by_guid/${guid}`} target="blank">
          {orderData.activityNumber || '–'}
        </Button>
      }
      actions={[
        <Button type="primary" icon={<EditOutlined />} disabled={loading} onClick={onEdit(row)}>
          Edit
        </Button>
      ]}
    >
      <p>
        Brand: <strong>{manufacturer.name}</strong>
        <br />
        Model: <strong>{productData.model || '-'}</strong>
        <br />
        Serial: <strong>{productData.serial || '-'}</strong>
        <br />
        Claim / Reference number: <strong>{orderData.refNo || '–'}</strong>
        <br />
        Customer: <strong>{customer.organizationName || `${customer.firstName} ${customer.lastName}` || '–'}</strong>
        <br />
        Address:{' '}
        <strong>
          {customer.address} {customer.city}, {customer.postalCode}, {customer.countryCode}
        </strong>
        <br />
        Booked by: <strong>{servicePartner.name}</strong>
      </p>
    </Card>
  );
};

const MatchCasesPage = () => {
  const setFocus = () => document.querySelector(`#${INPUT_ID}`)?.focus?.();

  useTimeout(setFocus, 300);

  const {
    loginData: { settings }
  } = useContext(userContext);
  const [form] = Form.useForm();
  const { data, run, loading } = useRequest(
    () =>
      RequestModel.postJsonData('/api/v1/activity/search-cases', {
        body: { filters: plainToNested(form.getFieldsValue()) }
      }),
    {
      manual: true,
      onError
    }
  );
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentRow, setCurrentRow] = useState();
  const handleSearch = () => run();
  const handleModal = (value) => () => setIsModalVisible(value);
  const { loading: isWaiting, run: matchCase } = useRequest(
    (activityNumber) =>
      RequestModel.postJsonData(`/api/v1/activity/match-case`, { body: { guid: currentRow.guid, activityNumber } }),
    {
      manual: true,
      onError
    }
  );

  const handleEdit = (row) => () => {
    setCurrentRow(row);
    setIsModalVisible(true);
  };

  return (
    <>
      <PageHeader title="Match cases" />
      <InputPrompt
        modalProps={{ open: isModalVisible }}
        onOk={matchCase}
        onClose={handleModal(false)}
        title="Match Sesam activities with Service Order Hub cases"
        message="Please enter activity number (be careful):"
      />

      <Block fitContent={!data} flexVerticalAlign={!data} transparent>
        <Spin spinning={loading || isWaiting}>
          <Card>
            <Space direction="vertical" size="middle">
              <Space className="ant-space-first-item-flex">
                <Form layout="vertical" form={form} onFinish={handleSearch} initialValues={{ country: 'SE' }}>
                  <Row gutter={8}>
                    <Col span={8}>
                      <Form.Item label="Search:" name="commonSearch">
                        <Input id={INPUT_ID} onPressEnter={handleSearch} />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item label="Country:" name="country">
                        <Select options={ALLOWED_COUNTRIES} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item>
                    <Button type="primary" size="large" htmlType="submit" disabled={loading}>
                      Search
                    </Button>
                  </Form.Item>
                </Form>
              </Space>

              {data?.length > 0 && (
                <div className="cards-wrapper">
                  {data.map((row) => (
                    <CaseCard
                      row={row}
                      settings={settings}
                      loading={loading || isModalVisible}
                      onEdit={handleEdit}
                      key={row.id}
                    />
                  ))}
                </div>
              )}
              {data && !data?.length && <Empty />}
            </Space>
          </Card>
        </Spin>
      </Block>
    </>
  );
};

export { MatchCasesPage };
