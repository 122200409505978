import { ReloadOutlined } from '@ant-design/icons';
import { ProCard } from '@ant-design/pro-components';
import { useRequest } from 'ahooks';
import { Alert, Button, Space, Tag, Timeline } from 'antd';
import _ from 'lodash';
import { useEffect, useState } from 'react';

import { DateInline } from '@/components/DateInline';
import { LogisticsModel } from '@/models/logistics';

import { TASK_RESULT_COLOR, TASK_RESULT_STATUS } from '../configs';

const TaskTimelineData = ({ detailedTask }) => {
  const rerunReq = useRequest((state) => LogisticsModel.reRunTask(detailedTask.id, state), {
    throwOnError: true,
    manual: true
  });

  const timelineItems = (detailedTask.subTasks || []).map((subTask) => {
    const { status } = subTask;
    let taskPath = subTask.type.split('_');

    if (taskPath.length < 3) {
      taskPath = [undefined, ...taskPath];
    }
    const subtaskTimelineItems = (subTask.externalRequests || []).map((subTaskRequest) => ({
      label: <DateInline value={subTaskRequest.createdAt} />,
      children: (
        <Space direction="vertical">
          <Space>
            <span>{subTaskRequest.type}</span>
            <Tag color={TASK_RESULT_STATUS[subTaskRequest.status] || 'default'}>
              {subTaskRequest.status.toUpperCase()}
            </Tag>
          </Space>
          {subTaskRequest.message && <Alert message={subTaskRequest.message} />}
        </Space>
      )
    }));

    return {
      color: TASK_RESULT_COLOR[status],
      label: <DateInline value={subTask.createdAt} />,
      children: (
        <Space direction="vertical">
          <Space>
            <b>
              {taskPath[2]} {taskPath[0] ? `[${taskPath[0]}]` : ''}
            </b>
            <Tag color={TASK_RESULT_STATUS[status] || 'default'}>{status.toUpperCase()}</Tag>
            <Button
              icon={<ReloadOutlined />}
              size="small"
              title={`Rerun subtask ${taskPath[2]}`}
              onClick={() => rerunReq.run(taskPath[2])}
            />
          </Space>
          {subTask.message && <Alert message={subTask.message} className="inline-alert" />}
          <Timeline className="timeline-with-timestamps" items={subtaskTimelineItems} mode="left" />
        </Space>
      )
    };
  });

  return (
    <Space direction="vertical">
      <ProCard collapsible defaultCollapsed title="Raw data" bordered headerBordered>
        <pre>{JSON.stringify(detailedTask.data, undefined, 2)}</pre>
      </ProCard>
      <Timeline className="timeline-with-timestamps" items={timelineItems} mode="left" />
    </Space>
  );
};

const TaskTimelineItem = ({ task, defaultOpen }) => {
  const [isVisible, setIsVisible] = useState(defaultOpen);
  const getTaskRequest = useRequest(() => LogisticsModel.getTask(task.id), {
    formatResult: (data) => {
      data.subTasks = _.sortBy(data.subTasks, (x) => x.id);

      return data;
    },
    throwOnError: true,
    manual: true
  });

  useEffect(() => {
    if (defaultOpen) {
      getTaskRequest.run();
    }
  }, [defaultOpen]);

  return (
    <ProCard size="small">
      <ProCard
        collapsible
        defaultCollapsed={!defaultOpen}
        onCollapse={(collapse) => {
          setIsVisible(!collapse);

          if (!collapse) {
            getTaskRequest.run();
          }
        }}
        loading={getTaskRequest.loading}
        title={
          <Space>
            <DateInline value={task.createdAt} />
            <span>Result:</span>
            <Tag color={TASK_RESULT_STATUS[task.status] || 'default'}>{task.status.toUpperCase()}</Tag>
            by
            {task.user ? `${task.user.email} (${task.user.firstName} ${task.user.lastName})` : 'unknown'}
            <span />
          </Space>
        }
        bordered
        headerBordered
        type="inner"
      >
        {isVisible && getTaskRequest.data && <TaskTimelineData detailedTask={getTaskRequest.data} />}
      </ProCard>
    </ProCard>
  );
};

const TaskTimeline = ({ group }) => {
  return (
    <>
      {group.map((task, i) => (
        <TaskTimelineItem key={task.id} task={task} defaultOpen={i === 0} />
      ))}
    </>
  );
};

export { TaskTimeline };
