import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { useMount, useRequest } from 'ahooks';
import { Button, Checkbox, Drawer, Form, Input, InputNumber, Select, Space, TreeSelect } from 'antd';
import { useState } from 'react';

import { LAYOUT } from '@/configs/layout';
import { onError } from '@/helpers/Alert';
import { RequestModel } from '@/models/request';

const LABEL_TYPES = [
  { value: 'none', label: 'none' },
  { value: 'bigZebra', label: 'bigZebra' },
  { value: 'A4', label: 'A4' }
];
let carriersDict = {};

const ShipmentTypeEditor = (props) => {
  const { id, visible, onClose, importedData = undefined } = props;
  const [currentCarrier, setCurrentCarrier] = useState();
  const [form] = Form.useForm();

  const saveShipmentRequest = useRequest((body) => RequestModel.postJson(`/api/v1/shipment/shipment-type`, { body }), {
    onSuccess: () => {
      onClose();
    },
    onError,
    manual: true
  });

  // We need this request only to avoid duplicated configs
  const actorsRequest = useRequest(() => RequestModel.getJsonData(`/api/v1/shipment/consignor-actors`), {
    initialData: [],
    /* eslint-disable no-shadow */
    formatResult: (data) => data.map(({ id, name }) => ({ value: id, label: name }))
  });

  const consignorRequest = useRequest(
    async () => {
      const actor = form.getFieldValue(['settings', 'actorCSID']);

      if (!actor) {
        return { data: [] };
      }

      return RequestModel.getJson(`/api/v1/shipment/consignor-products/${actor}`);
    },
    {
      initialData: [],
      manual: true,
      formatResult: ({ data }) => {
        carriersDict = {};
        const newData = data.map((carrier) => ({
          value: carrier.CarrierCSID,
          title: (
            <>
              <img src={`data:image/png;base64, ${carrier.Icon}`} style={{ height: '1em' }} alt="icon" />{' '}
              {carrier.CarrierFullName}
            </>
          ),
          selectable: false,
          children: carrier.Subcarriers.map((subcarrier) => ({
            value: subcarrier.SubcarrierCSID,
            title: subcarrier.SubcarrierName,
            selectable: false,
            children: subcarrier.Products.map((product) => {
              // Fill it here as well to get an easy access to the props
              carriersDict[product.ProdCSID] = {
                prodCSID: product.ProdCSID,
                prodConceptID: product.ProdConceptID,
                services: product.Services?.map((s) => ({ label: s.ServiceName, value: s.ServiceID })) || [],
                name: `${carrier.CarrierShortName} ${subcarrier.SubcarrierName} ${product.ProdName}`
              };

              return {
                value: product.ProdCSID,
                title: `${product.ProdName} (${product.ProdCSID})`
              };
            })
          }))
        }));

        setCurrentCarrier(carriersDict[form.getFieldValue(['settings', 'prodCSID'])]);

        return newData;
      },
      onError
    }
  );

  const shipmentRequest = useRequest(() => RequestModel.getJson(`/api/v1/shipment/shipment-type/${id}`), {
    initialData: { data: {} },
    onSuccess: ({ data }) => {
      form.setFieldsValue(data);
      consignorRequest.run();
    },
    onError,
    ready: id && id !== 'new'
  });

  useMount(() => {
    if (!importedData) {
      return;
    }

    form.setFieldsValue(importedData);
    consignorRequest.run();
  });

  const onFinish = (data) => {
    saveShipmentRequest.run({ id: id && id !== 'new' ? id : undefined, ...(importedData || {}), ...data });
  };

  const handleProductChanged = (pId) => {
    const productData = carriersDict[pId];
    form.setFieldValue(['settings', 'prodConceptID'], productData.prodConceptID);
    setCurrentCarrier(productData);
  };

  return (
    <Drawer
      title={id ? 'Edit shipment type' : 'Add shipment type'}
      open={visible}
      onClose={onClose}
      width={720}
      destroyOnClose
    >
      <Form
        form={form}
        disabled={shipmentRequest.isLoading}
        onFinish={onFinish}
        // eslint-disable-next-line no-template-curly-in-string
        validateMessages={{ required: 'Please input ${label}' }}
        scrollToFirstError
      >
        <Form.Item label="Name" name="name" {...LAYOUT.L6_W18} rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Worker" name="worker" {...LAYOUT.L6_W18} rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Sesam Id" name={['settings', 'sesamId']} {...LAYOUT.L6_W18}>
          <Input />
        </Form.Item>
        <Form.Item label="Tracking worker" name="trackingWorker" {...LAYOUT.L6_W18}>
          <Input />
        </Form.Item>
        <Form.Item label="API key" name={['settings', 'apiKey']} {...LAYOUT.L6_W18}>
          <Input />
        </Form.Item>
        <Form.Item label="Actor CSID" name={['settings', 'actorCSID']} {...LAYOUT.L6_W18}>
          <Select
            loading={actorsRequest.loading}
            className="full-width"
            options={actorsRequest.data}
            placeholder="Select actor / country"
            onChange={() => consignorRequest.run()}
          />
        </Form.Item>
        <Form.Item label="Product" name={['settings', 'prodCSID']} {...LAYOUT.L6_W18}>
          <TreeSelect
            loading={consignorRequest.loading}
            disabled={!form.getFieldValue(['settings', 'actorCSID'])}
            treeData={consignorRequest.data}
            className="full-width"
            placeholder="Select product"
            treeExpandAction="click"
            treeDefaultExpandAll
            onChange={handleProductChanged}
          />
        </Form.Item>
        <Form.Item label="prodCSID" name={['settings', 'prodCSID']} {...LAYOUT.L6_W18}>
          <Input />
        </Form.Item>
        <Form.Item label="prodConceptID" name={['settings', 'prodConceptID']} {...LAYOUT.L6_W18}>
          <Input />
        </Form.Item>
        <Form.Item label="Label type" name={['settings', 'labelType']} {...LAYOUT.L6_W18}>
          <Select options={LABEL_TYPES} />
        </Form.Item>
        <Form.Item label="Server url" name={['settings', 'serverUrl']} {...LAYOUT.L6_W18}>
          <Input />
        </Form.Item>
        <Form.Item label="Default width" name={['settings', 'defaultWidth']} {...LAYOUT.L6_W18}>
          <InputNumber />
        </Form.Item>
        <Form.Item label="Default height" name={['settings', 'defaultHeight']} {...LAYOUT.L6_W18}>
          <InputNumber />
        </Form.Item>
        <Form.Item label="Default length" name={['settings', 'defaultLength']} {...LAYOUT.L6_W18}>
          <InputNumber />
        </Form.Item>
        <Form.Item label="Default weight" name={['settings', 'defaultWeight']} {...LAYOUT.L6_W18}>
          <InputNumber />
        </Form.Item>
        <Form.Item label="Payer account" name={['settings', 'payerAccountAtCarrier']} {...LAYOUT.L6_W18}>
          <Input />
        </Form.Item>
        <Form.Item label="Sender account" name={['settings', 'senderAccountAtCarrier']} {...LAYOUT.L6_W18}>
          <Input />
        </Form.Item>
        <Form.Item label="Default goods type" name={['settings', 'defaultGoodsType']} {...LAYOUT.L6_W18}>
          <Input />
        </Form.Item>
        <Form.Item label="With calendar" name={['settings', 'withCalendar']} valuePropName="checked" {...LAYOUT.L6_W18}>
          <Checkbox />
        </Form.Item>
        <Form.Item label="services" name={['settings', 'services']} {...LAYOUT.L6_W18}>
          <Select mode={currentCarrier ? 'multiple' : 'tags'} options={currentCarrier?.services} />
        </Form.Item>
        <Form.Item label="Line references" {...LAYOUT.L6_W18}>
          <Form.List name={['settings', 'consignorLineReferences']}>
            {(fields, { add, remove }, { errors }) => {
              return (
                <>
                  {fields.map((field) => (
                    <Form.Item required={false} key={field.key} style={{ marginBottom: '12px' }}>
                      <Space.Compact>
                        <Form.Item {...field} noStyle name={[field.name, 'id']} key={`id${field.key}`}>
                          <Input placeholder="id" />
                        </Form.Item>
                        <Form.Item {...field} noStyle name={[field.name, 'value']} key={`value${field.key}`}>
                          <Input placeholder="value" />
                        </Form.Item>
                        <Button
                          icon={<MinusOutlined />}
                          className="dynamic-delete-button"
                          onClick={() => remove(field.name)}
                        />
                      </Space.Compact>
                    </Form.Item>
                  ))}
                  <Form.Item>
                    <Button type="dashed" onClick={() => add()} style={{ width: '60%' }} icon={<PlusOutlined />}>
                      Add reference
                    </Button>
                    <Form.ErrorList errors={errors} />
                  </Form.Item>
                </>
              );
            }}
          </Form.List>
        </Form.Item>
        <Form.Item label="Mark deprecated" name="deprecated" valuePropName="checked" {...LAYOUT.L6_W18}>
          <Checkbox />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={saveShipmentRequest.loading}>
            Save
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export { ShipmentTypeEditor };
