import { PageHeader } from '@ant-design/pro-layout';
import { useRequest, useTimeout } from 'ahooks';
import { Alert, Space } from 'antd';
import _ from 'lodash';
import { useState } from 'react';

import { AutoSearchInput } from '@/components/AutoSearchInput';
import { Block } from '@/components/Block';
import { SHIPMENTS_TYPE } from '@/configs/shipment';
import { ShipmentsTable } from '@/containers/shipmentsTable/ShipmentsTable';
import { onError } from '@/helpers/Alert';
import { LogisticsModel } from '@/models/logistics';

const INPUT_ID = 'search';

const SEARCH_TYPE = 'shipments';

const ShipmentSearchPage = () => {
  const [searchValue, setSearchValue] = useState(null);

  const setFocus = () => document.querySelector(`#${INPUT_ID}`)?.focus?.();

  useTimeout(setFocus, 300);

  const searchRequest = useRequest(() => LogisticsModel.search(SEARCH_TYPE, searchValue), {
    manual: true,
    onError,
    formatResult: (data) => _.sortBy(data, (x) => -x.id)
  });

  const handleSearch = () => searchRequest.run();

  const handleSearchChange = (value) => setSearchValue(value);

  const resultsFound = searchRequest.data?.length;

  return (
    <>
      <PageHeader title="Shipment search" />
      <Block fitContent={!resultsFound} flexVerticalAlign={!resultsFound} padded={resultsFound} transparent>
        <Space direction="vertical" size="middle">
          <Space className="ant-space-first-item-flex">
            <AutoSearchInput
              disabled={searchRequest.loading}
              loading={searchRequest.loading}
              allowClear
              placeholder="Shipment GUID, package number, shipment number, activity number, hub case GUID, ref no, serial"
              id={INPUT_ID}
              onChange={handleSearchChange}
              onPressEnter={handleSearch}
              onSearch={handleSearch}
              size="large"
            />
          </Space>
          {searchRequest.data && !searchRequest.data.length && <Alert type="info" message="Nothing found" />}
          {searchRequest.data?.length > 0 && (
            <ShipmentsTable
              customData={{ data: searchRequest.data, pagination: { offset: 0, count: 1 } }}
              customProviders={[]}
              hideHeader
              type={SHIPMENTS_TYPE.SHOW_INFO}
            />
          )}
        </Space>
      </Block>
    </>
  );
};

export { ShipmentSearchPage };
