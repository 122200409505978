import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Col, Space, Statistic, Tag } from 'antd';

import { COLOR } from '@/configs/general';
import { TimeTrackerModel } from '@/models/timetracker';

const EmployeeStatistic = (props) => {
  const { color, colProps = {}, isSaldo, loading, title, showHourlyPaidTag, value = 0 } = props;

  let valueColor = color || null;

  if (!color && isSaldo && value !== 0) {
    valueColor = value > 0 ? COLOR.GREEN : COLOR.RED;
  }

  return (
    <Col {...colProps}>
      <Statistic
        className="ant-statistic-small"
        loading={loading}
        title={
          !showHourlyPaidTag ? (
            title
          ) : (
            <Space>
              <span>{title}</span>
              <Tag color="warning" icon={<ExclamationCircleOutlined />}>
                Hourly paid
              </Tag>
            </Space>
          )
        }
        value={isSaldo ? TimeTrackerModel.durationToText(value) : value}
        formatter={(val) => val}
        valueStyle={valueColor && { color: valueColor }}
      />
    </Col>
  );
};

export { EmployeeStatistic };
