import { RequestModel } from './request';

const ContainersModel = {
  getAllowedContainers: async () =>
    await RequestModel.postJsonData('/common/TransportContainer/find', {
      body: {
        filters: { status: 'inStock' },
        order: [['id', 'ASC']]
      }
    }),

  getShipmentsByContainerId: async (containerId) =>
    await RequestModel.getJson(`/api/v1/shipment/search-by-container/${containerId}`),

  saveContainer: async (id, data = {}) =>
    await RequestModel.postJsonData(`/common/TransportContainer`, { body: { id, ...data } }),

  deleteContainer: async (id) => await RequestModel.deleteJsonData(`/common/TransportContainer/${id}`)
};

export { ContainersModel };
