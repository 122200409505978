import { useTimeout } from 'ahooks';
import { Button, Input, Space } from 'antd';
import { useState } from 'react';

import { ACTION } from '../configs';

const INPUT_ID = 'comment_area';

const AddCommentForm = ({ onFinish }) => {
  const [content, setContent] = useState('');

  const setFocus = () => document.querySelector(`#${INPUT_ID}`)?.focus?.();

  useTimeout(setFocus, 300);

  const handleAddComment = () => {
    onFinish(ACTION.ADD_COMMENT, {
      content
    });
  };

  return (
    <Space className="full-width" direction="vertical">
      <label>Comment:</label>
      <Input.TextArea rows={4} value={content} id={INPUT_ID} onChange={(e) => setContent(e.target.value)} />
      <Button disabled={!content.length} type="primary" onClick={handleAddComment}>
        Add comment
      </Button>
    </Space>
  );
};

export { AddCommentForm };
