import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { PageHeader } from '@ant-design/pro-layout';
import { useRequest } from 'ahooks';
import { Button } from 'antd';
import { useRef, useState } from 'react';

import ExtendedTable from '@/components/ExtendedTable';
import { onError } from '@/helpers/Alert';
import { RequestModel } from '@/models/request';

import { ShipmentTypeEditor } from './ShipmentTypeEditor';
import { ShipmentTypeImporter } from './ShipmentTypeImporter';

const ShipmentTypesPage = () => {
  const shipmentsRequest = useRequest(
    ({ page, pageSize }) => {
      const query = { offset: page * pageSize, limit: pageSize };

      return RequestModel.getJson(`/api/v1/shipment/shipment-types`, {
        query
      });
    },
    {
      initialData: { data: [], pagination: { offset: 0, count: 0 } },
      onError,
      manual: true
    }
  );

  const tableRef = useRef(null);

  const [typeToEdit, setTypeToEdit] = useState(null);
  const [importedData, setImportedData] = useState();
  const handleAdd = () => setTypeToEdit('import');
  const handleEdit = (id) => () => setTypeToEdit(id);

  const hideEditor = () => {
    setTypeToEdit(null);
    tableRef.current.refresh();
  };

  const continueImport = (iData) => {
    setImportedData(iData);
    setTypeToEdit('new');
  };

  const cols = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'sesamId',
      dataIndex: ['settings', 'sesamId'],
      key: 'sesamId'
    },
    {
      title: 'name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Action',
      key: 'action',
      width: 100,
      render: (_, row) => (
        <Button type="primary" ghost onClick={handleEdit(row.id)} icon={<EditOutlined />}>
          Edit
        </Button>
      )
    }
  ];

  return (
    <>
      <PageHeader
        title="Shipment types"
        extra={
          <Button onClick={handleAdd} icon={<PlusOutlined />} type="primary">
            Add shipment type
          </Button>
        }
      />
      <ExtendedTable columns={cols} request={shipmentsRequest} ref={tableRef} />
      {typeToEdit && typeToEdit !== 'import' && (
        <ShipmentTypeEditor
          id={typeToEdit}
          visible={typeToEdit && typeToEdit !== 'import'}
          onClose={hideEditor}
          importedData={importedData}
        />
      )}
      {typeToEdit === 'import' && (
        <ShipmentTypeImporter visible={typeToEdit === 'import'} onClose={hideEditor} onSuccess={continueImport} />
      )}
    </>
  );
};

export { ShipmentTypesPage };
