import { CountryFlag } from './Flag'

import flag_AD from './svgs/ad.svg'
import flag_AE from './svgs/ae.svg'
import flag_AF from './svgs/af.svg'
import flag_AG from './svgs/ag.svg'
import flag_AI from './svgs/ai.svg'
import flag_AL from './svgs/al.svg'
import flag_AM from './svgs/am.svg'
import flag_AN from './svgs/an.svg'
import flag_AO from './svgs/ao.svg'
import flag_AQ from './svgs/aq.svg'
import flag_AR from './svgs/ar.svg'
import flag_AS from './svgs/as.svg'
import flag_AT from './svgs/at.svg'
import flag_AU from './svgs/au.svg'
import flag_AW from './svgs/aw.svg'
import flag_AX from './svgs/ax.svg'
import flag_AZ from './svgs/az.svg'
import flag_BA from './svgs/ba.svg'
import flag_BB from './svgs/bb.svg'
import flag_BD from './svgs/bd.svg'
import flag_BE from './svgs/be.svg'
import flag_BF from './svgs/bf.svg'
import flag_BG from './svgs/bg.svg'
import flag_BH from './svgs/bh.svg'
import flag_BI from './svgs/bi.svg'
import flag_BJ from './svgs/bj.svg'
import flag_BL from './svgs/bl.svg'
import flag_BM from './svgs/bm.svg'
import flag_BN from './svgs/bn.svg'
import flag_BO from './svgs/bo.svg'
import flag_BQ from './svgs/bq.svg'
import flag_BR from './svgs/br.svg'
import flag_BS from './svgs/bs.svg'
import flag_BT from './svgs/bt.svg'
import flag_BV from './svgs/bv.svg'
import flag_BW from './svgs/bw.svg'
import flag_BY from './svgs/by.svg'
import flag_BZ from './svgs/bz.svg'
import flag_CA from './svgs/ca.svg'
import flag_CC from './svgs/cc.svg'
import flag_CD from './svgs/cd.svg'
import flag_CF from './svgs/cf.svg'
import flag_CG from './svgs/cg.svg'
import flag_CH from './svgs/ch.svg'
import flag_CI from './svgs/ci.svg'
import flag_CK from './svgs/ck.svg'
import flag_CL from './svgs/cl.svg'
import flag_CM from './svgs/cm.svg'
import flag_CN from './svgs/cn.svg'
import flag_CO from './svgs/co.svg'
import flag_CR from './svgs/cr.svg'
import flag_CU from './svgs/cu.svg'
import flag_CV from './svgs/cv.svg'
import flag_CW from './svgs/cw.svg'
import flag_CX from './svgs/cx.svg'
import flag_CY from './svgs/cy.svg'
import flag_CZ from './svgs/cz.svg'
import flag_DE from './svgs/de.svg'
import flag_DJ from './svgs/dj.svg'
import flag_DK from './svgs/dk.svg'
import flag_DM from './svgs/dm.svg'
import flag_DO from './svgs/do.svg'
import flag_DZ from './svgs/dz.svg'
import flag_EC from './svgs/ec.svg'
import flag_EE from './svgs/ee.svg'
import flag_EG from './svgs/eg.svg'
import flag_EH from './svgs/eh.svg'
import flag_ER from './svgs/er.svg'
import flag_ES from './svgs/es.svg'
import flag_ET from './svgs/et.svg'
import flag_EU from './svgs/eu.svg'
import flag_FI from './svgs/fi.svg'
import flag_FJ from './svgs/fj.svg'
import flag_FK from './svgs/fk.svg'
import flag_FM from './svgs/fm.svg'
import flag_FO from './svgs/fo.svg'
import flag_FR from './svgs/fr.svg'
import flag_GA from './svgs/ga.svg'
import flag_GB_ENG from './svgs/gb-eng.svg'
import flag_GB_NIR from './svgs/gb-nir.svg'
import flag_GB_SCT from './svgs/gb-sct.svg'
import flag_GB_WLS from './svgs/gb-wls.svg'
import flag_GB from './svgs/gb.svg'
import flag_GD from './svgs/gd.svg'
import flag_GE from './svgs/ge.svg'
import flag_GF from './svgs/gf.svg'
import flag_GG from './svgs/gg.svg'
import flag_GH from './svgs/gh.svg'
import flag_GI from './svgs/gi.svg'
import flag_GL from './svgs/gl.svg'
import flag_GM from './svgs/gm.svg'
import flag_GN from './svgs/gn.svg'
import flag_GP from './svgs/gp.svg'
import flag_GQ from './svgs/gq.svg'
import flag_GR from './svgs/gr.svg'
import flag_GS from './svgs/gs.svg'
import flag_GT from './svgs/gt.svg'
import flag_GU from './svgs/gu.svg'
import flag_GW from './svgs/gw.svg'
import flag_GY from './svgs/gy.svg'
import flag_HK from './svgs/hk.svg'
import flag_HM from './svgs/hm.svg'
import flag_HN from './svgs/hn.svg'
import flag_HR from './svgs/hr.svg'
import flag_HT from './svgs/ht.svg'
import flag_HU from './svgs/hu.svg'
import flag_ID from './svgs/id.svg'
import flag_IE from './svgs/ie.svg'
import flag_IL from './svgs/il.svg'
import flag_IM from './svgs/im.svg'
import flag_IN from './svgs/in.svg'
import flag_IO from './svgs/io.svg'
import flag_IQ from './svgs/iq.svg'
import flag_IR from './svgs/ir.svg'
import flag_IS from './svgs/is.svg'
import flag_IT from './svgs/it.svg'
import flag_JE from './svgs/je.svg'
import flag_JM from './svgs/jm.svg'
import flag_JO from './svgs/jo.svg'
import flag_JP from './svgs/jp.svg'
import flag_KE from './svgs/ke.svg'
import flag_KG from './svgs/kg.svg'
import flag_KH from './svgs/kh.svg'
import flag_KI from './svgs/ki.svg'
import flag_KM from './svgs/km.svg'
import flag_KN from './svgs/kn.svg'
import flag_KP from './svgs/kp.svg'
import flag_KR from './svgs/kr.svg'
import flag_KW from './svgs/kw.svg'
import flag_KY from './svgs/ky.svg'
import flag_KZ from './svgs/kz.svg'
import flag_LA from './svgs/la.svg'
import flag_LB from './svgs/lb.svg'
import flag_LC from './svgs/lc.svg'
import flag_LI from './svgs/li.svg'
import flag_LK from './svgs/lk.svg'
import flag_LR from './svgs/lr.svg'
import flag_LS from './svgs/ls.svg'
import flag_LT from './svgs/lt.svg'
import flag_LU from './svgs/lu.svg'
import flag_LV from './svgs/lv.svg'
import flag_LY from './svgs/ly.svg'
import flag_MA from './svgs/ma.svg'
import flag_MC from './svgs/mc.svg'
import flag_MD from './svgs/md.svg'
import flag_ME from './svgs/me.svg'
import flag_MF from './svgs/mf.svg'
import flag_MG from './svgs/mg.svg'
import flag_MH from './svgs/mh.svg'
import flag_MK from './svgs/mk.svg'
import flag_ML from './svgs/ml.svg'
import flag_MM from './svgs/mm.svg'
import flag_MN from './svgs/mn.svg'
import flag_MO from './svgs/mo.svg'
import flag_MP from './svgs/mp.svg'
import flag_MQ from './svgs/mq.svg'
import flag_MR from './svgs/mr.svg'
import flag_MS from './svgs/ms.svg'
import flag_MT from './svgs/mt.svg'
import flag_MU from './svgs/mu.svg'
import flag_MV from './svgs/mv.svg'
import flag_MW from './svgs/mw.svg'
import flag_MX from './svgs/mx.svg'
import flag_MY from './svgs/my.svg'
import flag_MZ from './svgs/mz.svg'
import flag_NA from './svgs/na.svg'
import flag_NC from './svgs/nc.svg'
import flag_NE from './svgs/ne.svg'
import flag_NF from './svgs/nf.svg'
import flag_NG from './svgs/ng.svg'
import flag_NI from './svgs/ni.svg'
import flag_NL from './svgs/nl.svg'
import flag_NO from './svgs/no.svg'
import flag_NP from './svgs/np.svg'
import flag_NR from './svgs/nr.svg'
import flag_NU from './svgs/nu.svg'
import flag_NZ from './svgs/nz.svg'
import flag_OM from './svgs/om.svg'
import flag_PA from './svgs/pa.svg'
import flag_PE from './svgs/pe.svg'
import flag_PF from './svgs/pf.svg'
import flag_PG from './svgs/pg.svg'
import flag_PH from './svgs/ph.svg'
import flag_PK from './svgs/pk.svg'
import flag_PL from './svgs/pl.svg'
import flag_PM from './svgs/pm.svg'
import flag_PN from './svgs/pn.svg'
import flag_PR from './svgs/pr.svg'
import flag_PS from './svgs/ps.svg'
import flag_PT from './svgs/pt.svg'
import flag_PW from './svgs/pw.svg'
import flag_PY from './svgs/py.svg'
import flag_QA from './svgs/qa.svg'
import flag_RE from './svgs/re.svg'
import flag_RO from './svgs/ro.svg'
import flag_RS from './svgs/rs.svg'
import flag_RU from './svgs/ru.svg'
import flag_RW from './svgs/rw.svg'
import flag_SA from './svgs/sa.svg'
import flag_SB from './svgs/sb.svg'
import flag_SC from './svgs/sc.svg'
import flag_SD from './svgs/sd.svg'
import flag_SE from './svgs/se.svg'
import flag_SG from './svgs/sg.svg'
import flag_SH from './svgs/sh.svg'
import flag_SI from './svgs/si.svg'
import flag_SJ from './svgs/sj.svg'
import flag_SK from './svgs/sk.svg'
import flag_SL from './svgs/sl.svg'
import flag_SM from './svgs/sm.svg'
import flag_SN from './svgs/sn.svg'
import flag_SO from './svgs/so.svg'
import flag_SR from './svgs/sr.svg'
import flag_SS from './svgs/ss.svg'
import flag_ST from './svgs/st.svg'
import flag_SV from './svgs/sv.svg'
import flag_SX from './svgs/sx.svg'
import flag_SY from './svgs/sy.svg'
import flag_SZ from './svgs/sz.svg'
import flag_TC from './svgs/tc.svg'
import flag_TD from './svgs/td.svg'
import flag_TF from './svgs/tf.svg'
import flag_TG from './svgs/tg.svg'
import flag_TH from './svgs/th.svg'
import flag_TJ from './svgs/tj.svg'
import flag_TK from './svgs/tk.svg'
import flag_TL from './svgs/tl.svg'
import flag_TM from './svgs/tm.svg'
import flag_TN from './svgs/tn.svg'
import flag_TO from './svgs/to.svg'
import flag_TR from './svgs/tr.svg'
import flag_TT from './svgs/tt.svg'
import flag_TV from './svgs/tv.svg'
import flag_TW from './svgs/tw.svg'
import flag_TZ from './svgs/tz.svg'
import flag_UA from './svgs/ua.svg'
import flag_UG from './svgs/ug.svg'
import flag_UM from './svgs/um.svg'
import flag_US from './svgs/us.svg'
import flag_UY from './svgs/uy.svg'
import flag_UZ from './svgs/uz.svg'
import flag_VA from './svgs/va.svg'
import flag_VC from './svgs/vc.svg'
import flag_VE from './svgs/ve.svg'
import flag_VG from './svgs/vg.svg'
import flag_VI from './svgs/vi.svg'
import flag_VN from './svgs/vn.svg'
import flag_VU from './svgs/vu.svg'
import flag_WF from './svgs/wf.svg'
import flag_XK from './svgs/xk.svg'
import flag_WS from './svgs/ws.svg'
import flag_YE from './svgs/ye.svg'
import flag_YT from './svgs/yt.svg'
import flag_ZA from './svgs/za.svg'
import flag_ZM from './svgs/zm.svg'
import flag_ZW from './svgs/zw.svg'

export const FlagAD = CountryFlag(flag_AD)
export const FlagAE = CountryFlag(flag_AE)
export const FlagAF = CountryFlag(flag_AF)
export const FlagAG = CountryFlag(flag_AG)
export const FlagAI = CountryFlag(flag_AI)
export const FlagAL = CountryFlag(flag_AL)
export const FlagAM = CountryFlag(flag_AM)
export const FlagAN = CountryFlag(flag_AN)
export const FlagAO = CountryFlag(flag_AO)
export const FlagAQ = CountryFlag(flag_AQ)
export const FlagAR = CountryFlag(flag_AR)
export const FlagAS = CountryFlag(flag_AS)
export const FlagAT = CountryFlag(flag_AT)
export const FlagAU = CountryFlag(flag_AU)
export const FlagAW = CountryFlag(flag_AW)
export const FlagAX = CountryFlag(flag_AX)
export const FlagAZ = CountryFlag(flag_AZ)
export const FlagBA = CountryFlag(flag_BA)
export const FlagBB = CountryFlag(flag_BB)
export const FlagBD = CountryFlag(flag_BD)
export const FlagBE = CountryFlag(flag_BE)
export const FlagBF = CountryFlag(flag_BF)
export const FlagBG = CountryFlag(flag_BG)
export const FlagBH = CountryFlag(flag_BH)
export const FlagBI = CountryFlag(flag_BI)
export const FlagBJ = CountryFlag(flag_BJ)
export const FlagBL = CountryFlag(flag_BL)
export const FlagBM = CountryFlag(flag_BM)
export const FlagBN = CountryFlag(flag_BN)
export const FlagBO = CountryFlag(flag_BO)
export const FlagBQ = CountryFlag(flag_BQ)
export const FlagBR = CountryFlag(flag_BR)
export const FlagBS = CountryFlag(flag_BS)
export const FlagBT = CountryFlag(flag_BT)
export const FlagBV = CountryFlag(flag_BV)
export const FlagBW = CountryFlag(flag_BW)
export const FlagBY = CountryFlag(flag_BY)
export const FlagBZ = CountryFlag(flag_BZ)
export const FlagCA = CountryFlag(flag_CA)
export const FlagCC = CountryFlag(flag_CC)
export const FlagCD = CountryFlag(flag_CD)
export const FlagCF = CountryFlag(flag_CF)
export const FlagCG = CountryFlag(flag_CG)
export const FlagCH = CountryFlag(flag_CH)
export const FlagCI = CountryFlag(flag_CI)
export const FlagCK = CountryFlag(flag_CK)
export const FlagCL = CountryFlag(flag_CL)
export const FlagCM = CountryFlag(flag_CM)
export const FlagCN = CountryFlag(flag_CN)
export const FlagCO = CountryFlag(flag_CO)
export const FlagCR = CountryFlag(flag_CR)
export const FlagCU = CountryFlag(flag_CU)
export const FlagCV = CountryFlag(flag_CV)
export const FlagCW = CountryFlag(flag_CW)
export const FlagCX = CountryFlag(flag_CX)
export const FlagCY = CountryFlag(flag_CY)
export const FlagCZ = CountryFlag(flag_CZ)
export const FlagDE = CountryFlag(flag_DE)
export const FlagDJ = CountryFlag(flag_DJ)
export const FlagDK = CountryFlag(flag_DK)
export const FlagDM = CountryFlag(flag_DM)
export const FlagDO = CountryFlag(flag_DO)
export const FlagDZ = CountryFlag(flag_DZ)
export const FlagEC = CountryFlag(flag_EC)
export const FlagEE = CountryFlag(flag_EE)
export const FlagEG = CountryFlag(flag_EG)
export const FlagEH = CountryFlag(flag_EH)
export const FlagER = CountryFlag(flag_ER)
export const FlagES = CountryFlag(flag_ES)
export const FlagET = CountryFlag(flag_ET)
export const FlagEU = CountryFlag(flag_EU)
export const FlagFI = CountryFlag(flag_FI)
export const FlagFJ = CountryFlag(flag_FJ)
export const FlagFK = CountryFlag(flag_FK)
export const FlagFM = CountryFlag(flag_FM)
export const FlagFO = CountryFlag(flag_FO)
export const FlagFR = CountryFlag(flag_FR)
export const FlagGA = CountryFlag(flag_GA)
export const FlagGB_ENG = CountryFlag(flag_GB_ENG)
export const FlagGB_NIR = CountryFlag(flag_GB_NIR)
export const FlagGB_SCT = CountryFlag(flag_GB_SCT)
export const FlagGB_WLS = CountryFlag(flag_GB_WLS)
export const FlagGB = CountryFlag(flag_GB)
export const FlagGD = CountryFlag(flag_GD)
export const FlagGE = CountryFlag(flag_GE)
export const FlagGF = CountryFlag(flag_GF)
export const FlagGG = CountryFlag(flag_GG)
export const FlagGH = CountryFlag(flag_GH)
export const FlagGI = CountryFlag(flag_GI)
export const FlagGL = CountryFlag(flag_GL)
export const FlagGM = CountryFlag(flag_GM)
export const FlagGN = CountryFlag(flag_GN)
export const FlagGP = CountryFlag(flag_GP)
export const FlagGQ = CountryFlag(flag_GQ)
export const FlagGR = CountryFlag(flag_GR)
export const FlagGS = CountryFlag(flag_GS)
export const FlagGT = CountryFlag(flag_GT)
export const FlagGU = CountryFlag(flag_GU)
export const FlagGW = CountryFlag(flag_GW)
export const FlagGY = CountryFlag(flag_GY)
export const FlagHK = CountryFlag(flag_HK)
export const FlagHM = CountryFlag(flag_HM)
export const FlagHN = CountryFlag(flag_HN)
export const FlagHR = CountryFlag(flag_HR)
export const FlagHT = CountryFlag(flag_HT)
export const FlagHU = CountryFlag(flag_HU)
export const FlagID = CountryFlag(flag_ID)
export const FlagIE = CountryFlag(flag_IE)
export const FlagIL = CountryFlag(flag_IL)
export const FlagIM = CountryFlag(flag_IM)
export const FlagIN = CountryFlag(flag_IN)
export const FlagIO = CountryFlag(flag_IO)
export const FlagIQ = CountryFlag(flag_IQ)
export const FlagIR = CountryFlag(flag_IR)
export const FlagIS = CountryFlag(flag_IS)
export const FlagIT = CountryFlag(flag_IT)
export const FlagJE = CountryFlag(flag_JE)
export const FlagJM = CountryFlag(flag_JM)
export const FlagJO = CountryFlag(flag_JO)
export const FlagJP = CountryFlag(flag_JP)
export const FlagKE = CountryFlag(flag_KE)
export const FlagKG = CountryFlag(flag_KG)
export const FlagKH = CountryFlag(flag_KH)
export const FlagKI = CountryFlag(flag_KI)
export const FlagKM = CountryFlag(flag_KM)
export const FlagKN = CountryFlag(flag_KN)
export const FlagKP = CountryFlag(flag_KP)
export const FlagKR = CountryFlag(flag_KR)
export const FlagKW = CountryFlag(flag_KW)
export const FlagKY = CountryFlag(flag_KY)
export const FlagKZ = CountryFlag(flag_KZ)
export const FlagLA = CountryFlag(flag_LA)
export const FlagLB = CountryFlag(flag_LB)
export const FlagLC = CountryFlag(flag_LC)
export const FlagLI = CountryFlag(flag_LI)
export const FlagLK = CountryFlag(flag_LK)
export const FlagLR = CountryFlag(flag_LR)
export const FlagLS = CountryFlag(flag_LS)
export const FlagLT = CountryFlag(flag_LT)
export const FlagLU = CountryFlag(flag_LU)
export const FlagLV = CountryFlag(flag_LV)
export const FlagLY = CountryFlag(flag_LY)
export const FlagMA = CountryFlag(flag_MA)
export const FlagMC = CountryFlag(flag_MC)
export const FlagMD = CountryFlag(flag_MD)
export const FlagME = CountryFlag(flag_ME)
export const FlagMF = CountryFlag(flag_MF)
export const FlagMG = CountryFlag(flag_MG)
export const FlagMH = CountryFlag(flag_MH)
export const FlagMK = CountryFlag(flag_MK)
export const FlagML = CountryFlag(flag_ML)
export const FlagMM = CountryFlag(flag_MM)
export const FlagMN = CountryFlag(flag_MN)
export const FlagMO = CountryFlag(flag_MO)
export const FlagMP = CountryFlag(flag_MP)
export const FlagMQ = CountryFlag(flag_MQ)
export const FlagMR = CountryFlag(flag_MR)
export const FlagMS = CountryFlag(flag_MS)
export const FlagMT = CountryFlag(flag_MT)
export const FlagMU = CountryFlag(flag_MU)
export const FlagMV = CountryFlag(flag_MV)
export const FlagMW = CountryFlag(flag_MW)
export const FlagMX = CountryFlag(flag_MX)
export const FlagMY = CountryFlag(flag_MY)
export const FlagMZ = CountryFlag(flag_MZ)
export const FlagNA = CountryFlag(flag_NA)
export const FlagNC = CountryFlag(flag_NC)
export const FlagNE = CountryFlag(flag_NE)
export const FlagNF = CountryFlag(flag_NF)
export const FlagNG = CountryFlag(flag_NG)
export const FlagNI = CountryFlag(flag_NI)
export const FlagNL = CountryFlag(flag_NL)
export const FlagNO = CountryFlag(flag_NO)
export const FlagNP = CountryFlag(flag_NP)
export const FlagNR = CountryFlag(flag_NR)
export const FlagNU = CountryFlag(flag_NU)
export const FlagNZ = CountryFlag(flag_NZ)
export const FlagOM = CountryFlag(flag_OM)
export const FlagPA = CountryFlag(flag_PA)
export const FlagPE = CountryFlag(flag_PE)
export const FlagPF = CountryFlag(flag_PF)
export const FlagPG = CountryFlag(flag_PG)
export const FlagPH = CountryFlag(flag_PH)
export const FlagPK = CountryFlag(flag_PK)
export const FlagPL = CountryFlag(flag_PL)
export const FlagPM = CountryFlag(flag_PM)
export const FlagPN = CountryFlag(flag_PN)
export const FlagPR = CountryFlag(flag_PR)
export const FlagPS = CountryFlag(flag_PS)
export const FlagPT = CountryFlag(flag_PT)
export const FlagPW = CountryFlag(flag_PW)
export const FlagPY = CountryFlag(flag_PY)
export const FlagQA = CountryFlag(flag_QA)
export const FlagRE = CountryFlag(flag_RE)
export const FlagRO = CountryFlag(flag_RO)
export const FlagRS = CountryFlag(flag_RS)
export const FlagRU = CountryFlag(flag_RU)
export const FlagRW = CountryFlag(flag_RW)
export const FlagSA = CountryFlag(flag_SA)
export const FlagSB = CountryFlag(flag_SB)
export const FlagSC = CountryFlag(flag_SC)
export const FlagSD = CountryFlag(flag_SD)
export const FlagSE = CountryFlag(flag_SE)
export const FlagSG = CountryFlag(flag_SG)
export const FlagSH = CountryFlag(flag_SH)
export const FlagSI = CountryFlag(flag_SI)
export const FlagSJ = CountryFlag(flag_SJ)
export const FlagSK = CountryFlag(flag_SK)
export const FlagSL = CountryFlag(flag_SL)
export const FlagSM = CountryFlag(flag_SM)
export const FlagSN = CountryFlag(flag_SN)
export const FlagSO = CountryFlag(flag_SO)
export const FlagSR = CountryFlag(flag_SR)
export const FlagSS = CountryFlag(flag_SS)
export const FlagST = CountryFlag(flag_ST)
export const FlagSV = CountryFlag(flag_SV)
export const FlagSX = CountryFlag(flag_SX)
export const FlagSY = CountryFlag(flag_SY)
export const FlagSZ = CountryFlag(flag_SZ)
export const FlagTC = CountryFlag(flag_TC)
export const FlagTD = CountryFlag(flag_TD)
export const FlagTF = CountryFlag(flag_TF)
export const FlagTG = CountryFlag(flag_TG)
export const FlagTH = CountryFlag(flag_TH)
export const FlagTJ = CountryFlag(flag_TJ)
export const FlagTK = CountryFlag(flag_TK)
export const FlagTL = CountryFlag(flag_TL)
export const FlagTM = CountryFlag(flag_TM)
export const FlagTN = CountryFlag(flag_TN)
export const FlagTO = CountryFlag(flag_TO)
export const FlagTR = CountryFlag(flag_TR)
export const FlagTT = CountryFlag(flag_TT)
export const FlagTV = CountryFlag(flag_TV)
export const FlagTW = CountryFlag(flag_TW)
export const FlagTZ = CountryFlag(flag_TZ)
export const FlagUA = CountryFlag(flag_UA)
export const FlagUG = CountryFlag(flag_UG)
export const FlagUM = CountryFlag(flag_UM)
export const FlagUS = CountryFlag(flag_US)
export const FlagUY = CountryFlag(flag_UY)
export const FlagUZ = CountryFlag(flag_UZ)
export const FlagVA = CountryFlag(flag_VA)
export const FlagVC = CountryFlag(flag_VC)
export const FlagVE = CountryFlag(flag_VE)
export const FlagVG = CountryFlag(flag_VG)
export const FlagVI = CountryFlag(flag_VI)
export const FlagVN = CountryFlag(flag_VN)
export const FlagVU = CountryFlag(flag_VU)
export const FlagWF = CountryFlag(flag_WF)
export const FlagXK = CountryFlag(flag_XK)
export const FlagWS = CountryFlag(flag_WS)
export const FlagYE = CountryFlag(flag_YE)
export const FlagYT = CountryFlag(flag_YT)
export const FlagZA = CountryFlag(flag_ZA)
export const FlagZM = CountryFlag(flag_ZM)
export const FlagZW = CountryFlag(flag_ZW)