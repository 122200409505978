import { Tag, Tooltip } from 'antd';

import { COLOR } from '@/configs/general';

const GDPRTooltip = () => (
  <Tooltip title="Warning. Access to personal data is possible">
    <Tag color={COLOR.RED}>GDRP</Tag>
  </Tooltip>
);

export { GDPRTooltip };
