import { Checkbox } from 'antd';
import { useContext } from 'react';

import { logisticsContext } from '../logisticsContext';

const AccessoriesDeviationInformCheckbox = () => {
  const { isOtherAccessoriesChanged, informAboutAccessories, setInformAboutAccessories } = useContext(logisticsContext);
  const handleInformCheckboxChange = (e) => setInformAboutAccessories(e.target.checked);

  if (!isOtherAccessoriesChanged) {
    return;
  }

  return (
    <Checkbox checked={informAboutAccessories} onChange={handleInformCheckboxChange}>
      Inform the store about accessories changes
    </Checkbox>
  );
};

export { AccessoriesDeviationInformCheckbox };
