import { PrinterOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { Button, Dropdown } from 'antd';
import { useContext } from 'react';

import { userContext } from '@/helpers/userContext';
import { RequestModel } from '@/models/request';

const PrintButton = (props) => {
  const { disabled, convertToPdf, pdfUrl, text, type } = props;
  const { loading, run: print } = useRequest(
    (printerId) => RequestModel.postJson(`/api/v1/activity/print-pdf`, { body: { pdfUrl, printerId, convertToPdf } }),
    { manual: true }
  );
  const { user } = useContext(userContext);

  if (!(user?.printers || []).filter((p) => p.settings.type === type).length) {
    return (
      <Button href={pdfUrl} target="_blank" rel="noreferrer" icon={<PrinterOutlined />} disabled={disabled}>
        {text}
      </Button>
    );
  }

  const handlePrint = (printerId) => {
    if (loading) {
      return;
    }

    print(Number(printerId), pdfUrl);
  };

  const onPrinterClick = (id) => () => handlePrint(id);
  const onPrinterDropdownClick = ({ key }) => handlePrint(key);

  const { printers } = user;

  if (printers.length === 1) {
    return (
      <Button icon={<PrinterOutlined />} onClick={onPrinterClick(printers[0].id)} type="primary" disabled={disabled}>
        {text}
      </Button>
    );
  }

  const defaultByTypePrinterId = (printers.find(({ settings }) => settings && settings.type === type) || printers[0])
    ?.id;

  const printersMenuItems = printers.map(({ id: key, name: label }) => ({ key, label }));

  return (
    <Dropdown
      menu={{
        items: printersMenuItems,
        onClick: onPrinterDropdownClick
      }}
    >
      <Button
        icon={<PrinterOutlined />}
        onClick={onPrinterClick(defaultByTypePrinterId)}
        type="primary"
        loading={loading}
        disabled={disabled}
      >
        {text}
      </Button>
    </Dropdown>
  );
};

export { PrintButton };
