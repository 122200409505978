import { useMount } from 'ahooks';
import { Table } from 'antd';
import { forwardRef, useImperativeHandle, useState } from 'react';

const loadSavedPaginationData = () => {
  try {
    const key = `paginationData:${window.location.pathname}`;

    const stored = localStorage.getItem(key);

    if (stored) {
      return JSON.parse(stored);
    }
  } catch {
    // We don't care
  }
};

const savePaginationData = (data) => {
  const key = `paginationData:${window.location.pathname}`;
  localStorage.setItem(key, JSON.stringify(data));
};

export default forwardRef((props, ref) => {
  const { request, pageSize = 50, rowKey = 'id', ...tableProps } = props;

  const [currentPage, setPage] = useState(1);

  const [currentPageSize, setPageSize] = useState(pageSize);

  const run = (page = currentPage, myPageSize = currentPageSize, data = {}) => {
    request.run({ page: page - 1, pageSize: myPageSize, ...data });
  };

  useMount(() => {
    const saved = loadSavedPaginationData();
    const myPage = saved?.page || 1;
    const myPageSize = saved?.pageSize || pageSize;
    setPage(myPage);
    setPageSize(myPageSize);
    run(myPage, myPageSize);
  });

  const updatePagination = (newPage, newPageSize) => {
    if (newPageSize !== currentPageSize) {
      /* eslint-disable-next-line no-param-reassign */
      newPage = 1;
      setPage(1);
      setPageSize(newPageSize);
    } else if (newPage !== currentPage) {
      setPage(newPage);
    }

    savePaginationData({ page: newPage, pageSize: newPageSize });

    run(newPage, newPageSize);
  };

  const data = request.data;
  const paginationProps = {
    current: currentPage,
    pageSize: currentPageSize,
    total: data?.pagination.count || 0,
    onChange: updatePagination,
    position: ['bottomLeft']
  };

  useImperativeHandle(ref, () => {
    return {
      reset(extra = {}) {
        setPage(1);
        run(1, currentPageSize, extra);
      },
      refresh(extra = {}) {
        run(currentPage, currentPageSize, extra);
      }
    };
  });

  return (
    <Table
      rowKey={rowKey}
      pagination={paginationProps}
      loading={request.loading}
      dataSource={data?.data}
      {...tableProps}
    />
  );
});
