import { useRequest } from 'ahooks';
import { Card, Space } from 'antd';
import { useContext } from 'react';

import { Block } from '@/components/Block';
import { BalanceInfo } from '@/containers/timeTracker/BalanceInfo';
import { GeneralEmployeeInfo } from '@/containers/timeTracker/GeneralEmployeeInfo';
import { GeneralInfo } from '@/containers/timeTracker/GeneralInfo';
import { userContext } from '@/helpers/userContext';
import { RequestModel } from '@/models/request';
import { TimeTrackerModel } from '@/models/timetracker';

import { TimeTrackerCalendar, VIEW_TYPE } from '../timeTracker/components/Calendar';
import { TimeTrackerProvider } from '../timeTracker/timeTrackerContext';

const Statistics = () => {
  const { user } = useContext(userContext);
  const { timeTrackerUser } = user;

  if (!timeTrackerUser) {
    return null;
  }

  const addWorkDay = useRequest(TimeTrackerModel.changeWorkday, {
    manual: true
  });

  const employeeWorkPeriod = useRequest(() => TimeTrackerModel.getCurrentWorkPeriod(timeTrackerUser), {
    onError: (error) => {
      if (error instanceof RequestModel.NotFoundError) {
        addWorkDay.run(
          {
            userId: timeTrackerUser.userId,
            schema: timeTrackerUser.schema
          },
          true
        );
      }
    },
    refreshDeps: [addWorkDay.data]
  });
  const employeeWorkDay = useRequest(() => TimeTrackerModel.getCurrentWorkDay(timeTrackerUser));
  const employeeInfo = useRequest(() => TimeTrackerModel.getEmployeeInfo(timeTrackerUser));

  const hideFlexTime = user.country === 'NO' || TimeTrackerModel.isHourlyPaidEmployee(timeTrackerUser);
  const loading = employeeWorkPeriod.loading || employeeWorkDay.loading || addWorkDay.loading || employeeInfo.loading;

  return (
    <>
      <GeneralEmployeeInfo title="My info" user={user} />
      <GeneralInfo user={user} employeeInfo={employeeInfo?.data} loading={loading} />
      <BalanceInfo
        user={user}
        employeeInfo={employeeInfo?.data}
        workPeriod={employeeWorkPeriod?.data}
        workDay={employeeWorkDay?.data}
        loading={loading}
        hideFlexTime={hideFlexTime}
      />
      <Block>
        <Card title="Overview">
          <Space direction="vertical" size="large">
            <TimeTrackerCalendar user={user} editable={false} hideFlexTime={hideFlexTime} />
            <TimeTrackerCalendar user={user} editable={false} type={VIEW_TYPE.SCHEMAS} />
          </Space>
        </Card>
      </Block>
    </>
  );
};

const TimeTrackerStatistics = () => (
  <TimeTrackerProvider>
    <Statistics />
  </TimeTrackerProvider>
);

export { TimeTrackerStatistics };
