import { useMount } from 'ahooks';
import { Layout, Spin } from 'antd';
import _ from 'lodash';
import { createContext, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { PRINTER_TYPE } from '@/configs/entity';
import { PERMISSION, ROUTE } from '@/configs/general';
import { UserModel } from '@/models/user';

import { useRedirectTo } from './hooks';

const userContext = createContext(null);
const { Provider } = userContext;

const UserProvider = (props) => {
  const { children } = props;
  const [data, setData] = useState(null);
  const goLogin = useRedirectTo(ROUTE.LOGIN);
  const history = useHistory();
  const { pathname } = useLocation();
  const needToLoadUserInfo = pathname !== ROUTE.LOGIN;

  const getLocalSettings = () => JSON.parse(window.localStorage.getItem('localSettings')) || {};

  const changeLocalSettings = (changes) => {
    const newSettings = {
      ...getLocalSettings(),
      ...changes
    };

    window.localStorage.setItem('localSettings', JSON.stringify(newSettings));

    setData({
      ...data,
      localSettings: newSettings
    });
  };

  const handleResponse = async (response) => {
    const { user, ...loginData } = response;

    const contactDataPath = 'logisticUser.department.contactData';

    if (
      [
        (_.get(user, `${contactDataPath}.name`),
        _.get(user, `${contactDataPath}.address`),
        _.get(user, `${contactDataPath}.postalCode`),
        _.get(user, `${contactDataPath}.city`),
        _.get(user, `${contactDataPath}.countryCode`),
        _.get(user, `${contactDataPath}.email`))
      ].every(Boolean)
    ) {
      user.canForwardActivities = true;
    }

    if (user?.printers?.some(({ settings }) => settings?.type === PRINTER_TYPE.SMALL_LABEL)) {
      user.canPrintLabels = true;
    }

    setData({
      loginData,
      user,
      localSettings: getLocalSettings()
    });
  };

  const getUserSettings = async () => {
    const currentUserData = await UserModel.loadCurrentUser();

    if (!currentUserData) {
      setData({
        localSettings: getLocalSettings()
      });
      goLogin();

      return;
    }

    await handleResponse(currentUserData);
  };

  useMount(async () => {
    if (needToLoadUserInfo) {
      await getUserSettings();
    }
  });

  const hasPermission = (permission) => {
    const userPermissions = data.loginData?.permissions || [];

    if (userPermissions.includes(PERMISSION.WILDCARD)) {
      return true;
    }

    if (_.isString(permission)) {
      return userPermissions.includes(permission);
    }

    if (_.isArray(permission)) {
      return _.intersection(userPermissions, permission).length > 0;
    }

    return false;
  };

  if (!data && needToLoadUserInfo) {
    return (
      <Spin className="ant-spin-preloader" tip="Loading">
        <Layout />
      </Spin>
    );
  }

  const login = async (credentials, redirectUrl) => {
    const auth = await UserModel.login(credentials.login, credentials.password);

    if (!auth) {
      return false;
    }

    await handleResponse(auth);

    history.replace(redirectUrl || '/');

    return true;
  };

  const logout = async () => {
    await UserModel.logout();

    goLogin();

    setData({
      localSettings: getLocalSettings()
    });
  };

  return (
    <Provider value={{ changeLocalSettings, hasPermission, login, logout, syncUserSettings: getUserSettings, ...data }}>
      {children}
    </Provider>
  );
};

export { userContext, UserProvider };
