/* eslint-disable no-template-curly-in-string */
import { useRequest } from 'ahooks';
import { Alert, Select, Spin } from 'antd';
import _ from 'lodash';

import { EntitiesModel } from '@/models/entities';

const LIMIT = 100;

const dataToOptions = (data = []) => data.map(({ id, name, value }) => ({ label: name || value, value: id }));

const isEmptyValue = (value) => {
  return (
    value === undefined ||
    value === null ||
    Number.isNaN(value) ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0)
  );
};

const CustomSelect = (props) => {
  const { customDataToOptions, entity, extraFilters = {}, searchBy = 'name', value, values = [], ...restProps } = props;
  const isSearchable = _.isString(entity);

  const { data, loading } = useRequest(
    () => EntitiesModel.getEntityValues(entity, { filters: { id: _.isArray(value) ? { $in: value } : value } }),
    {
      formatResult: (result) => ({
        ...result,
        options: _.sortBy(customDataToOptions?.(result.data) || dataToOptions(result.data), 'label')
      }),
      initialData: null,
      ready: isSearchable && !isEmptyValue(value)
    }
  );
  const { data: searchResults, loading: searchLoading, run: search } = useRequest(
    (key, searchValue) => {
      const params = searchValue
        ? { filters: { [key]: { $ilike: `%${searchValue}%` }, ...extraFilters } }
        : { filters: { ...extraFilters } };

      return EntitiesModel.getEntityValues(entity, params);
    },
    {
      formatResult: (result) => {
        if (result) {
          return {
            ...result,
            options: _.sortBy(customDataToOptions?.(result.data) || dataToOptions(result.data), 'label')
          };
        }
      },
      debounceInterval: 300,
      manual: true
    }
  );

  const onSearch = (key) => (searchValue) => {
    search(key, searchValue);
  };

  const onDropdownVisible = (isVisible) => {
    if (!isVisible) {
      return;
    }

    search();
  };

  const searchableProps = isSearchable
    ? {
        showSearch: true,
        filterOption: false,
        onSearch: onSearch(searchBy),
        onDropdownVisibleChange: onDropdownVisible
      }
    : {};

  return (
    <>
      <Select
        {...restProps}
        {...searchableProps}
        value={value}
        loading={loading}
        options={searchResults?.options || data?.options || _.sortBy(values, 'label')}
        notFoundContent={searchLoading ? <Spin size="small" /> : 'Not Found'}
      />
      {data?.pagination?.count > LIMIT && (
        <Alert message="Too many options, use search to narrow them." type="warning" />
      )}
    </>
  );
};

export { CustomSelect };
