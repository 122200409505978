import { Tag } from 'antd';

import styles from './styles.module.scss';

const DateInline = ({ asString, asTag, value }) => {
  const data = value ? new Date(value).toLocaleString('sv-SE') : '–';

  if (asString) {
    return data;
  }

  if (asTag) {
    return <Tag className={styles.dateInline}>{data}</Tag>;
  }

  return <>{data}</>;
};

export { DateInline };
