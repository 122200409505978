import { Button, Form, Space } from 'antd';

import { SelectAutocomplete } from '@/components/SelectAutocomplete';

const CoreValuesForm = ({ caseData, coreValues, onFinish }) => {
  const { productTypeId, manufacturerId, serviceTypeId } = caseData;

  const onFormFinish = (values) => onFinish(values);

  return (
    <Form
      initialValues={{
        productTypeId,
        manufacturerId,
        serviceTypeId
      }}
      onFinish={onFormFinish}
      autoComplete="off"
    >
      <Form.Item label="Brand" name="manufacturerId">
        <SelectAutocomplete
          options={coreValues.manufacturers.map((x) => ({ value: x.id, label: x.name }))}
          skipMapping
        />
      </Form.Item>
      <Form.Item label="Service type" name="serviceTypeId">
        <SelectAutocomplete
          options={coreValues.serviceTypes.map((x) => ({ value: x.id, label: x.name }))}
          skipMapping
        />
      </Form.Item>
      <Form.Item label="Product type" name="productTypeId">
        <SelectAutocomplete
          options={coreValues.productTypes.map((x) => ({ value: x.id, label: x.name }))}
          skipMapping
        />
      </Form.Item>
      <Form.Item>
        <Space>
          <Button type="primary" htmlType="submit">
            Change
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export { CoreValuesForm };
