/* eslint-disable no-template-curly-in-string */
import { Checkbox, Divider, Form, Input, InputNumber, Skeleton, Switch } from 'antd';
import _ from 'lodash';
import { useMemo } from 'react';

import { PROPERTY_TYPE } from '@/configs/entity';

import { CustomDatePicker } from './CustomDatePicker';
import { CustomSelect } from './CustomSelect';
import { CustomTimestampPicker } from './CustomTimestampPicker';

const typeToComponent = (type) => {
  const components = {
    [PROPERTY_TYPE.ARRAY]: CustomSelect,
    [PROPERTY_TYPE.BOOL]: Switch,
    [PROPERTY_TYPE.BOOLEAN]: Checkbox,
    [PROPERTY_TYPE.CHECKBOX]: Checkbox,
    [PROPERTY_TYPE.DATE]: CustomDatePicker,
    [PROPERTY_TYPE.ENUM]: CustomSelect,
    [PROPERTY_TYPE.NUMBER]: InputNumber,
    [PROPERTY_TYPE.STRING]: Input,
    [PROPERTY_TYPE.TIMESTAMP]: CustomTimestampPicker
  };

  return components[type] || Input;
};

// More information here: https://ant.design/components/form/#validateMessages
const typeToValidationType = (type) => {
  const validationTypes = {
    [PROPERTY_TYPE.ARRAY]: 'array',
    [PROPERTY_TYPE.BOOL]: 'boolean',
    [PROPERTY_TYPE.BOOLEAN]: 'boolean',
    [PROPERTY_TYPE.CHECKBOX]: 'boolean',
    [PROPERTY_TYPE.DATE]: 'string',
    [PROPERTY_TYPE.ENUM]: 'string',
    [PROPERTY_TYPE.NUMBER]: 'number',
    [PROPERTY_TYPE.STRING]: 'string',
    [PROPERTY_TYPE.TIMESTAMP]: 'string',
    [PROPERTY_TYPE.OBJECT]: 'object'
  };

  return validationTypes[type] || 'string';
};

const schemaToFormFields = (schema, params = {}) => {
  const { customFormFieldsProps = {}, data = {}, extraFiltersByFieldKey = {}, hiddenFieldsKeys = [] } = params;
  const { parentKeys = null, reorderKeys = [] } = params;
  const { properties } = schema;

  if (!properties) {
    throw new Error(`Field "${parentKeys ? parentKeys.join() : 'unknown'}" has no properties`);
  }

  const fields = [];
  const isNew = _.isEmpty(data);

  const hiddenFields = {};
  _.keyBy(hiddenFieldsKeys, (key) => _.set(hiddenFields, key, true));

  const orderedKeys = Object.keys(properties);

  for (const { key, placeAfter, placeBefore } of reorderKeys) {
    const keyIndex = orderedKeys.findIndex((value) => value === key);

    if (keyIndex === -1) {
      continue;
    }

    if (placeAfter && orderedKeys.some((value) => value === placeAfter)) {
      orderedKeys.splice(keyIndex, 1);
      orderedKeys.splice(orderedKeys.findIndex((value) => value === placeAfter) + 1, 0, key);
    } else if (placeBefore && orderedKeys.some((value) => value === placeBefore)) {
      orderedKeys.splice(keyIndex, 1);
      // prettier-ignore
      orderedKeys.splice(orderedKeys.findIndex((value) => value === placeBefore), 0, key);
    }
  }

  for (const propertyKey of orderedKeys) {
    const property = properties[propertyKey];
    property.key = propertyKey;

    // We can set primary keys for newly created entities if they are not read-only
    if (
      _.get(hiddenFields, [...(parentKeys || []), propertyKey].filter(Boolean)) === true ||
      property.readOnly ||
      (property.primaryKey && !isNew) ||
      property.referencedProperties
    ) {
      continue;
    }

    if (property.title) {
      fields.push({
        alwaysVisible: property.alwaysVisible,
        title: _.capitalize(_.startCase(property.title))
      });
    } else if (property.title === null) {
      fields.push({
        alwaysVisible: property.alwaysVisible,
        title: null
      });
    }

    if (property.type === PROPERTY_TYPE.EMPTY) {
      continue;
    }

    if (property.type === PROPERTY_TYPE.OBJECT && !property.component) {
      if (property.properties) {
        fields.push(
          ...schemaToFormFields(property, {
            ...params,
            parentKeys: [...(parentKeys || []), propertyKey]
          })
        );
      }

      continue;
    }

    const path = parentKeys ? [...parentKeys, propertyKey] : propertyKey;

    const itemProps = {
      label: property.label || _.capitalize(_.startCase(propertyKey)),
      name: path,
      placeholder: property.description,
      required: Boolean(property.required),
      // More information here: https://ant.design/components/form/#validateMessages
      rules: [
        {
          required: Boolean(property.required),
          max: property.maxLength,
          min: property.minLength,
          type: typeToValidationType(property.validationType || property.type.toLowerCase())
        }
      ],
      hidden: property.hidden,
      ..._.get(customFormFieldsProps, _.isArray(path) ? [...path, 'itemProps'] : [path, 'itemProps'], {})
    };

    // Pass initial value ONLY if it is defined and the actual data doesn't have any value
    // Otherwise we will get console errors from Antd because of initial values in Form and Form.Item at the same time
    if (property.initialValue !== undefined && _.get(data, path) === undefined) {
      itemProps.initialValue = property.initialValue;
    }

    let componentProps = {};
    const componentType = property.referencedEntities ? PROPERTY_TYPE.ENUM : property.type.toLowerCase();

    if (componentType === PROPERTY_TYPE.NUMBER) {
      componentProps.min = property.min;
      componentProps.max = property.max;
    }

    if (componentType === PROPERTY_TYPE.ENUM) {
      componentProps.entity = property.referencedEntities?.[0];
      componentProps.values = property.values;
    }

    if (componentType === PROPERTY_TYPE.ARRAY) {
      componentProps.entity = property.items?.title;
      // Switch to tags mode if this is a simple array and not a relation
      componentProps.mode = property.items ? 'multiple' : 'tags';

      if (extraFiltersByFieldKey?.[property.key]) {
        componentProps.extraFilters = extraFiltersByFieldKey[property.key];
      }
    }

    if ([PROPERTY_TYPE.BOOL, PROPERTY_TYPE.BOOLEAN, PROPERTY_TYPE.CHECKBOX].includes(componentType)) {
      itemProps.valuePropName = 'checked';
    }

    if (componentType === PROPERTY_TYPE.DATE) {
      componentProps.allowClear = !property.required;
    }

    componentProps = {
      ...componentProps,
      ..._.get(customFormFieldsProps, _.isArray(path) ? [...path, 'componentProps'] : [path, 'componentProps'], {})
    };

    fields.push({
      alwaysVisible: property.alwaysVisible,
      componentProps,
      itemProps,
      Component: property.component || typeToComponent(componentType)
    });
  }

  return fields;
};

const dataToFormData = (data, schema) => {
  const processedData = { ...data };

  for (const [key, property] of Object.entries(schema.properties)) {
    if (property.type === 'array' && property.items) {
      // We should replace lists of related entities to lists of primary keys
      // Otherwise selects won't work
      processedData[key] = data[key].map((x) => x.id);
    } else if (property.type === 'object' && property.properties) {
      // Process nested entities as well
      processedData[key] = dataToFormData(data[key], property);
    }
  }

  return processedData;
};

const LocalSchemaForm = (props) => {
  const { customFormFieldsProps, data, schema, loading, bottomContent, onFinish, formPrefix, formSuffix } = props;
  const { showTitles, extraFiltersByFieldKey, hiddenFieldsKeys, predefinedObjects, reorderKeys } = props;

  const values = useMemo(() => (data ? dataToFormData(data, schema) : undefined), [schema, schema]);

  const formSchema = useMemo(() => {
    if (!schema) {
      return;
    }

    const overriddenSchema = _.clone(schema);

    /*
      Transform keys to be override from `key` to `properties.key`, and replace the initial value
      E.g.:
        `logisticUser.settings` will become `properties.logisticUser.properties.settings';
        `properties.logisticUser.properties.settings` will be overridden by `logisticUser.settings` value.
      */
    _.forIn(predefinedObjects, ({ merge, ...value } = {}, key) => {
      const schemaKey = `properties.${key.replace('.', '.properties.')}`;

      if (merge) {
        _.set(overriddenSchema, schemaKey, { ..._.get(overriddenSchema, schemaKey, {}), ...value });

        return;
      }

      _.set(overriddenSchema, schemaKey, value);
    });

    const mySchema = schemaToFormFields(overriddenSchema, {
      customFormFieldsProps,
      data,
      extraFiltersByFieldKey,
      hiddenFieldsKeys: _.isFunction(hiddenFieldsKeys) ? hiddenFieldsKeys(data) : hiddenFieldsKeys,
      reorderKeys
    });

    return mySchema;
  }, [schema, data]);

  const [form] = Form.useForm();

  return (
    <Skeleton active loading={loading}>
      {props.children}
      <Form
        className="schema-form"
        form={form}
        initialValues={values}
        // More information here: https://ant.design/components/form/#validateMessages
        validateMessages={{
          required: 'Please input ${label}',
          string: {
            len: '${label} must be exactly ${len} characters',
            min: '${label} must be at least ${min} characters',
            max: '${label} cannot be longer than ${max} characters',
            range: '${label} must be between ${min} and ${max} characters'
          }
        }}
        scrollToFirstError
        onFinish={onFinish}
      >
        {formPrefix}
        {formSchema?.map(({ alwaysVisible, componentProps, itemProps, title, Component }) =>
          title || title === null ? (
            (showTitles || alwaysVisible) && <Divider key={title}>{title}</Divider>
          ) : (
            <Form.Item key={itemProps.name} {...itemProps}>
              <Component {...componentProps} />
            </Form.Item>
          )
        )}
        {formSuffix}
        {bottomContent}
        {/* For debug purposes */}
        {/* <Form.Item noStyle shouldUpdate>
          {() => <pre>{JSON.stringify(form.getFieldsValue(), null, 2)}</pre>}
        </Form.Item> */}
      </Form>
    </Skeleton>
  );
};

export { LocalSchemaForm };
