import { Card, Row } from 'antd';

import { Block } from '@/components/Block';
import { CheckInInline } from '@/components/CheckInInline';
import { TIMETRACKER_SCHEMA_LABEL } from '@/configs/entity';
import { COLOR } from '@/configs/general';
import { TimeTrackerModel } from '@/models/timetracker';

import { EmployeeStatistic } from './EmployeeStatistic';

const COL_PROPS = { span: 6 };

const GeneralInfo = (props) => {
  const { employeeInfo, loading, user } = props;
  const { timeTrackerUser } = user || {};

  const isHourlyPaidEmployee = TimeTrackerModel.isHourlyPaidEmployee(timeTrackerUser);
  const checkedIn = TimeTrackerModel.checkCheckedIn(timeTrackerUser?.activeCheckInTimestamp);
  const isOvernight = TimeTrackerModel.checkOvernight(timeTrackerUser?.activeCheckInTimestamp);

  return (
    <Block>
      <Card title="Time tracking info">
        <Row>
          {!isOvernight && (
            <EmployeeStatistic
              title="Current status"
              value={checkedIn ? 'Working' : 'Away'}
              color={checkedIn ? COLOR.GREEN : COLOR.GOLD}
              loading={loading}
              colProps={COL_PROPS}
            />
          )}
          {isOvernight && (
            <EmployeeStatistic
              title="Current status"
              value="Overnight"
              color={COLOR.RED}
              loading={loading}
              colProps={COL_PROPS}
            />
          )}
          {!isOvernight && employeeInfo?.checkedInAt && (
            <EmployeeStatistic
              title="Last check-in"
              value={<CheckInInline value={employeeInfo.checkedInAt} />}
              loading={loading}
              colProps={COL_PROPS}
            />
          )}
          <EmployeeStatistic
            title="Workday info"
            value={
              !employeeInfo?.isRegularDay || !employeeInfo?.schema
                ? "Work isn't scheduled"
                : `${TimeTrackerModel.durationToText(employeeInfo.ratedMinutes)} worked${
                    employeeInfo.flexTime < 0 ? `, ${TimeTrackerModel.durationToText(-employeeInfo.flexTime)} left` : ''
                  }`
            }
            loading={loading}
            colProps={COL_PROPS}
          />
          <EmployeeStatistic
            title="Schema"
            value={TIMETRACKER_SCHEMA_LABEL[String(user?.country)]?.[String(timeTrackerUser?.schema)]}
            color={timeTrackerUser?.schema < 100 ? COLOR.RED : null}
            showHourlyPaidTag={isHourlyPaidEmployee}
            loading={loading}
            colProps={COL_PROPS}
          />
        </Row>
      </Card>
    </Block>
  );
};

export { GeneralInfo };
