/* eslint-disable */
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
/* eslint-enable */

// prettier-ignore
import { AlertOutlined, ExclamationCircleOutlined, EditOutlined, MessageOutlined, ReloadOutlined } from '@ant-design/icons';
import { useCreation, useMount, usePersistFn, useRequest, useUnmount } from 'ahooks';
import { Button, Drawer, Space, Spin, Tag, Tooltip } from 'antd';
import moment from 'moment';
import { useRef, useState, useContext } from 'react';

import { PERMISSION } from '@/configs/general';
import { showError } from '@/helpers/Alert';

import { userContext } from '@/helpers/userContext';
import { TimeTrackerModel } from '@/models/timetracker';
import { TimeTrackerSchemaEditForm } from './SchemaEditForm';
import { timeTrackerContext, TIMETRACKER_EVENT } from '../timeTrackerContext';
// prettier-ignore
import { DAY_ABSENCE_LABEL, DAY_ABSENCE_LABEL_NO, HOURLY_ABSENCE_LABEL, HOURLY_ABSENCE_LABEL_NO, HOURLY_WORKTYPE_LABEL, WAGE_LABEL, WORKTYPE, WORKTYPE_LABEL } from '../configs';

const VIEW_TYPE = {
  INTERVALS_WEEK: 'listWeek',
  INTERVALS_MONTH: 'listMonth',
  SCHEMAS: 'dayGridMonth'
};

const getTitleByPartDayCode = (partDayCode, country) => {
  if (!partDayCode) {
    return '';
  }

  const [code, schema] = partDayCode.split('_');

  return `, ${(country === 'NO' ? HOURLY_ABSENCE_LABEL_NO : HOURLY_ABSENCE_LABEL)[code]} ${schema}%`;
};

const getIntervalTitle = (interval, user, hideFlexTime) => {
  const { rate, totalMinutes, payload, workType } = interval;
  const isHourlyPaidEmployee = TimeTrackerModel.isHourlyPaidEmployee(user.timeTrackerUser);
  const additionalInfo = `(${totalMinutes} minutes${
    Math.abs(rate) === 100 ? ')' : ` at rate ${rate}% – ${Math.floor(totalMinutes * rate * 0.01)} minutes total)`
  }`;

  if (workType === WORKTYPE.ABSENCE) {
    if (payload?.wageCode) {
      return `${WAGE_LABEL[payload?.wageCode] || 'Unknown'} ${additionalInfo}`;
    }

    return `${WORKTYPE_LABEL[workType]}: ${
      (user.country === 'NO' ? HOURLY_ABSENCE_LABEL_NO : HOURLY_ABSENCE_LABEL)[payload?.code] || 'Unknown'
    } ${additionalInfo}`;
  }

  if (isHourlyPaidEmployee) {
    if (workType === WORKTYPE.COMPENSATION_TIME) {
      return `${HOURLY_WORKTYPE_LABEL[workType]} ${rate > 0 ? 'added' : 'subtracted'} ${additionalInfo}`;
    }

    return `${HOURLY_WORKTYPE_LABEL[workType]} ${additionalInfo}`;
  }

  if (workType === WORKTYPE.REGULAR) {
    return `${WORKTYPE_LABEL[workType]} ${additionalInfo}`;
  }

  if (user.country === 'NO' && workType === WORKTYPE.FLEX_TIME) {
    return rate > 0 && !hideFlexTime
      ? `Time deficit decreased ${additionalInfo}`
      : 'At work outside schema at good will';
  }

  return `${WORKTYPE_LABEL[workType]} ${rate > 0 ? 'added' : 'subtracted'} ${additionalInfo}`;
};

const TimeTrackerCalendar = (props) => {
  const { editable = true, hideFlexTime, type, user } = props;
  const { timeTrackerUser } = user;

  const defaultView = type || VIEW_TYPE.INTERVALS_WEEK;

  const { hasPermission } = useContext(userContext);
  const { onIntervalCancel, onIntervalUpdate, onIntervalSelect, toggleCallback } = useContext(timeTrackerContext);
  const calendarRef = useRef();
  const calendarApi = calendarRef?.current?.getApi && calendarRef.current.getApi();
  const intervals = useRequest(TimeTrackerModel.getIntervals, { manual: true, throwOnError: true });
  const workdays = useRequest(TimeTrackerModel.getWorkdays, { manual: true, throwOnError: true });
  const [selectedDates, selectDates] = useState(null);

  const fillDay = useRequest(
    (date, schema) =>
      TimeTrackerModel.changeInterval({
        userId: timeTrackerUser.userId,
        beginTimestamp: moment(date).add(8, 'hours').utc().format(),
        endTimestamp: moment(date)
          .clone()
          .add(8 * 60 + TimeTrackerModel.getSchemaMinutes({ ...timeTrackerUser, user }, true, schema), 'minutes')
          .utc()
          .format(),
        workType: WORKTYPE.REGULAR,
        rate: 100
      }),
    {
      onSuccess: () => onIntervalUpdate(),
      manual: true,
      throwOnError: true
    }
  );

  const handleFillDay = (day, schema) => () => fillDay.run(day, schema);

  const toggleCalendarEventEditable = (event) => () => {
    if (!event) {
      return;
    }

    onIntervalSelect(event?.extendedProps?.interval);
  };

  const selectDateToAddNewInterval = (date) => () => {
    if (!calendarApi) {
      return;
    }

    let latestEvent = null;
    const startOfDay = moment(date).startOf('day');
    const endOfDay = moment(date).endOf('day');

    for (const event of calendarApi.getEvents()) {
      if (moment(event.start).isBetween(startOfDay, endOfDay)) {
        if (!latestEvent || new Date(event.end).valueOf() > new Date(latestEvent.end).valueOf()) {
          latestEvent = event;
        }
      }
    }

    onIntervalSelect({ beginTimestamp: latestEvent?.extendedProps?.interval?.endTimestamp || startOfDay.format() });
  };

  const getIntervals = async (rangeData, onSuccess, onFail) => {
    const { startStr: beginTimestamp, endStr: endTimestamp } = rangeData;

    onIntervalCancel(null);

    try {
      const result = await intervals.run({
        userId: timeTrackerUser.userId,
        beginTimestamp,
        endTimestamp,
        useExactEndStamp: true
      });
      const events = result.map((interval) => ({
        title: getIntervalTitle(interval, user, hideFlexTime),
        start: interval.beginTimestamp,
        end: interval.endTimestamp,
        extendedProps: { interval },
        classNames: interval.workType === WORKTYPE.REGULAR ? ['fc-event-hide-event-dot'] : []
      }));
      const scheduledWorkdays = (
        await workdays.run({
          userId: timeTrackerUser.userId,
          beginTimestamp,
          endTimestamp,
          useExactEndStamp: true
        })
      ).map((workDay) => ({
        classNames: 'fc-event-hide-event',
        start: workDay.date,
        extendedProps: { workDay, country: user.country }
      }));

      onSuccess([...events, ...scheduledWorkdays]);

      // Rerender list date format to keep buttons up to date. It won't refetch data
      calendarApi?.changeView?.(calendarApi.view.type, calendarApi.view.currentStart);
    } catch (e) {
      showError(e?.message, { messagePrefix: 'Time tracker error: ' });
      onFail(e);
    }
  };

  const getWorkdays = async (rangeData, onSuccess, onFail) => {
    const { startStr: beginTimestamp, endStr: endTimestamp } = rangeData;
    const userCreated = moment(timeTrackerUser.createdAt);

    try {
      const result = await workdays.run({
        userId: timeTrackerUser.userId,
        beginTimestamp,
        endTimestamp,
        useExactEndStamp: true
      });
      const events = result
        .filter(
          (date) => (userCreated.isBefore(date.date) || date.id) && (date.isRegularDay || date.schema || date.code)
        )
        .map((workday) => ({
          start: workday.date,
          title: `Working ${workday.schema}%${getTitleByPartDayCode(workday.partDayCode, user.country)}`,
          className:
            workday.partDayCode || timeTrackerUser.schema !== workday.schema ? ['fc-event-overrode-schema'] : [],
          display: 'background',
          extendedProps: { workday, country: user.country }
        }));

      onSuccess(events);
    } catch (e) {
      showError(e?.message, { messagePrefix: 'Time tracker error: ' });
      onFail(e);
    }
  };

  const handleIntervalEventRender = (data) => {
    const approved = data.event.extendedProps?.interval?.approved;
    const revision = data.event.extendedProps?.interval?.revision;
    const isResolvedOvernight = data.event.extendedProps?.interval?.payload?.automaticallyResolvedOvernight;
    const showEdit =
      editable && (hasPermission(PERMISSION.TIMETRACKER_ADMIN) || TimeTrackerModel.canEditByDate(data.event.startStr));

    return (
      <Space className={showEdit ? 'ant-space-last-aligned-right' : ''}>
        {data.event.title}
        {approved !== null && <Tag color={approved ? 'success' : 'error'}>{approved ? 'Approved' : 'Rejected'}</Tag>}
        {isResolvedOvernight && (
          <Tag color="error" icon={<ExclamationCircleOutlined />}>
            Automatically resolved overnight
          </Tag>
        )}
        {revision > 1 && (
          <Tag color="processing" icon={<EditOutlined />}>
            Edited
          </Tag>
        )}
        {showEdit && (
          <Button
            type="primary"
            ghost
            icon={<EditOutlined />}
            onClick={toggleCalendarEventEditable(data.event)}
            size="small"
          >
            Edit
          </Button>
        )}
      </Space>
    );
  };

  const handleWorkdayEventRender = (data) => {
    const { isFuture, isToday } = data;
    const { code, compensationTime, isRegularDay, expectedMinutes, ratedMinutes, schema } =
      data.event?.extendedProps?.workday || {};
    const country = data.event?.extendedProps?.country;

    // Yesterday can have all isPast, isToday and isPast set as false
    const noRatedMinutes =
      !isFuture &&
      !isToday &&
      isRegularDay &&
      expectedMinutes &&
      !compensationTime &&
      ((schema && ratedMinutes === 0) || -ratedMinutes === expectedMinutes);

    if (code || noRatedMinutes) {
      return (
        <>
          <span>{data.event.title}</span>
          {Boolean(code) && (
            <Tooltip title={(country === 'NO' ? DAY_ABSENCE_LABEL_NO : DAY_ABSENCE_LABEL)[code]}>
              <div className="fc-bg-event-tooltip">
                <MessageOutlined />
              </div>
            </Tooltip>
          )}
          {Boolean(noRatedMinutes) && (
            <Tooltip
              title={
                country === 'NO'
                  ? 'Ingen tidsstempler for en planlagt dag. Vil være FRAVÆR'
                  : 'Inga tidstämplingar för en schemalagd dag. Kommer att bli FRÅNVARO'
              }
            >
              <div className="fc-bg-event-tooltip fc-bg-event-tooltip-left">
                <AlertOutlined />
              </div>
            </Tooltip>
          )}
        </>
      );
    }

    return <span>{data.event.title}</span>;
  };

  const handleEventRender = (data) => {
    if ([VIEW_TYPE.INTERVALS_WEEK, VIEW_TYPE.INTERVALS_MONTH].includes(data.view.type)) {
      return handleIntervalEventRender(data);
    }

    if (data.view.type === VIEW_TYPE.SCHEMAS) {
      return handleWorkdayEventRender(data);
    }
  };

  const unselectDates = () => {
    calendarApi.unselect();
    selectDates(null);
  };

  const handleSchemaUpdate = () => {
    unselectDates();
    calendarApi.refetchEvents();
  };

  const handleRefetchEvents = usePersistFn(() => {
    if (!calendarApi) {
      return;
    }

    calendarApi.refetchEvents();
  });

  useMount(() => {
    toggleCallback(
      TIMETRACKER_EVENT.ON_INTERVAL_UPDATE,
      `refetchCalendarIntervals${type ? `:${type}` : ''}`,
      handleRefetchEvents
    );
  });

  useUnmount(() => {
    toggleCallback(
      TIMETRACKER_EVENT.ON_INTERVAL_UPDATE,
      `refetchCalendarIntervals${type ? `:${type}` : ''}`,
      handleRefetchEvents
    );
  });

  // Remove me when https://github.com/fullcalendar/fullcalendar/issues/5628 is closed
  const handleViewDidMount = ({ view, el }) => {
    if (view.type !== VIEW_TYPE.INTERVALS_WEEK) {
      return;
    }

    el.addEventListener(
      'mousedown',
      (e) => {
        e.stopPropagation();
      },
      true
    );
  };

  const rightToolbarButtons = type === VIEW_TYPE.SCHEMAS ? '' : 'listWeek listMonth';

  const MemoizedCalendar = useCreation(
    () => (
      <FullCalendar
        ref={calendarRef}
        plugins={[dayGridPlugin, listPlugin, interactionPlugin]}
        initialView={defaultView}
        weekNumbers
        firstDay="1"
        contentHeight="auto"
        customButtons={{
          refreshButton: {
            text: <ReloadOutlined />,
            click: handleRefetchEvents
          }
        }}
        headerToolbar={{
          left: 'prev next refreshButton',
          center: 'title',
          right: rightToolbarButtons
        }}
        eventSources={
          calendarApi && (!type || [VIEW_TYPE.INTERVALS_WEEK, VIEW_TYPE.INTERVALS_MONTH].includes(type))
            ? [getIntervals]
            : [getWorkdays]
        }
        eventTimeFormat={{
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          meridiem: false,
          hour12: false
        }}
        listDayFormat={(data) => {
          const { date } = data;

          const now = moment().format('YYYY-MM-DD');
          const dateKey = moment(date.array).format('YYYY-MM-DD');
          const datesWithIntervals = {};
          const schemaByDate = {};
          let extraData = '';

          for (const interval of calendarApi.getEvents()) {
            const key = moment(interval.start).format('YYYY-MM-DD');
            const { flexTime, ratedMinutes } = interval.extendedProps?.workDay || {};

            if (key === dateKey && flexTime && !(now === dateKey && ratedMinutes === 0) && !hideFlexTime) {
              if (user.country === 'SE') {
                extraData = ` | Flex time change: ${(flexTime < 0 ? '' : '+') + flexTime} minutes`;
              } else {
                extraData = ` | Time deficit ${(flexTime < 0 ? 'increased: ' : 'decreased: +') + flexTime} minutes`;
              }
            }

            if (interval.allDay) {
              schemaByDate[key] = interval.extendedProps?.workDay?.schema;
              continue;
            }

            datesWithIntervals[key] = true;
          }

          const isEmptyDay = Boolean(!datesWithIntervals[dateKey] && schemaByDate[dateKey]);

          return (
            <Space size="middle">
              <span>
                {moment(date.array).format('YYYY-MM-DD, dddd, [W]W')}
                {extraData}
              </span>
              {editable && (
                <Space>
                  <Button type="primary" ghost size="small" onClick={selectDateToAddNewInterval(date)}>
                    Add new interval
                  </Button>
                  {isEmptyDay && (
                    <Button type="primary" ghost size="small" onClick={handleFillDay(date, schemaByDate[dateKey])}>
                      Fill based on day&apos;s schema
                    </Button>
                  )}
                </Space>
              )}
            </Space>
          );
        }}
        listDaySideFormat={false}
        titleFormat={{ year: 'numeric', month: 'long', day: '2-digit', week: 'numeric' }}
        eventContent={handleEventRender}
        fixedWeekCount={false}
        selectable={editable}
        select={selectDates}
        selectAllow={({ start, end }) =>
          moment(start).isBetween(calendarApi.view.currentStart, calendarApi.view.currentEnd, undefined, '[]') &&
          moment(end).isBetween(calendarApi.view.currentStart, calendarApi.view.currentEnd, undefined, '[]')
        }
        unselectAuto={false}
        viewDidMount={handleViewDidMount}
        views={{
          listMonth: { buttonText: 'Month' },
          listWeek: { buttonText: 'Week' }
        }}
      />
    ),
    [calendarApi]
  );

  return (
    <Spin
      className="ant-spin-no-max-height"
      wrapperClassName="ant-drawer-absolute"
      spinning={intervals.loading || workdays.loading}
    >
      {MemoizedCalendar}
      {editable && type === VIEW_TYPE.SCHEMAS && (
        <Drawer
          getContainer={false}
          width={300}
          placement="right"
          open={editable && selectedDates}
          title={`Change schema${selectedDates?.length > 1 ? 's' : ''}`}
          onClose={unselectDates}
        >
          <TimeTrackerSchemaEditForm
            workdays={workdays.data}
            selectedDates={selectedDates}
            user={user}
            onUpdate={handleSchemaUpdate}
          />
        </Drawer>
      )}
    </Spin>
  );
};

export { TimeTrackerCalendar, VIEW_TYPE };
