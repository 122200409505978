import { FrownOutlined, InfoCircleFilled, LoadingOutlined, WarningFilled } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { Alert, Collapse, Space, Table } from 'antd';
import _ from 'lodash';
import moment from 'moment';

import { DateInline } from '@/components/DateInline';
import { LinkButton } from '@/components/LinkButton';
import { ROUTE } from '@/configs/general';
import { LogisticsModel } from '@/models/logistics';

import styles from '../styles.module.scss';

const cols = [
  {
    title: 'Activity',
    dataIndex: 'activity',
    key: 'activity',
    render: (__, row) => <LinkButton to={`${ROUTE.LOGISTICS_ACTIVITY}/${row.guid}`}>{row.activity || 'N/A'}</LinkButton>
  },
  {
    title: 'Serial',
    dataIndex: 'serial',
    key: 'serial'
  },
  {
    title: 'Imei',
    dataIndex: 'imei',
    key: 'imei'
  },
  {
    title: 'Created at',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (__, row) => <DateInline value={row.createdAt} />
  }
];

const SimilarCases = ({ guid }) => {
  const getSimilarCases = useRequest(() => LogisticsModel.getSimilarCases(guid), {
    formatResult: (data) => {
      const last3m = moment().subtract(3, 'months');
      const last30d = moment().subtract(30, 'days');

      for (const row of data) {
        const parsedDate = moment(row.createdAt);
        let level = 1;

        // Level 4 is not doable right now
        if (parsedDate.isAfter(last30d)) {
          level = 3;
        } else if (parsedDate.isAfter(last3m)) {
          level = 2;
        }
        row.level = level;
      }

      return _.sortBy(data, (x) => -moment(x.createdAt).valueOf());
    },
    throwOnError: false,
    refreshDeps: [guid]
  });

  if (getSimilarCases.loading) {
    return <Alert message="Looking for similar cases" type="info" icon={<LoadingOutlined />} showIcon />;
  }

  if (getSimilarCases.error) {
    return (
      <Alert
        message="An error while looking for similar cases"
        type="info"
        icon={<FrownOutlined style={{ color: '#ff4d4f' }} />}
        showIcon
      />
    );
  }

  if (!getSimilarCases.data.length) {
    return <Alert message="No similar cases found" type="success" showIcon />;
  }

  const cases = getSimilarCases.data;
  const casesTableNode = <Table rowKey="guid" columns={cols} dataSource={cases} pagination={false} size="small" />;
  const level = cases[0].level;
  let message = 'Another case with the same serial number / imei was ever repaired by us earlier than 6 months ago';

  if (level === 2) {
    message = (
      <Space>
        <WarningFilled style={{ color: '#ff4d4f' }} />
        <span>
          Another case with the same serial number / imei was ever repaired by us earlier than <b>3 months ago</b>
        </span>
      </Space>
    );
  } else if (level === 3) {
    message = (
      <Space>
        <InfoCircleFilled style={{ color: '#52c41a' }} />
        <span>
          Another case with the same serial number / imei was ever repaired by us earlier than <b>30 days ago</b>
        </span>
      </Space>
    );
  }

  return (
    <Collapse
      className={styles[`collapseLevel${level}`]}
      items={[
        {
          key: 'data',
          label: message,
          children: casesTableNode
        }
      ]}
    />
  );
};

export { SimilarCases };
