import { ProCard, StatisticCard } from '@ant-design/pro-components';
import * as _ from 'lodash';

import { statisticString } from '@/helpers/ui';

const { Statistic } = StatisticCard;

const DetailsInfo = ({ compact = false, data }) => (
  <StatisticCard className="value-over-title" size="small">
    {data.organizationName && (
      <Statistic
        title="Organization"
        value={`${data.organizationName}${
          !_.isNull(data.organizationNumber) &&
          data.organizationName !== 'null' &&
          // eslint-disable-next-line eqeqeq
          data.organizationNumber != 0
            ? ` ${data.organizationNumber}`
            : ''
        }`}
      />
    )}
    {data.firstName && <Statistic title="Name" value={`${data.firstName} ${data.lastName}`} />}
    <Statistic title="Att" value={statisticString(data.addName)} />
    <Statistic title="Email" value={statisticString(data.email)} />
    <Statistic title="Phone" value={statisticString(data.phone)} />
    <Statistic title="Mobile" value={statisticString(data.mobile)} />
    <Statistic
      title="Address"
      value={`${data.address || '–'} ${data.postalCode || '–'} ${data.city || '–'} ${data.countryCode || '–'}`}
    />
    {!compact && (
      <>
        <Statistic title="Country" value={statisticString(data.countryCode)} />
        <Statistic title="Entrance" value={statisticString(data.entrance)} />
        <Statistic title="Floor" value={statisticString(data.floor)} />
      </>
    )}
  </StatisticCard>
);

const ContactDetails = ({ caseData }) => (
  <ProCard size="small" wrap>
    <ProCard gutter={[12, 0]} size="small" className="no-outer-padding">
      <ProCard title="Customer" bordered headerBordered size="small" colSpan={12} type="inner">
        <DetailsInfo data={caseData.customer} />
      </ProCard>
      <ProCard title="Consumer" bordered headerBordered size="small" colSpan={12} type="inner">
        <DetailsInfo data={caseData.consumer} />
      </ProCard>
    </ProCard>
    <ProCard gutter={[12, 0]} size="small" className="no-outer-padding" style={{ paddingTop: 12 }}>
      <ProCard title="Sender" bordered headerBordered size="small" colSpan={12} type="inner">
        <DetailsInfo data={caseData.sender} />
      </ProCard>
      <ProCard title="Receiver" bordered headerBordered size="small" colSpan={12} type="inner">
        <DetailsInfo data={caseData.receiver} />
      </ProCard>
    </ProCard>
  </ProCard>
);

export { ContactDetails, DetailsInfo };
