import { Drawer } from 'antd';

import { ENTITY, PROPERTY_TYPE } from '@/configs/entity';
import { SchemaForm } from '@/containers/schemaForm/SchemaForm';

import { STATUS, STATUS_LABEL } from './config';

const ContainerEditor = (props) => {
  const { id, visible, onClose, onSuccess = undefined } = props;

  return (
    <Drawer
      title={id ? 'Edit container' : 'Add new container'}
      open={visible}
      onClose={onClose}
      width={720}
      destroyOnClose
    >
      <SchemaForm
        entity={ENTITY.CONTAINER}
        id={id || null}
        onSuccess={onSuccess || onClose}
        withRelations={false}
        predefinedObjects={{
          status: {
            type: PROPERTY_TYPE.ENUM,
            values: [
              { value: STATUS.IN_STOCK, label: STATUS_LABEL[STATUS.IN_STOCK] },
              { value: STATUS.AWAY, label: STATUS_LABEL[STATUS.AWAY] }
            ],
            required: false
          }
        }}
      />
    </Drawer>
  );
};

export { ContainerEditor };
