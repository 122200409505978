import { get } from 'lodash';

import { SHIPMENT_ACTIVITY_STATUS } from '@/configs/shipment';

import { RequestModel } from './request';

const ShipmentModel = {
  getProviders: async (queryParams) =>
    await RequestModel.getJsonData('/api/v1/shipment/providers', { query: queryParams }),

  getShipment: async (guid) => await RequestModel.getJsonData(`/api/v1/shipment/${guid}`),

  addItem: async (guid, { activityNumber }) =>
    await RequestModel.postJsonData(`/api/v1/shipment/${guid}/add-item`, { body: { activityNumber } }),

  deleteItem: async (guid, itemGuid) =>
    await RequestModel.postJsonData(`/api/v1/shipment/${guid}/delete-item`, { body: { guid: itemGuid } }),

  getLabelUrl: (guid) => `https://senderella.io/v1/shipment/${guid}/label`,

  itemIsProcessed: (item) => item.customData && ['Arrived', 'Missing'].includes(item.customData.status),

  filterItemsByStatus: (itemsList, tab) => {
    if (tab === SHIPMENT_ACTIVITY_STATUS.ALL) {
      return itemsList;
    }

    return itemsList.filter(
      (item) =>
        (tab === SHIPMENT_ACTIVITY_STATUS.UNPROCESSED && !ShipmentModel.itemIsProcessed(item)) ||
        (tab === SHIPMENT_ACTIVITY_STATUS.PROCESSED && ShipmentModel.itemIsProcessed(item))
    );
  },

  getItemsByType(packages, itemsTab) {
    return packages
      .map((pack) => {
        const data = ShipmentModel.filterItemsByStatus(pack.items, itemsTab);

        if (!data.length) {
          return null;
        }

        return {
          title: pack.type,
          data
        };
      })
      .filter(Boolean);
  },

  canCompleteShipment(shipment) {
    if (shipment.finalized) {
      return false;
    }

    for (const pack of shipment.packages) {
      for (const item of pack.items) {
        if (!ShipmentModel.itemIsProcessed(item)) {
          return false;
        }
      }
    }

    return true;
  },

  matchItem(item, val) {
    const activity = String(get(item, 'customData.activityNumber'));

    return item.guid === val || activity === val;
  },

  findItem(shipment, val) {
    for (const pack of shipment.packages) {
      const item = pack.items.find((i) => ShipmentModel.matchItem(i, val));

      if (item) {
        return item;
      }
    }

    return null;
  },

  finalizeShipment: async (shipmentGuid, data = {}) =>
    await RequestModel.postJsonData(`/api/v1/shipment/${shipmentGuid}/finalize`, { body: data }),

  cancelShipment: async (shipmentGuid, data = {}) =>
    await RequestModel.postJsonData(`/api/v1/shipment/${shipmentGuid}/cancel`, { body: data }),

  setStatus: async (guid, { itemId, status, options }) =>
    await RequestModel.postJsonData(`/api/v1/shipment/${guid}/set-item-status`, { body: { itemId, status, options } }),

  sendPrealert: async (guid, onUserEmail) =>
    await RequestModel.postJson(`/api/v1/shipment/${guid}/send-prealert`, { body: { onUserEmail } }),

  changeCaseCreatedStatus: async (shipmentGuid, itemGuid, status) =>
    await RequestModel.postJsonData(`/api/v1/shipment/change-case-created-status`, {
      body: { shipmentGuid, itemGuid, status }
    }),

  hubToSenderellaContacts: (hubContacts) => ({
    name: hubContacts.organizationName || hubContacts.name || `${hubContacts.firstName} ${hubContacts.lastName}`,
    address: hubContacts.address,
    postalCode: hubContacts.postalCode,
    city: hubContacts.city,
    phoneNumber: hubContacts.phone || hubContacts.mobile,
    mobilePhoneNumber: hubContacts.mobile,
    email: hubContacts.email,
    countryCode: hubContacts.countryCode,
    attention: hubContacts.addName
  }),

  addressToString: (data) => `${data.name}, ${data.address} ${data.postalCode} ${data.city} ${data.countryCode}`
};

export { ShipmentModel };
