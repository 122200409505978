/* eslint-disable react/no-array-index-key */
import { DeleteOutlined, InboxOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { Button, List, Select, Space, Tag, Upload } from 'antd';
import { useState } from 'react';

import { onError } from '@/helpers/Alert';
import { LogisticsModel } from '@/models/logistics';

const FILE_TYPES = [
  { value: 'costProposal', label: 'Cost Proposal' },
  { value: 'proofOfPurchase', label: 'Proof Of Purchase' },
  { value: 'proofOfDamage', label: 'Proof Of Damage' },
  { value: 'transportDamage', label: 'Transport Damage' },
  { value: 'claim', label: 'Claim' },
  { value: 'report', label: 'Report' },
  { value: 'shipmentLabel', label: 'Shipment Label' },
  { value: 'other', label: 'Other' }
];

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

const FilesView = ({ caseData, onReload }) => {
  const [fileType, setFileType] = useState('other');
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);

  // TODO: This method is very slow and this is a """temporary""" solution. Send files directly to amazon/azure storage instead
  const sendFiles = useRequest((data) => LogisticsModel.attachFiles(caseData.guid, data), {
    manual: true,
    onError
  });

  const handleUpload = async (e) => {
    e.stopPropagation();
    setUploading(true);
    const toSend = [];

    try {
      for (const file of fileList) {
        if (file.url) {
          continue;
        }
        const base64 = await toBase64(file.file);
        toSend.push({ name: file.file.name, data: base64.split(',')[1], type: file.type });
      }
      await sendFiles.run(toSend);
      onReload();
    } catch (err) {
      console.log(err);
      onError(err);
    }

    setFileList([]);
    setUploading(false);
  };

  const uploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      const item = { file, type: fileType };
      setFileList([...fileList, item]);

      return false;
    },
    showUploadList: {
      showRemoveIcon: false
    },
    fileList,
    itemRender: (el, item, list, actions) => (
      <div>
        {item.file.name} <Tag>{item.type}</Tag>
        <Button type="link" onClick={actions.remove} icon={<DeleteOutlined />} />
      </div>
    )
  };

  return (
    <Space align="center" direction="vertical" style={{ gap: 0, marginTop: 20, marginBottom: 20 }}>
      <List>
        {(caseData.orderData.files || []).map((x, i) => (
          <List.Item key={i}>
            <a href={x.url} target="_blank" rel="noreferrer">
              {x.name} {x.type && <Tag>{x.type}</Tag>}
            </a>
          </List.Item>
        ))}
      </List>
      <br />
      <Upload.Dragger name="file" multiple {...uploadProps}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">Click or drag file to this area to upload</p>
        <p className="ant-upload-hint">
          Support for a single or bulk upload. Strictly prohibited from uploading company data or other banned files.
        </p>
        <Select
          options={FILE_TYPES}
          value={fileType}
          onChange={setFileType}
          onClick={(e) => e.stopPropagation()}
          style={{ width: '50%' }}
        />
        <br />
        <Button
          type="primary"
          onClick={handleUpload}
          disabled={fileList.length === 0}
          loading={uploading}
          style={{ marginTop: 16 }}
        >
          {uploading ? 'Uploading' : 'Upload all'}
        </Button>
      </Upload.Dragger>
    </Space>
  );
};

export { FilesView };
