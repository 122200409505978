import { PageHeader } from '@ant-design/pro-layout';
import { useContext } from 'react';

import { COMMON_LOGISTIC_PERMISSIONS, COMMON_TIMETRACKER_PERMISSIONS } from '@/configs/general';
import { userContext } from '@/helpers/userContext';

import { LogisticSettings } from './LogisticSettings';
import { TimeTrackerStatistics } from './TimeTrackerStatistics';

const MyPage = () => {
  const { user, hasPermission } = useContext(userContext);

  if (!user) {
    return null;
  }

  const name = `${user.firstName} ${user.lastName}`;

  return (
    <>
      <PageHeader title={`Hello, ${name}!`} />
      {hasPermission(COMMON_TIMETRACKER_PERMISSIONS) && <TimeTrackerStatistics />}
      {hasPermission(COMMON_LOGISTIC_PERMISSIONS) && <LogisticSettings />}
    </>
  );
};

export { MyPage };
