import { Alert, Button, InputNumber, Space } from 'antd';
import { useContext, useState } from 'react';

import { PRINTER_TYPE } from '@/configs/entity';
import { userContext } from '@/helpers/userContext';

import { ACTION } from '../configs';
import { AccessoriesDeviationInformCheckbox } from './AccessoriesDeviationInformCheckbox';

const ArriveForm = ({ caseData, serviceProvider, onFinish }) => {
  const { user } = useContext(userContext);
  const [labelsCount, setLabelsCount] = useState(
    (user.printers || []).some(({ settings }) => settings?.type === PRINTER_TYPE.SMALL_LABEL) ? 1 : 0
  );

  const handleAssign = (alsoAssign) => () => {
    onFinish(ACTION.ARRIVE, {
      alsoAssign,
      labelsCount
    });
  };

  return (
    <Space direction="vertical" size="large">
      {serviceProvider.id !== caseData.serviceProvider.id && (
        <Alert type="warning" showIcon message={`This case will be assigned to ${serviceProvider.name}`} />
      )}
      <Space>
        <label>Labels count:</label>
        <InputNumber min={0} max={100} value={labelsCount} onChange={setLabelsCount} />
      </Space>
      <AccessoriesDeviationInformCheckbox />
      <Space>
        <Button type="primary" onClick={handleAssign(false)}>
          Arrive
        </Button>
        <Button type="primary" onClick={handleAssign(true)}>
          Arrive and assign
        </Button>
      </Space>
    </Space>
  );
};

export { ArriveForm };
