import { PageHeader } from '@ant-design/pro-layout';

import { SHIPMENTS_TYPE } from '@/configs/shipment';
import { ShipmentsTable } from '@/containers/shipmentsTable/ShipmentsTable';

const ForwardedShipmentsPage = () => {
  return (
    <>
      <PageHeader title="Forwarded shipments" />
      <ShipmentsTable type={SHIPMENTS_TYPE.FORWARDED} />
    </>
  );
};

export { ForwardedShipmentsPage };
