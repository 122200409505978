// prettier-ignore
import { CheckCircleTwoTone, DeleteOutlined, ExportOutlined, QuestionCircleTwoTone, SearchOutlined, TagOutlined, UnorderedListOutlined, WarningFilled } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import {
  Alert,
  Button,
  Checkbox,
  Col,
  DatePicker,
  Flex,
  Form,
  Input,
  Modal,
  Popover,
  Row,
  Select,
  Space,
  Table
} from 'antd';
import _ from 'lodash';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { AutoSearchInput } from '@/components/AutoSearchInput';
import { DateInline } from '@/components/DateInline';
import { LinkButton } from '@/components/LinkButton';
import { PrintButton } from '@/components/PrintButton';
import { SelectAutocomplete } from '@/components/SelectAutocomplete';
import { PRINTER_TYPE } from '@/configs/entity';
import { ROUTE } from '@/configs/general';
import { SHIPMENT_LABEL_TYPE, SHIPMENTS_TYPE } from '@/configs/shipment';
import { onError, showError } from '@/helpers/Alert';
import { defer } from '@/helpers/defer';
import { userContext } from '@/helpers/userContext';
import { RequestModel } from '@/models/request';
import { ShipmentModel } from '@/models/shipment';

import { ShipmentPreview } from './ShipmentPreview';

const DEFAULT_PAGE_SIZE = 50;

const getMappedProviders = (providers = [], mapProviders = true) => [
  { label: 'Any providers', value: '' },
  ...(mapProviders ? providers.map((x) => ({ label: x.name, value: x.id })) : providers)
];

const MY_SHIPMENTS = 'my';
const ALL_SHIPMENTS = 'all';
const createdByOptions = [
  { value: MY_SHIPMENTS, label: 'My shipments' },
  { value: ALL_SHIPMENTS, label: 'All shipments' }
];

const ShipmentsTable = (props) => {
  const { customProviders, hideHeader, type, showOnlyMyShipments = false } = props;
  const { user } = useContext(userContext);

  const [searchValue, setSearchValue] = useState('');
  const [dateRange, setDateRange] = useState([null, null]);
  const [providerId, setProviderId] = useState('');
  const [currentPageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [mappedProviders, setMappedProviders] = useState(getMappedProviders(customProviders, false));
  const [failedOnly, setFailedOnly] = useState(false);
  const [createdByFilter, setCreatedByFilter] = useState(showOnlyMyShipments ? MY_SHIPMENTS : ALL_SHIPMENTS);
  const customProviderIdToName = customProviders ? _.keyBy(customProviders, 'value') : {};

  const shipmentsRequest = useRequest(
    ({ page, pageSize }) => {
      const query = { offset: page * pageSize, limit: pageSize, search: searchValue, providerId };
      query.failedOnly = failedOnly;

      if (dateRange && dateRange[0]) {
        query.startDate = moment(dateRange[0]).startOf('day').format();
      }

      if (dateRange && dateRange[1]) {
        query.endDate = moment(dateRange[1]).endOf('day').format();
      }

      if (createdByFilter === MY_SHIPMENTS) {
        query.user = user.email;
      }

      return RequestModel.getJson(`/api/v1/shipment/${type}`, {
        query
      });
    },
    {
      manual: true,
      initialData: { data: [], pagination: { offset: 0, count: 0 } },
      onError
    }
  );

  const providersRequest = useRequest(ShipmentModel.getProviders, {
    manual: customProviders,
    onSuccess: (result) => {
      setMappedProviders(getMappedProviders(result));
    },
    onError
  });

  const search = (page = 1, pageSize = currentPageSize) => {
    if (pageSize !== currentPageSize) {
      setPageSize(pageSize);
    }

    if (!props.customData) {
      shipmentsRequest.run({ page: page - 1, pageSize });
    }
  };

  const handleSearch = () => search();

  useEffect(search, [dateRange, providerId, failedOnly, createdByFilter]);

  const handleSearchChanged = (value) => setSearchValue(value);

  const handleDateRangeChanged = (dates) => {
    setDateRange(dates);
  };

  const handleFailedOnlyChange = (e) => setFailedOnly(e.target.checked);

  const finalizeShipment = (shipment) => async () => {
    let packageNumber;

    if (shipment.shipmentData.pending) {
      packageNumber = defer();

      const onFinish = (values) => {
        packageNumber.resolve(values.packageNumber);
      };

      Modal.confirm({
        title: 'Add package number',
        content: (
          <Form onFinish={onFinish} scrollToFirstError>
            <Form.Item
              label="Package number"
              name="packageNumber"
              rules={[{ required: true, message: 'Please input Package number' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item>
              <Space>
                <Button type="primary" htmlType="submit">
                  Finalize
                </Button>
                <Button htmlType="button" onClick={Modal.destroyAll}>
                  Cancel
                </Button>
              </Space>
            </Form.Item>
          </Form>
        ),
        centered: true,
        maskClosable: false,
        footer: null
      });

      packageNumber = await packageNumber;

      Modal.destroyAll();
    }

    try {
      await ShipmentModel.finalizeShipment(
        shipment.guid,
        packageNumber
          ? {
              packages: [{ packageId: 1, packageNumber }]
            }
          : {}
      );
      search();
    } catch (e) {
      showError(e.message);
    }
  };

  const cancelShipment = (shipment) => async () => {
    let validate = defer();

    Modal.confirm({
      title: 'Cancel shipment',
      content: (
        <Flex vertical>
          <p>Do you want to cancel the shipment? It can&apos;t be undone.</p>
          <Space>
            <Button type="primary" danger onClick={() => validate.resolve(true)}>
              Cancel the shipment
            </Button>
            <Button onClick={Modal.destroyAll}>Leave it be</Button>
          </Space>
        </Flex>
      ),
      centered: true,
      maskClosable: false,
      footer: null
    });

    validate = await validate;

    Modal.destroyAll();

    try {
      await ShipmentModel.cancelShipment(shipment.guid);
      search();
    } catch (e) {
      showError(e.message);
    }
  };

  const removeItem = (shipment, item) => async () => {
    try {
      await ShipmentModel.deleteItem(shipment.guid, item.guid);
      search();
    } catch (e) {
      showError(e.message);
    }
  };

  const cols = [
    {
      title: 'Service provider',
      dataIndex: 'customData',
      key: 'customData',
      render: (customData) =>
        customProviders
          ? customProviderIdToName?.[customData?.serviceProviderId]?.label
          : customData?.serviceProviderName
    },
    type !== SHIPMENTS_TYPE.PUSHED
      ? {
          title: 'Shipment',
          dataIndex: 'shipmentType',
          key: 'shipmentType',
          render: (shipmentType) => shipmentType.name
        }
      : null,
    type === SHIPMENTS_TYPE.RETURNED
      ? {
          title: 'Container',
          dataIndex: 'container',
          key: 'container',
          width: 100,
          render: (__, row) => {
            const container = (row.customData || {}).containerId;

            if (container) {
              return <Link to={`${ROUTE.CONTAINER}/${container}`}>{container}</Link>;
            }

            return '–';
          }
        }
      : null,
    {
      title: 'Created at',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => <DateInline value={text} />
    },
    {
      title: 'Items',
      dataIndex: 'packages',
      key: 'packages',
      render: (packages) => {
        const items = packages.flatMap((x) => x.items);
        const withFailedCase = items.some(({ customData }) => customData?.caseCreated === 'fail');

        return (
          <Space>
            {withFailedCase && (
              <Popover content="This shipment contains items with errors">
                <WarningFilled style={{ color: '#f5222d' }} />
              </Popover>
            )}
            <span>{items.length}</span>
          </Space>
        );
      }
    },
    type === SHIPMENTS_TYPE.RETURNED
      ? {
          title: 'Receiver',
          dataIndex: 'receiver',
          key: 'receiver',
          render: (__, row) =>
            [
              row.receiver.name,
              row.receiver.address,
              row.receiver.postalCode,
              row.receiver.city,
              row.receiver.countryCode
            ].join(' ')
        }
      : null,
    type === SHIPMENTS_TYPE.SHOW_INFO
      ? {
          title: 'Matched by',
          dataIndex: 'matchedBy',
          key: 'matchedBy'
        }
      : null,
    [SHIPMENTS_TYPE.FORWARDED, SHIPMENTS_TYPE.PUSHED].includes(type)
      ? {
          title: 'Pre-alert',
          key: 'pre-alert',
          render: (__, row) =>
            row?.customData?.preAlertSent ? (
              <CheckCircleTwoTone twoToneColor="#52c41a" />
            ) : (
              <QuestionCircleTwoTone twoToneColor="#faad14" />
            )
        }
      : null,
    type !== SHIPMENTS_TYPE.PUSHED
      ? {
          title: 'Actions',
          key: 'actions',
          width: 150,
          render: (__, row) => (
            <Flex vertical gap="small">
              <LinkButton to={`${ROUTE.LOGISTICS_SHIPMENT}/${row.guid}`} type="default">
                <SearchOutlined />
                Details
              </LinkButton>
              {row.finalized && (
                <>
                  {row.shipmentData?.labelType !== SHIPMENT_LABEL_TYPE.NONE && (
                    <Space className="ant-space-first-item-flex">
                      <Button href={ShipmentModel.getLabelUrl(row.guid)} target="_blank" rel="noopener noreferrer">
                        <TagOutlined />
                        Label
                      </Button>
                      <PrintButton
                        pdfUrl={`https://senderella.io/v1/shipment/${row.guid}/label?format=pdf`}
                        type={PRINTER_TYPE.BIG_LABEL}
                      />
                    </Space>
                  )}
                  <Space>
                    <Button
                      href={`https://senderella.io/v1/shipment/${row.guid}/picking-list`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <UnorderedListOutlined />
                      Picking list
                    </Button>
                    <PrintButton
                      pdfUrl={`https://senderella.io/v1/shipment/${row.guid}/picking-list?format=pdf`}
                      type={PRINTER_TYPE.A4}
                    />
                  </Space>
                </>
              )}
              {!row.finalized && (
                <>
                  <Button onClick={finalizeShipment(row)} type="primary">
                    <ExportOutlined />
                    Finalize
                  </Button>
                  <Button onClick={cancelShipment(row)} danger>
                    <DeleteOutlined />
                    Cancel
                  </Button>
                </>
              )}
            </Flex>
          )
        }
      : null,
    type === SHIPMENTS_TYPE.PUSHED
      ? {
          title: 'Pushed by',
          dataIndex: 'customData',
          key: 'customData',
          render: (__, row) => row.customData?.pushedBy ?? '-'
        }
      : null
  ].filter(Boolean);

  const data = props.customData || shipmentsRequest.data;
  const useFailedOnlyCheckbox = [SHIPMENTS_TYPE.FORWARDED, SHIPMENTS_TYPE.PUSHED].includes(type);

  if (!data) {
    return <Alert type="error" message="Failed to load" />;
  }
  const paginationProps = {
    current: Math.ceil(data.pagination.offset / currentPageSize) + 1,
    pageSize: currentPageSize,
    total: data.pagination.count,
    onChange: search
  };

  return (
    <Table
      columns={cols}
      dataSource={data?.data}
      rowKey="guid"
      pagination={paginationProps}
      loading={shipmentsRequest.loading || providersRequest.loading}
      title={
        hideHeader
          ? null
          : () => (
              <Row gutter={8} align="middle">
                <Col flex="auto">
                  <AutoSearchInput
                    placeholder="Search for activity"
                    size="large"
                    value={searchValue}
                    onChange={handleSearchChanged}
                    onSearch={handleSearch}
                    onPressEnter={handleSearch}
                  />
                </Col>
                <Col span={6}>
                  <DatePicker.RangePicker
                    value={dateRange}
                    onCalendarChange={handleDateRangeChanged}
                    allowEmpty={[true, true]}
                    className="full-width"
                    size="large"
                  />
                </Col>
                <Col span={6}>
                  <SelectAutocomplete
                    options={mappedProviders}
                    value={providerId}
                    onSelect={setProviderId}
                    className="full-width"
                    size="large"
                    skipMapping
                  />
                </Col>
                <Col span={3}>
                  <Select
                    className="full-width"
                    size="large"
                    options={createdByOptions}
                    value={createdByFilter}
                    onChange={setCreatedByFilter}
                  />
                </Col>
                {useFailedOnlyCheckbox && (
                  <Col flex="150px">
                    <Checkbox checked={failedOnly} onChange={handleFailedOnlyChange}>
                      Show failed only
                    </Checkbox>
                  </Col>
                )}
              </Row>
            )
      }
      expandable={{
        expandedRowRender: (row) => (
          <ShipmentPreview
            data={row}
            onRemove={type !== SHIPMENTS_TYPE.PUSHED ? removeItem : null}
            showCaseCreatedStatus={type !== SHIPMENTS_TYPE.RETURNED}
          />
        )
      }}
    />
  );
};

export { ShipmentsTable };
