import { EditOutlined } from '@ant-design/icons';
import { PageHeader } from '@ant-design/pro-layout';
import { useRequest } from 'ahooks';
import { Button, Drawer, Flex, Input } from 'antd';
import { useRef, useState } from 'react';

import ExtendedTable from '@/components/ExtendedTable';
import { LocalSchemaForm } from '@/containers/schemaForm/LocalSchemaForm';
import { onError } from '@/helpers/Alert';
import { RequestModel } from '@/models/request';

const CaseListPage = (props) => {
  const slug = props.match.params.slug;

  const casesRequest = useRequest(
    ({ commonSearch, page, pageSize }) => {
      const query = { commonSearch, offset: page * pageSize, limit: pageSize };

      return RequestModel.getJson(`/api/v1/caseLists/cases/${slug}`, {
        query
      });
    },
    {
      initialData: { data: [], pagination: { offset: 0, count: 0 } },
      onError,
      manual: true
    }
  );

  const actionRequest = useRequest(
    (body) => {
      return RequestModel.postJson(`/api/v1/caseLists/cases/${slug}/action`, {
        body
      });
    },
    {
      onError,
      manual: true
    }
  );

  const [drawerVisible, setDrawerVisible] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [currentRow, setCurrentRow] = useState();
  const tableRef = useRef(null);
  const onDrawerClose = () => setDrawerVisible(false);

  const schema = casesRequest.data?.schema;
  const hasForm = schema && Object.keys(schema.properties).length !== 0;
  let cols = [];

  const search = () => {
    tableRef.current.reset({ commonSearch: searchValue });
  };

  const handleSearchChanged = (e) => {
    setSearchValue(String(e.target.value).trim());
  };

  const handleFormFinish = async (res) => {
    await actionRequest.run({ guid: currentRow.guid, id: currentRow.id, ...res });
    onDrawerClose();
    await search();
  };
  const loading = false;

  const showDrawerOrDoAction = (row) => async () => {
    if (hasForm) {
      setCurrentRow(row);
      setDrawerVisible(true);
    } else {
      // Form is empty, send request without extra questions
      await actionRequest.run({ guid: row.guid, id: row.id });
      await search();
    }
  };

  if (casesRequest?.data?.columns) {
    cols = [
      {
        title: 'Guid',
        key: 'guid',
        width: 100,
        render: (_, row) => (
          <a href={`https://serviceorderhub.com/admin/orders/${row.id}`} target="_blank" rel="noreferrer">
            {row.guid}
          </a>
        )
      },

      ...casesRequest.data.columns.map(({ title, key }) => ({
        title,
        key,
        dataIndex: key
      })),

      {
        title: 'Action',
        key: 'action',
        width: 100,
        render: (_, row) => (
          <Button type="primary" ghost icon={<EditOutlined />} onClick={showDrawerOrDoAction(row)}>
            {hasForm ? 'Action' : casesRequest.data.buttonName}
          </Button>
        )
      }
    ];
  }

  return (
    <>
      <PageHeader title={casesRequest?.data?.name || 'Loading custom list...'} />
      <ExtendedTable
        columns={cols}
        request={casesRequest}
        ref={tableRef}
        title={() => (
          <Flex gap="small">
            <Input.Search
              placeholder="Search for cases"
              size="large"
              enterButton
              value={searchValue}
              onChange={handleSearchChanged}
              onSearch={search}
              onPressEnter={search}
            />
          </Flex>
        )}
      />
      <Drawer title="Do action" open={drawerVisible} onClose={onDrawerClose} width={720} destroyOnClose>
        <LocalSchemaForm
          data={{}}
          schema={schema}
          loading={!schema || actionRequest.loading}
          onFinish={handleFormFinish}
          bottomContent={
            casesRequest?.data?.buttonName && (
              <Button type="primary" htmlType="submit" disabled={loading}>
                {casesRequest.data.buttonName}
              </Button>
            )
          }
        />
      </Drawer>
    </>
  );
};

export { CaseListPage };
