import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';

export const useGoTo = (url) => {
  const history = useHistory();

  return () => {
    history.push(url);
  };
};

export const useRedirectTo = (url) => {
  const history = useHistory();

  return () => {
    history.replace(url);
  };
};

export const useGoBack = (defaultUrl) => {
  const history = useHistory();
  const prevLocation = useLastLocation();

  return () => {
    if (prevLocation) {
      history.goBack();
    } else {
      history.push(defaultUrl);
    }
  };
};

export const useQuery = () => {
  const { search } = useLastLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};
