import { GlobalOutlined, MinusCircleOutlined, PlusCircleOutlined, SaveOutlined, UndoOutlined } from '@ant-design/icons';
import { useUpdateEffect } from 'ahooks';
import { Badge, Button, Collapse, Divider, Form, Input, List, Modal, Space, Tag, Tooltip, Typography } from 'antd';
import _ from 'lodash';
import { useState } from 'react';

import { defer } from '@/helpers/defer';
import { AuthToolModel } from '@/models/authTool';

import { GDPRTooltip } from './GdprTooltip';
// prettier-ignore
import { getProjectsIds, mapEntitiesChangesIntoTransactions, markAssignedEntities, processProjects, TRANSACTION_TYPE } from './helpers';

const { Paragraph } = Typography;

const PERMISSION_ENTITY = {
  PERMISSION: 'permission',
  ROLE: 'role'
};

const RolePermissionsDetails = (props) => {
  const { permissions } = props;

  if (!permissions?.length) {
    return <div>Permissions included: no permissions assigned yet</div>;
  }

  return (
    <div>
      Permissions included:{' '}
      {permissions.map(({ id, name, description, gdprImportant }) => (
        <Tooltip key={id} title={description}>
          <Tag color={gdprImportant ? 'error' : 'default'}>{name}</Tag>
        </Tooltip>
      ))}
    </div>
  );
};

const UserEntitiesListByProject = (props) => {
  const { entityKey, itemExtra, onChange } = props;
  const keywords = props.keywords || {
    assign: {
      present: 'assign',
      past: 'assigned'
    },
    unassign: {
      present: 'unassign',
      past: 'unassigned'
    }
  };
  const pluralEntityKey = `${entityKey}s`;
  const [entities, setEntities] = useState(
    markAssignedEntities(props[pluralEntityKey], props[`assigned${_.capitalize(pluralEntityKey)}`])
  );
  const [entitiesToBeChanged, setEntitiesToBeChanged] = useState([]);

  const handleEntityToggle = (id) => () => {
    const changedEntities = [...entities];
    let selectedEntity;

    for (const permission of changedEntities) {
      if (permission.id !== id) {
        continue;
      }

      selectedEntity = permission;

      break;
    }

    selectedEntity.isChanged = !selectedEntity.isChanged;

    const newEntitiesToBeChanged = selectedEntity.isChanged
      ? [selectedEntity, ...entitiesToBeChanged]
      : entitiesToBeChanged.filter((permission) => permission.id !== id);

    onChange(mapEntitiesChangesIntoTransactions(newEntitiesToBeChanged));

    setEntitiesToBeChanged(newEntitiesToBeChanged);
    setEntities(changedEntities);
  };

  return (
    <>
      {entitiesToBeChanged.length > 0 && (
        <>
          <Divider plain>Changes</Divider>
          <List
            bordered
            itemLayout="horizontal"
            size="small"
            dataSource={entitiesToBeChanged}
            renderItem={(entity) => (
              <List.Item
                className={`${entity.isAssigned ? 'un' : ''}assigned-entity-item changed-entity-item`}
                actions={[
                  <Button icon={<UndoOutlined />} type="default" onClick={handleEntityToggle(entity.id)}>
                    Revert
                  </Button>
                ]}
              >
                <List.Item.Meta
                  title={
                    <Space>
                      <span>{entity.name}</span>
                      {entity.gdprImportant && <GDPRTooltip />}
                      <Badge
                        status="processing"
                        text={`To be ${keywords[`${entity.isAssigned ? 'un' : ''}assign`].past}`}
                      />
                    </Space>
                  }
                  description={
                    <>
                      {entity.description}
                      {itemExtra?.(entity)}
                    </>
                  }
                />
              </List.Item>
            )}
          />
        </>
      )}
      {entitiesToBeChanged.length > 0 && entitiesToBeChanged.length !== entities.length && (
        <Divider plain>Unchanged</Divider>
      )}
      {entitiesToBeChanged.length !== entities.length && (
        <List
          bordered
          itemLayout="horizontal"
          dataSource={entities}
          size="small"
          renderItem={(entity) =>
            entity.isChanged ? null : (
              <List.Item
                className={`${entity.isAssigned ? '' : 'un'}assigned-entity-item`}
                actions={[
                  <Button
                    icon={entity.isAssigned ? <MinusCircleOutlined /> : <PlusCircleOutlined />}
                    type="default"
                    onClick={handleEntityToggle(entity.id)}
                  >
                    {_.capitalize(keywords[`${entity.isAssigned ? 'un' : ''}assign`].present)}
                  </Button>
                ]}
              >
                <List.Item.Meta
                  title={
                    <Space>
                      <span>{entity.name}</span>
                      {entity.gdprImportant && <GDPRTooltip />}
                      {entity.isAssigned && <Badge status="success" text={_.capitalize(keywords.assign.past)} />}
                    </Space>
                  }
                  description={
                    <>
                      {entity.description}
                      {itemExtra?.(entity)}
                    </>
                  }
                />
              </List.Item>
            )
          }
        />
      )}
    </>
  );
};

const UserPermissionsList = (props) => {
  const { onChange, projects: assignedProjects, projectsList: allProjects, syncedAt, user } = props;
  const [projects, setProjects] = useState(processProjects(allProjects, assignedProjects));
  const [unfoldedProjects, setUnfoldedProjects] = useState(getProjectsIds(assignedProjects, syncedAt));
  const [changesTransactions, setChangesTransactions] = useState({});
  const [form] = Form.useForm();

  useUpdateEffect(() => {
    setProjects(processProjects(allProjects, assignedProjects));
    setUnfoldedProjects(getProjectsIds(assignedProjects, syncedAt));
    setChangesTransactions({});
  }, [syncedAt]);

  const handleChangeTransactions = (projectId, entityKey) => (transactions) => {
    const newChangesTransactions = { ...changesTransactions };

    if (!newChangesTransactions[projectId]) {
      newChangesTransactions[projectId] = {};
    }

    if (transactions) {
      newChangesTransactions[projectId][entityKey] = transactions;
    } else {
      delete newChangesTransactions[projectId][entityKey];
    }

    if (_.isEmpty(newChangesTransactions[projectId])) {
      delete newChangesTransactions[projectId];
    }

    setChangesTransactions(newChangesTransactions);
  };

  const executeTransactions = (projectId) => async () => {
    if (!changesTransactions[projectId]) {
      return;
    }

    const [selectedProject] = allProjects.filter((project) => project.id === projectId);

    if (selectedProject.readOnly) {
      const fixitNotification = defer();

      Modal.warning({
        title: 'To remove this we have a manual fixit-routine',
        content: (
          <div>
            Please create a ticket{' '}
            <Button
              icon={<GlobalOutlined />}
              type="link"
              href="https://jira.elkjop.com/servicedesk/customer/portal/101/create/2974"
              target="_blank"
            >
              here
            </Button>
          </div>
        ),
        centered: true,
        onOk: () => {
          fixitNotification.resolve();
        }
      });

      await fixitNotification;

      onChange();

      return;
    }

    const { firstName, id, lastName } = user;
    const userName = `${firstName} ${lastName}`;
    const failedTransactions = [];

    // eslint-disable-next-line guard-for-in
    for (const permissionEntity in changesTransactions[projectId]) {
      for (const transaction of changesTransactions[projectId][permissionEntity]) {
        const executeTransactionDefer = defer();

        const { entityId, entityName, type } = transaction;
        let title;

        if (permissionEntity === PERMISSION_ENTITY.ROLE) {
          if (type === TRANSACTION_TYPE.SET) {
            title = `Do you want to assign ${userName} for '${entityName}' role?`;
          } else {
            title = `Do you want to unassign ${userName} from '${entityName}' role?`;
          }
        } else if (type === TRANSACTION_TYPE.SET) {
          title = `Do you want to grant '${entityName}' permission for ${userName}?`;
        } else {
          title = `Do you want to revoke '${entityName}' permission of ${userName}?`;
        }

        const confirmationModal = Modal.confirm({
          title,
          content:
            type === TRANSACTION_TYPE.SET ? (
              <Form form={form} layout="vertical">
                <Form.Item
                  name="comment"
                  label="Leave a comment"
                  rules={[
                    {
                      message: 'We need this information to keep track of changes',
                      required: true
                    }
                  ]}
                >
                  <Input.TextArea />
                </Form.Item>
              </Form>
            ) : null,
          centered: true,
          cancelText: 'No',
          okText: 'Yes',
          onCancel: () => {
            executeTransactionDefer.resolve(false);
          },
          onOk: () => {
            if (type === TRANSACTION_TYPE.SET) {
              return form
                .validateFields()
                .then(() => executeTransactionDefer.resolve(true))
                .then(() => new Promise(() => {}));
            }

            executeTransactionDefer.resolve(true);

            // Create an empty promise to keep the modal open
            return new Promise(() => {});
          }
        });

        const executeTransaction = await executeTransactionDefer;

        if (!executeTransaction) {
          form.resetFields();

          continue;
        }

        confirmationModal.update({
          okButtonProps: { loading: true },
          cancelButtonProps: { disabled: true }
        });

        try {
          if (permissionEntity === PERMISSION_ENTITY.ROLE) {
            if (type === TRANSACTION_TYPE.SET) {
              await AuthToolModel.setRole(id, entityId, form.getFieldValue('comment'));
            } else {
              await AuthToolModel.deleteRole(id, entityId);
            }
          } else if (type === TRANSACTION_TYPE.SET) {
            await AuthToolModel.setPermission(id, entityId, form.getFieldValue('comment'));
          } else {
            await AuthToolModel.deletePermission(id, entityId);
          }
        } catch (e) {
          failedTransactions.push({ ...transaction, entityType: permissionEntity });
        }

        form.resetFields();
        confirmationModal.destroy();
      }
    }

    const failedTransactionNotifications = defer();

    if (failedTransactions.length) {
      Modal.error({
        title: `Those changes haven't been processed due to errors`,
        content: (
          <Paragraph>
            <ul>
              {failedTransactions.map((transaction) => {
                const { entityId, entityName, entityType, type } = transaction;

                let action;

                if (entityType === PERMISSION_ENTITY.ROLE) {
                  if (type === TRANSACTION_TYPE.SET) {
                    action = 'Assign';
                  } else {
                    action = 'Unassign';
                  }
                } else if (type === TRANSACTION_TYPE.SET) {
                  action = 'Grant';
                } else {
                  action = 'Revoke';
                }

                return <li key={entityId}>{`${action} '${entityName}' ${entityType}`}</li>;
              })}
            </ul>
          </Paragraph>
        ),
        onOk: () => {
          failedTransactionNotifications.resolve();
        }
      });
    } else {
      failedTransactionNotifications.resolve();
    }

    onChange();
  };

  const projectItems = projects
    .map((project) => {
      if (!project.permissions && !project.roles) {
        return null;
      }

      const item = {
        id: `${project.id}_${syncedAt}`,
        key: `${project.id}_${syncedAt}`,
        label: (
          <Space>
            <span>{project.name}</span>
            {project.isAssigned && <Badge status="success" text="Has access to this project" />}
          </Space>
        ),
        className: `${project.isAssigned ? '' : 'un'}assigned-project-item`,
        children: (
          <>
            <Divider>Roles</Divider>
            <UserEntitiesListByProject
              entityKey={PERMISSION_ENTITY.ROLE}
              itemExtra={RolePermissionsDetails}
              onChange={handleChangeTransactions(project.id, PERMISSION_ENTITY.ROLE)}
              {...project}
            />
            {project.permissions && (
              <>
                <Divider>Direct permissions</Divider>
                <UserEntitiesListByProject
                  entityKey={PERMISSION_ENTITY.PERMISSION}
                  keywords={{
                    assign: {
                      present: 'grant',
                      past: 'granted'
                    },
                    unassign: {
                      present: 'revoke',
                      past: 'revoked'
                    }
                  }}
                  onChange={handleChangeTransactions(project.id, PERMISSION_ENTITY.PERMISSION)}
                  {...project}
                />
              </>
            )}
          </>
        )
      };

      if (changesTransactions[project.id]) {
        item.extra = (
          <Button icon={<SaveOutlined />} type="primary" size="small" onClick={executeTransactions(project.id)}>
            Apply changes
          </Button>
        );
      }

      return item;
    })
    .filter(Boolean);

  return (
    <Collapse
      key={syncedAt}
      collapsible="header"
      activeKey={unfoldedProjects}
      onChange={setUnfoldedProjects}
      items={projectItems}
    />
  );
};

export { UserPermissionsList };
