import _ from 'lodash';

const TRANSACTION_TYPE = {
  DELETE: 'delete',
  SET: 'set'
};

const getProjectsIds = (projects, postfix = '') => projects.map((project) => `${project.id}_${postfix}`);

const getUnassignedEntities = (allEntities, assignedEntities) => _.differenceBy(allEntities, assignedEntities, 'id');

const getUnassignedProjects = (projects, assignedProjects) => _.differenceBy(projects, assignedProjects, 'id');

const mapEntitiesChangesIntoTransactions = (changes) => {
  if (!changes?.length) {
    return null;
  }

  return changes.map(({ isAssigned, id, name }) => ({
    entityId: id,
    entityName: name,
    type: isAssigned ? TRANSACTION_TYPE.DELETE : TRANSACTION_TYPE.SET
  }));
};

const mapAssignedRolesAndPermissionsOfUser = (users = [], projects) => {
  const mappedProject = _.keyBy(projects, 'id');
  const mappedUsers = [...users];

  for (const user of mappedUsers) {
    const assignedProjects = {};

    for (const permission of user?.directPermissions || []) {
      if (!assignedProjects[permission.projectId]) {
        assignedProjects[permission.projectId] = { ...mappedProject[permission.projectId] };
        assignedProjects[permission.projectId].assignedPermissions = [];
      }

      assignedProjects[permission.projectId].assignedPermissions.push(permission);
    }

    for (const role of user?.roles || []) {
      if (!assignedProjects[role.projectId]) {
        assignedProjects[role.projectId] = { ...mappedProject[role.projectId] };
      }

      if (!assignedProjects[role.projectId].assignedRoles) {
        assignedProjects[role.projectId].assignedRoles = [];
      }

      role.permissions = _.sortBy(role.permissions, ['name']);

      assignedProjects[role.projectId].assignedRoles.push(role);
    }

    _.forEach(assignedProjects, (assignedProject) => {
      if (assignedProject?.assignedPermissions?.length) {
        assignedProject.assignedPermissions = _.sortBy(assignedProject.assignedPermissions, ['name']);
      }

      if (assignedProject?.assignedRoles?.length) {
        assignedProject.assignedRoles = _.sortBy(assignedProject.assignedRoles, ['name']);
      }
    });

    user.projects = _.flatMap(assignedProjects);
  }

  return mappedUsers;
};

const markAssignedEntities = (allEntities = [], assignedEntities = []) => {
  const mappedEntities = assignedEntities.map((permission) => ({ ...permission, isAssigned: true }));

  mappedEntities.push(...getUnassignedEntities(allEntities, assignedEntities));

  return mappedEntities;
};

const processProjects = (projects = [], assignedProjects = []) => {
  const processedProjects = assignedProjects.map((project) => ({ ...project, isAssigned: true }));

  processedProjects.push(...getUnassignedProjects(projects, assignedProjects));

  return processedProjects;
};

const sortEntitiesByName = (data) => {
  const sortedEntities =
    data?.map((project) => ({
      ...project,
      permissions: _.sortBy(project.permissions, ['name']),
      roles: _.sortBy(project.roles, ['name']).map((role) => ({
        ...role,
        permissions: _.sortBy(role.permissions, ['name'])
      }))
    })) || [];

  return sortedEntities;
};

export {
  getProjectsIds,
  getUnassignedEntities,
  getUnassignedProjects,
  mapEntitiesChangesIntoTransactions,
  mapAssignedRolesAndPermissionsOfUser,
  markAssignedEntities,
  processProjects,
  sortEntitiesByName,
  TRANSACTION_TYPE
};
