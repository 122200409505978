/* eslint-disable jsx-a11y/iframe-has-title */
import { Card } from 'antd';

const NewsFeedCard = () => {
  return (
    <Card title="Newsfeed">
      <iframe
        src="https://elkjopnordic.workplace.com/plugins/group_feed/816191530169432/?workplace_plugin_mobile=1"
        width="100%"
        style={{ border: 'none', overflow: 'auto', minHeight: 500 }}
        frameBorder="0"
        allowTransparency="true"
        allow="encrypted-media"
        sandbox="allow-same-origin allow-scripts allow-popups-to-escape-sandbox allow-popups allow-storage-access-by-user-activation"
      />
    </Card>
  );
};

export { NewsFeedCard };
