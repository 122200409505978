/* eslint-disable no-nested-ternary */
import { EditOutlined, HistoryOutlined, MessageOutlined } from '@ant-design/icons';
import { ProCard, StatisticCard } from '@ant-design/pro-components';
import { useBoolean, useRequest } from 'ahooks';
import { Alert, Button, Drawer, List, Space, Table, Tooltip } from 'antd';
import cn from 'classnames';
import { useContext, useState } from 'react';

import { DateInline } from '@/components/DateInline';
import { statisticString } from '@/helpers/ui';
import { LogisticsModel } from '@/models/logistics';

import { CASE_STATUS_LOWERCASE, ROUTING_DATA_ERROR, ROUTING_DATA_MAPPING } from '../configs';
import { logisticsContext } from '../logisticsContext';
import styles from '../styles.module.scss';
import { DetailsInfo } from './ContactDetails';
import { CoreValuesForm } from './CoreValuesForm';
import { EditableCaseInfoForm } from './EditableCaseInfoForm';
import { RoutingDataForm } from './RoutingDataForm';
import { SimilarCases } from './SimilarCases';

const { Statistic } = StatisticCard;

const DATE_TITLE_BY_STATUS = {
  [CASE_STATUS_LOWERCASE.REC]: 'Date booked',
  [CASE_STATUS_LOWERCASE.WEB]: 'Date booked',
  [CASE_STATUS_LOWERCASE.ARR]: 'Date received',
  [CASE_STATUS_LOWERCASE.START]: 'Date started',
  [CASE_STATUS_LOWERCASE.CLOSE]: 'Date closed',
  [CASE_STATUS_LOWERCASE.RET]: 'Date returned'
};

const cols = [
  {
    title: 'Location',
    dataIndex: 'location',
    key: 'location'
  },
  {
    title: 'Date placed',
    dataIndex: 'datePlaced',
    key: 'datePlaced',
    render: (value) => <DateInline value={value} />
  },
  {
    title: 'Date taken',
    dataIndex: 'dateTaken',
    key: 'dateTaken',
    render: (value) => <DateInline value={value} />
  },
  {
    title: 'Last comment',
    dataIndex: 'lastComment',
    key: 'lastComment'
  }
];

const WarehouseTable = ({ warehouseData }) => (
  <ProCard headerBordered className="no-outer-padding" colSpan={24} size="small">
    <Table columns={cols} dataSource={warehouseData} rowKey="id" pagination={false} size="small" />
  </ProCard>
);

const getDatesListItems = (caseData) => {
  const items = [];

  const used = new Set();
  const statuses = caseData.caseStatuses || [];
  statuses.reverse();

  for (const { declaredAt, key } of statuses) {
    if (used.has(key)) {
      // We want only first unique statuses
      continue;
    }
    used.add(key);

    const title = DATE_TITLE_BY_STATUS[key.toLowerCase()];

    if (!title) {
      continue;
    }

    items.push({
      date: declaredAt,
      title
    });
  }

  if (!items.length) {
    items.push({
      date: caseData.createdAt,
      title: DATE_TITLE_BY_STATUS[CASE_STATUS_LOWERCASE.REC]
    });
  }

  items.reverse();

  return items;
};

const getServiceProperties = (routingData) => {
  const data = [];

  for (const { newKey, label: title } of ROUTING_DATA_MAPPING) {
    const oldValue = routingData?.initialData?.[newKey];
    const newValue = routingData?.mergedData?.[newKey];

    data.push({
      title,
      value: (
        <>
          {oldValue || '∅'} ⇒ {newValue ? newValue !== oldValue ? <b>{newValue}</b> : newValue : '∅'}
        </>
      )
    });
  }

  return data;
};

const ChangedValueTooltip = (props) => {
  const { caseDataValue, value, valueName } = props;

  if (caseDataValue === value) {
    return null;
  }

  return (
    <Tooltip
      title={
        <>
          Current {valueName}: <b>{caseDataValue}</b>
        </>
      }
    >
      <HistoryOutlined style={{ fontSize: '16px', color: '#d4380d' }} />
    </Tooltip>
  );
};

const CaseInfo = (props) => {
  const { caseData, warehouseData, getRouting, onAddComment } = props;
  const [isCoreValuesFormOpened, { setTrue: openCoreValuesForm, setFalse: closeCoreValuesForm }] = useBoolean(false);
  const [isRoutingDataFormOpened, { setTrue: openRoutingDataForm, setFalse: closeRoutingDataForm }] = useBoolean(false);
  const [datesListItems] = useState(getDatesListItems(caseData));
  const {
    productTypeId,
    manufacturerId,
    serviceTypeId,
    routingData,
    setProductType,
    setManufacturer,
    setServiceType,
    setRoutingData
  } = useContext(logisticsContext);

  const getCoreValues = useRequest(() => LogisticsModel.getCoreValues(caseData.guid), {
    throwOnError: false,
    initialData: {
      serviceTypes: [],
      manufacturers: [],
      productTypes: []
    }
  });

  const handleCoreValuesChange = (values) => {
    setProductType(values.productTypeId);
    setManufacturer(values.manufacturerId);
    setServiceType(values.serviceTypeId);

    closeCoreValuesForm();
  };

  const handleRoutingDataChange = (values) => {
    setRoutingData(values);

    closeRoutingDataForm();
  };

  const competence = getRouting.data?.serviceProviderCompetence || {};
  const smallBorderedCardProps = {
    bordered: true,
    headerBordered: true,
    size: 'small',
    type: 'inner'
  };
  const manufacturerName = getCoreValues.data.manufacturers.find((type) => type.id === manufacturerId)?.name;
  const caseManufacturerName = getCoreValues.data.manufacturers.find((type) => type.id === caseData.manufacturerId)
    ?.name;
  const serviceTypeName = getCoreValues.data.serviceTypes.find((type) => type.id === serviceTypeId)?.name;
  const caseServiceTypeName = getCoreValues.data.serviceTypes.find((type) => type.id === caseData.serviceTypeId)?.name;
  const productTypeName = getCoreValues.data.productTypes.find((type) => type.id === productTypeId)?.name;
  const caseProductTypeName = getCoreValues.data.productTypes.find((type) => type.id === caseData.productTypeId)?.name;

  return (
    <>
      <ProCard split="vertical">
        <ProCard className="no-outer-padding" colSpan={16} size="small" split="horizontal">
          <ProCard gutter={12} size="small">
            <ProCard
              title="General info"
              {...smallBorderedCardProps}
              colSpan={10}
              extra={
                <Button onClick={openCoreValuesForm} size="small" type="primary" ghost icon={<EditOutlined />}>
                  Change brand or type
                </Button>
              }
            >
              <StatisticCard className="value-over-title" size="small">
                <Statistic title="Guid" value={statisticString(caseData.guid)} />
                <Statistic title="Activity status" value={statisticString(caseData.currentStatus)} />
                <Statistic
                  title="Brand"
                  value={statisticString(manufacturerName)}
                  suffix={
                    <ChangedValueTooltip
                      caseDataValue={caseManufacturerName}
                      value={manufacturerName}
                      valueName="brand"
                    />
                  }
                />
                <Statistic
                  title="Service type"
                  value={statisticString(serviceTypeName)}
                  suffix={
                    <ChangedValueTooltip
                      caseDataValue={caseServiceTypeName}
                      value={serviceTypeName}
                      valueName="service type"
                    />
                  }
                />
                <Statistic
                  title="Product type"
                  value={statisticString(
                    getCoreValues.data.productTypes.find((type) => type.id === productTypeId)?.name
                  )}
                  suffix={
                    <ChangedValueTooltip
                      caseDataValue={caseProductTypeName}
                      value={productTypeName}
                      valueName="product type"
                    />
                  }
                />
                <Statistic title="Partner" value={statisticString(caseData.servicePartner.name)} />
                <Statistic title="Service provider" value={statisticString(caseData.serviceProvider.name)} />
                <Statistic title="Department" value={statisticString(caseData.serviceProviderDepartment?.name)} />
              </StatisticCard>
            </ProCard>
            <ProCard title="Customer" {...smallBorderedCardProps} colSpan={8}>
              <DetailsInfo data={caseData.customer} compact />
            </ProCard>
            <ProCard
              {...smallBorderedCardProps}
              loading={!routingData.initialData && !routingData.error === ROUTING_DATA_ERROR.NO_DATA}
              colSpan={6}
              title="Service properties"
              bodyStyle={{ paddingBlockStart: 4 }}
              extra={
                <Button onClick={openRoutingDataForm} size="small" type="primary" ghost icon={<EditOutlined />}>
                  Edit
                </Button>
              }
            >
              <Space direction="vertical" size="small">
                <StatisticCard
                  className={cn('value-over-title', styles.serviceOptionsCard)}
                  size="small"
                  loading={getRouting.loading}
                >
                  {getServiceProperties(routingData).map(({ title, value }) => (
                    <Statistic key={title} title={title} description={value} />
                  ))}
                </StatisticCard>
                {routingData.error === ROUTING_DATA_ERROR.NO_DATA && (
                  <Alert message="No routing data loaded" type="error" showIcon />
                )}
              </Space>
            </ProCard>
          </ProCard>
          <EditableCaseInfoForm caseData={caseData} />
          {warehouseData && warehouseData.length > 0 && <WarehouseTable warehouseData={warehouseData} />}
          <ProCard colSpan={24} size="small">
            <SimilarCases guid={caseData.guid} />
          </ProCard>
        </ProCard>
        <ProCard direction="column" gutter={[12, 16]} colSpan={8} size="small">
          {competence?.logisticNote && (
            <Alert type="warning" message={competence.logisticNote} className={styles.logisticNote} />
          )}
          <Alert
            message={
              <span>
                Wrong or outdated business rules? Let us know!{' '}
                <a href="https://forms.office.com/e/xTzHgLsw42" target="_blank" rel="noopener noreferrer">
                  https://forms.office.com/e/xTzHgLsw42
                </a>
              </span>
            }
            type="info"
            showIcon
          />
          <ProCard title="Dates" {...smallBorderedCardProps}>
            <List
              itemLayout="horizontal"
              dataSource={datesListItems}
              rowKey="id"
              renderItem={({ date, title }) => (
                <List.Item>
                  <List.Item.Meta
                    title={
                      <>
                        <DateInline asTag value={date} />
                        {title}
                      </>
                    }
                  />
                </List.Item>
              )}
            />
          </ProCard>
          <ProCard
            title="Comments"
            {...smallBorderedCardProps}
            extra={
              <Button size="small" ghost type="primary" icon={<MessageOutlined />} onClick={onAddComment}>
                Add comment
              </Button>
            }
          >
            {!caseData.caseMessages.length && <Alert type="info" message="No comments yet" />}
            {caseData.caseMessages.length > 0 && (
              <List
                itemLayout="horizontal"
                dataSource={caseData.caseMessages}
                renderItem={(message) => (
                  <List.Item>
                    <List.Item.Meta
                      title={
                        <>
                          <DateInline asTag value={message.createdAt} />
                          {message.content}
                        </>
                      }
                    />
                  </List.Item>
                )}
              />
            )}
          </ProCard>
        </ProCard>
      </ProCard>
      <Drawer
        title="Change brand or type"
        open={isCoreValuesFormOpened}
        width={400}
        onClose={closeCoreValuesForm}
        destroyOnClose
      >
        <CoreValuesForm
          caseData={{ serviceTypeId, manufacturerId, productTypeId }}
          coreValues={getCoreValues.data}
          onFinish={handleCoreValuesChange}
        />
      </Drawer>
      <Drawer
        title="Change service properties"
        open={isRoutingDataFormOpened}
        width={400}
        onClose={closeRoutingDataForm}
        destroyOnClose
      >
        <RoutingDataForm routingData={routingData} onFinish={handleRoutingDataChange} onClose={closeRoutingDataForm} />
      </Drawer>
    </>
  );
};

export { CaseInfo };
