import { CheckCard } from '@ant-design/pro-components';
import { useRequest } from 'ahooks';
import { Alert, Button, Collapse, Divider, Empty, List, Radio, Space, Spin, Tag } from 'antd';
import _ from 'lodash';
import { useState } from 'react';

import { DateInline } from '@/components/DateInline';
import { LogisticsModel } from '@/models/logistics';
import { ShipmentModel } from '@/models/shipment';

import { ACTION } from '../configs';
import styles from '../styles.module.scss';
import { AccessoriesDeviationInformCheckbox } from './AccessoriesDeviationInformCheckbox';
import { SelectAnyShippingMethod } from './SelectAnyShippingMethod';

const NEW_SHIPMENT_GUID = 'new';

const ForwardForm = ({ caseData, coreValues, inboundCompetence = {}, serviceProvider, onFinish }) => {
  const [shipmentTypeId, setShipmentTypeId] = useState();
  const [bundleToShipment, setBundleTo] = useState(NEW_SHIPMENT_GUID);
  const [availableShipmentsOptions, setAvailableShipmentsOptions] = useState([]);
  const withBundling = !inboundCompetence.disableBundling;

  const { data: shippingMethods, loading: loadingShippingMethods } = useRequest(
    () => LogisticsModel.getShippingMethods(caseData.guid, ACTION.FORWARD, coreValues),
    {
      initialData: [],
      throwOnError: true,
      ready: !caseData.orderData.blockForwardingWarning,
      formatResult: (data) => data.map((x) => ({ id: Number(x.properties.serviceId), name: x.name })),
      onSuccess: (data) => {
        setBundleTo(NEW_SHIPMENT_GUID);

        if (data.length) {
          setShipmentTypeId(data[0].id);
        }
      }
    }
  );

  const { data: allShippingMethods, loading: loadingAllShippingMethods } = useRequest(
    () => LogisticsModel.getAllShippingMethods(),
    {
      initialData: [],
      throwOnError: true,
      formatResult: (data) => _.sortBy(data, (x) => x.name)
    }
  );

  const { loading: loadingAvailableShipments } = useRequest(
    () =>
      LogisticsModel.getShipmentsForBundling({
        serviceProviderId: serviceProvider.id,
        departmentId: inboundCompetence.departmentId || caseData.serviceProviderDepartmentId,
        shipmentTypeId, // We need senderella shipment type here
        logisticAction: ACTION.FORWARD,
        unlimitedBundling: inboundCompetence.unlimitedBundling
      }),
    {
      initialData: [],
      throwOnError: true,
      refreshDeps: [shipmentTypeId],
      ready: Boolean(shipmentTypeId) && Boolean(withBundling),
      onSuccess: (result) => {
        const sortedShipments = _.sortBy(result, (x) => -x.id);

        setBundleTo(sortedShipments?.[0]?.guid || NEW_SHIPMENT_GUID);

        setAvailableShipmentsOptions(
          sortedShipments.map((shipment) => {
            const { createdAt, guid, packages, receiver, shipmentType } = shipment;
            const items = _.flatMap(packages, (p) => p.items);

            return {
              description: (
                <Space direction="vertical">
                  <span>
                    <DateInline asTag value={createdAt} /> <b>{shipmentType.name}</b>
                  </span>
                  <span>
                    <b>Receiver:</b>
                    <br />
                    {ShipmentModel.addressToString(receiver)}
                  </span>
                  <Collapse
                    size="small"
                    items={[
                      {
                        label: `Contains ${items.length} item${items.length !== 1 ? 's' : ''}`,
                        children: (
                          <List
                            size="small"
                            dataSource={items}
                            renderItem={(item) => (
                              <List.Item>
                                {item.customData?.activityNumber ? <Tag>{item.customData.activityNumber}</Tag> : null}
                                {item.brand} {item.model}
                                {item.senderRef ? `, ref: ${item.senderRef}` : null}
                              </List.Item>
                            )}
                          />
                        ),
                        onClick: (e) => e.stopPropagation()
                      }
                    ]}
                  />
                </Space>
              ),
              value: guid
            };
          })
        );
      }
    }
  );

  const handleForward = (alsoStart = undefined, skipStatusChange = undefined) => () => {
    onFinish(ACTION.FORWARD, {
      shipmentTypeId, // SenderellaId here!
      alsoStart,
      skipStatusChange,
      bundleToShipmentId: bundleToShipment === NEW_SHIPMENT_GUID ? undefined : bundleToShipment
    });
  };

  if (caseData.orderData.blockForwardingWarning) {
    // Special case for rerouted cases
    return <Empty description={caseData.orderData.blockForwardingWarning} />;
  }

  if (loadingShippingMethods || loadingAllShippingMethods) {
    return (
      <Spin tip="Loading shipping methods">
        <div />
      </Spin>
    );
  }

  if (!shippingMethods.length && !allShippingMethods.length) {
    return <Empty description="No shipping methods available" />;
  }

  const handleShipmentTypeChange = (e) => {
    const newShipmentId = e.target ? e.target.value : e;
    const newShipment = allShippingMethods.find((x) => x.id === newShipmentId);

    if (!newShipment) {
      return;
    }

    setShipmentTypeId(newShipmentId);
  };

  const handleShipmentChange = (guid) => {
    if (!guid) {
      return;
    }

    setBundleTo(guid === true ? NEW_SHIPMENT_GUID : guid);
  };

  return (
    <Space direction="vertical" size="large">
      {!caseData.logisticHub && (
        <Alert
          type="warning"
          showIcon
          message="The order was originally sent to another location than you're forwarding to. It could be done by mistake from the customer, but please double-check just in case"
        />
      )}
      {serviceProvider.id !== caseData.serviceProvider.id && (
        <Alert type="warning" showIcon message={`This case will be assigned to ${serviceProvider.name}`} />
      )}
      {shippingMethods.length > 0 && (
        <Space direction="vertical">
          <b>Recommended shipping methods:</b>
          <div>
            <Radio.Group
              value={shipmentTypeId}
              onChange={handleShipmentTypeChange}
              disabled={loadingAvailableShipments}
            >
              {shippingMethods.map((x) => (
                <Radio key={x.id} value={x.id}>
                  {x.name} ({x?.settings?.sesamId ? `Sesam ID: ${x.settings.sesamId} / ` : ''}Hub ID: {x.id})
                </Radio>
              ))}
            </Radio.Group>
            <Divider plain>or select from all shipping methods</Divider>
            <SelectAnyShippingMethod
              allShippingMethods={allShippingMethods}
              value={shipmentTypeId}
              onChange={handleShipmentTypeChange}
            />
          </div>
        </Space>
      )}
      {!shippingMethods.length && (
        <Space direction="vertical">
          <b>Select shipping method:</b>
          <SelectAnyShippingMethod
            allShippingMethods={allShippingMethods}
            value={shipmentTypeId}
            onChange={handleShipmentTypeChange}
          />
        </Space>
      )}

      {loadingAvailableShipments && (
        <Spin tip="Loading shipments">
          <div style={{ height: 60 }} />
        </Spin>
      )}
      {!loadingAvailableShipments && (
        <Space direction="vertical">
          {availableShipmentsOptions.length > 0 && <b>Shipment to bundle together:</b>}
          <div>
            {availableShipmentsOptions.length > 0 && withBundling && (
              <>
                <CheckCard.Group
                  className={styles.shipmentCheckCard}
                  value={bundleToShipment}
                  options={availableShipmentsOptions}
                  onChange={handleShipmentChange}
                />
                <Divider plain>or create new shipment</Divider>
              </>
            )}
            <CheckCard
              className={styles.shipmentCheckCard}
              title="Create new shipment"
              checked={bundleToShipment === NEW_SHIPMENT_GUID}
              onChange={handleShipmentChange}
            />
          </div>
        </Space>
      )}
      <AccessoriesDeviationInformCheckbox />
      <Space direction="horizontal">
        <Button type="primary" disabled={loadingAvailableShipments} onClick={handleForward()}>
          Forward (arr+wext)
        </Button>
        <Button type="primary" disabled={loadingAvailableShipments} onClick={handleForward(true)}>
          Forward (arr+start+wext)
        </Button>
        <Button type="primary" disabled={loadingAvailableShipments} onClick={handleForward(undefined, true)}>
          Forward (no status change)
        </Button>
      </Space>
    </Space>
  );
};

export { ForwardForm };
