import { useDebounceFn } from 'ahooks';
import _ from 'lodash';
import { createContext, useEffect, useState } from 'react';

import { ROUTING_DATA_ERROR, ROUTING_DATA_MAPPING } from './configs';

const logisticsContext = createContext(null);
const { Provider } = logisticsContext;

const ALLOWED_KEYS = ROUTING_DATA_MAPPING.map(({ newKey }) => newKey);

const LogisticsProvider = (props) => {
  const { caseData, children } = props;

  const [productTypeId, setProductType] = useState(null);
  const [manufacturerId, setManufacturer] = useState(null);
  const [serviceTypeId, setServiceType] = useState(null);
  const [routingData, setRoutingData] = useState({});
  const [formValues, setFormValues] = useState(null);
  const [isOtherAccessoriesChanged, setIsOtherAccessoriesChanged] = useState(false);
  const [informAboutAccessories, setInformAboutAccessories] = useState(false);

  const { run: handleFormChange } = useDebounceFn((val, allValues) => setFormValues(allValues), {
    wait: 350
  });

  useEffect(() => {
    if (!caseData) {
      return;
    }

    setProductType(caseData.productTypeId);
    setManufacturer(caseData.manufacturerId);
    setServiceType(caseData.serviceTypeId);
    setRoutingData({});
    setFormValues(null);
    setIsOtherAccessoriesChanged(false);
    setInformAboutAccessories(false);
  }, [caseData?.id]);

  const mergeRoutingData = (initialData = {}, newData = {}) => {
    let mappedInitialData = {};
    let mappedNewData = {};

    for (const { oldKey, newKey } of ROUTING_DATA_MAPPING) {
      mappedInitialData[newKey] = initialData[newKey] || initialData[oldKey];
      mappedNewData[newKey] = newData[newKey] || newData[oldKey];
    }

    mappedInitialData = _.pick(mappedInitialData, ALLOWED_KEYS);
    mappedNewData = _.pick(mappedNewData, ALLOWED_KEYS);

    setRoutingData({
      initialData: mappedInitialData,
      mergedData: _.merge({ ...mappedInitialData }, newData)
    });
  };

  const overrideMergedRoutingData = (newData) => {
    setRoutingData({
      initialData: routingData.initialData,
      mergedData: _.pick(newData, ALLOWED_KEYS)
    });
  };

  const setRoutingDataError = (error = ROUTING_DATA_ERROR.NO_DATA) => {
    setRoutingData({
      ...routingData,
      error
    });
  };

  return (
    <Provider
      value={{
        formValues,
        productTypeId,
        manufacturerId,
        serviceTypeId,
        routingData,
        isOtherAccessoriesChanged,
        informAboutAccessories,
        handleFormChange,
        setFormValues,
        setProductType,
        setManufacturer,
        setServiceType,
        mergeRoutingData,
        setRoutingData: overrideMergedRoutingData,
        setRoutingDataError,
        setIsOtherAccessoriesChanged,
        setInformAboutAccessories
      }}
    >
      {children}
    </Provider>
  );
};

export { logisticsContext, LogisticsProvider };
