import { DatePicker } from 'antd';
import moment from 'moment';
import { useState } from 'react';

const CustomTimestampPicker = (props) => {
  const { onChange, value, ...restProps } = props;
  const [defaultValue] = useState(value ? moment(value) : null);

  const handleChange = (date) => onChange(date ? date.format() : null);

  return (
    <DatePicker defaultValue={defaultValue} onChange={handleChange} showTime style={{ width: 180 }} {...restProps} />
  );
};

export { CustomTimestampPicker };
