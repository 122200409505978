import { DeleteOutlined, EditOutlined, PlusOutlined, RedoOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { Button, Drawer, Input, Space } from 'antd';
import _ from 'lodash';
import { useRef, useState } from 'react';

import ExtendedTable from '@/components/ExtendedTable';
import { ENTITY, PRINTER_SETTINGS, PRINTER_TYPE_NAME } from '@/configs/entity';
import { SchemaForm } from '@/containers/schemaForm/SchemaForm';
import { EntitiesModel } from '@/models/entities';

const Printers = () => {
  const [printerToEditIndex, setPrinterToEditIndex] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const tableRef = useRef(null);
  const getPrintersRequest = useRequest(
    ({ page, pageSize, commonSearch }) =>
      EntitiesModel.getEntityValues(ENTITY.PRINTER, {
        commonSearch,
        order: ['name'],
        pagination: { offset: page * pageSize, pageSize }
      }),
    { manual: true }
  );
  const { data } = getPrintersRequest;

  const refresh = (commonSearch = searchValue) => {
    tableRef.current.reset({ commonSearch });
  };

  const deleteEntityInstance = useRequest((id) => EntitiesModel.deleteEntityInstance(ENTITY.PRINTER, id), {
    manual: true,
    onSuccess: refresh
  });

  const handleEditClick = (printerIndex) => () => setPrinterToEditIndex(printerIndex);
  const handleDeleteClick = (id) => () => deleteEntityInstance.run(id);

  const handleSuccessChanges = () => {
    setPrinterToEditIndex(null);
    refresh();
  };

  const handleSearchChanged = (e) => {
    const value = _.trim(e.target.value);
    setSearchValue(value);
    refresh(value);
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name'
    },
    {
      title: 'Type',
      render: (__, rowData) => PRINTER_TYPE_NAME[rowData?.settings?.type]
    },
    {
      title: 'Labels quantity',
      dataIndex: ['settings', 'quantity']
    },
    {
      title: 'Actions',
      width: 200,
      render: (__, rowData, index) => (
        <Actions
          data={rowData}
          index={index}
          onEditClick={handleEditClick}
          onDeleteClick={handleDeleteClick}
          disabled={deleteEntityInstance.loading}
        />
      )
    }
  ];

  return (
    <>
      <Space className="tab-actions">
        <Button type="primary" onClick={handleEditClick('new')} icon={<PlusOutlined />}>
          Add new printer
        </Button>
        <Button onClick={refresh} icon={<RedoOutlined />}>
          Refresh
        </Button>
      </Space>
      <Space direction="vertical">
        <Input.Search
          placeholder="Search value"
          size="large"
          onSearch={handleSearchChanged}
          onPressEnter={handleSearchChanged}
        />
        <ExtendedTable request={getPrintersRequest} columns={columns} ref={tableRef} />
      </Space>
      <Drawer
        title={
          printerToEditIndex === 'new'
            ? 'Add new printer'
            : `Edit printer: ${data?.data?.[printerToEditIndex]?.name || ''}`
        }
        open={printerToEditIndex !== null}
        onClose={handleEditClick(null)}
        width={720}
        destroyOnClose
      >
        <SchemaForm
          entity={ENTITY.PRINTER}
          id={data?.data?.[printerToEditIndex]?.id}
          onSuccess={handleSuccessChanges}
          predefinedObjects={{ settings: { ...PRINTER_SETTINGS } }}
        />
      </Drawer>
    </>
  );
};

const Actions = (props) => {
  const { data, disabled, index, onEditClick, onDeleteClick } = props;

  return (
    <Space>
      <Button type="primary" onClick={onEditClick(index)} icon={<EditOutlined />} disabled={disabled}>
        Edit
      </Button>
      <Button type="primary" danger onClick={onDeleteClick(data.id)} icon={<DeleteOutlined />} disabled={disabled}>
        Delete
      </Button>
    </Space>
  );
};

export { Printers };
