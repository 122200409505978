import { createContext } from 'react';

const timeTrackerContext = createContext(null);
const { Provider } = timeTrackerContext;

const TIMETRACKER_EVENT = {
  ON_INTERVAL_SELECT: 'onIntervalSelect',
  ON_INTERVAL_UPDATE: 'onIntervalUpdate',
  ON_INTERVAL_CANCEL: 'onIntervalCancel',
  ON_PENDING_REQUEST_RESOLVE: 'onPendingRequestResolve'
};

const callbacks = {
  [TIMETRACKER_EVENT.ON_INTERVAL_SELECT]: [],
  [TIMETRACKER_EVENT.ON_INTERVAL_UPDATE]: [],
  [TIMETRACKER_EVENT.ON_INTERVAL_CANCEL]: [],
  [TIMETRACKER_EVENT.ON_PENDING_REQUEST_RESOLVE]: []
};

const TimeTrackerProvider = (props) => {
  const { children } = props;

  const toggleCallback = (event, id, callback) => {
    if (!callbacks[event]) {
      return;
    }

    const filteredCallbacks = callbacks[event].filter((callbackObject) => callbackObject.id !== id);

    if (filteredCallbacks.length === callbacks[event].length) {
      filteredCallbacks.push({ id, callback });
    }

    callbacks[event] = filteredCallbacks;
  };

  const handleUpdateCallbacks = (event) => (...args) => {
    if (!callbacks[event]) {
      return;
    }

    for (const { callback } of callbacks[event]) {
      callback?.(...args);
    }
  };

  return (
    <Provider
      value={{
        onIntervalSelect: handleUpdateCallbacks(TIMETRACKER_EVENT.ON_INTERVAL_SELECT),
        onIntervalUpdate: handleUpdateCallbacks(TIMETRACKER_EVENT.ON_INTERVAL_UPDATE),
        onIntervalCancel: handleUpdateCallbacks(TIMETRACKER_EVENT.ON_INTERVAL_CANCEL),
        onPendingRequestResolve: handleUpdateCallbacks(TIMETRACKER_EVENT.ON_PENDING_REQUEST_RESOLVE),
        toggleCallback
      }}
    >
      {children}
    </Provider>
  );
};

export { timeTrackerContext, TimeTrackerProvider, TIMETRACKER_EVENT };
