/* eslint-disable no-template-curly-in-string */
import { useRequest } from 'ahooks';
import { Button } from 'antd';
import _ from 'lodash';

import { onError } from '@/helpers/Alert';
import { EntitiesModel } from '@/models/entities';

import { LocalSchemaForm } from './LocalSchemaForm';

const SchemaForm = (props) => {
  const {
    customFormFieldsProps = {},
    entity,
    extraFiltersByFieldKey = {},
    formPrefix,
    formSuffix,
    // Can be a function
    hiddenFieldsKeys = [],
    id,
    predefinedObjects = {},
    relations = [],
    reorderKeys = [],
    saveButtonText,
    showTitles = true,
    transformValuesBeforeChange,
    withRelations = true
  } = props;

  const { data, loading } = useRequest(
    () => {
      if (id) {
        return EntitiesModel.getEntityValueById(entity, id, withRelations, relations);
      }

      return EntitiesModel.getEntitySchema(entity, withRelations);
    },
    {
      formatResult: (result) => {
        if (id) {
          props?.onDataLoaded?.(result);

          return result;
        }

        return { schema: result };
      },
      onError
    }
  );

  const changeEntityValues = useRequest(
    (values) =>
      EntitiesModel.changeEntityValues(
        entity,
        transformValuesBeforeChange ? transformValuesBeforeChange(values) : values
      ),
    {
      manual: true,
      onSuccess: () => {
        props.onEdit?.();
        props.onSuccess?.();
      },
      onError
    }
  );

  const handleFormFinish = (formValues) => {
    const newValues = {
      ...formValues
    };

    if (id) {
      newValues.id = id;
    }

    changeEntityValues.run(newValues);
  };

  return (
    <LocalSchemaForm
      customFormFieldsProps={customFormFieldsProps}
      data={data?.data}
      schema={data?.schema}
      loading={!data?.schema}
      onFinish={handleFormFinish}
      showTitles={showTitles}
      extraFiltersByFieldKey={extraFiltersByFieldKey}
      hiddenFieldsKeys={hiddenFieldsKeys}
      predefinedObjects={predefinedObjects}
      reorderKeys={reorderKeys}
      formPrefix={formPrefix}
      formSuffix={formSuffix}
      bottomContent={
        <Button type="primary" htmlType="submit" disabled={loading} loading={changeEntityValues.loading}>
          {saveButtonText || `Save ${_.lowerCase(entity)}`}
        </Button>
      }
    >
      {props.children}
    </LocalSchemaForm>
  );
};

export { SchemaForm };
