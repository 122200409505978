import { FireTwoTone } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { Badge, Card, List } from 'antd';

import { RequestModel } from '@/models/request';

const MILLISECONDS_IN_DAY = 8.64e7;

const DateDisplay = (props) => {
  const { date: inputDate, inline } = props;

  const date = inputDate instanceof Date ? inputDate : new Date(inputDate);

  if (inline) {
    // eslint-disable-next-line no-restricted-globals
    return isNaN(date) ? null : date.toLocaleString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
  }

  const today = new Date();
  const passedDays = Math.floor((today.getTime() - date.getTime()) / MILLISECONDS_IN_DAY);
  const freshNews = passedDays < 5;

  const output = (
    <DateElement date={date.getDate()} month={date.toLocaleString('sv-SE', { month: 'short' }).substring(0, 3)} />
  );

  if (freshNews) {
    return <Badge count={<FireTwoTone twoToneColor="#d4380d" />}>{output}</Badge>;
  }

  return output;
};

const DateElement = (props) => {
  const { date, month } = props;

  return (
    <div className="date-display">
      <div className="date-display-date">{date}</div>
      <div className="date-display-month">{month}</div>
    </div>
  );
};

const BirthdaysCard = () => {
  const { data, loading } = useRequest(() => RequestModel.getJsonData('/api/v1/dashboard/birthdays'), {
    initialData: []
  });

  return (
    <Card title="Personal news" loading={loading}>
      <List
        dataSource={data || []}
        renderItem={(item) => {
          const { age, department, name } = item;
          const date = new Date(item.date);
          date.setFullYear(date.getFullYear() + item.age);

          return (
            <List.Item key={name}>
              <List.Item.Meta
                title={<DateDisplay date={date} inline />}
                description={`${name} working at ${department} celebrates ${age}`}
              />
            </List.Item>
          );
        }}
      />
    </Card>
  );
};

export { BirthdaysCard };
