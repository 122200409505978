import { message } from 'antd';
import { isArray, isString } from 'lodash';

const MESSAGE_TYPE_METHOD = {
  ERROR: message.error,
  SUCCESS: message.success,
  WARNING: message.warning
};

const showMessage = (method, msg, { displayTime = 30, messagePrefix = '' } = {}) => {
  let msgToDisplay = `${messagePrefix}Unknown error`;

  if (isString(msg) || isArray(msg)) {
    msgToDisplay = (
      <div style={{ display: 'inline-block', verticalAlign: 'top', textAlign: 'left' }}>
        {(isString(msg) ? msg.split('\n') : msg).map((x) => (
          <div key={x.length}>
            {messagePrefix}
            {x}
          </div>
        ))}
      </div>
    );
  }

  return method(msgToDisplay, displayTime);
};

export const showSuccess = (msg, params) => {
  return showMessage(MESSAGE_TYPE_METHOD.SUCCESS, msg, params);
};

export const showError = (msg, params) => {
  return showMessage(MESSAGE_TYPE_METHOD.ERROR, msg, params);
};

export const showWarning = (msg, params) => {
  return showMessage(MESSAGE_TYPE_METHOD.WARNING, msg, params);
};

export const onError = (e) => {
  if (typeof e === 'string') {
    showError(e);

    return;
  }

  if (e?.bypassOutput) {
    return;
  }

  showError(e?.message);
};

export const onTimeTrackerError = (e) => {
  if (e?.bypassOutput) {
    return;
  }

  showError(e?.message, { messagePrefix: 'Time tracker error: ' });
};
