import _ from 'lodash';

export const ACCESSORIES = {
  ACCESSORIES_UNKNOWN: 'Accessories unknown',
  NO_ACCESSORIES: 'No accessories',
  OTHER_ACCESSORIES: 'Other accessories'
};

export const ACTION = {
  ADD_COMMENT: 'add_comment',
  ARRIVE: 'arrive',
  // EXPORT: 'export',
  FORWARD: 'forward',
  RETURN: 'return',
  SAVE: 'save',
  SET_STATUS: 'set_status',
  REROUTE: 'reroute',
  ADD_COST_PROPOSALS: 'add_cost_proposals'
};

export const ACTION_LABEL = {
  [ACTION.ADD_COMMENT]: 'Add comment',
  [ACTION.ARRIVE]: 'Arrive',
  // [ACTION.EXPORT]: 'Export',
  [ACTION.FORWARD]: 'Forward',
  [ACTION.RETURN]: 'Return',
  [ACTION.SAVE]: 'Save fields',
  [ACTION.SET_STATUS]: 'Change activity status',
  [ACTION.REROUTE]: 'Reroute to another workshop',
  [ACTION.ADD_COST_PROPOSALS]: 'Add cost proposals'
};

export const CASE_SEARCH_TYPE = {
  CASES_STRICT: 'cases-strict',
  CASES_COMMON: 'cases'
};

export const CASE_STATUS = {
  ACCEPT: 'Accept',
  ARR: 'Arr',
  ASS: 'Ass',
  CALL: 'Call',
  CAR_DC: 'CarDC',
  CAR_DI: 'CarDI',
  CAR_MP: 'CarMP',
  CAR_MR: 'CarMR',
  CAR_MS: 'CarMS',
  CAR_OR_PRR: 'CarOrPrr',
  CAR_PC: 'CarPC',
  CAR_PI: 'CarPI',
  CAR_SC: 'CarSC',
  CLOSE_NO_RET: 'CloseNoRet',
  CLOSE: 'Close',
  CRE_OK: 'CreOK',
  CRED_DENIED: 'CredDenied',
  CREQ: 'Creq',
  DBD: 'DBD',
  DEA: 'DEA',
  EMB: 'EMB',
  EST_DENIED: 'EstDenied',
  EST_NO_INS: 'EstNoIns',
  EST_NO_REP: 'EstNoRep',
  EST_OK: 'EstOk',
  EST_QUERY: 'EstQuery',
  EST_RETURN: 'EstReturn',
  EST_SCRAP: 'EstScrap',
  EST_SENT: 'EstSent',
  EST_WANTED: 'EstWanted',
  FINAL: 'Final',
  NFF: 'NFF',
  PLS: 'PLS',
  PREORDER: 'Preorder',
  REC: 'rec',
  REP_OK: 'RepOK',
  RET: 'Ret',
  SENT_NO: 'SentNO',
  SENT_SE: 'SentSE',
  SOFT: 'Soft',
  START: 'Start',
  TO_CLAIM: 'ToClaim',
  TRANSIT: 'Transit',
  W_ARR: 'WArr',
  W_BACKUP: 'WBackup',
  W_BUY_BACK: 'WBuyBack',
  W_CARR: 'WCarr',
  W_CRED: 'WCred',
  W_CUST: 'WCust',
  W_DLY: 'WDly',
  W_EST: 'WEst',
  W_EXT: 'WExt',
  W_FIELD: 'WField',
  W_INV: 'wInv',
  W_MARKET: 'WMarket',
  W_PART: 'Wpart',
  W_PASS: 'WPass',
  W_PO: 'wPO',
  W_POOL: 'WPool',
  W_SCRAP: 'WScrap',
  W_SUPP: 'WSupp',
  W_SWAP: 'WSwap',
  WAIT: 'Wait',
  WDBL: 'Wdbl',
  WEB: 'web',
  WEX_PA: 'WexPa',
  WIP: 'WIP',
  WPARTNER: 'Wpartner',
  WPOP: 'WPOP',
  WRMA: 'WRMA',
  WTEK: 'Wtek',
  WTEST: 'Wtest',
  WWS: 'WWS',
  WXC: 'WXC'
};

export const CASE_STATUS_LOWERCASE = _.mapValues(CASE_STATUS, (value) => value.toLowerCase());

export const CASE_STATUS_LABEL = {
  [CASE_STATUS.ACCEPT]: 'Accept',
  [CASE_STATUS.ARR]: 'Unit arrived',
  [CASE_STATUS.ASS]: 'Assign tech/group/distr',
  [CASE_STATUS.CALL]: 'Assigned to call center',
  [CASE_STATUS.CAR_DC]: 'Delivered to Customer',
  [CASE_STATUS.CAR_DI]: 'Delivered to InfoCare',
  [CASE_STATUS.CAR_MP]: 'Missed pickup',
  [CASE_STATUS.CAR_MR]: 'Missed Return',
  [CASE_STATUS.CAR_MS]: 'Missed swap',
  [CASE_STATUS.CAR_OR_PRR]: 'Carrier must be ordered PRR',
  [CASE_STATUS.CAR_PC]: 'Picked at Customer',
  [CASE_STATUS.CAR_PI]: 'Picked at InfoCare',
  [CASE_STATUS.CAR_SC]: 'Swapped at Customer',
  [CASE_STATUS.CLOSE_NO_RET]: 'Close product no return',
  [CASE_STATUS.CLOSE]: 'Close activity',
  [CASE_STATUS.CRE_OK]: 'Credit OK',
  [CASE_STATUS.CRED_DENIED]: 'Credit Denied',
  [CASE_STATUS.CREQ]: 'Credit request',
  [CASE_STATUS.DBD]: 'Diagnostic Before Dispatch',
  [CASE_STATUS.DEA]: 'Deactivate',
  [CASE_STATUS.EMB]: 'Packaging sent to customer',
  [CASE_STATUS.EST_DENIED]: 'Estimate denied',
  [CASE_STATUS.EST_NO_INS]: 'No inscuranse',
  [CASE_STATUS.EST_NO_REP]: 'No answer from Cust on CostEst',
  [CASE_STATUS.EST_OK]: 'Estimate ok',
  [CASE_STATUS.EST_QUERY]: 'Estimate question from partner',
  [CASE_STATUS.EST_RETURN]: 'Estimate reject, return',
  [CASE_STATUS.EST_SCRAP]: 'Estimate reject, scrap',
  [CASE_STATUS.EST_SENT]: 'Estimate sent to customer',
  [CASE_STATUS.EST_WANTED]: 'Estimate wanted',
  [CASE_STATUS.FINAL]: 'Ready for Invoice (Final)',
  [CASE_STATUS.NFF]: 'No fault found',
  [CASE_STATUS.PLS]: 'Post Label Sent',
  [CASE_STATUS.PREORDER]: 'Preorder',
  [CASE_STATUS.REC]: 'Received in sesam',
  [CASE_STATUS.REP_OK]: 'Test OK',
  [CASE_STATUS.RET]: 'Unit returned to customer',
  [CASE_STATUS.SENT_NO]: 'Sent to Norway',
  [CASE_STATUS.SENT_SE]: 'Sent to Sweden',
  [CASE_STATUS.SOFT]: 'Wait Soft',
  [CASE_STATUS.START]: 'Start Work',
  [CASE_STATUS.TO_CLAIM]: 'ToClaim',
  [CASE_STATUS.TRANSIT]: 'In transit to workshop',
  [CASE_STATUS.W_ARR]: 'Wait - unit not arrived',
  [CASE_STATUS.W_BACKUP]: 'Wait - backup',
  [CASE_STATUS.W_BUY_BACK]: 'Wait-for buyback request',
  [CASE_STATUS.W_CARR]: 'Wait - carrier damage',
  [CASE_STATUS.W_CRED]: 'Wait for credit',
  [CASE_STATUS.W_CUST]: 'Wait-for info from customer',
  [CASE_STATUS.W_DLY]: 'Wait - delayed del.exchg.unit',
  [CASE_STATUS.W_EST]: 'Wait estimate response',
  [CASE_STATUS.W_EXT]: 'Wait - external repair',
  [CASE_STATUS.W_FIELD]: 'Wait - field',
  [CASE_STATUS.W_INV]: 'Wait for Invoice',
  [CASE_STATUS.W_MARKET]: 'Wait market',
  [CASE_STATUS.W_PART]: 'Wait - parts',
  [CASE_STATUS.W_PASS]: 'Contact Customer for PassWord',
  [CASE_STATUS.W_PO]: 'Wait for Part Order',
  [CASE_STATUS.W_POOL]: 'Waiting for swap pool repair',
  [CASE_STATUS.W_SCRAP]: 'Wait-scrapped del.exchg.unit',
  [CASE_STATUS.W_SUPP]: 'Wait support from supplier',
  [CASE_STATUS.W_SWAP]: 'Wait - for swap unit',
  [CASE_STATUS.WAIT]: 'Wait',
  [CASE_STATUS.WDBL]: 'Wait - double activity',
  [CASE_STATUS.WEB]: 'Received in sesam',
  [CASE_STATUS.WEX_PA]: 'Wait-part from Supplier/extern',
  [CASE_STATUS.WIP]: 'Work In Progress',
  [CASE_STATUS.WPARTNER]: 'Awaiting Partner Response',
  [CASE_STATUS.WPOP]: 'Proof of purchase',
  [CASE_STATUS.WRMA]: 'Wait - RMA underlag',
  [CASE_STATUS.WTEK]: 'Wait technician',
  [CASE_STATUS.WTEST]: 'Wait - for test',
  [CASE_STATUS.WWS]: 'Wait - Workshop',
  [CASE_STATUS.WXC]: 'Wait Exchange'
};

export const CASE_STATUS_LABEL_LOWERCASE = _.mapKeys(CASE_STATUS_LABEL, (__, key) => key.toLowerCase());

export const ROUTING_DATA_MAPPING = [
  {
    newKey: 'group',
    oldKey: 'initialGroup',
    label: 'Group'
  },
  {
    newKey: 'person',
    oldKey: 'initialPerson',
    label: 'Person'
  },
  {
    newKey: 'district',
    oldKey: 'initialDistrict',
    label: 'District'
  },
  {
    newKey: 'persDep',
    oldKey: 'persDep',
    label: 'Department'
  },
  {
    newKey: 'serviceCode',
    oldKey: 'serviceCode',
    label: 'Service code'
  }
];

export const ROUTING_DATA_ERROR = {
  NO_DATA: 'no data'
};

export const SESAM_DB = {
  SESAM_16: 'sesam16',
  SESAM_31: 'sesam31',
  SESAM_56: 'sesam56'
};

export const TASK_TYPE = {
  ADD_COMMENT: 'AddCommentTask',
  ARRIVE: 'ArrivalTask',
  FORWARD: 'ForwardingTask',
  SAVE: 'SaveTask',
  SET_STATUS: 'SetStatusTask',
  RETURN: 'ReturnTask',
  REROUTE: 'RerouteTask',
  ADD_COST_PROPOSALS: 'AddCostProposalsTask'
};

export const TASK_TYPE_LABEL = {
  [TASK_TYPE.ADD_COMMENT]: 'Comment',
  [TASK_TYPE.ARRIVE]: 'Arrive',
  [TASK_TYPE.FORWARD]: 'Forward',
  [TASK_TYPE.SAVE]: 'Save',
  [TASK_TYPE.SET_STATUS]: 'Set status',
  [TASK_TYPE.RETURN]: 'Return',
  [TASK_TYPE.REROUTE]: 'Reroute',
  [TASK_TYPE.ADD_COST_PROPOSALS]: 'Add cost proposals'
};

export const TASK_RESULT = {
  CONTINUED: 'continued',
  DELAYED: 'delayed',
  ERROR: 'error',
  FAILED: 'failed',
  FINISHED: 'finished',
  QUEUED: 'queued',
  SKIPPED: 'skipped',
  SUCCESS: 'success'
};

export const TASK_RESULT_STATUS = {
  [TASK_RESULT.CONTINUED]: 'processing',
  [TASK_RESULT.DELAYED]: 'warning',
  [TASK_RESULT.ERROR]: 'error',
  [TASK_RESULT.FAILED]: 'error',
  [TASK_RESULT.FINISHED]: 'success',
  [TASK_RESULT.QUEUED]: 'processing',
  [TASK_RESULT.SKIPPED]: 'warning',
  [TASK_RESULT.SUCCESS]: 'success'
};

export const TASK_RESULT_COLOR = {
  [TASK_RESULT.CONTINUED]: 'blue',
  [TASK_RESULT.DELAYED]: '#faad14',
  [TASK_RESULT.ERROR]: 'red',
  [TASK_RESULT.FAILED]: 'red',
  [TASK_RESULT.FINISHED]: 'green',
  [TASK_RESULT.QUEUED]: 'blue',
  [TASK_RESULT.SKIPPED]: '#faad14',
  [TASK_RESULT.SUCCESS]: 'green'
};
