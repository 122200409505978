import { useRequest } from 'ahooks';
import { Button, Form, Input, Space } from 'antd';

import { SelectAutocomplete } from '@/components/SelectAutocomplete';
import { ENTITY } from '@/configs/entity';
import { EntitiesModel } from '@/models/entities';

import { ROUTING_DATA_MAPPING } from '../configs';

const TeamSelect = (props) => {
  const { data, loading } = useRequest(
    () =>
      EntitiesModel.getEntityValues(ENTITY.TEAM, {
        order: ['code']
      }),
    {
      formatResult: (result) => ({
        ...result,
        options: result?.data?.map(({ code, name }) => ({ label: `${code} (${name})`, value: code }))
      }),
      initialData: null
    }
  );

  return <SelectAutocomplete {...props} loading={loading} options={data?.options || []} skipMapping />;
};

const RoutingDataForm = ({ routingData, onClose, onFinish }) => {
  return (
    <Form initialValues={routingData.mergedData} onFinish={onFinish}>
      {ROUTING_DATA_MAPPING.map(({ newKey: key, label }) => (
        <Form.Item key={key} label={label} name={key}>
          {key === 'group' ? <TeamSelect /> : <Input />}
        </Form.Item>
      ))}
      <Form.Item>
        <Space>
          <Button type="primary" htmlType="submit">
            Save and close
          </Button>
          <Button onClick={onClose}>Close</Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export { RoutingDataForm };
