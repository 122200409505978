/* eslint-disable no-plusplus */
// prettier-ignore
import { AlertOutlined, CheckOutlined, CloseOutlined, DeleteOutlined, DownloadOutlined, ExclamationCircleFilled, FileAddOutlined, LockOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { useBoolean, useRequest } from 'ahooks';
// prettier-ignore
import { Badge, Button, Checkbox, DatePicker, Divider, Dropdown, Popconfirm, Radio, Space, Table, Tooltip } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import xlsx from 'xlsx-populate';

import { DateInline } from '@/components/DateInline';
import { SelectAutocomplete } from '@/components/SelectAutocomplete';
import { COLOR, PERMISSION } from '@/configs/general';
import { onTimeTrackerError } from '@/helpers/Alert';
import { userContext } from '@/helpers/userContext';
import { TimeTrackerModel } from '@/models/timetracker';

import {
  DAY_ABSENCE_LABEL,
  DAY_ABSENCE_LABEL_NO,
  HOURLY_ABSENCE_LABEL,
  HOURLY_ABSENCE_LABEL_NO,
  WAGE_LABEL
} from '../configs';

const { RangePicker } = DatePicker;

const REPORT_TYPE = {
  ABSENCES: 'absences',
  ACCOUNTING: 'accounting',
  ALL_EMPLOYEE_REPORTS: 'allEmployeeReports',
  FIRE_ALARM: 'fireAlarm',
  PLANNED_TIME: 'plannedWorkingTime',
  WAGE_TYPES: 'wageTypes'
};

const REPORT_TYPE_LABEL = {
  [REPORT_TYPE.ABSENCES]: 'Absences',
  [REPORT_TYPE.PLANNED_TIME]: 'Planned working time',
  [REPORT_TYPE.WAGE_TYPES]: 'Wage types'
};

const CODE_TO_LABEL = {
  SE: {
    ...DAY_ABSENCE_LABEL,
    ...HOURLY_ABSENCE_LABEL,
    ...WAGE_LABEL,
    '0700': 'Kompledighet, del av dag',
    '2301': 'Timlön',
    '2305': 'Mertid kontant',
    '2K71': 'Komptid 1:1,5',
    '2K72': 'Komptid 1:2',
    '2102': 'Övertid kontant enkel',
    '2103': 'Övertid kontant dubbel'
  },
  NO: {
    ...DAY_ABSENCE_LABEL_NO,
    ...HOURLY_ABSENCE_LABEL_NO,
    '0801': 'Øvertid 50%',
    '0802': 'Øvertid 100%'
  }
};

const downloadReport = async (xlsxReport, name) => {
  const blob = await xlsxReport.outputAsync();

  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    // If IE, you must uses a different method.
    window.navigator.msSaveOrOpenBlob(blob, `${name}.xlsx`);
  } else {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.href = url;
    a.download = `${name}.xlsx`;
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }
};

const generateFireAlarmReport = async (data) => {
  const report = await xlsx.fromBlankAsync();
  const sheet = report.sheet(0);
  sheet.row(1).style({ bold: true, horizontalAlignment: 'center' });
  sheet.column('A').width(21);
  sheet.column('B').width(18);
  sheet.cell('A1').value([['Name', 'Checked-in']]);

  if (!data?.length) {
    return report;
  }

  sheet
    .cell('A2')
    .value(data.map(({ name, latestCheckIn }) => [name, moment(latestCheckIn).format('YYYY-MM-DD HH:mm:ss')]));

  return report;
};

const generateZalarisReport = async (data) => {
  const report = await xlsx.fromBlankAsync();
  const sheet = report.sheet(0);

  let rowId = 1;
  let codeIndex = 0;
  let countryIndex = 0;

  for (let i = 0; i < data[0].length; i++) {
    if (data[0][i].toLowerCase() === 'code') {
      codeIndex = i;
    }

    if (data[0][i].toLowerCase() === 'country') {
      countryIndex = i;
    }
  }

  for (let i = 0; i < data.length; i++) {
    const dataRow = data[i];

    if (codeIndex && countryIndex) {
      const country = dataRow[countryIndex];
      const code = dataRow[codeIndex];
      dataRow.splice(codeIndex + 1, 0, i === 0 ? 'Code description' : CODE_TO_LABEL[country][code]);
    }

    sheet.cell(`A${rowId++}`).value([dataRow]);
  }

  return report;
};

const generateAccountingReports = async ([start, end]) => {
  const data = await TimeTrackerModel.generateAccountingReports({ start, end });
  const rangeString = `${start.format('YYYY-MM-DD')}-${end.format('YYYY-MM-DD')}`;

  const wageTypesReport = await generateZalarisReport(data.wageTypes);
  await downloadReport(wageTypesReport, `wage-types_${rangeString}.xlsx`);

  const absencesReport = await generateZalarisReport(data.absence);
  await downloadReport(absencesReport, `absences_${rangeString}.xlsx`);
};

const MONTH = {
  PAST_MONTH: 'pastMonth',
  CURRENT_MONTH: 'currentMonth'
};

const getColumns = (props) => {
  const { inAdminMode, onDelete: handleDelete, onDownload, onEdit, userId } = props;

  const handleApprove = (id) => () => onEdit(id, { approvedBy: userId });
  const handleReject = (id) => () => onEdit(id, { approvedBy: null });
  // const confirmFinalize = (id) => () => onEdit(id, { finalized: true });

  const handleFinalizeAndApprove = (id) => async () => {
    await onEdit(id, { finalized: true, approvedBy: userId });
  };

  const handleFinishReport = (id) => async () => {
    await onEdit(id, { finalized: true, approvedBy: userId, sentAt: moment().toISOString() });
  };

  const reportsMenuItems = [
    { key: REPORT_TYPE.PLANNED_TIME, label: REPORT_TYPE_LABEL[REPORT_TYPE.PLANNED_TIME] },
    { key: REPORT_TYPE.WAGE_TYPES, label: REPORT_TYPE_LABEL[REPORT_TYPE.WAGE_TYPES] },
    { key: REPORT_TYPE.ABSENCES, label: REPORT_TYPE_LABEL[REPORT_TYPE.ABSENCES] }
  ];

  return [
    {
      title: 'Id',
      key: 'id',
      dataIndex: 'id'
    },
    {
      title: 'Month',
      key: 'month',
      render: (__, { month }) => moment(month).format('MMM, YYYY')
    },
    {
      title: 'Status',
      key: 'status',
      render: (__, { creator, finalized, approvedBy, sentAt }) => {
        if (!finalized) {
          return <Badge status="default" text="Draft" />;
        }

        if (!approvedBy) {
          return <Badge status="processing" text="Attested" />;
        }

        if (!sentAt) {
          return <Badge status="processing" text="Scheduled for sending to Zalaris" />;
        }

        return <Badge status="success" text={creator.country === 'SE' ? 'Received by Zalaris' : 'Attested'} />;
      }
    },
    inAdminMode
      ? {
          title: 'Attested by',
          key: 'month',
          render: (__, { creator }) => `${creator?.firstName} ${creator?.lastName}`
        }
      : null,
    {
      title: 'Finalized by',
      key: 'approvedBy',
      render: (__, { approver, approvedBy }) => (approvedBy ? `${approver?.firstName} ${approver?.lastName}` : '-')
    },
    {
      title: 'Created at',
      key: 'month',
      render: (__, { createdAt }) => <DateInline value={createdAt} />
    },
    {
      key: 'actions',
      width: 32,
      render: (__, { id, finalized, creator, approvedBy, sentAt }) => (
        <Space>
          <Dropdown
            menu={{
              items: reportsMenuItems,
              onClick: ({ key: type }) => onDownload(id, type)
            }}
            placement="bottomLeft"
          >
            <Button icon={<DownloadOutlined />} />
          </Dropdown>
          {!finalized &&
            (creator.country === 'SE' ? (
              <Popconfirm
                title="Varning!!!"
                description={
                  <>
                    <p>
                      Jag bekräftar att jag har gått igenom samtliga anställda i mitt team och kontrollerat att: <br />
                      - samtliga schemalagda dagar innehåller riktiga tidstämplingar <br />- det inte finns schemalagda
                      dagar som saknar redovisad tid (markeras i kalendern med blixtljusikonen{' '}
                      <span style={{ padding: '4px', background: '#ff4d4f' }}>
                        <AlertOutlined style={{ color: '#fff', fontSize: '16px' }} />
                      </span>
                      )
                    </p>
                    <p>
                      Jag FÖRSTÅR att om det finns schemalagda dagar utan tidstämplingar kan den påverkade anställda få{' '}
                      <b>löneavdrag</b>!!!
                    </p>
                  </>
                }
                // onConfirm={confirmFinalize(id)}
                onConfirm={handleFinalizeAndApprove(id)}
                onCancel={handleDelete(id)}
                okText="Ja, jag är säker och attesterar"
                cancelText="Nej, jag ska dubbelkolla"
                okButtonProps={{ size: 'medium', icon: <ExclamationCircleFilled /> }}
                cancelButtonProps={{ size: 'medium' }}
                placement="topRight"
              >
                <Button icon={<LockOutlined />} type="primary">
                  Attest
                </Button>
              </Popconfirm>
            ) : (
              <Button icon={<LockOutlined />} type="primary" onClick={handleFinishReport(id)}>
                Attest
              </Button>
            ))}
          {/* {!sentAt && finalized && inAdminMode && ( */}
          {!sentAt && finalized && (
            <>
              {!approvedBy && (
                <Button icon={<CheckOutlined />} onClick={handleApprove(id)} type="primary">
                  Ready for Zalaris
                </Button>
              )}
              {approvedBy && (
                <Button icon={<CloseOutlined />} onClick={handleReject(id)} type="primary" ghost>
                  WAIT, NOT READY!
                </Button>
              )}
            </>
          )}
          {/* {!sentAt && (!approvedBy || inAdminMode) && ( */}
          {!sentAt && !approvedBy && (
            <Button icon={<DeleteOutlined />} onClick={handleDelete(id)} danger ghost>
              Remove
            </Button>
          )}
        </Space>
      )
    }
  ].filter(Boolean);
};

const mapSupervisorsToOptions = (supervisors = []) =>
  supervisors.map((timeTrackerUser) => ({
    value: timeTrackerUser.userId,
    label: `${timeTrackerUser.user.firstName} ${timeTrackerUser.user.lastName}`
  }));

const TimeTrackerReports = (props) => {
  const {
    hasPermission,
    user: {
      id: userId,
      timeTrackerUser: { locationId }
    }
  } = useContext(userContext);
  const { supervisors } = props;
  const [inAdminMode, { toggle: setAdminMode }] = useBoolean(false);
  const [selectedMonth, selectMonth] = useState(MONTH.PAST_MONTH);
  const [selectedUser, selectUser] = useState(null);
  const [reportSubType, setReportSubType] = useState(REPORT_TYPE.PLANNED_TIME);
  const [accountingReportsRange, setAccountingReportRange] = useState([
    moment().startOf('week').subtract(1, 'week'),
    moment().endOf('week').subtract(1, 'week')
  ]);
  const [supervisorsAsOptions, setSupervisorsAsOptions] = useState(mapSupervisorsToOptions(supervisors));
  const [isSupervisorsFiltered, setIsSupervisorsFiltered] = useState(false);
  const isLocationSupervisor = hasPermission(PERMISSION.TIMETRACKER_LOCATION_SUPERVISOR);
  const isAdmin = hasPermission(PERMISSION.TIMETRACKER_ADMIN);
  const [reportType, setReportType] = useState(
    isLocationSupervisor ? REPORT_TYPE.FIRE_ALARM : REPORT_TYPE.ALL_EMPLOYEE_REPORTS
  );

  const getSupervisorsWithoutReports = useRequest(
    () => TimeTrackerModel.getUsersWithoutReports(supervisors.map((supervisor) => supervisor.userId)),
    {
      onSuccess: (userIds) => {
        const mappedUserIds = userIds.map((value) => ({ value }));
        setSupervisorsAsOptions(_.intersectionBy(mapSupervisorsToOptions(supervisors), mappedUserIds, 'value'));
      },
      onError: onTimeTrackerError,
      manual: true
    }
  );
  const getReports = useRequest(() => TimeTrackerModel.getReportsData(inAdminMode ? {} : { userId }), {
    initialData: [],
    onSuccess: () => {
      if (isSupervisorsFiltered) {
        selectUser(null);
        getSupervisorsWithoutReports.refresh();
      }
    },
    onError: onTimeTrackerError,
    refreshDeps: [inAdminMode]
  });
  const generateReport = useRequest(
    async (id, type) => {
      const month = moment().startOf('month');

      if (selectedMonth === MONTH.PAST_MONTH) {
        month.subtract(1, 'month');
      }

      const params = {
        ...(id ? { id } : {})
      };

      if (reportType === REPORT_TYPE.FIRE_ALARM) {
        params.locationId = locationId;
      } else {
        params.userId = selectedUser || userId;
        params.month = month.format('YYYY-MM-DD[T]HH:mm:[00]');
      }

      if (!id && reportType === REPORT_TYPE.ALL_EMPLOYEE_REPORTS && selectedMonth !== MONTH.CURRENT_MONTH) {
        await TimeTrackerModel.generateZalarisReports(params);
        getReports.refresh();

        return;
      }

      const generatedReportType =
        type || (reportType === REPORT_TYPE.ALL_EMPLOYEE_REPORTS ? reportSubType : reportType);

      if (generatedReportType === REPORT_TYPE.ACCOUNTING) {
        await generateAccountingReports(accountingReportsRange);

        return;
      }

      const getData = {
        [REPORT_TYPE.FIRE_ALARM]: TimeTrackerModel.getFireAlarmReportData,
        [REPORT_TYPE.PLANNED_TIME]: TimeTrackerModel.generatePlannedWorkingTimeReport,
        [REPORT_TYPE.WAGE_TYPES]: TimeTrackerModel.generateWageTypesReport,
        [REPORT_TYPE.ABSENCES]: TimeTrackerModel.generateAbsencesReport
      }[generatedReportType];
      const generateXLSXReport = {
        [REPORT_TYPE.FIRE_ALARM]: generateFireAlarmReport,
        [REPORT_TYPE.PLANNED_TIME]: generateZalarisReport,
        [REPORT_TYPE.WAGE_TYPES]: generateZalarisReport,
        [REPORT_TYPE.ABSENCES]: generateZalarisReport
      }[generatedReportType];
      const reportName = {
        [REPORT_TYPE.FIRE_ALARM]: `fire-alarm-report_${moment().format('YYYY-MM-DD_HH-mm-ss')}`,
        [REPORT_TYPE.PLANNED_TIME]: `planned-working-time_${id ?? moment().format('YYYY-MM')}`,
        [REPORT_TYPE.WAGE_TYPES]: `wage-types_${id ?? moment().format('YYYY-MM')}`,
        [REPORT_TYPE.ABSENCES]: `absences_${id ?? moment().format('YYYY-MM')}`
      }[generatedReportType];

      const data = await getData(params);
      const report = await generateXLSXReport(data);
      await downloadReport(report, reportName);

      return data;
    },
    { onError: onTimeTrackerError, manual: true }
  );
  const editReport = useRequest((id, data) => TimeTrackerModel.editReport(id, data), {
    onSuccess: getReports.refresh,
    onError: onTimeTrackerError,
    manual: true
  });
  const deleteReport = useRequest((id) => TimeTrackerModel.deleteReport(id), {
    onSuccess: getReports.refresh,
    onError: onTimeTrackerError,
    manual: true
  });
  const handleReportTypeChange = (e) => setReportType(e.target.value);
  const handleReportSubTypeChange = (e) => setReportSubType(e.target.value);
  const handleAccountingReportsRangeChange = (range) => setAccountingReportRange(range);

  const handleAdminModeChanged = (e) => {
    if (!e.target.value) {
      selectUser(null);
    }

    setAdminMode(e.target.value);
  };
  const handleSelectMonth = (e) => selectMonth(e.target.value);
  const handleDeleteReport = (id) => () => deleteReport.run(id);
  const handleGenerateReport = () => generateReport.run();
  const handleDownloadReport = (id, type) => generateReport.run(id, type);

  const handleChangeFilterSupervisors = (e) => {
    setIsSupervisorsFiltered(e.target.checked);
    selectUser(null);

    if (e.target.checked) {
      getSupervisorsWithoutReports.run();
    } else {
      setSupervisorsAsOptions(mapSupervisorsToOptions(supervisors));
    }
  };
  const [columns, setColumns] = useState(
    getColumns({
      inAdminMode,
      onDelete: handleDeleteReport,
      onDownload: handleDownloadReport,
      onEdit: editReport.run,
      userId
    })
  );

  useEffect(() => setSupervisorsAsOptions(mapSupervisorsToOptions(supervisors)), [supervisors.length]);

  useEffect(
    () =>
      setColumns(
        getColumns({
          inAdminMode,
          onDelete: handleDeleteReport,
          onDownload: handleDownloadReport,
          onEdit: editReport.run,
          userId
        })
      ),
    [inAdminMode]
  );

  const downloadOnly = reportType !== REPORT_TYPE.ALL_EMPLOYEE_REPORTS || selectedMonth === MONTH.CURRENT_MONTH;

  return (
    <>
      <Space size="large" direction="vertical">
        <Radio.Group value={reportType} onChange={handleReportTypeChange}>
          {isLocationSupervisor && <Radio value={REPORT_TYPE.FIRE_ALARM}>Fire alarm report</Radio>}
          <Radio value={REPORT_TYPE.ALL_EMPLOYEE_REPORTS}>Zalaris reports</Radio>
          {isAdmin && <Radio value={REPORT_TYPE.ACCOUNTING}>Accounting reports</Radio>}
        </Radio.Group>
        {reportType === REPORT_TYPE.ALL_EMPLOYEE_REPORTS && (
          <>
            <Space size="middle">
              <Radio.Group onChange={handleSelectMonth} value={selectedMonth} buttonStyle="solid">
                <Radio.Button value={MONTH.PAST_MONTH}>
                  Past month ({moment().subtract(1, 'month').format('MMMM')})
                </Radio.Button>
                <Radio.Button value={MONTH.CURRENT_MONTH}>Current month ({moment().format('MMMM')})</Radio.Button>
              </Radio.Group>
              {isAdmin && (
                <Radio.Group value={inAdminMode} onChange={handleAdminModeChanged} buttonStyle="solid">
                  <Radio.Button value={false}>Supervisor mode</Radio.Button>
                  <Radio.Button value>Admin mode</Radio.Button>
                </Radio.Group>
              )}
              {selectedMonth === MONTH.CURRENT_MONTH && (
                <Radio.Group value={reportSubType} onChange={handleReportSubTypeChange}>
                  <Radio value={REPORT_TYPE.PLANNED_TIME}>{REPORT_TYPE_LABEL[REPORT_TYPE.PLANNED_TIME]}</Radio>
                  <Radio value={REPORT_TYPE.WAGE_TYPES}>{REPORT_TYPE_LABEL[REPORT_TYPE.WAGE_TYPES]}</Radio>
                  <Radio value={REPORT_TYPE.ABSENCES}>{REPORT_TYPE_LABEL[REPORT_TYPE.ABSENCES]}</Radio>
                </Radio.Group>
              )}
            </Space>
            {inAdminMode && (
              <Space>
                <Tooltip title="Select team leader to generate reports for other team (for you, if nothing selected)">
                  <span>
                    Team leader <QuestionCircleOutlined style={{ color: COLOR.BLUE }} /> :
                  </span>
                </Tooltip>
                {Boolean(supervisorsAsOptions.length) && (
                  <SelectAutocomplete
                    options={supervisorsAsOptions}
                    skipMapping
                    style={{ width: 200 }}
                    allowClear
                    value={selectedUser}
                    onChange={selectUser}
                    loading={getSupervisorsWithoutReports.loading}
                  />
                )}
                <Checkbox onChange={handleChangeFilterSupervisors} disabled={getSupervisorsWithoutReports.loading}>
                  Show team leaders without reports only
                </Checkbox>
              </Space>
            )}
          </>
        )}
        {reportType === REPORT_TYPE.ACCOUNTING && (
          <RangePicker
            allowClear={false}
            defaultValue={accountingReportsRange}
            onChange={handleAccountingReportsRangeChange}
            style={{ width: 220 }}
          />
        )}
        <Space>
          <Button
            type="primary"
            icon={downloadOnly ? <DownloadOutlined /> : <FileAddOutlined />}
            onClick={handleGenerateReport}
            loading={generateReport.loading}
          >
            {downloadOnly ? 'Preview report' : 'Generate reports for attestation'}
          </Button>
        </Space>
        <Divider dashed>Zalaris reports</Divider>
        <Table
          columns={columns}
          dataSource={getReports.data}
          rowKey="id"
          loading={getReports.loading || deleteReport.loading}
          pagination={false}
        />
      </Space>
    </>
  );
};

export { TimeTrackerReports };
