import { RequestModel } from './request';

const UserModel = {
  async login(login, password) {
    const result = await RequestModel.postJsonData('/api/v1/login', {
      body: {
        login,
        password
      }
    });

    return result;
  },

  async logout() {
    await RequestModel.postJson('/api/v1/logout');
  },

  async loadCurrentUser() {
    try {
      const result = await RequestModel.getJsonData('/api/v1/current_user');

      return result;
    } catch (e) {
      if (e instanceof RequestModel.ForbiddenError) {
        return null;
      }

      throw e;
    }
  }
};

export { UserModel };
