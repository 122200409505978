import { CheckOutlined, TagOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { useDebounceFn } from 'ahooks';
import {
  Affix,
  Button,
  Card,
  Col,
  Descriptions,
  Divider,
  Empty,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Spin,
  Steps
} from 'antd';
import invariant from 'invariant';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Block } from '@/components/Block';
import { PrintButton } from '@/components/PrintButton';
import { PRINTER_TYPE } from '@/configs/entity';
import { ROUTE } from '@/configs/general';
import { SHIPMENT_ACTIVITY_STATUS } from '@/configs/shipment';
import { showError } from '@/helpers/Alert';
import { ShipmentModel } from '@/models/shipment';

import { ShipmentItem } from './ShipmentItem';

const FILTER_INPUT_ID = 'export-filter-input';

const ViewShipment = (props) => {
  const { data, onRefresh } = props;
  const [itemFilter, setItemFilter] = useState('');
  const [itemFilterMode, setItemFilterMode] = useState('process');
  const history = useHistory();

  const focusOnFilter = () => {
    if (!document.querySelector(`#${FILTER_INPUT_ID}`)) {
      return;
    }

    setTimeout(() => {
      document.querySelector(`#${FILTER_INPUT_ID}`).focus();
    }, 500);
  };

  useEffect(focusOnFilter, [data?.guid]);

  const showItemDetails = (item) => () => {
    invariant(item, 'item is not set');
    history.push(`${ROUTE.LOGISTICS_ACTIVITY}/${item.guid}`);
  };

  const { run: findItem } = useDebounceFn(
    (value) => {
      const item = ShipmentModel.findItem(data, value);

      if (item) {
        showItemDetails(item)();
      } else {
        showError(`Item ${value} is not assigned to this shipment`, { displayTime: 5 });
      }
    },
    { wait: 500 }
  );

  const handleToolbarInput = (type, value) => {
    window.scrollTo(0, 0);
    setItemFilter(value);
    setItemFilterMode(type);

    if (type !== 'process' || !value.length) {
      return;
    }

    findItem(value);
  };

  const handleFilterMode = (val) => handleToolbarInput(val, itemFilter);

  const handleFilter = (e) => handleToolbarInput(itemFilterMode, e.target.value);

  const completeShipment = async () => {
    invariant(data, 'shipment is not set');

    try {
      await ShipmentModel.finalizeShipment(data.guid);
      onRefresh();
    } catch (e) {
      showError(e.message);
    }
  };

  const handleComplete = () => {
    Modal.confirm({
      title: 'Confirmation',
      content: 'Are you sure that you have processed all items in this shipment?',
      onOk: completeShipment,
      onCancel() {}
    });
  };

  const checkItemPassFilter = (item) => {
    const val = itemFilter;
    const activity = item?.customData?.activityNumber ?? '';

    return item.guid === val || activity.toString().includes(val);
  };

  const filterPackages = (packages) => {
    invariant(packages, 'packages is not set');

    if (itemFilterMode !== 'filter' || itemFilter === '') {
      return packages;
    }

    const newPackages = [];

    for (const pack of packages) {
      const newPack = { ...pack, items: [] };

      for (const item of pack.items) {
        if (checkItemPassFilter(item)) {
          newPack.items.push(item);
        }
      }

      if (newPack.items.length) {
        newPackages.push(newPack);
      }
    }

    return newPackages;
  };

  if (!data) {
    return (
      <Spin>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      </Spin>
    );
  }

  const packages = filterPackages(data.packages);
  const canComplete = ShipmentModel.canCompleteShipment(data);
  const isOperational = !data.finalized;
  const filterPlaceholders = {
    filter: 'Filter items by guid/activity',
    process: 'Process items by guid/activity'
  };

  const shipmentType = data?.customData?.type || '';
  const shipmentNumber = data?.shipmentData?.shipmentNumber;
  const packageNumber = data?.shipmentData?.packages?.[0]?.packageNumber;

  const receivedByCarrierAt = data?.receivedByCarrierAt
    ? moment(data?.receivedByCarrierAt).format('YYYY-MM-DD HH:mm')
    : '';
  const deliveredByCarrierAt = data?.deliveredByCarrierAt
    ? moment(data?.deliveredByCarrierAt).format('YYYY-MM-DD HH:mm')
    : '';
  const createdAt = moment(data?.createdAt).format('YYYY-MM-DD HH:mm');

  const isProcessable = !shipmentType.includes('forward', 'return', 'push');
  const descriptionColumns =
    3 +
    (shipmentNumber ? 1 : 0) +
    (packageNumber && packageNumber !== shipmentNumber ? 1 : 0) +
    (isOperational ? 0 : 1);

  let currentStep = 0;

  if (receivedByCarrierAt !== '') {
    currentStep = 1;
  }

  if (deliveredByCarrierAt !== '') {
    currentStep = 2;
  }

  return (
    <Block>
      <Affix offsetTop={0} className="activity-actions-panel activity-actions-panel-top">
        <Card className="ant-card-full-width">
          <Input
            id={FILTER_INPUT_ID}
            className="export-filter"
            addonBefore={
              <Select value={itemFilterMode} onChange={handleFilterMode}>
                <Select.Option value="process">Process</Select.Option>
                <Select.Option value="filter">Filter</Select.Option>
              </Select>
            }
            addonAfter={
              (isOperational || canComplete) && (
                <Button.Group>
                  {canComplete && (
                    <Button onClick={handleComplete} icon={<CheckOutlined />} type="primary">
                      Complete
                    </Button>
                  )}
                </Button.Group>
              )
            }
            placeholder={filterPlaceholders[itemFilterMode]}
            value={itemFilter}
            onChange={handleFilter}
          />
        </Card>
      </Affix>
      <div className="shipment">
        <Row gutter={16}>
          <Col span={24}>
            <Descriptions layout="vertical" bordered column={descriptionColumns}>
              <Descriptions.Item label="Sender">
                <AddressInfo data={data.sender} />
              </Descriptions.Item>
              <Descriptions.Item label="Receiver">
                <AddressInfo data={data.receiver} />
              </Descriptions.Item>
              <Descriptions.Item label="Shipment type">{data.shipmentType?.name || ''}</Descriptions.Item>
              {shipmentNumber && <Descriptions.Item label="Shipment number">{shipmentNumber}</Descriptions.Item>}
              {packageNumber && packageNumber !== shipmentNumber && (
                <Descriptions.Item label="Package number">{packageNumber}</Descriptions.Item>
              )}
              {!isOperational && (
                <Descriptions.Item label="Actions">
                  <Space direction="vertical">
                    <Space className="ant-space-first-item-flex">
                      <Button href={ShipmentModel.getLabelUrl(data.guid)} target="_blank" rel="noopener noreferrer">
                        <TagOutlined />
                        Label
                      </Button>
                      <PrintButton
                        pdfUrl={`https://senderella.io/v1/shipment/${data.guid}/label?format=pdf`}
                        type={PRINTER_TYPE.BIG_LABEL}
                      />
                    </Space>
                    <Space className="ant-space-first-item-flex">
                      <Button
                        href={`https://senderella.io/v1/shipment/${data.guid}/picking-list`}
                        target="_blank"
                        rel="noopener noreferrer"
                        block
                      >
                        <UnorderedListOutlined />
                        Picking list
                      </Button>
                      <PrintButton
                        pdfUrl={`https://senderella.io/v1/shipment/${data.guid}/picking-list?format=pdf`}
                        type={PRINTER_TYPE.A4}
                      />
                    </Space>
                  </Space>
                </Descriptions.Item>
              )}
            </Descriptions>
          </Col>
        </Row>
        {(shipmentNumber || packageNumber) && (
          <Steps
            className="shipment-steps-component"
            current={currentStep}
            items={[
              {
                title: 'Booked',
                description: createdAt
              },
              {
                title: 'Dispatched',
                description: receivedByCarrierAt
              },
              {
                title: 'Delivered',
                description: deliveredByCarrierAt
              }
            ]}
          />
        )}
        <Row gutter={16}>
          {isProcessable && (
            <>
              <Col span={12}>
                <Group
                  packages={packages}
                  groupName={SHIPMENT_ACTIVITY_STATUS.UNPROCESSED}
                  data={data}
                  showItemDetails={showItemDetails}
                />
              </Col>
              <Col span={12}>
                <Group
                  packages={packages}
                  groupName={SHIPMENT_ACTIVITY_STATUS.PROCESSED}
                  data={data}
                  showItemDetails={showItemDetails}
                />
              </Col>
            </>
          )}
          {!isProcessable && (
            <Col span={24}>
              <Group
                packages={packages}
                groupName={SHIPMENT_ACTIVITY_STATUS.ALL}
                data={data}
                showItemDetails={showItemDetails}
              />
            </Col>
          )}
        </Row>
      </div>
    </Block>
  );
};

const AddressInfo = (props) => {
  const { data } = props;

  return (
    <>
      {data.name}
      <br />
      {data.address} {data.city}, {data.postalCode}, {data.countryCode}
    </>
  );
};

const Group = (props) => {
  const { data, groupName, packages, showItemDetails } = props;
  const sections = ShipmentModel.getItemsByType(packages, groupName);
  const namesByGroup = {
    [SHIPMENT_ACTIVITY_STATUS.ALL]: 'Items',
    [SHIPMENT_ACTIVITY_STATUS.UNPROCESSED]: 'Unprocessed',
    [SHIPMENT_ACTIVITY_STATUS.PROCESSED]: 'Processed'
  };

  return (
    <Card title={namesByGroup[groupName]}>
      {sections.map(({ title, data: sectionData }) => (
        <React.Fragment key={title}>
          <Divider plain>{title}</Divider>
          <Space direction="vertical" size="middle">
            {sectionData.map((item) => (
              <ShipmentItem
                key={item.guid}
                item={item}
                spinning={false}
                hideStatus={groupName === SHIPMENT_ACTIVITY_STATUS.ALL}
                isProcessed={groupName === SHIPMENT_ACTIVITY_STATUS.ALL || ShipmentModel.itemIsProcessed(item)}
                canProcess={!data.isFinalized}
                onToggleArrived={showItemDetails(item)}
              />
            ))}
          </Space>
        </React.Fragment>
      ))}
    </Card>
  );
};

export { ViewShipment };
