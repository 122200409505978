// prettier-ignore
import { CloseCircleTwoTone, HistoryOutlined, LoadingOutlined, PlayCircleTwoTone, PlusCircleOutlined, SaveOutlined, SyncOutlined, WalletOutlined, WarningFilled } from '@ant-design/icons';
import { useBoolean, useRequest } from 'ahooks';
import { Button, DatePicker, Divider, Modal, Popover, Row, Spin } from 'antd';
import moment from 'moment';
import { useContext } from 'react';

import { CheckInInline } from '@/components/CheckInInline';
import { COLOR, PERMISSION } from '@/configs/general';
import { showError } from '@/helpers/Alert';
import { defer } from '@/helpers/defer';
import { userContext } from '@/helpers/userContext';
import { TimeTrackerModel } from '@/models/timetracker';

import { WORKTYPE } from './configs';

const TYPE = {
  CHECKIN: 'checkin',
  CHECKOUT: 'checkout'
};

// It's easier than using useState because Modal.info will be out of Widget context
let customTimestamp = null;

const TimeTrackerMenuWidget = () => {
  const [isLoading, { toggle: toggleLoading }] = useBoolean(false);
  const { hasPermission, user } = useContext(userContext);

  if (!user?.timeTrackerUser?.userId) {
    return null;
  }

  const { timeTrackerUser } = user;

  const isCheckInOutTester = hasPermission(PERMISSION.TIMETRACKER_CHECK_IN_OUT_TESTER);
  const isHourlyPaid = TimeTrackerModel.isHourlyPaidEmployee(timeTrackerUser);
  const isEmployeeWithDefaultSchema = !TimeTrackerModel.isEmployeeWithReducedSchema(timeTrackerUser);

  if (timeTrackerUser.error) {
    return [
      {
        key: 'timetracker-widget',
        icon: <WarningFilled style={{ color: COLOR.RED }} />,
        children: <span style={{ color: COLOR.RED }}>Reload page</span>
      }
    ];
  }

  const handleCustomTimestampSet = (date) => {
    customTimestamp = date;
  };

  const checkIn = async () => {
    const timestamp = (customTimestamp || moment()).clone().utc().format();
    let success = true;

    try {
      await TimeTrackerModel.checkIn(timeTrackerUser.userId, timestamp);
    } catch (e) {
      showError(e?.message, { displayTime: 5, messagePrefix: 'Time tracker error: ' });
      success = false;
    }

    return success;
  };

  const checkOut = async (data) => {
    const timestamp = (customTimestamp || moment()).clone().utc().format();
    const workType = data?.workType || WORKTYPE.REGULAR;
    const addMertid = data?.addMertid || null;
    const subtractCompensationMinutes = data?.subtractCompensationMinutes || null;
    let success = true;

    try {
      await TimeTrackerModel.checkOut(timeTrackerUser.userId, timestamp, {
        workType,
        addMertid,
        subtractCompensationMinutes
      });
    } catch (e) {
      showError(e?.message, { displayTime: 5, messagePrefix: 'Time tracker error: ' });
      success = false;
    }

    return success;
  };

  const handleModalChoice = (refetchDataDefer, data) => async () => {
    let result;

    if (data?.type) {
      if (data.type === TYPE.CHECKIN) {
        result = await checkIn();
      } else {
        result = await checkOut(data);
      }

      refetchDataDefer.resolve(result);
    } else {
      refetchDataDefer.resolve(false);
    }

    Modal.destroyAll();
  };

  const toggleCheckStatusAfterFetchInfoSe = async (data, params) => {
    const [prevInfo, refresh] = params;

    const { checkedInAt, compensationTimeSaldo, compensationTimeUsed, flexTime, possibleMertid, isRegularDay } = data;
    const checkedIn = TimeTrackerModel.checkCheckedIn(checkedInAt);

    if (!prevInfo || checkedIn !== TimeTrackerModel.checkCheckedIn(prevInfo.checkedInAt)) {
      return;
    }

    toggleLoading();

    const refetchDataDefer = defer();

    if (!checkedIn) {
      if (compensationTimeUsed > 0) {
        showError("You can't check-in, because you already had finished the workday", {
          messagePrefix: 'Time tracker error: '
        });

        refetchDataDefer.resolve(false);
      } else {
        const result = await checkIn();
        refetchDataDefer.resolve(result);
      }
    } else if (isHourlyPaid) {
      if (!isRegularDay || flexTime >= 30) {
        Modal.info({
          className: 'ant-modal-hide-footer',
          centered: true,
          title: 'Checkout',
          content: (
            <>
              <Row>
                <span>
                  Congrats! Your worked <strong>{TimeTrackerModel.durationToText(flexTime)}</strong> extra today.
                </span>
              </Row>
              <Row>
                <Button
                  block
                  icon={<PlusCircleOutlined />}
                  onClick={handleModalChoice(refetchDataDefer, {
                    type: TYPE.CHECKOUT,
                    workType: WORKTYPE.REGULAR
                  })}
                >
                  Add to my regular time
                </Button>
                <Button
                  block
                  icon={<SaveOutlined />}
                  onClick={handleModalChoice(refetchDataDefer, {
                    type: TYPE.CHECKOUT,
                    workType: WORKTYPE.COMPENSATION_TIME
                  })}
                >
                  Request compensation time
                </Button>
                <Button
                  block
                  icon={<WalletOutlined />}
                  onClick={handleModalChoice(refetchDataDefer, {
                    type: TYPE.CHECKOUT,
                    workType: WORKTYPE.PAID_OVERTIME
                  })}
                >
                  Request paid overtime
                </Button>
                <Button
                  block
                  icon={<PlayCircleTwoTone twoToneColor={COLOR.GREEN} />}
                  onClick={handleModalChoice(refetchDataDefer)}
                >
                  Continue work instead
                </Button>
              </Row>
            </>
          ),
          maskClosable: true,
          destroyOnClose: true,
          onCancel: handleModalChoice(refetchDataDefer)
        });
      } else if (flexTime < 0 && compensationTimeSaldo) {
        Modal.info({
          className: 'ant-modal-hide-footer',
          centered: true,
          title: 'Checkout',
          content: (
            <>
              <Row>
                <span>
                  You worked <strong>{TimeTrackerModel.durationToText(Math.abs(flexTime))}</strong> less today.
                  <br />
                  Current compensation time saldo{' '}
                  <strong>{TimeTrackerModel.durationToText(compensationTimeSaldo)}</strong>.
                </span>
              </Row>
              <Row>
                <Button
                  block
                  icon={<CloseCircleTwoTone twoToneColor={COLOR.VOLCANO} />}
                  onClick={handleModalChoice(refetchDataDefer, {
                    type: TYPE.CHECKOUT,
                    workType: WORKTYPE.REGULAR
                  })}
                >
                  I&apos;m going to lunch or finishing workday
                </Button>
                <Button
                  block
                  icon={<HistoryOutlined />}
                  onClick={handleModalChoice(refetchDataDefer, {
                    type: TYPE.CHECKOUT,
                    workType: WORKTYPE.REGULAR,
                    subtractCompensationMinutes: Math.min(-flexTime, compensationTimeSaldo)
                  })}
                >
                  Withdraw from my compensation time
                </Button>
                <Button
                  block
                  icon={<PlayCircleTwoTone twoToneColor={COLOR.GREEN} />}
                  onClick={handleModalChoice(refetchDataDefer)}
                >
                  Continue work instead
                </Button>
              </Row>
            </>
          ),
          maskClosable: true,
          destroyOnClose: true,
          onCancel: handleModalChoice(refetchDataDefer)
        });
      } else {
        const result = await checkOut();

        refetchDataDefer.resolve(result);
      }
    } else if (!isRegularDay || (isEmployeeWithDefaultSchema && flexTime >= 30)) {
      Modal.info({
        className: 'ant-modal-hide-footer',
        centered: true,
        title: 'Checkout',
        content: (
          <>
            <Row>
              <span>
                Congrats! Your worked <strong>{TimeTrackerModel.durationToText(flexTime)}</strong> extra today.
              </span>
            </Row>
            <Row>
              <Button
                block
                icon={<PlusCircleOutlined />}
                onClick={handleModalChoice(refetchDataDefer, {
                  type: TYPE.CHECKOUT,
                  workType: WORKTYPE.FLEX_TIME
                })}
              >
                Add to my flex-time
              </Button>
              <Button
                block
                icon={<SaveOutlined />}
                onClick={handleModalChoice(refetchDataDefer, {
                  type: TYPE.CHECKOUT,
                  workType: WORKTYPE.COMPENSATION_TIME
                })}
              >
                Request compensation time
              </Button>
              <Button
                block
                icon={<WalletOutlined />}
                onClick={handleModalChoice(refetchDataDefer, {
                  type: TYPE.CHECKOUT,
                  workType: WORKTYPE.PAID_OVERTIME
                })}
              >
                Request paid overtime
              </Button>
              <Button
                block
                icon={<PlayCircleTwoTone twoToneColor={COLOR.GREEN} />}
                onClick={handleModalChoice(refetchDataDefer)}
              >
                Continue work instead
              </Button>
            </Row>
          </>
        ),
        maskClosable: true,
        destroyOnClose: true,
        onCancel: handleModalChoice(refetchDataDefer)
      });
    } else if (flexTime < 0 && compensationTimeSaldo) {
      Modal.info({
        className: 'ant-modal-hide-footer',
        centered: true,
        title: 'Checkout',
        content: (
          <>
            <Row>
              <span>
                You worked <strong>{TimeTrackerModel.durationToText(Math.abs(flexTime))}</strong> less today.
                <br />
                Current compensation time saldo{' '}
                <strong>{TimeTrackerModel.durationToText(compensationTimeSaldo)}</strong>.
              </span>
            </Row>
            <Row>
              <Button
                block
                icon={<CloseCircleTwoTone twoToneColor={COLOR.VOLCANO} />}
                onClick={handleModalChoice(refetchDataDefer, {
                  type: TYPE.CHECKOUT,
                  workType: WORKTYPE.REGULAR
                })}
              >
                I&apos;m going to lunch or finishing workday
              </Button>
              <Button
                block
                icon={<HistoryOutlined />}
                onClick={handleModalChoice(refetchDataDefer, {
                  type: TYPE.CHECKOUT,
                  workType: WORKTYPE.REGULAR,
                  subtractCompensationMinutes: Math.min(-flexTime, compensationTimeSaldo)
                })}
              >
                Withdraw from my compensation time
              </Button>
              <Button
                block
                icon={<PlayCircleTwoTone twoToneColor={COLOR.GREEN} />}
                onClick={handleModalChoice(refetchDataDefer)}
              >
                Continue work instead
              </Button>
            </Row>
          </>
        ),
        maskClosable: true,
        destroyOnClose: true,
        onCancel: handleModalChoice(refetchDataDefer)
      });
    } else if (!isEmployeeWithDefaultSchema && flexTime > 0) {
      if (flexTime - possibleMertid < 30) {
        Modal.info({
          className: 'ant-modal-hide-footer',
          centered: true,
          title: 'Checkout',
          content: (
            <>
              <Row>
                <span>
                  Congrats! Your worked <strong>{TimeTrackerModel.durationToText(flexTime)}</strong> extra today.
                </span>
              </Row>
              <Row>
                <Button
                  block
                  icon={<PlusCircleOutlined />}
                  onClick={handleModalChoice(refetchDataDefer, {
                    type: TYPE.CHECKOUT,
                    workType: WORKTYPE.FLEX_TIME
                  })}
                >
                  Add to my flex-time
                </Button>
                <Button
                  block
                  icon={<SaveOutlined />}
                  onClick={handleModalChoice(refetchDataDefer, {
                    type: TYPE.CHECKOUT,
                    workType: WORKTYPE.MERTID
                  })}
                >
                  Add mertid
                </Button>
                <Button
                  block
                  icon={<PlayCircleTwoTone twoToneColor={COLOR.GREEN} />}
                  onClick={handleModalChoice(refetchDataDefer)}
                >
                  Continue work instead
                </Button>
              </Row>
            </>
          ),
          maskClosable: true,
          destroyOnClose: true,
          onCancel: handleModalChoice(refetchDataDefer)
        });
      } else {
        Modal.info({
          className: 'ant-modal-hide-footer',
          centered: true,
          title: 'Checkout',
          width: 450,
          content: (
            <>
              <Row>
                <span>
                  Congrats! Your worked <strong>{TimeTrackerModel.durationToText(flexTime)}</strong> extra today.
                </span>
              </Row>
              <Row>
                <Button
                  block
                  icon={<PlusCircleOutlined />}
                  onClick={handleModalChoice(refetchDataDefer, {
                    type: TYPE.CHECKOUT,
                    workType: WORKTYPE.FLEX_TIME
                  })}
                >
                  Add to my flex-time
                </Button>
              </Row>
              <Divider dashed>or</Divider>
              <Row>
                <span>
                  Add <strong>{TimeTrackerModel.durationToText(possibleMertid)}</strong> as mertid and the rest{' '}
                  <strong>{TimeTrackerModel.durationToText(flexTime - possibleMertid)}</strong> as:
                </span>
              </Row>
              <Row>
                <Button
                  block
                  icon={<PlusCircleOutlined />}
                  onClick={handleModalChoice(refetchDataDefer, {
                    type: TYPE.CHECKOUT,
                    addMertid: true,
                    workType: WORKTYPE.FLEX_TIME
                  })}
                >
                  Flex-time
                </Button>
                <Button
                  block
                  icon={<SaveOutlined />}
                  onClick={handleModalChoice(refetchDataDefer, {
                    type: TYPE.CHECKOUT,
                    addMertid: true,
                    workType: WORKTYPE.COMPENSATION_TIME
                  })}
                >
                  Compensation time
                </Button>
                <Button
                  block
                  icon={<WalletOutlined />}
                  onClick={handleModalChoice(refetchDataDefer, {
                    type: TYPE.CHECKOUT,
                    addMertid: true,
                    workType: WORKTYPE.PAID_OVERTIME
                  })}
                >
                  Paid overtime
                </Button>
                <Button
                  block
                  icon={<PlayCircleTwoTone twoToneColor={COLOR.GREEN} />}
                  onClick={handleModalChoice(refetchDataDefer)}
                >
                  Continue work instead
                </Button>
              </Row>
            </>
          ),
          maskClosable: true,
          destroyOnClose: true,
          onCancel: handleModalChoice(refetchDataDefer)
        });
      }
    } else {
      const result = await checkOut({
        type: TYPE.CHECKOUT,
        workType: flexTime > 0 ? WORKTYPE.FLEX_TIME : WORKTYPE.REGULAR
      });

      refetchDataDefer.resolve(result);
    }

    const refetchData = await refetchDataDefer;

    if (refresh && refetchData) {
      await refresh();
    }

    toggleLoading();
  };

  const toggleCheckStatusAfterFetchInfoNo = async (data, params) => {
    const [prevInfo, refresh] = params;

    const { checkedInAt, flexTime, isRegularDay } = data;
    const checkedIn = TimeTrackerModel.checkCheckedIn(checkedInAt);

    if (!prevInfo || checkedIn !== TimeTrackerModel.checkCheckedIn(prevInfo.checkedInAt)) {
      return;
    }

    toggleLoading();

    const refetchDataDefer = defer();

    if (!checkedIn) {
      const result = await checkIn();
      refetchDataDefer.resolve(result);
    } else if (!isRegularDay || flexTime >= 30) {
      Modal.info({
        className: 'ant-modal-hide-footer',
        centered: true,
        title: 'Checkout',
        content: (
          <>
            <Row>
              <span>
                You stayed at work <strong>{TimeTrackerModel.durationToText(flexTime)}</strong> extra today.
              </span>
            </Row>
            <Row>
              <Button
                block
                icon={<PlusCircleOutlined />}
                onClick={handleModalChoice(refetchDataDefer, {
                  type: TYPE.CHECKOUT,
                  workType: WORKTYPE.FLEX_TIME
                })}
              >
                Ok
              </Button>
              <Button
                block
                icon={<WalletOutlined />}
                onClick={handleModalChoice(refetchDataDefer, {
                  type: TYPE.CHECKOUT,
                  workType: WORKTYPE.PAID_OVERTIME
                })}
              >
                Request paid overtime
              </Button>
              <Button
                block
                icon={<PlayCircleTwoTone twoToneColor={COLOR.GREEN} />}
                onClick={handleModalChoice(refetchDataDefer)}
              >
                Continue work instead
              </Button>
            </Row>
          </>
        ),
        maskClosable: true,
        destroyOnClose: true,
        onCancel: handleModalChoice(refetchDataDefer)
      });
    } else {
      const result = await checkOut({
        type: TYPE.CHECKOUT,
        workType: flexTime > 0 ? WORKTYPE.FLEX_TIME : WORKTYPE.REGULAR
      });

      refetchDataDefer.resolve(result);
    }

    const refetchData = await refetchDataDefer;

    if (refresh && refetchData) {
      await refresh();
    }

    toggleLoading();
  };

  const info = useRequest(
    () => TimeTrackerModel.getEmployeeInfo(timeTrackerUser, customTimestamp?.clone().utc().format()),
    {
      throwOnError: true,
      onSuccess: user.country === 'SE' ? toggleCheckStatusAfterFetchInfoSe : toggleCheckStatusAfterFetchInfoNo
    }
  );

  const fetchInfoOnMouseEnter = () => info.run();

  if (!info?.data || (timeTrackerUser.flexTimeBypassed && !isCheckInOutTester)) {
    return null;
  }

  const isOvernight = TimeTrackerModel.checkOvernight(info.data.checkedInAt);

  if (isOvernight && !isCheckInOutTester) {
    return [
      {
        key: 'timetracker-widget',
        icon: <WarningFilled style={{ color: COLOR.RED }} />,
        children: <span style={{ color: COLOR.RED }}>Ask manager</span>
      }
    ];
  }

  const isCheckedIn = TimeTrackerModel.checkCheckedIn(info.data.checkedInAt);
  const loading = info?.loading || isLoading;
  const customTimestampSubmenu = isCheckInOutTester
    ? {
        disabled: loading,
        key: 'timetracker-widget-customTimestamp',
        children: (
          <DatePicker
            allowClear={false}
            showTime
            onChange={handleCustomTimestampSet}
            onOk={handleCustomTimestampSet}
            defaultValue={customTimestamp || moment()}
          />
        )
      }
    : null;

  const popOverContent = loading ? (
    <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
  ) : (
    <div>
      <b>
        {!info?.data?.isRegularDay || !info?.data?.schema
          ? "Work isn't scheduled"
          : `${TimeTrackerModel.durationToText(info.data.ratedMinutes)} worked today${
              info.data.flexTime < 0 ? `, ${TimeTrackerModel.durationToText(-info.data.flexTime)} left` : ''
            }`}
      </b>
      {isCheckedIn && (
        <>
          <br />
          <span>
            Last check-in: <CheckInInline value={info.data.checkedInAt} />
          </span>
        </>
      )}
    </div>
  );
  const loadingIcon = isCheckedIn ? (
    <SyncOutlined spin style={{ color: COLOR.VOLCANO }} />
  ) : (
    <SyncOutlined spin style={{ color: COLOR.GREEN }} />
  );
  const regularIcon = isCheckedIn ? (
    <CloseCircleTwoTone twoToneColor={COLOR.VOLCANO} />
  ) : (
    <PlayCircleTwoTone twoToneColor={COLOR.GREEN} />
  );

  return [
    customTimestampSubmenu,
    {
      disabled: loading,
      key: 'timetracker-widget-stop',
      icon: loading ? loadingIcon : regularIcon,
      children: (
        <Popover content={popOverContent} trigger="hover" placement="right" onMouseEnter={fetchInfoOnMouseEnter}>
          <span style={{ color: isCheckedIn ? COLOR.VOLCANO : COLOR.GREEN, display: 'block' }}>{`Check ${
            isCheckedIn ? 'out' : 'in'
          }`}</span>
        </Popover>
      ),
      onClick: () => info.run(info.data, info.refresh)
    }
  ].filter(Boolean);
};

export { TimeTrackerMenuWidget };
