import { PageHeader } from '@ant-design/pro-layout';
import { Tabs } from 'antd';
import { useContext } from 'react';

import { Block } from '@/components/Block';
import { PERMISSION } from '@/configs/general';
import { userContext } from '@/helpers/userContext';

import { Departments } from './Departments';
import { Printers } from './Printers';
import { Teams } from './Teams';
import { Users } from './Users';

const SettingsPage = () => {
  const { hasPermission } = useContext(userContext);

  const tabPanes = [
    {
      permission: PERMISSION.EDIT_USERS,
      key: 'users',
      label: 'Users',
      children: (
        <Block>
          <Users canEditPermissions={hasPermission(PERMISSION.AUTH_TOOL_SUPERVISOR)} />
        </Block>
      )
    },
    {
      permission: PERMISSION.EDIT_DEPARTMENTS,
      key: 'departments',
      label: 'Departments',
      children: (
        <Block>
          <Departments />
        </Block>
      )
    },
    {
      permission: PERMISSION.EDIT_PRINTERS,
      key: 'printers',
      label: 'Printers',
      children: (
        <Block>
          <Printers />
        </Block>
      )
    },
    {
      permission: PERMISSION.EDIT_TEAMS,
      key: 'teams',
      label: 'Teams',
      children: (
        <Block>
          <Teams />
        </Block>
      )
    }
  ].filter((tabPane) => hasPermission(tabPane.permission));

  return (
    <>
      <PageHeader title="Settings" />
      <Tabs className="tabs-with-block settings-tabs" defaultActiveKey="users" type="card" items={tabPanes} />
    </>
  );
};

export { SettingsPage };
